import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  TextField,
  Modal,
  Box,
  Typography,
  IconButton,
  Button,
  MenuItem,
} from "@mui/material";
import TextFieldCom from "./../../components/Form/TextFieldCom";
import OtpCom from "./../../components/Form/OtpCom";
import ButtonCom from "./../../components/ButtonCom";
import {
  Visibility,
  VisibilityOff,
  ErrorRounded,
  CheckCircleRounded,
  RestoreFromTrashRounded,
} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { login, emailCheck, getMenuAccessed } from "./clients/login/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../core/axios_config";
import { useNavigate } from "react-router-dom";
import { verifyOTP } from "./clients/verif-otp/actions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: 4,
  bgcolor: "background.paper",
  border: "none",
  // boxShadow: 24,
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showModalForgotPassword, setShowModalForgotPassword] = useState(false);
  const [showModalInputOtp, setShowModalInputOtp] = useState(false);
  const [confirmOtpIsLoading, setconfirmOtpIsLoading] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [otpIsWrong, setotpIsWrong] = useState(false);
  const [msgOtp, setMsgOtp] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginIsLoading, setloginIsLoading] = useState(false);
  const [resendOtpIsLoading, setResendOtpIsLoading] = useState(false);
  const [msgCheckEmail, setMsgCheckEmail] = useState("");
  const [statusCheckEmail, setStatusCheckEmail] = useState("");
  const [msgPassword, setMsgPassword] = useState("");
  const [statusPassword, setStatusPassword] = useState("");
  const [user, setUser] = useState({});

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleModalForgotPassword = () => {
    setShowModalForgotPassword(!showModalForgotPassword);
  };

  const handleModalInputOtp = () => {
    setShowModalInputOtp(!showModalInputOtp);
  };

  // NEW
  const handleOtpChange = async (otp) => {
    console.log("masuk handle otp change");
    setconfirmOtpIsLoading(true);

    try {
      // const response = await dispatch(
      //   verifyOTP(user.id, otp.join("").replace(",", ""))
      // );
      const response = await dispatch(
        verifyOTP(user.id, otp.join("").replace(",", ""))
      );

      if (response.status == true) {
        // localStorage.setItem("t", response.data.token); // Save the token to local storage
        if (response.data.user.is_new === true) {
          navigate("/create-new-password");
        } else {
          setMsgOtp("Loading to redirect dashboard ...");
          // const response1 = await dispatch(getMenuAccessed(response.data.token));
          navigate("/");
        }
      } else {
        setotpIsWrong(true);
      }
      setOtpValues(["", "", "", "", "", ""]);
      setconfirmOtpIsLoading(false);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      setconfirmOtpIsLoading(false);
    }
  };

  /*
  OLD
  const handleOtpChange = async (otp) => {
    console.log("masuk handle otp change");
    setconfirmOtpIsLoading(true);

    await axios
      .post("/verif-otp/" + user.id, { otp: otp.join("").replace(",", "") })
      .then((response) => {
        const a = response.data.data;
        debugger;
        if (response.data.data.otp_verified == true) {
          localStorage.setItem("t", response.data.data.token);
          if (user.is_new == true) {
            navigate("/create-new-password");
          } else {
            window.location.href = "/";
            // navigate('/');
          }
        } else {
          toast.error("Otp not valid");
        }

        setOtpValues(["", "", "", "", "", ""]);
        setconfirmOtpIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setconfirmOtpIsLoading(false);
      });
  };*/

  const handleEmailChange = (e) => {
    clearTimeout(typingTimeout);

    const newEmail = e.target.value;
    setEmail(newEmail);

    // nambah delay, biar ga ngecheck terus setiap input
    const newTypingTimeout = setTimeout(() => {
      handleCheckEmailAvailability(newEmail);
    }, 3000);

    setTypingTimeout(newTypingTimeout);
  };

  const handleCheckEmailAvailability = async (newEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(newEmail);
    if (isValid) {
      const response = await dispatch(emailCheck(newEmail));
      if (response.data.registered_user) {
        setStatusCheckEmail("success");
        setMsgCheckEmail("Email registered");
      } else {
        setStatusCheckEmail("error");
        setMsgCheckEmail("Email not registered");
      }
    }
  };

  const handleLogin = async () => {
    setloginIsLoading(true);
    await axios
      .post("/login", { email, password })
      .then((response) => {
        console.log("response.response");
        console.log(response.response);
        console.log("response.response");
        if (response.status === 200) {
          setUser(response.data.data);
          setShowModalInputOtp(true);
          setStatusCheckEmail("");
          setMsgCheckEmail("");
          setStatusPassword("");
          setMsgPassword("");
        }
        setloginIsLoading(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          if (error.response.data.errors.email) {
            setStatusCheckEmail("error");
            setMsgCheckEmail(error.response.data.errors.email);
          }
          if (error.response.data.errors.password) {
            setStatusPassword("error");
            setMsgPassword(error.response.data.errors.password);
          }
        }
        setloginIsLoading(false);
        // toast.error(error.response.data.message);
      });
  };

  const handleResendOtp = async () => {
    setResendOtpIsLoading(true);
    await axios
      .post("/login", { email, password })
      .then((response) => {
        if (response.status === 200) {
          setUser(response.data.data);
          toast.success("Sucessfully send new otp");
        }
        setResendOtpIsLoading(false);
      })
      .catch((error) => {
        toast.error("Failed send new otp");
        setResendOtpIsLoading(false);
      });
  };

  const toContactAdmin = () => {
    window.open("https://wa.me/6285922450670", "_blank");
  };

  return (
    <Grid container spacing={2} sx={{ pt: 4, pl: 4, pr: 4, height: "100vh" }}>
      <ToastContainer />
      <Grid
        item
        xs={6}
        sx={{
          bgcolor: "primary.secondary1",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img style={{ width: "80%" }} src="assets/auth/login.svg" alt="Login" />
      </Grid>
      <Grid item xs={6} sx={{ m: "auto" }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src="logo.png" alt="Logo" width="40%" />
        </Box>
        <Box sx={{ mx: 12 }}>
          <TextFieldCom
            label="Email"
            hint="Enter your email address"
            value={email}
            message={msgCheckEmail}
            status={statusCheckEmail}
            suffixIcon={
              statusCheckEmail == "error" ? (
                <ErrorRounded />
              ) : statusCheckEmail == "success" ? (
                <CheckCircleRounded />
              ) : undefined
            }
            onChange={(e) => handleEmailChange(e)}
            id="email"
          ></TextFieldCom>
          <TextFieldCom
            label="Password"
            hint="Enter your password"
            onChange={(e) => {
              setPassword(e.target.value);
              setMsgPassword("");
              setStatusPassword("");
            }}
            value={password}
            type={showPassword ? "text" : "password"}
            message={msgPassword}
            status={statusPassword}
            suffixIcon={
              showPassword ? (
                <VisibilityOff sx={{ cursor: "pointer" }} />
              ) : (
                <Visibility sx={{ cursor: "pointer" }} />
              )
            }
            onSuffixIconClick={handleTogglePasswordVisibility}
            id="password"
          ></TextFieldCom>
          <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "primary.main",
                cursor: "pointer",
              }}
              onClick={handleModalForgotPassword}
            >
              Forgot Password?
            </Typography>
          </Box>
          <ButtonCom
            sx={{ mt: 2 }}
            textButton="Login"
            onClick={handleLogin}
            isDisable={loginIsLoading}
            // isLoading={loginIsLoading}
          ></ButtonCom>
        </Box>
      </Grid>

      {/* Modal forgot password */}
      <Modal
        open={showModalForgotPassword}
        onClose={handleModalForgotPassword}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            direction="column"
            alignItems="center"
            display="flex"
            sx={{ p: 4 }}
          >
            <Grid item>
              <img src="assets/auth/ask.svg" width="150px" alt="" />
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: "bold" }}>
                You forgot the password?
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontWeight: "light", opacity: 0.5, fontStyle: "italic" }}
              >
                Click the button below, to contact the admin
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: 72,
              bgcolor: "grey.50",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <ButtonCom
              width="30%"
              textButton="Contact Admin"
              sx={{ mt: 2 }}
              onClick={toContactAdmin}
            ></ButtonCom>
          </Box>
        </Box>
      </Modal>

      {/* Modal input otp */}
      <Modal
        open={showModalInputOtp}
        onClose={handleModalInputOtp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            direction="column"
            alignItems="center"
            display="flex"
            sx={{ p: 4 }}
          >
            <Grid item>
              <img src="assets/auth/email-notif.svg" width="150px" alt="" />
            </Grid>
            <Grid item>
              <OtpCom
                otpValues={otpValues}
                onOtpChange={handleOtpChange}
                isDisabled={confirmOtpIsLoading}
                isWrong={otpIsWrong}
                msg={msgOtp}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              height: 72,
              bgcolor: "grey.50",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              Didn't receive the verification code?{" "}
              <Box
                sx={{
                  color: resendOtpIsLoading ? "grey.50" : "primary.main",
                  display: "inline",
                  cursor: "pointer",
                }}
                onClick={handleResendOtp}
              >
                {" "}
                Resend Code
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

export default Login;
