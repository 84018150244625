//* Get
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_FAILED = "GET_INVOICE_FAILED";

//* POST
export const ADD_INVOICE_SUCCESS = "ADD_INVOICE_SUCCESS";
export const ADD_INVOICE_FAILED = "ADD_INVOICE_FAILED";

//* PUT
export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS";
export const EDIT_INVOICE_FAILED = "EDIT_INVOICE_FAILED";

//* DELETE
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILED = "DELETE_INVOICE_FAILED";

//* GET PAYLOAD
export const GET_PAYLOAD_FORM_SUCCESS = "GET_PAYLOAD_ADD_FORM";
export const GET_PAYLOAD_FORM_FAILED = "GET_PAYLOAD_FORM_FAILED";

//* DETAIL
export const DETAIL_INVOICE_SUCCESS = "DETAIL_INVOICE_SUCCESS";
export const DETAIL_INVOICE_FAILED = "DETAIL_INVOICE_FAILED";

//* SET ID FOR EDIT
export const SET_EDIT_ID = "SET_EDIT_ID_INVOICE";

//* OPTIONS
export const SET_KEY_OPTION = "SET KEY OPTIONS INVOICE";
export const CLEAR_KEY_OPTION = "CLEAR KEY OPTIONS INVOICE";
