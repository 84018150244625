import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
} from "../../../util/style";
import axios from "../../../core/axios_config";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
} from "../../../components/Form";
import { addApplicant, deleteApplicant, editApplicant, getApplicant } from "./clients/actions";
import {
  ACTION_TYPE,
  APPLICANT_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import LeftDrawer from "../../../components/LeftDrawer";
import { TransitionAlerts } from "../../../components/Alert";

const Applicant = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setApplicantDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add Applicant");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [ setType] = useState(ACTION_TYPE.ALL_TYPE);
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(false);
  const dispatch = useDispatch();

  const columns = [
    {
      field: "code",
      headerName: "Code",
      width: 160,
      disableColumnMenu: true,
    },
    {
      field: "client_name",
      headerName: "Client Name",
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Applicant Name",
      width: 260,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ color: "blue" }}>
          <a
            // onClick={() => navigate(`detail/${params.row.id}`)}
            onClick={() => handleFormModal(ACTION_TYPE.DETAIL, params.row)}
            style={{ textDecoration: "none", cursor: "grab" }}
          >
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      width: 200,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => (
        <Box sx={{ '& > img': { mr: 1, flexShrink: 0 }, '& > span': { color: '#555' } }}>
          <img
            loading="lazy"
            width="20"
            // srcSet={`https://flagcdn.com/w40/${params.value.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${params.value.code.toLowerCase()}.png`}
            alt=""
          />
          {params.value.name} <span>({params.value.code})</span>
        </Box>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      width: 400,
      disableColumnMenu: true,
      renderCell: (params) => {
        const text = params.value || '';
        return text.length > 52 ? text.slice(0, 49) + '...' : text;
      },
    },
    // {
    //   field: "approval",
    //   headerName: "Approval",
    //   width:180,
    //   disableColumnMenu: true,
    //   sortable: true,
    //   valueGetter: (params) => {
    //     return params.row.approval ? params.row.approval?.fullname : "-";
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 180,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => (
        <StatusBadge status={params.value}> {params.value} </StatusBadge>
      ),
    },
  ];

  const fields = [
    {
      name: "client_id",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      label: "Client Name",
      // apiEndpoint: "clients?&imit=99999999",
      apiEndpoint: "master/clients",
      required: true,
    },
    {
      name: "name",
      type: INPUT_TYPE.TEXT,
      label: "Applicant Name",
      apiEndpoint: "master/clients",
      defaultValue: "",
      required: true,
    },
    {
      name: "country_id",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY,
      label: "Country",
      apiEndpoint: "/master/countries",
      defaultValue: "",
      required: true,
    },
    {
      name: "address",
      type: INPUT_TYPE.TEXTAREA,
      label: "Address",
      defaultValue: "",
      required: true,
    },
    {
      name: "email",
      type: INPUT_TYPE.TEXT,
      label: "Email",
      placeholder: "Input email ( Ops )",
      defaultValue: "",
      required: false,
      validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      errorMessage: "Invalid email address",
    },
    {
      name: "phone",
      type: INPUT_TYPE.NUMBER,
      label: "Phone",
      placeholder: "Input phone ( Ops )",
      defaultValue: "",
      required: false,
    },
  ];

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.applicants);

  // const rows = [

  //   {
  //     "id": 1,
  //     "code": "Code 1",
  //     "client_name": "PT. Telkom Indonesia ",
  //     "name": "Marco",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 2,
  //     "code": "Code 2",
  //     "client_name": "PT. Unilever Tbk",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 3,
  //     "code": "Code 3",
  //     "client_name": "PT. Kompas Tbk",
  //     "name": "Faris",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 4,
  //     "code": "Code 4",
  //     "client_name": "PT. Siantar Top Tbk",
  //     "name": "Zaid",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 5,
  //     "code": "Code 5",
  //     "client_name": "PT. Wilmar Tbk",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 6,
  //     "code": "Anauco",
  //     "client_name": "PT. Gudang Garam Tbk",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 7,
  //     "code": "Code 7",
  //     "client_name": "Applicant Name 7",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 8,
  //     "code": "Code 8",
  //     "client_name": "Applicant Name 8",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 9,
  //     "code": "Code 9",
  //     "client_name": "Applicant Name 9",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 10,
  //     "code": "Code 10",
  //     "client_name": "Applicant Name 10",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 11,
  //     "code": "Code 11",
  //     "client_name": "Applicant Name 11",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 12,
  //     "code": "Code 12",
  //     "client_name": "Applicant Name 12",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 13,
  //     "code": "Code 13",
  //     "client_name": "Applicant Name 13",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 14,
  //     "code": "Code 14",
  //     "client_name": "Applicant Name 14",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 15,
  //     "code": "Code 15",
  //     "client_name": "Applicant Name 15",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 16,
  //     "code": "Code 16",
  //     "client_name": "Applicant Name 16",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 17,
  //     "code": "Code 17",
  //     "client_name": "Applicant Name 17",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 18,
  //     "code": "Code 18",
  //     "client_name": "Applicant Name 18",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   },
  //   {
  //     "id": 19,
  //     "code": "Code 19",
  //     "client_name": "Applicant Name 19",
  //     "name": "-",
  //     "country": { "code": "ID", "name": "Indonesia" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "active"
  //   },
  //   {
  //     "id": 20,
  //     "code": "Code 20",
  //     "client_name": "Applicant Name 20",
  //     "name": "-",
  //     "country": { "code": "SA", "name": "Arab Saudi" },
  //     "address": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel nisl odio. Cras vestibulum dictum tortor tempor tempus. ", "status": "inactive"
  //   }
  // ];

  // const countries = [
  //   { code: 'AD', label: 'Andorra', phone: '376' },
  //   {
  //     code: 'AE',
  //     label: 'United Arab Emirates',
  //     phone: '971',
  //   },
  //   { code: 'AF', label: 'Afghanistan', phone: '93' },
  //   {
  //     code: 'AG',
  //     label: 'Antigua and Barbuda',
  //     phone: '1-268',
  //   },
  //   { code: 'AI', label: 'Anguilla', phone: '1-264' },
  //   { code: 'AL', label: 'Albania', phone: '355' },
  //   { code: 'AM', label: 'Armenia', phone: '374' },
  //   { code: 'AO', label: 'Angola', phone: '244' },
  //   { code: 'AQ', label: 'Antarctica', phone: '672' },
  //   { code: 'AR', label: 'Argentina', phone: '54' },
  //   { code: 'AS', label: 'American Samoa', phone: '1-684' },
  //   { code: 'AT', label: 'Austria', phone: '43' },
  //   {
  //     code: 'AU',
  //     label: 'Australia',
  //     phone: '61',
  //     suggested: true,
  //   },
  //   { code: 'AW', label: 'Aruba', phone: '297' },
  //   { code: 'AX', label: 'Alland Islands', phone: '358' },
  //   { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  //   {
  //     code: 'BA',
  //     label: 'Bosnia and Herzegovina',
  //     phone: '387',
  //   },
  //   { code: 'BB', label: 'Barbados', phone: '1-246' },
  //   { code: 'BD', label: 'Bangladesh', phone: '880' },
  //   { code: 'BE', label: 'Belgium', phone: '32' },
  //   { code: 'BF', label: 'Burkina Faso', phone: '226' },
  //   { code: 'BG', label: 'Bulgaria', phone: '359' },
  //   { code: 'BH', label: 'Bahrain', phone: '973' },
  //   { code: 'BI', label: 'Burundi', phone: '257' },
  //   { code: 'BJ', label: 'Benin', phone: '229' },
  //   { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  //   { code: 'BM', label: 'Bermuda', phone: '1-441' },
  //   { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  //   { code: 'BO', label: 'Bolivia', phone: '591' },
  //   { code: 'BR', label: 'Brazil', phone: '55' },
  //   { code: 'BS', label: 'Bahamas', phone: '1-242' },
  //   { code: 'BT', label: 'Bhutan', phone: '975' },
  //   { code: 'BV', label: 'Bouvet Island', phone: '47' },
  //   { code: 'BW', label: 'Botswana', phone: '267' },
  //   { code: 'BY', label: 'Belarus', phone: '375' },
  //   { code: 'BZ', label: 'Belize', phone: '501' },
  //   {
  //     code: 'CA',
  //     label: 'Canada',
  //     phone: '1',
  //     suggested: true,
  //   },
  //   {
  //     code: 'CC',
  //     label: 'Cocos (Keeling) Islands',
  //     phone: '61',
  //   },
  //   {
  //     code: 'CD',
  //     label: 'Congo, Democratic Republic of the',
  //     phone: '243',
  //   },
  //   {
  //     code: 'CF',
  //     label: 'Central African Republic',
  //     phone: '236',
  //   },
  //   {
  //     code: 'CG',
  //     label: 'Congo, Republic of the',
  //     phone: '242',
  //   },
  //   { code: 'CH', label: 'Switzerland', phone: '41' },
  //   { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  //   { code: 'CK', label: 'Cook Islands', phone: '682' },
  //   { code: 'CL', label: 'Chile', phone: '56' },
  //   { code: 'CM', label: 'Cameroon', phone: '237' },
  //   { code: 'CN', label: 'China', phone: '86' },
  //   { code: 'CO', label: 'Colombia', phone: '57' },
  //   { code: 'CR', label: 'Costa Rica', phone: '506' },
  //   { code: 'CU', label: 'Cuba', phone: '53' },
  //   { code: 'CV', label: 'Cape Verde', phone: '238' },
  //   { code: 'CW', label: 'Curacao', phone: '599' },
  //   { code: 'CX', label: 'Christmas Island', phone: '61' },
  //   { code: 'CY', label: 'Cyprus', phone: '357' },
  //   { code: 'CZ', label: 'Czech Republic', phone: '420' },
  //   {
  //     code: 'DE',
  //     label: 'Germany',
  //     phone: '49',
  //     suggested: true,
  //   },
  //   { code: 'DJ', label: 'Djibouti', phone: '253' },
  //   { code: 'DK', label: 'Denmark', phone: '45' },
  //   { code: 'DM', label: 'Dominica', phone: '1-767' },
  //   {
  //     code: 'DO',
  //     label: 'Dominican Republic',
  //     phone: '1-809',
  //   },
  //   { code: 'DZ', label: 'Algeria', phone: '213' },
  //   { code: 'EC', label: 'Ecuador', phone: '593' },
  //   { code: 'EE', label: 'Estonia', phone: '372' },
  //   { code: 'EG', label: 'Egypt', phone: '20' },
  //   { code: 'EH', label: 'Western Sahara', phone: '212' },
  //   { code: 'ER', label: 'Eritrea', phone: '291' },
  //   { code: 'ES', label: 'Spain', phone: '34' },
  //   { code: 'ET', label: 'Ethiopia', phone: '251' },
  //   { code: 'FI', label: 'Finland', phone: '358' },
  //   { code: 'FJ', label: 'Fiji', phone: '679' },
  //   {
  //     code: 'FK',
  //     label: 'Falkland Islands (Malvinas)',
  //     phone: '500',
  //   },
  //   {
  //     code: 'FM',
  //     label: 'Micronesia, Federated States of',
  //     phone: '691',
  //   },
  //   { code: 'FO', label: 'Faroe Islands', phone: '298' },
  //   {
  //     code: 'FR',
  //     label: 'France',
  //     phone: '33',
  //     suggested: true,
  //   },
  //   { code: 'GA', label: 'Gabon', phone: '241' },
  //   { code: 'GB', label: 'United Kingdom', phone: '44' },
  //   { code: 'GD', label: 'Grenada', phone: '1-473' },
  //   { code: 'GE', label: 'Georgia', phone: '995' },
  //   { code: 'GF', label: 'French Guiana', phone: '594' },
  //   { code: 'GG', label: 'Guernsey', phone: '44' },
  //   { code: 'GH', label: 'Ghana', phone: '233' },
  //   { code: 'GI', label: 'Gibraltar', phone: '350' },
  //   { code: 'GL', label: 'Greenland', phone: '299' },
  //   { code: 'GM', label: 'Gambia', phone: '220' },
  //   { code: 'GN', label: 'Guinea', phone: '224' },
  //   { code: 'GP', label: 'Guadeloupe', phone: '590' },
  //   { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  //   { code: 'GR', label: 'Greece', phone: '30' },
  //   {
  //     code: 'GS',
  //     label: 'South Georgia and the South Sandwich Islands',
  //     phone: '500',
  //   },
  //   { code: 'GT', label: 'Guatemala', phone: '502' },
  //   { code: 'GU', label: 'Guam', phone: '1-671' },
  //   { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  //   { code: 'GY', label: 'Guyana', phone: '592' },
  //   { code: 'HK', label: 'Hong Kong', phone: '852' },
  //   {
  //     code: 'HM',
  //     label: 'Heard Island and McDonald Islands',
  //     phone: '672',
  //   },
  //   { code: 'HN', label: 'Honduras', phone: '504' },
  //   { code: 'HR', label: 'Croatia', phone: '385' },
  //   { code: 'HT', label: 'Haiti', phone: '509' },
  //   { code: 'HU', label: 'Hungary', phone: '36' },
  //   { code: 'ID', label: 'Indonesia', phone: '62' },
  //   { code: 'IE', label: 'Ireland', phone: '353' },
  //   { code: 'IL', label: 'Israel', phone: '972' },
  //   { code: 'IM', label: 'Isle of Man', phone: '44' },
  //   { code: 'IN', label: 'India', phone: '91' },
  //   {
  //     code: 'IO',
  //     label: 'British Indian Ocean Territory',
  //     phone: '246',
  //   },
  //   { code: 'IQ', label: 'Iraq', phone: '964' },
  //   {
  //     code: 'IR',
  //     label: 'Iran, Islamic Republic of',
  //     phone: '98',
  //   },
  //   { code: 'IS', label: 'Iceland', phone: '354' },
  //   { code: 'IT', label: 'Italy', phone: '39' },
  //   { code: 'JE', label: 'Jersey', phone: '44' },
  //   { code: 'JM', label: 'Jamaica', phone: '1-876' },
  //   { code: 'JO', label: 'Jordan', phone: '962' },
  //   {
  //     code: 'JP',
  //     label: 'Japan',
  //     phone: '81',
  //     suggested: true,
  //   },
  //   { code: 'KE', label: 'Kenya', phone: '254' },
  //   { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  //   { code: 'KH', label: 'Cambodia', phone: '855' },
  //   { code: 'KI', label: 'Kiribati', phone: '686' },
  //   { code: 'KM', label: 'Comoros', phone: '269' },
  //   {
  //     code: 'KN',
  //     label: 'Saint Kitts and Nevis',
  //     phone: '1-869',
  //   },
  //   {
  //     code: 'KP',
  //     label: "Korea, Democratic People's Republic of",
  //     phone: '850',
  //   },
  //   { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  //   { code: 'KW', label: 'Kuwait', phone: '965' },
  //   { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  //   { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  //   {
  //     code: 'LA',
  //     label: "Lao People's Democratic Republic",
  //     phone: '856',
  //   },
  //   { code: 'LB', label: 'Lebanon', phone: '961' },
  //   { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  //   { code: 'LI', label: 'Liechtenstein', phone: '423' },
  //   { code: 'LK', label: 'Sri Lanka', phone: '94' },
  //   { code: 'LR', label: 'Liberia', phone: '231' },
  //   { code: 'LS', label: 'Lesotho', phone: '266' },
  //   { code: 'LT', label: 'Lithuania', phone: '370' },
  //   { code: 'LU', label: 'Luxembourg', phone: '352' },
  //   { code: 'LV', label: 'Latvia', phone: '371' },
  //   { code: 'LY', label: 'Libya', phone: '218' },
  //   { code: 'MA', label: 'Morocco', phone: '212' },
  //   { code: 'MC', label: 'Monaco', phone: '377' },
  //   {
  //     code: 'MD',
  //     label: 'Moldova, Republic of',
  //     phone: '373',
  //   },
  //   { code: 'ME', label: 'Montenegro', phone: '382' },
  //   {
  //     code: 'MF',
  //     label: 'Saint Martin (French part)',
  //     phone: '590',
  //   },
  //   { code: 'MG', label: 'Madagascar', phone: '261' },
  //   { code: 'MH', label: 'Marshall Islands', phone: '692' },
  //   {
  //     code: 'MK',
  //     label: 'Macedonia, the Former Yugoslav Republic of',
  //     phone: '389',
  //   },
  //   { code: 'ML', label: 'Mali', phone: '223' },
  //   { code: 'MM', label: 'Myanmar', phone: '95' },
  //   { code: 'MN', label: 'Mongolia', phone: '976' },
  //   { code: 'MO', label: 'Macao', phone: '853' },
  //   {
  //     code: 'MP',
  //     label: 'Northern Mariana Islands',
  //     phone: '1-670',
  //   },
  //   { code: 'MQ', label: 'Martinique', phone: '596' },
  //   { code: 'MR', label: 'Mauritania', phone: '222' },
  //   { code: 'MS', label: 'Montserrat', phone: '1-664' },
  //   { code: 'MT', label: 'Malta', phone: '356' },
  //   { code: 'MU', label: 'Mauritius', phone: '230' },
  //   { code: 'MV', label: 'Maldives', phone: '960' },
  //   { code: 'MW', label: 'Malawi', phone: '265' },
  //   { code: 'MX', label: 'Mexico', phone: '52' },
  //   { code: 'MY', label: 'Malaysia', phone: '60' },
  //   { code: 'MZ', label: 'Mozambique', phone: '258' },
  //   { code: 'NA', label: 'Namibia', phone: '264' },
  //   { code: 'NC', label: 'New Caledonia', phone: '687' },
  //   { code: 'NE', label: 'Niger', phone: '227' },
  //   { code: 'NF', label: 'Norfolk Island', phone: '672' },
  //   { code: 'NG', label: 'Nigeria', phone: '234' },
  //   { code: 'NI', label: 'Nicaragua', phone: '505' },
  //   { code: 'NL', label: 'Netherlands', phone: '31' },
  //   { code: 'NO', label: 'Norway', phone: '47' },
  //   { code: 'NP', label: 'Nepal', phone: '977' },
  //   { code: 'NR', label: 'Nauru', phone: '674' },
  //   { code: 'NU', label: 'Niue', phone: '683' },
  //   { code: 'NZ', label: 'New Zealand', phone: '64' },
  //   { code: 'OM', label: 'Oman', phone: '968' },
  //   { code: 'PA', label: 'Panama', phone: '507' },
  //   { code: 'PE', label: 'Peru', phone: '51' },
  //   { code: 'PF', label: 'French Polynesia', phone: '689' },
  //   { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  //   { code: 'PH', label: 'Philippines', phone: '63' },
  //   { code: 'PK', label: 'Pakistan', phone: '92' },
  //   { code: 'PL', label: 'Poland', phone: '48' },
  //   {
  //     code: 'PM',
  //     label: 'Saint Pierre and Miquelon',
  //     phone: '508',
  //   },
  //   { code: 'PN', label: 'Pitcairn', phone: '870' },
  //   { code: 'PR', label: 'Puerto Rico', phone: '1' },
  //   {
  //     code: 'PS',
  //     label: 'Palestine, State of',
  //     phone: '970',
  //   },
  //   { code: 'PT', label: 'Portugal', phone: '351' },
  //   { code: 'PW', label: 'Palau', phone: '680' },
  //   { code: 'PY', label: 'Paraguay', phone: '595' },
  //   { code: 'QA', label: 'Qatar', phone: '974' },
  //   { code: 'RE', label: 'Reunion', phone: '262' },
  //   { code: 'RO', label: 'Romania', phone: '40' },
  //   { code: 'RS', label: 'Serbia', phone: '381' },
  //   { code: 'RU', label: 'Russian Federation', phone: '7' },
  //   { code: 'RW', label: 'Rwanda', phone: '250' },
  //   { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  //   { code: 'SB', label: 'Solomon Islands', phone: '677' },
  //   { code: 'SC', label: 'Seychelles', phone: '248' },
  //   { code: 'SD', label: 'Sudan', phone: '249' },
  //   { code: 'SE', label: 'Sweden', phone: '46' },
  //   { code: 'SG', label: 'Singapore', phone: '65' },
  //   { code: 'SH', label: 'Saint Helena', phone: '290' },
  //   { code: 'SI', label: 'Slovenia', phone: '386' },
  //   {
  //     code: 'SJ',
  //     label: 'Svalbard and Jan Mayen',
  //     phone: '47',
  //   },
  //   { code: 'SK', label: 'Slovakia', phone: '421' },
  //   { code: 'SL', label: 'Sierra Leone', phone: '232' },
  //   { code: 'SM', label: 'San Marino', phone: '378' },
  //   { code: 'SN', label: 'Senegal', phone: '221' },
  //   { code: 'SO', label: 'Somalia', phone: '252' },
  //   { code: 'SR', label: 'Suriname', phone: '597' },
  //   { code: 'SS', label: 'South Sudan', phone: '211' },
  //   {
  //     code: 'ST',
  //     label: 'Sao Tome and Principe',
  //     phone: '239',
  //   },
  //   { code: 'SV', label: 'El Salvador', phone: '503' },
  //   {
  //     code: 'SX',
  //     label: 'Sint Maarten (Dutch part)',
  //     phone: '1-721',
  //   },
  //   {
  //     code: 'SY',
  //     label: 'Syrian Arab Republic',
  //     phone: '963',
  //   },
  //   { code: 'SZ', label: 'Swaziland', phone: '268' },
  //   {
  //     code: 'TC',
  //     label: 'Turks and Caicos Islands',
  //     phone: '1-649',
  //   },
  //   { code: 'TD', label: 'Chad', phone: '235' },
  //   {
  //     code: 'TF',
  //     label: 'French Southern Territories',
  //     phone: '262',
  //   },
  //   { code: 'TG', label: 'Togo', phone: '228' },
  //   { code: 'TH', label: 'Thailand', phone: '66' },
  //   { code: 'TJ', label: 'Tajikistan', phone: '992' },
  //   { code: 'TK', label: 'Tokelau', phone: '690' },
  //   { code: 'TL', label: 'Timor-Leste', phone: '670' },
  //   { code: 'TM', label: 'Turkmenistan', phone: '993' },
  //   { code: 'TN', label: 'Tunisia', phone: '216' },
  //   { code: 'TO', label: 'Tonga', phone: '676' },
  //   { code: 'TR', label: 'Turkey', phone: '90' },
  //   {
  //     code: 'TT',
  //     label: 'Trinidad and Tobago',
  //     phone: '1-868',
  //   },
  //   { code: 'TV', label: 'Tuvalu', phone: '688' },
  //   {
  //     code: 'TW',
  //     label: 'Taiwan',
  //     phone: '886',
  //   },
  //   {
  //     code: 'TZ',
  //     label: 'United Republic of Tanzania',
  //     phone: '255',
  //   },
  //   { code: 'UA', label: 'Ukraine', phone: '380' },
  //   { code: 'UG', label: 'Uganda', phone: '256' },
  //   {
  //     code: 'US',
  //     label: 'United States',
  //     phone: '1',
  //     suggested: true,
  //   },
  //   { code: 'UY', label: 'Uruguay', phone: '598' },
  //   { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  //   {
  //     code: 'VA',
  //     label: 'Holy See (Vatican City State)',
  //     phone: '379',
  //   },
  //   {
  //     code: 'VC',
  //     label: 'Saint Vincent and the Grenadines',
  //     phone: '1-784',
  //   },
  //   { code: 'VE', label: 'Venezuela', phone: '58' },
  //   {
  //     code: 'VG',
  //     label: 'British Virgin Islands',
  //     phone: '1-284',
  //   },
  //   {
  //     code: 'VI',
  //     label: 'US Virgin Islands',
  //     phone: '1-340',
  //   },
  //   { code: 'VN', label: 'Vietnam', phone: '84' },
  //   { code: 'VU', label: 'Vanuatu', phone: '678' },
  //   { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  //   { code: 'WS', label: 'Samoa', phone: '685' },
  //   { code: 'XK', label: 'Kosovo', phone: '383' },
  //   { code: 'YE', label: 'Yemen', phone: '967' },
  //   { code: 'YT', label: 'Mayotte', phone: '262' },
  //   { code: 'ZA', label: 'South Africa', phone: '27' },
  //   { code: 'ZM', label: 'Zambia', phone: '260' },
  //   { code: 'ZW', label: 'Zimbabwe', phone: '263' },
  // ];
  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });
  }, []);

  useEffect(() => {
    if (
      status !== ACTION_TYPE.DEFAULT_STATUS
    ) {

      dispatch(
        getApplicant({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          
        })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [ status]);

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues(fields), actionType !== 'ADD_NEW_GROUP'
        ? {
          id: null,
          status: false,
        }
        : {})
    );
  };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  const initialFormValues = (type = "fields") => {
    if (type === "columns") {
      return columns.reduce(
        (acc, field) => ({ ...acc, [field.field]: "" }),
        {}
      );
    } else {
      return fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
        {}
      );
    }
  };

  const initialErrors = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), { id: null, status: false })
  );
  const [detailRow, setDetailRow] = useState({});
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getApplicant({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
        })
      );
    }
  }, [isActive]);

  const setFormValuesFromSelectedData = () => {
    const data = rows.filter((row) => selectedData.includes(row.id))[0];
    
    for (const key in data) {
      // console.log(key, "key");
      // Check if the property exists in formValues before updating
      if (formValues.hasOwnProperty(key)) {
        // Special handling for the 'status' property
        const value = key === "status" ? data[key] === "active" : data[key];
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [key]: typeof value === "object" ? value ?.id : value,
        }));
      }
    }
  };

  const setFormValuesFromClientId = async (id) => {
    try {
      const {data} = await(await axios.get(`/clients/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }))?.data;

        data['client_id'] = data.id;
        delete data.id
        debugger;
        for (const key in data) {
          if (formValues.hasOwnProperty(key)) {
            // Special handling for the 'status' property
            const value = key === "status" ? data[key] === "active" : data[key];
            setFormValues((prevFormValues) => ({
              ...prevFormValues,
              [key]: typeof value === "object" ? value ?.id : value,
            }));
          }
        }

        setErrors(initialErrors);

    } catch (error) {
      console.error('Error fetching sub-service categories:', error);
    }
  };


  const setDetailRowFromSelectedData = async (dataSelected) => {
    const {id} = dataSelected;
    // debugger;
    setIsLoadedFormValues(true);
    try {
        const response = await axios.get(`/aplicants/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        let data = response.data.data;
        debugger;
        setDetailRow(data);

      } catch (error) {
        console.error("Error fetching client data:", error);
    } finally {
      setIsLoadedFormValues(false);
    }
    
  };


  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleFileSelect = (
    file,
    fieldName
  ) => {
    const [name] = [fieldName]
    // debugger;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: file,
    }));
  };

  const handleInputChange = async (event, field) => {
    const { name, value, type } = event.target;
    const newErrors = { ...errors };
    console.log(name, value, "name, value");
    // debugger
    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const fileError = validateFileInput(file, field);
        if (fileError) {
          newErrors[name] = fileError;
          setErrors(newErrors);
          return;
        }
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: file,
        }));
      }
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }

    if (name === "client_id") {
      if (value) {
        await setFormValuesFromClientId(value);
      }
    }

  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateFileInput = (
    fieldValue,
    field,
  ) => {
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB

      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }

    return "";
  };

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label, type } = field;

      if (
        required &&
        (String(formValues[name]).trim() === "" || formValues[name] === undefined ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = errorMessage || `Please enter ${String(field.label).toLowerCase()}`
      } else if (
        formValues[name] &&
        formValues[name].length &&
        validation &&
        !validation(formValues[name])
      ) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
      console.log(newErrors, "newErrors");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...initialErrors };

    fields.forEach((field) => {
      const { name, required, validation, errorMessage, type } = field;


      if (
        type === INPUT_TYPE.FILE
      ) {
        // Handle file input validation
        const fileError = validateFileInput(formValues[name], field);
        if (fileError) {
          valid = false;
        }
      } else if (type !== "file") {

        if (
          required &&
          (String(formValues[name]).trim() === "" || formValues[name] === undefined ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
            formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
        ) {
          newErrors[name] = errorMessage || `Please enter ${String(field.label).toLowerCase()}`
          valid = false;
        }

        if (
          formValues[name] &&
          formValues[name].length &&
          validation &&
          !validation(formValues[name])
        ) {
          newErrors[name] = errorMessage || `${field.label} is invalid`;
          valid = false;
        }

      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
          debugger;
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
        } else if (actionType === "ADD_NEW_GROUP") {
          handleCloseFormModal(true);
          handleFormModal(ACTION_TYPE.ADD)
        }
        setSelectedData([]);
      }
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD, dataSelected) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Applicant");
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Applicant");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setApplicantDeleted(
        `the applicant '${data.map((row) => row.name).join(", ")}'`
      );
    } else if (actionType === "ADD_NEW_GROUP") {
      setTitle("Add New Group");
      resetFormValues();
    }  else if (actionType === ACTION_TYPE.DETAIL) {
      setTitle("Detail Applicant");
      setDetailRowFromSelectedData(dataSelected)
    } else {
      resetFormValues();
      setTitle("Add Applicant");
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD || actionType === 'ADD_NEW_GROUP') {
        console.log("b", important);
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);

    if (actionType === 'ADD_NEW_GROUP') {
      handleFormModal(ACTION_TYPE.ADD) // formValue yang digunakan adalah formValues yang bukan ADD_NEW_GROUP
    } else {
      setFormModal(false);
      resetFormValues()
    }

    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    resetFormValues()
  };

  const handleAddRow = () => {
    dispatch(addApplicant(token, formValues));
  };

  const handleEditRow = () => {
    const aa =formValues;
    debugger;
    dispatch(editApplicant(token, formValues, formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteApplicant(token, { data: { ids } }, true));
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */
  const Detail = () => {

    return (
      <>
        <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' } }}>
          <Grid item xs={4}>
            <Typography variant="body2">Code</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.code}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Client Name</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.client?.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Applicant Name</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.name}</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body2">Country</Typography>
          </Grid>
          <Grid item xs={8}>
            {detailRow.country ? (
              <Box sx={{ '& > img': { mr: 1, flexShrink: 0 }, '& > span': { color: '#555' } }}>
                <img src={`https://flagcdn.com/w20/${detailRow.country.code.toLowerCase()}.png`} alt={`${detailRow.country.name} flag`} loading="lazy"
                  width="20" />
                {detailRow.country.name} <span>({detailRow.country.code})</span>
              </Box>
            ) : (
                <div>No country information available</div>
              )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Address</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.address || '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Email</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.email || '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Phone</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle2">{detailRow.phone || '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Status</Typography>
          </Grid>
          <Grid item xs={8}>
            { detailRow.status && <StatusBadge status={detailRow.status}> {detailRow.status} </StatusBadge> }
          </Grid>
        </Grid>

      </>
    );
  };

  const renderFormContent = () => {
    if (
      (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD) || actionType === 'ADD_NEW_GROUP'
    ) {
      return (
        <>
          <Grid>
            {fields.map((field) => {
              // # Old
              // const fieldOptions = Array.isArray(options[field.name])
              //   ? [
              //     field.type == INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
              //       id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
              //       // name: `Select ${field.label}`,
              //       name: `Choose Option`,
              //     },
              //     ...options[field.name],
              //   ]
              //   : [];

              // # New
              let optionsData;
              if (Array.isArray(options[field.name])) {
                const isRequired = field.required &&
                  field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                    id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                    name: "Choose Option",
                  };

                if (isRequired) {
                  optionsData = [
                    // field.required &&
                    //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                    //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                    //     name: "Choose Option",
                    //   },
                    ...options[field.name],
                  ];
                } else {
                  optionsData = [...options[field.name]];
                }
              }

              const fieldOptions = Array.isArray(options[field.name])
                ? optionsData
                : [];



              let fieldComponent = null;

              switch (field.type) {
                case INPUT_TYPE.TEXT:
                case INPUT_TYPE.NUMBER:
                case INPUT_TYPE.PHONE_NUMBER:
                case INPUT_TYPE.POINT:
                case INPUT_TYPE.CURRENCY:
                  fieldComponent = (
                    <TextInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.PASSWORD:
                  fieldComponent = (
                    <PasswordInputWithValidation
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                case INPUT_TYPE.TEXTAREA:
                  fieldComponent = (
                    <TextArea
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      tip={field.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.FILE:
                  fieldComponent = (
                    <FilePicker
                      field={field}
                      formValues={formValues[field.name]}
                      errors={errors}
                      onFileSelect={(file) =>
                        handleFileSelect(
                          file,
                          field.name
                        )
                      }
                      handleInputChange={(e) =>
                        handleInputChange(
                          e,
                          field
                        )
                      }
                      tip={field ?.tip}
                    />
                  );

                  break;
                case INPUT_TYPE.SELECT_ONE:
                  fieldComponent = (
                    <SelectOne
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                  fieldComponent = (
                    <SelectOneAutocomplete
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;
                  case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE_COUNTRY:
                    fieldComponent = (
                      <SelectOneCountryAutocomplete
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                    );
  
                    break;

                case INPUT_TYPE.SELECT_MULTIPLE:
                  fieldComponent = (
                    <SelectMultiple
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;
                case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                  fieldComponent = (
                    <SelectMultipleAutocomplete
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={fieldOptions}
                      tip={field ?.tip}
                    />
                  );
                  break;

                case INPUT_TYPE.CHECKBOX:
                  fieldComponent = (
                    <CheckboxGroupInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={
                        fieldOptions.length
                          ? fieldOptions.filter((option) => option !== false)
                          : field.options
                      }
                    />
                  );
                  break;

                case INPUT_TYPE.RADIO:
                  fieldComponent = (
                    <RadioGroupInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                      fieldOptions={
                        fieldOptions.length
                          ? fieldOptions.filter((option) => option !== false)
                          : field.options
                      }
                    />
                  );
                  break;

                case INPUT_TYPE.DATE:
                  fieldComponent = (
                    <DatePickerInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                case INPUT_TYPE.TIME:
                  fieldComponent = (
                    <TimePickerInput
                      key={field.name}
                      field={field}
                      formValues={formValues}
                      errors={errors}
                      handleInputChange={handleInputChange}
                      handleInputBlur={handleInputBlur}
                    />
                  );
                  break;
                default:
                  fieldComponent = null;
              }

              return (
                <Grid
                  item
                  xs={field.gridWidth || 12}
                  key={field.name}
                  sx={{ paddingTop: "unset !important" }}
                >
                  {fieldComponent}

                </Grid>
              );
            })}
          </Grid>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...formGroup,
              padding: "0 1rem",
            }}
          >
            <FormControlLabel
              control={
                <StatusSwitch
                  checked={formValues.status}
                  name="status"
                  onChange={handleSwitchChange}
                />
              }
              label={
                <Typography
                  variant="h7"
                  sx={{ marginLeft: 1, fontWeight: "500" }}
                >
                  {formValues.status ? "Active" : "Inactive"}
                </Typography>
              }
            />
          </Box>
        </>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    } else if (actionType === ACTION_TYPE.DETAIL) {
      return <Detail />
    }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        {actionType !== ACTION_TYPE.DETAIL ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
              id="cancel-modal"
            >
              Cancel
        </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
              id="submit-modal"
            >
              {actionType === ACTION_TYPE.DELETED || actionType === "PREVIEW" ? "Yes" : "Save"}
            </Button>
          </>
        ) : (
            <>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={() => handleCloseFormModal()}
                sx={{ mr: 2 }}
                id="cancel-modal"
              >
                Cancel
        </Button>
              <Button
                variant="outlined"
                sx={{ ...btnWhite }}
                onClick={() => handleCloseFormModal()}
                id="submit-modal"
              >
                Oke
        </Button>
            </>
          )}
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
    console.log(formValues, 'formValues')
  }, [search, status, isLoading, formValues]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };
  ;

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getApplicant({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getApplicant({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getApplicant({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        
      })
    );
  }
  function _search() {
    if(status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getApplicant({ token, limit, search, status, sortDirection, sortBy })
      );
    }
  }

  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getApplicant({ token, limit, search, status, sortDirection, sortBy })
    // );
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder="Search name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
                id="search-input"
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
                id="search-button"
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
                id="status-select"
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS} id="select_status">
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS} id="all_status">All Status</MenuItem>
                <MenuItem value="active" id="active">Active</MenuItem>
                <MenuItem value="inactive" id="inactive">Inactive</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Applicant"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={status}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          // userAccess={userAccess}
          />
        </CardContent>
      </Card>

      {actionType !== ACTION_TYPE.DELETED && actionType !== ACTION_TYPE.DETAIL ? (
        <LeftDrawer open={formModal}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
              workingWithDrawer={true}
            />
          </Box>
        </LeftDrawer>
      ) : (
          <Modal open={formModal} onClose={() => handleCloseFormModal()}>
            <Box>
              <Form
                title={title}
                onCloseModal={() => handleCloseFormModal()}
                elementForm={renderFormContent}
                elementActionButton={renderActionButtons}
                onSubmit={handleSubmit}
              />
            </Box>
          </Modal>
        )}

      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Applicant;
