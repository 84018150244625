import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Select,
  Autocomplete,
  Checkbox,
  IconButton,
  FormHelperText,
  Divider,
  CardActions,
} from "@mui/material";
import { Stepper, Step, StepLabel, Container, Paper } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import CalendarIcon from "./../../../assets/calendar.png";
import SearchIconBlack from "./../../../assets/search-black.svg";
// import RemoveIcon from "@mui/icons-material/Remove";
import RemoveIcon from "./../../../assets/minus.svg";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "./../../../assets/back.svg";
import { AccessRestrictedAlert } from "../../../components/Alert";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  FacebookCircularProgress,
  removeButton,
  justifyContentEnd,
} from "../../../util/style";
import axios from "../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
} from "../../../components/Form";
import {
  addEmployee,
  deleteEmployee,
  editEmployee,
  getEmployee,
} from "./../clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import { modalActionButton } from "../../../util/style";
import { useNavigate, useParams } from "react-router-dom";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";

const Wizard = () => {
  const steps = [
    "Personal Information",
    "Employment",
    "Education & Experience",
    "Other Information",
  ];

  const [stepLoading, setStepLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isNextTriggered, setIsNextTriggered] = useState({ isRun: "NOT" });

  // // Create an array of data (e.g., binary or text data)
  // const dataArray = ["Hello, World!"];
  // // Create a Blob object from the array of data
  // const blob = new Blob(dataArray, { type: "application/pdf" });
  // // Create a File object with the Blob and a filename
  // const file = new File([blob], "example.pdf", { type: "application/pdf" });

  // console.log(file);
  const [formData, setFormData] = useState({
    /*
    StepThree: {
      "Formal Education": [
        {
          grade_id: 1,
          major: "89",
          institution_name: "ibd",
          score: "9000",
          start_year: "2023-09-11T17:00:00.000Z",
          end_year: "2029-12-11T17:00:00.000Z",
          certificates: [file],
        },
      ],
      "Informal Education": [],
      "Work Experience": [],
    },
    StepOne: {
      "Personal Data": {
        employe_name: "Britanney Mills",
        birthdate: "2023-09-14T17:00:00.000Z",
        birthplace:
          "Quibusdam sint amet mollitia dolorum occaecat et aperiam fugit",
        gender: "male",
        religion_id: 1,
        marital_status_id: 1,
      },
      "Contact Information": {
        email: "zohylag@mailinator.com",
        mobile_phone: "26",
        phone: "72",
      },
      "Identity & Address": {
        identity_type: "passport",
        identity_number: "700",
        identity_expiry: "2023-09-21T17:00:00.000Z",
        postal_code: "66",
        is_permanent: "",
        citizen_id_address:
          "Labore sed alias recusandae Dolores ullam voluptatem consectetur voluptates vel odit fugit tempor reprehenderit unde molestias repudiandae",
        use_as_residential_address: [],
        residential_address: "Expedita repellendus Molestias sapiente iure",
      },
      Family: [
        {
          name: "Thomas Salazar",
          relation_id: 2,
          birthdate: "2023-09-21T17:00:00.000Z",
          gender: "male",
          religion_id: "",
          marital_status_id: "",
          identity_number: "336",
          job_id: "",
        },
      ],
      "Emergency Contact": [
        {
          name: "Zelda Bates",
          relation_id: 1,
          phone: "38",
        },
      ],
    },
    */
  });
  const dispatch = useDispatch();

  const {
    data: rows,
    message,
    isLoading,
  } = useSelector((state) => state.employees);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  const updateWizardData = (stepName, stepData) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [stepName]: stepData,
    }));
  };

  useEffect(() => {
    if (isActive === true) {
      navigate(-1);
    }
  }, [isActive]);

  const handleCancel = () => {
    setConfirmModal(true);
  };
  const handleNext = () => {
    // Trigger validation for the current step

    // setIsNextTriggered({isRun:"NOT"});

    // Check if the current step is valid based on isFormValid
    // if (isFormValid) {
    // setIsNextTriggered(true);
    setIsNextTriggered({ isRun: "YES" });
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsNextTriggered({ isRun: "NOT" });
    // setStepLoading(false);
  };

  const handleSubmit = () => {
    // handleNext();
    // setStepLoading(true);
    dispatch(addEmployee(token, formData));
  };

  const formAttempted = (val) => {
    setIsNextTriggered({ isRun: "NOT" });
    setIsFormValid(val);
    if (val) {
      if (activeStep === 3) {
        return;
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  useEffect(() => {
    if (isFormValid) {
      //   dispatch(addEmployee(token, formData));
    } else {
      setIsNextTriggered({ isRun: "NOT" });
    }
  }, [isFormValid]);

  useEffect(() => {
    console.log(
      "------------------------------ NEW FORM DATA ----------------------------- "
    );
    console.warn(formData, activeStep, steps.length);
    if (activeStep >= 3 && formData.hasOwnProperty("StepFour")) {
//       handleSubmit();
    }
    if(activeStep > steps.length - 1) {
//       setActiveStep(3);
    }
  }, [formData, activeStep]);

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    navigate(-1);
  };

  return (
    <Paper elevation={0} sx={{ paddingTop: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ marginTop: -1 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconProps={{ style: { fontSize: "30px" } }}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <br />
      {isLoading && (
        <div
          style={{
            height: "45vh",
            display: "flex",
            alignItems: " center",
            justifyContent: "center",
          }}
        >
          <FacebookCircularProgress />
        </div>
      )}

      <div style={{ display: isLoading ? "none" : "block" }}>
        {activeStep === 0 && (
          <Step1
            formData={formData["StepOne"]}
            formAttempted={formAttempted}
            isNextTriggered={isNextTriggered}
            updateWizardData={updateWizardData}
          />
        )}
        {activeStep === 1 && (
          <Step2
            formData={formData["StepTwo"]}
            formAttempted={formAttempted}
            isNextTriggered={isNextTriggered}
            updateWizardData={updateWizardData}
          />
        )}
        {activeStep === 2 && (
          <Step3
            formData={formData["StepThree"]}
            formAttempted={formAttempted}
            isNextTriggered={isNextTriggered}
            updateWizardData={updateWizardData}
          />
        )}
        {activeStep === 3 && (
          <Step4
            formData={formData["StepFour"]}
            formAttempted={formAttempted}
            isNextTriggered={isNextTriggered}
            updateWizardData={updateWizardData}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          bottom: 0,
          width: "100%",
          left: 0,
          padding: ".5rem 1.7rem 1.5rem ",
          background: "white",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          Previous
        </Button>
        <div>
          <Button
            variant="outlined"
            sx={{ ...btnWhite }}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          {activeStep < steps.length - 1 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
              disableElevation
            >
              Next
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button
              disableElevation
              variant="contained"
              color="primary"
//               onClick={() => handleNext()}
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </Paper>
  );
};

export default Wizard;
