import { useEffect, useState } from "react";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../../../util/style";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
  ButtonGroup,
} from "@mui/material";
import PrinterGrayIcon from "../../../../../assets/printer-gray.png";
import DownloadGrayIcon from "../../../../../assets/download-gray.png";

/**
import { Box } from '@mui/material';
 * @typedef {object} ParamComponent
 * @property {FunctionConstructor} handlePrintBtn
 * @property {FunctionConstructor} handleRightAction
 * @property {'PAYMENT_REQUEST'|'PAYMENT'} mode
 * @property {string} status
 * @property {string|number} id
 *
 */

/**
 * | Render bottom action on detail page |
 * @param {ParamComponent} param0
 * @returns {React.JSX.Element}
 */
const RenderBottomAction = ({
  handlePrintBtn,
  handleRightAction,
  handleSecondRightAction,
  mode,
  status,
  id,
}) => {
  let targetUrl =
    mode === "PAYMENT"
      ? "payments"
      : mode === "PAYMENT_REQUEST"
      ? "payment-requests"
      : "";

  const downloadURL = process.env.REACT_APP_API_URL.concat(
    "/api/",
    targetUrl,
    "/",
    id,
    "/download-pdf"
  );

  const [rightBtn, setRightBtn] = useState(false);

  useEffect(() => {
    if (
      mode === "PAYMENT" &&
      (status === "Waiting" || status === "Approved" || status === "Verified")
    ) {
      setRightBtn(true);
    } else if (mode === "PAYMENT_REQUEST" && status === "Rejected") {
      setRightBtn(true);
    } else {
      setRightBtn(false);
    }
  }, [status, mode]);
  const disabledStatus = ["Pending", "Rejected"];

  const RenderRightAction = () => {
    if (mode === "PAYMENT_REQUEST") {
      return <Button onClick={handleRightAction}>Edit</Button>;
    } else if (mode === "PAYMENT") {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: ".5rem" }}>
          <Button
            onClick={handleSecondRightAction}
            variant={mode === "PAYMENT" ? "contained" : "outlined"}
            color={mode === "PAYMENT" ? "error" : "inherit"}
          >
            {mode === "PAYMENT" ? "Reject" : "Cancel"}
          </Button>
          <Button onClick={handleRightAction} variant="contained">
            Pay
          </Button>
        </div>
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        marginInline: "1rem",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: ".5rem", marginInline: "1rem" }}>
        <Button
          variant="outlined"
          sx={{
            ...btnLightBlue,
          }}
          style={{ borderRadius: "7px" }}
          disableElevation
          className="not-effect"
          disabled={
            mode === "PAYMENT_REQUEST" &&
            disabledStatus.includes(disabledStatus)
          }
          onClick={handlePrintBtn}
        >
          <img
            src={PrinterGrayIcon}
            style={{ marginRight: "5px", margin: "4px" }}
            alt="Printer Icon"
          />
          Print
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...btnLightBlue,
          }}
          disableElevation
          className="not-effect"
          disabled={
            mode === "PAYMENT_REQUEST" && disabledStatus.includes(status)
          }
          onClick={() => {
            window.open(downloadURL, "_blank");
          }}
        >
          <img
            src={DownloadGrayIcon}
            style={{ marginRight: "5px", margin: "4px" }}
            alt="Download Icon"
          />
          Download
        </Button>
      </Box>
      <Box>{rightBtn && <RenderRightAction />}</Box>
    </Box>
  );
};

export default RenderBottomAction;
