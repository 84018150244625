export const ADD_PURCHASE_REQUEST_SUCCESS = "ADD PURCHASE REQUEST SUCCESS"; 
export const ADD_PURCHASE_REQUEST_FAIL = "ADD PURCHASE REQUEST FAIL";

export const GET_PURCHASE_REQUEST_SUCCESS = "GET PURCHASE REQUEST SUCCESS";
export const GET_PURCHASE_REQUEST_FAIL = "GET PURCHASE REQUEST FAIL";

export const UPDATE_PURCHASE_REQUEST_SUCCESS = "UPDATE PURCHASE REQUEST SUCCESS";
export const UPDATE_PURCHASE_REQUEST_FAIL = "UPDATE PURCHASE REQUEST FAIL";

export const DELETE_PURCHASE_REQUEST_SUCCESS = "DELETE PURCHASE REQUEST SUCCESS";
export const DELETE_PURCHASE_REQUEST_FAIL = "DELETE PURCHASE REQUEST FAIL";

export const DETAIL_PURCHASE_REQUEST_SUCCESS = "DETAIL PURCHASE REQUEST SUCCESS";
export const DETAIL_PURCHASE_REQUEST_FAIL = "DETAIL PURCHASE REQUEST FAIL";