import { defaultStylePage, borderRadius, btnWhite, StatusSwitch, formGroup, StatusBadge } from "../../../../util/style";
import { ACTION_TYPE, MODE_CRUD_WITH, INPUT_TYPE } from "../../../../util/function";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/styles";
import { useEffect, useState } from "react";
import { addRoomName, deleteRoomName, editRoomName, getRoomName } from "./clients/actions";
import { Box, Button, Card, CardContent, FormControlLabel, Grid, InputLabel, MenuItem, Modal, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DataTable from "../../../../components/Table";
import Form, { TextArea, TextInput } from "../../../../components/Form";
import ModalConfirmCancel from "../../../../components/ConfirmCancel";
import ConfirmDelete from "../../../../components/ConfirmDelete";
import axios, { headersAPIToken } from "../../../../core/axios_config";
const columns = [
    {
        field: "code",
        headerName: "No. Reference",
        flex: 1,
        disableColumnMenu: true,
    },
    {
        field: "room_name",
        headerName: "Room Name",
        flex: 1,
        disableColumnMenu: true,
    },
    {
        field: "max_capacity",
        headerName: "Maximum Capacity",
        flex: 1,
        disableColumnMenu: true,
    },
    {
        field: "desc",
        headerName: "Description",
        flex: 1,
        disableColumnMenu: true,
    },
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params) => params.value,
        renderCell: (params) => (
            <StatusBadge status={params.value}> {params.value} </StatusBadge>
        ),
    },
]

const fields = [
    {
        name: "room_name",
        type: INPUT_TYPE.TEXT,
        label: "Room Name",
        placeholder: "Input Room Name",
        defaultValue: "",
        required: true,
    },
    {
        name: "max_capacity",
        type: INPUT_TYPE.NUMBER,
        label: "Maximum Capacity",
        placeholder: "Input Maximum Capacity",
        defaultValue: "",
        required: true,
    },
    {
        name: "desc",
        type: INPUT_TYPE.TEXTAREA,
        label: "Description",
        placeholder: "Input Description (Optional)",
        defaultValue: "",
    },
]

const RoomName = () => {
    const theme = useTheme();
    const useStyles = defaultStylePage;
    const dispatch = useDispatch();

    const { token } = useSelector((state) => state.auth);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [searchBy, setSearchBy] = useState("no_reference");
    const [statusFilter, setStatusFilter] = useState(ACTION_TYPE.ALL_STATUS);
    const [sortDirection, setSortDirection] = useState("desc");
    const [sortBy, setSortBy] = useState("id");
    const [sortModel, setSortModel] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [formModal, setFormModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [title, setTitle] = useState("Add Room Name");
    const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
    const [roomDeleted, setRoomDeleted] = useState(null);
    const [spareTime, setSpareTime] = useState(0);
    const placeholder = {
        "no_reference": "No. Reference",
        "room_name": "Room Name",
    }

    const initialFormValues = (type = "fields") => {
        return fields.reduce(
            (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
            {}
        );
    };
    const [formValues, setFormValues] = useState(
        Object.assign({}, initialFormValues("fields"), { id: null, status: false })
    );
    const initialErrors = fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: "" }),
        {}
    );

    const [errors, setErrors] = useState(initialErrors);

    const {
        data: rows,
        message,
        isLoading,
        pagination,
    } = useSelector((state) => state.roomName);

    const {
        modal: { isActive },
    } = useSelector((state) => state.global);

    useEffect(() => {
        dispatch(
            getRoomName({
                statusFilter,
                token,
                page,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }, [sortDirection, sortBy]);

    useEffect(() => {
        if (isActive) {
            dispatch(
                getRoomName({
                    statusFilter,
                    token,
                    page,
                    limit,
                    searchValue,
                    searchBy,
                    sortDirection,
                    sortBy,
                })
            );
        }
    }, [isActive]);

    useEffect(() => {
        axios.get(`/room/spare_time`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setSpareTime(response.data.data.time)
        })
    }, [])

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && searchValue) {
            _search();
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
        _getByPage(newPage + 1);
    };

    const handleLimitChange = (newLimit) => {
        setPage(0);
        setLimit(newLimit);
        _getByLimit(newLimit);
    };

    const handleSelectionChange = (selection) => {
        console.log(selection, "selection");
        setSelectedData(selection || []);
    };

    const handleSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
        setActionType(actionType);
        setFormModal(true);
        console.log("actionType==>" + actionType);
        if (actionType === ACTION_TYPE.ADD) {
            setTitle(`Add Room Name`);
        } else if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
            setTitle(`Edit Room Name`);
            setFormValuesFromSelectedData();
        } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            setTitle(`Delete Room Name`);
            const data = rows.filter((row) => selectedData.includes(row.id));
            setRoomDeleted(data.map((row) => row.room_name).join(", "));
        }
    };

    const setFormValuesFromSelectedData = () => {
        const data = rows.filter((row) => selectedData.includes(row.id))[0];
        for (const key in data) {
            // console.log(key, "key");
            // Check if the property exists in formValues before updating
            if (formValues.hasOwnProperty(key)) {
                // Special handling for the 'status' property
                const value = key === "status" ? data[key] === "Active" : data[key];
                setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    [key]: typeof value === "object" ? value?.id : value,
                }));
            }
        }
    };

    const handleRowDoubleClick = (selection) => {
        // navigate(`detail/${selection.id}`, { state: { type: typeData } });
    }

    const handleCloseFormModal = (important = false) => {
        if (!important) {
            if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
                if (true) setConfirmModal(true);
            } else {
                setFormModal(false);
            }
        } else {
            setFormModal(false);
        }
    };

    const handleOnConfirmCancelModal = () => {
        setConfirmModal(false);
    };

    const resetForm = () => {
        const newErrors = { ...errors }
        newErrors.room_name = "";
        newErrors.desc = "";
        const newFormValues = { ...formValues }
        newFormValues.room_name = "";
        newFormValues.desc = "";
        newFormValues.status = false;
        setFormValues(newFormValues);
        setErrors(newErrors);
    }

    const handleOnCloseConfirmCancelModal = () => {
        resetForm();
        setConfirmModal(false);
        setFormModal(false);
        // setSearchTerm("");
        setSelectedData([]); //buat disabled button toolbar
    };

    const handleAddRow = () => {
        dispatch(addRoomName(token, formValues));
    };

    const handleEditRow = () => {
        dispatch(editRoomName(token, formValues, formValues.id));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            handleDeleteRow();
            handleCloseFormModal(true);
            setSelectedData([]);
        } else {
            if (validateForm()) {
                console.log(formValues);
                if (formValues.status) {
                    formValues.status = "Active"
                } else {
                    formValues.status = "In Active"
                }
                if (selectedData.length && actionType === ACTION_TYPE.EDIT) {
                    handleEditRow();
                    handleCloseFormModal(true);
                } else if (actionType === ACTION_TYPE.ADD) {
                    handleAddRow();
                    handleCloseFormModal(true);
                }
                resetForm();
                setSelectedData([]);
            }
        }
    }

    const handleSubmitSpareTime = (e) => {
        e.preventDefault();
        axios.post(`room/spare_time_change`, { time: spareTime }, headersAPIToken(token)).then((response) => {
            console.log("response");
            console.log(response);
        })

    }

    const validateField = (fieldName) => {
        const newErrors = { ...errors };
        if (fieldName === "room_name") {
            newErrors.room_name = !formValues.room_name
                ? "Room Name is required"
                : null
        }
        if (fieldName === "max_capacity") {
            newErrors.max_capacity = !formValues.max_capacity
                ? "Max Capacity is required"
                : null
        }
        setErrors(newErrors);
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...initialErrors };

        if (!formValues.room_name) {
            newErrors.room_name = "Room Name is required"
            valid = false;
        } else {
            newErrors.room_name = null
        }
        if (!formValues.max_capacity) {
            newErrors.max_capacity = "Max Capacity is required"
            valid = false;
        } else {
            newErrors.max_capacity = null
        }

        setErrors(newErrors);
        return valid;
    }

    const handleDeleteRow = () => {
        const data = rows.filter((row) => selectedData.includes(row.id));
        const ids = data.map((row) => row.id);
        dispatch(deleteRoomName(token, { data: { ids } }, true));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log(name, value, "name, value");
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSwitchChange = (event) => {
        const { name, checked } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: checked,
        }));
    };

    const handleInputBlur = (event) => {
        const { name } = event.target;
        validateField(name);
    };

    const renderFormContent = () => {
        if ((selectedData.length && actionType === ACTION_TYPE.EDITED) ||
            (!selectedData.length && actionType === ACTION_TYPE.ADD)) {
            return (
                <>
                    {fields.map((field) => {
                        switch (field.type) {
                            case INPUT_TYPE.TEXT:
                            case INPUT_TYPE.NUMBER:
                                return (
                                    <TextInput
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                    />
                                )
                            case INPUT_TYPE.TEXTAREA:
                                return (
                                    <TextArea
                                        key={field.name}
                                        field={field}
                                        formValues={formValues}
                                        errors={errors}
                                        handleInputChange={handleInputChange}
                                        handleInputBlur={handleInputBlur}
                                    />
                                )
                            default:
                                break;
                        }
                    })}
                    <InputLabel>
                        Status
                    </InputLabel>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            ...formGroup,
                            padding: "0 1rem",
                        }}
                    >
                        <FormControlLabel
                            control={
                                <StatusSwitch
                                    checked={formValues.status}
                                    name="status"
                                    onChange={handleSwitchChange}
                                />
                            }
                            label={
                                <Typography
                                    variant="h7"
                                    sx={{ marginLeft: 1, fontWeight: "500" }}
                                >
                                    {formValues.status ? "Active" : "In Active"}
                                </Typography>
                            }
                        />
                    </Box>
                </>
            );

        } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            return <ConfirmDelete userDeleted={roomDeleted} />;
        }
    }

    const renderActionButtons = () => {
        return (
            <Box>
                <Button
                    variant="outlined"
                    sx={{ ...btnWhite }}
                    onClick={() => handleCloseFormModal()} >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    type="submit" >
                    {
                        actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"
                    }
                </Button>
            </Box>
        )
    };

    function _getByPage(page) {
        if (pagination === null || pagination === undefined) {
            return;
        }
        dispatch(
            getRoomName({
                statusFilter,
                token,
                page,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }

    function _getByLimit(limit) {
        dispatch(
            getRoomName({
                statusFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }

    function _statusFilter(status) {
        setStatusFilter(status);
        dispatch(
            getRoomName({
                statusFilter: status,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }

    function _search() {
        dispatch(
            getRoomName({
                statusFilter,
                token,
                limit,
                searchValue,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }
    return (
        <>
            <Card style={{ boxShadow: "unset" }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                placeholder={`Search ${placeholder[searchBy]}`}
                                size="small"
                                sx={{ borderRadius }}
                                onChange={(e) => setSearchValue(e.target.value)}
                                InputProps={{
                                    onKeyDown: handleKeyPress,
                                    startAdornment: <SearchIcon />,
                                    endAdornment: (
                                        <TextField
                                            select
                                            fullWidth
                                            size="small"
                                            SelectProps={{
                                                IconComponent: ExpandMoreIcon,
                                            }}
                                            value={searchBy}
                                            sx={{
                                                width: 250,
                                                '& fieldset': {
                                                    border: 'unset',
                                                }, borderLeft: `2px solid #ddd`
                                            }}
                                            onChange={(e) => setSearchBy(e.target.value)}
                                        >
                                            <MenuItem value="no_reference">No. Reference</MenuItem>
                                            <MenuItem value="room_name">Room Name</MenuItem>
                                        </TextField>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                disableElevation
                                sx={{ height: "100%" }}
                                onClick={_search}
                                onKeyPress={handleKeyPress}
                            >
                                <SearchIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                SelectProps={{ IconComponent: ExpandMoreIcon }}
                                value={statusFilter}
                                onChange={(e) => _statusFilter(e.target.value)}
                                sx={{ borderRadius }} >
                                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="In Active">Inactive</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
                <CardContent sx={{ padding: '24' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography sx={{ fontWeight: 700 }}>
                                Spare Time
                            </Typography>
                            <Typography>
                                Set spare time for all meeting room here!
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>
                                    <TextField
                                        placeholder={`0`}
                                        size="small"
                                        type="number"
                                        value={spareTime}
                                        sx={{ borderRadius, width: '50px' }}
                                        onChange={(e) => setSpareTime(e.target.value)}
                                        InputProps={{ onKeyDown: handleKeyPress }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography sx={{ padding: 1 }}>
                                        Minutes
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                disableElevation
                                // sx={{ height: "100%" }}
                                onClick={handleSubmitSpareTime}
                            // onKeyPress={handleSubmitSpareTime}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
                <CardContent sx={{ paddingTop: "unset" }}>
                    <DataTable
                        title={"Room Name"}
                        useStyles={useStyles}
                        rows={rows}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        limit={limit}
                        page={page}
                        handlePageChange={handlePageChange}
                        handleLimitChange={handleLimitChange}
                        handleFormModal={handleFormModal}
                        selectedData={selectedData}
                        handleSelectionChange={handleSelectionChange}
                        handleRowDoubleClick={handleRowDoubleClick}
                        theme={theme}
                        sortModel={sortModel}
                        search={searchValue}
                        onSortModelChange={handleSortModelChange}
                    // modeCRUD={MODE_CRUD_WITH.PAGING}
                    // linkCRUD={{ create: "add", update: `edit/${selectedData}`, createOption: "hybrid" }}
                    />
                </CardContent>
            </Card>
            <Modal open={formModal} onClose={() => handleCloseFormModal()}>
                <Box>
                    <Form
                        title={title}
                        onCloseModal={() => handleCloseFormModal()}
                        elementForm={renderFormContent}
                        elementActionButton={renderActionButtons}
                        onSubmit={handleSubmit}
                    />
                </Box>
            </Modal>
            <ModalConfirmCancel
                confirmModal={confirmModal}
                onConfirmModal={handleOnConfirmCancelModal}
                onCloseModal={handleOnCloseConfirmCancelModal}
            ></ModalConfirmCancel>
        </>
    )
}
export default RoomName