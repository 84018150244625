import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import Switch from "@mui/material/Switch";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { styled } from "@mui/system";
import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CalendarIcon from "./../assets/calendar.png";
import DocumentIcon from "./../assets/document.png";

export const mainColor = "#1268B8"; // First color
export const secondColor = "#C2C2C2"; // Second color
export const fourColor = "#999999"; // Second color
export const thirdColor = "#F5F5F5"; // Second color
export const surfaceColor = "#D0E1F1"; // Surface color
export const borderColor = "#B0CDE7"; // Border color
export const warningSurfaceColor = "#FFF1CF"; // Warning Surface color
export const warningMainColor = "#FFBA10"; // Warning Main color
export const successSurfaceColor = "#D2F0CC"; // Success Surface color
export const successMainColor = "#1DB200"; // Success Main color
export const dangerSurfaceColor = "#FFCDCC"; // Danger Surface color
export const dangerMainColor = "#FF0400"; // Danger Main color
export const infoSurfaceColor = "#D2DAF0"; // Info Surface color
export const infoMainColor = "#2047B2"; // Info Main color
export const neutral10 = "#FFFFFF"; // Neutral10 color
export const neutral20 = "#F5F5F5"; // Neutral20 color
export const neutral30 = "#EDEDED"; // Neutral30 color
export const neutral40 = "#E0E0E0"; // Neutral40 color
export const neutral50 = "#C2C2C2"; // Neutral50 color
export const neutral60 = "#9E9E9E"; // Neutra60 color
export const neutral70 = "#757575"; // Neutral70 color
export const neutral80 = "#616161"; // Neutral80 color
export const neutral90 = "#404040"; // Neutral90 color
export const neutral100 = "#0A0A0A"; // Neutral100 color

export const drawerWidth = 240;
export const borderRadius = 10;

export const btnWhite = {
  mr: 2,
  backgroundColor: "#ffffff",
  color: "#000000",
  borderColor: "#ddd",
};


export const btnGreen = {
  backgroundColor: "#689f38",
  color: "#FFFFFF",
  borderColor: "#689f38",
};



export const btnLightBlue = {
  backgroundColor: surfaceColor,
  color: mainColor,
  borderColor: surfaceColor,
};

export const btnGray = {
  backgroundColor: neutral30,
  color: fourColor,
  borderColor: 'transparent',
};




export const modal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  // padding: "2rem 1.5rem",
  width: 600,
  borderRadius: 4, // Set the border radius here
};

export const drawer = {
  bgcolor: "background.paper",
};

export const modalHeader = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1.3rem",
};

export const drawerHeader = {
  display: "flex",
  alignItems: "center",
  padding: "1.3rem",
  flexDirection: "row-reverse",
  justifyContent: "left",
  gap: "1rem",
};

export const form = {
  padding: "1.5rem 1.5rem 1rem",
  maxHeight: 400,
  overflow: "auto",
};
export const formDrawer = {
  padding: "1.5rem 1.5rem 1rem",
  height: "73vh",
  overflow: "auto",
};

export const modalActionButton = {
  display: "flex",
  // justifyContent: "flex-end",
  justifyContent: "space-between",
  background: thirdColor,
  padding: "1.3rem",

  borderTopLeftRadius: "0px !important",
  borderTopRightRadius: "0px !important",
  borderRadius: 4,
};

export const formGroup = {
  mt: 0.5,
  mb: 1,
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#fb2525",
    fontSize: "1rem",
    margin: "0.3rem 0 0",
  },
  "~ .MuiFormHelperText-root.Mui-error": {
    color: "#fb2525",
    fontSize: "1rem",
    margin: "-5px 0 0.4rem 0",
  },
  "& .MuiInputBase-root": {
    paddingRight: 'unset'
  }
};

export const btnTableToolbar = {
  borderRadius: "30%",
  margin: "0 4px",
  color: "white",
  "&:disabled": {
    backgroundColor: "#ccc",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1.5rem",
  },
};

export const imgBtnToolbar = {
  width: "18px",
  height: "18px",
};

export const table = {
  height: "68vh",
  width: "100%",
  borderRadius,
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
  display: "inline-block",
};

export const confirmDelete = {
  display: " flex",
  justifyContent: " center",
  flexDirection: " column",
  alignItems: " center",
  padding: "15px 0 20px",
  height: "85%",
};

export const StyledTextArea = styled(({ error, InputProps, ...rest }) => (
  <TextareaAutosize {...rest} InputProps={InputProps} />
))(({ theme, error }) => ({
  width: "98%",
  height: "auto !important",
  padding: "8px",
  border: `1px solid ${error ? "red" : " #ccc"}`,
  borderRadius: "4px",
  fontFamily: "inherit",
  fontSize: "inherit",
  resize: "vertical",
  transition: "border-color 0.2s ease-in-out",
  "&:hover": {
    borderColor: error ? "red" : " #aaa",
  },
  "&:focus": {
    outlineColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    // boxShadow: '0 0 0 2px rgba(0, 123, 255, 0.25)',
  },
}));

export const StatusBadge = styled(Badge)(
  ({ theme, status, isinfo, style = {}, bgColor, colors }) => {
    let backgroundColor = "#dbf0ce";
    let color = "#79bc3f";

    if (colors) {
      color = colors;
    }
    if (bgColor) {
      backgroundColor = bgColor;
    } else if (isinfo) {
      backgroundColor = "#D2DAF0";
      color = "#2047B2";
    } else if (status.toLowerCase() === "inactive" || status.toLowerCase() === "in active") {
      backgroundColor = "#f2cdcc";
      color = "#dd1511";
    }

    return {
      backgroundColor,
      color,
      padding: "2px 8px",
      borderRadius,
      textTransform: "capitalize",
      ...style,
    };
  }
);
export const StatusSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    // disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 58,
  height: 30,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2.5,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(27px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 25,
    height: 25,
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

// Inspired by the former Facebook spinners.
export const FacebookCircularProgress = (props) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
        height: "90%",
        alignItems: "center",
      }}
    >
      <center>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
            position: "absolute  !important",
          }}
          size={60}
          thickness={5.5}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => theme.palette.primary.main,
            animationDuration: "1s",
            position: "relative !important",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={60}
          thickness={5.5}
          {...props}
        />
        <Typography variant="h6" sx={{ color: "black" }}>
          Please wait.
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontStyle: "italic", color: secondColor }}
        >
          The data you need is being processed.
        </Typography>
      </center>
      {/* *   subtitle1: 'h6',
       *   subtitle2: 'h6',
       *   body1: 'p',
       *   body2: 'p', */}
    </Box>
  );
};

export const ButtonSaveOnStep = ({ onSave, style = {} }) => {
  return (
    <Button
      color="primary"
      variant="contained"
      disableElevation
      // type="submit"
      style={{
        ...style,
        position: "absolute",
        bottom: "1.5rem",
        zIndex: "99",
        right: "1.5rem",
      }}
      onClick={onSave}
    >
      Save
    </Button>
  );
};

export const CustomOpenPickerButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick} size="medium" variant="text">
      <img src={CalendarIcon} />
    </IconButton>
  );
};

export const defaultStylePage = makeStyles((theme) => ({
  root: {
    justifyContent: "flex-end",
    display: "block",
    width: "100%",
    "& .MuiDataGrid-selectedRowCount ": {
      fontSize: "12px",
      display: "none",
    },
    "& .MuiInputBase-root ": {
      position: "absolute",
      left: "120px",
    },
    "& .MuiTablePagination-selectLabel ": {
      position: "absolute",
    },
  },
  button: {
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      width: "624px",
      borderRadius,
    },
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  dialogActions: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: thirdColor,
  },
  drawerContent: {
    padding: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  drawerPaper: {
    minWidth: 460,
    maxWidth: 460,
    borderTopLeftRadius: borderRadius * 2,
    borderBottomLeftRadius: borderRadius * 2,
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  customIcon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  treeItemLabel: {
    position: "relative", // To contain the icon within the label
  },
}));

export const inputDate = {
  width: "100%", // Set the desired width
  "& .MuiIconButton-root": {
    padding: "10px", // Adjust the padding of the icon button
  },
  "& .MuiInputBase-input": {
    fontSize: "12px", // Adjust the font size of the input
    padding: " 0.7rem 1rem",
  },
};

export const removeButton = {
  color: "#fff",
  minWidth: "auto",
  marginLeft: "7px",
  width: "40px !important",
};

export const alignItemsCenter = {
  display: "flex",
  alignItems: "center",
};

export const justifyContentEnd = {
  display: "flex",
  justifyContent: "end",
};

export const justifyContentBetween = {
  display: "flex",
  justifyContent: "space-between",
};

export const justifyContentCenter = {
  display: "flex",
  justifyContent: "center",
};

export const tabStyles = {
  display: "flex",
  justifyContent: "flex-start",
  gap: "1.2rem",
  // background: " white",

  "& .MuiChip-root": {
    height: "38px",
    fontWeight: "500",
    borderRadius: "25px !important",
  },
};

export const AttachmentItem = ({ item, title }) => {
  const alignItemsCenter = {
    display: 'flex',
    alignItems: 'center',
  };

  const fourColor = '#yourColorCode'; // Replace with your desired color code

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        margin: '.3rem 0',
        ...alignItemsCenter,
      }}
    >
      <Typography variant="subtitle2" sx={{ minWidth: '170px', display: 'block' }}>
        {title}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: '500',
        }}
      >
        <div
          style={{
            ...alignItemsCenter,
            gap: '1rem',
            padding: '0.5rem 0',
          }}
        >
          <img src={DocumentIcon} alt="Document Icon" />
          <span>{item.filename}</span>
          <span style={{ textTransform: 'uppercase', color: fourColor }}>
            {/* {formatSize(item.size)} */}
          </span>
          <a href={item.url} target="_blank" rel="noopener noreferrer">
            Open
          </a>
        </div>
      </Typography>
    </Box>
  );
};
