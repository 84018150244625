import { REQUEST_LOADING } from "../../../util/global_state";

import {
  ADD_ATTENDANCE_FAILED,
  ADD_ATTENDANCE_SUCCESS,
  DELETE_ATTENDANCE_FAILED,
  DELETE_ATTENDANCE_SUCCESS,
  EDIT_ATTENDANCE_FAILED,
  EDIT_ATTENDANCE_SUCCESS,
  GET_ATTENDANCE_FAILED,
  GET_ATTENDANCE_SUCCESS,
  GET_ATTENDANCE_DETAILS_SUCCESS,
  GET_ATTENDANCE_DETAILS_FAILED,
} from "./state";

const initialState = {
  data: {
    attendances: [],
    analytic: {},
  },
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
};

// Define a helper function for common state updates
const updateState = (state, payload, pagination, isLoading = false) => {
  return {
    ...state,
    data: payload,
    pagination,
    message: null,
    isLoading,
  };
};

const attendancesReducer = (
  state = initialState,
  { type, payload, pagination }
) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_ATTENDANCE_SUCCESS:
    case GET_ATTENDANCE_DETAILS_SUCCESS:
      return updateState(state, payload, pagination);
    case GET_ATTENDANCE_FAILED:
    case GET_ATTENDANCE_DETAILS_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case ADD_ATTENDANCE_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
        response_status: true,
      };
    case ADD_ATTENDANCE_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_ATTENDANCE_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_ATTENDANCE_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_ATTENDANCE_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_ATTENDANCE_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default attendancesReducer;
