import { useState } from "react";
import axios, { headersAPIToken } from "../../../../core/axios_config";

/**
 *
 * @param {string} url endpoint for detail
 */
function useGetDetail(url, token) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  /**@param {string} id */
  async function getDetailForEdit(id) {
    try {
      let localUrl = url.concat(id,'/detail-for-edit');
      const response = await axios.get(localUrl, headersAPIToken(token));
      if (response.status === 200 && response.data.status === true) {
        setResponse(response.data);
      } else if (response.status === 200 && response.data.status == false) {
        setResponse([]);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError(error.response.data.message);
    }
  }
  return [response, error, getDetailForEdit];
}

export default useGetDetail;
