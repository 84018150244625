import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../../util/global_state";
import {
  GET_SETUP_REWARD_SUCCESS,
  GET_SETUP_REWARD_FAILED,
  ADD_SETUP_REWARD_SUCCESS,
  ADD_SETUP_REWARD_FAILED,
  EDIT_SETUP_REWARD_SUCCESS,
  EDIT_SETUP_REWARD_FAILED,
  DELETE_SETUP_REWARD_FAILED,
  DELETE_SETUP_REWARD_SUCCESS,
} from "./state";
import { logout } from "../../../../auth/clients/login/actions";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../../util/function";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getSetupReward = ({
  token,
  page = 1,
  limit = 10,
  searchValue = null,
  sortDirection = null,
  categoryFilter = null,
  sortBy = null,
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "setup-reward",
          page,
          limit,
          searchValue,
          sortDirection,
          sortBy,
          categoryFilter: categoryFilter == "" ? null : categoryFilter,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data.status === true) {
          dispatch(getSetupRewardSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getSetupRewardSuccess([], null));
        } else {
          dispatch(getSetupRewardFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getSetupRewardFailed(error.response.data.message));
          }
        } else {
          dispatch(getSetupRewardFailed(error.message));
        }
      });
  };
};

const getSetupRewardSuccess = (data, pagination) => {
  return {
    type: GET_SETUP_REWARD_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getSetupRewardFailed = (errorMessage) => {
  return {
    type: GET_SETUP_REWARD_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addSetupReward = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`setup-reward`, Object.assign({}, formData), headersAPIToken(token))
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "Setup Reward"));
          dispatch(addSetupRewardSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addSetupRewardFailed(response.data.message));
        } else {
          dispatch(addSetupRewardFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addSetupRewardFailed(error.response.data.message));
          }
        } else {
          dispatch(addSetupRewardFailed(error.message));
        }
      });
  };
};

const addSetupRewardSuccess = (message) => {
  return {
    type: ADD_SETUP_REWARD_SUCCESS,
    payload: message,
  };
};

const addSetupRewardFailed = (message) => {
  return {
    type: ADD_SETUP_REWARD_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editSetupReward = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .patch(
        `setup-reward/${id}`,
        Object.assign({}, formData),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "Setup Reward"));
          dispatch(editSetupRewardSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editSetupRewardFailed(response.data.message));
        } else {
          dispatch(editSetupRewardFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editSetupRewardFailed(error.response.data.message));
          }
        } else {
          dispatch(editSetupRewardFailed(error.message));
        }
      });
  };
};

const editSetupRewardSuccess = (message) => {
  return {
    type: EDIT_SETUP_REWARD_SUCCESS,
    payload: message,
  };
};

const editSetupRewardFailed = (message) => {
  return {
    type: EDIT_SETUP_REWARD_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteSetupReward = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`setup-reward${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "Setup Reward"));
          dispatch(deleteSetupRewardSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteSetupRewardFailed(response.data.message));
        } else {
          dispatch(deleteSetupRewardFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteSetupRewardFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteSetupRewardFailed(error.message));
        }
      });
  };
};

const deleteSetupRewardSuccess = (message) => {
  return {
    type: DELETE_SETUP_REWARD_SUCCESS,
    payload: message,
  };
};

const deleteSetupRewardFailed = (message) => {
  return {
    type: DELETE_SETUP_REWARD_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
