//@ts-check
import { REQUEST_LOADING } from "./../../../../util/global_state";
import {
  ADD_PURCHASE_REQUEST_FAIL,
  ADD_PURCHASE_REQUEST_SUCCESS,
  DELETE_PURCHASE_REQUEST_FAIL,
  DELETE_PURCHASE_REQUEST_SUCCESS,
  DETAIL_PURCHASE_REQUEST_FAIL,
  DETAIL_PURCHASE_REQUEST_SUCCESS,
  GET_PURCHASE_REQUEST_FAIL,
  GET_PURCHASE_REQUEST_SUCCESS,
  UPDATE_PURCHASE_REQUEST_FAIL,
  UPDATE_PURCHASE_REQUEST_SUCCESS,
} from "./state";

const initialState = {
  isLoading: false,
  data: null,
  pagination: null,
  message: null,
  detail: null,
};

const purchaseRequestReducer = (
  state = initialState,
  { type, payload, pagination }
) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        pagination,
      };
    case DETAIL_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: payload,
      };
    //* wrap all state for same action
    case GET_PURCHASE_REQUEST_FAIL:
    case DETAIL_PURCHASE_REQUEST_FAIL:
    case UPDATE_PURCHASE_REQUEST_FAIL:
    case UPDATE_PURCHASE_REQUEST_SUCCESS:
    case DELETE_PURCHASE_REQUEST_FAIL:
    case DELETE_PURCHASE_REQUEST_SUCCESS:
    case ADD_PURCHASE_REQUEST_FAIL:
    case ADD_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: payload,
      };
    default:
      return state;
  }
};

export default purchaseRequestReducer;
