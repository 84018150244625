import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import { loginSuccess } from "../login/actions";
import { VERIFY_OTP_SUCCESS, VERIFY_OTP_FAILED } from "./state";

export const verifyOTP = (userId, otp) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/verif-otp/${userId}`, { otp });
      const { data } = response;
      // debugger;
      if (response.status === 200 && data.status === true) {
        const token = data.data.token; // Adjust this line based on your response structure
        dispatch(verifyOTPSuccess(token));
        dispatch(loginSuccess(data.data)); // Assuming you have a loginSuccess action
        return data; // Return the token to the component
      } else {
        dispatch(verifyOTPFailed(data.message));
        return data;
      }
    } catch (error) {
      dispatch(verifyOTPFailed(error.message));
      throw error; // Rethrow the error to the component
    }
  };
};

const verifyOTPSuccess = (data) => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: data,
  };
};

const verifyOTPFailed = (errorMessage) => {
  return {
    type: VERIFY_OTP_FAILED,
    payload: errorMessage,
  };
};
