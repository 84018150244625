import { REQUEST_LOADING } from "../../../../util/global_state";

import {
  GET_GROUP_SUCCESS,
  GET_GROUP_FAILED,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILED,
} from "./state";

const initialState = {
  data: {},
  message: null,
  isLoading: false,
  response_status: false,
};

// Define a helper function for common state updates
const updateState = (state, payload, pagination, isLoading = false) => {
  return {
    ...state,
    data: payload,
    message: null,
    isLoading,
  };
};

const groupClientReducer = (
  state = initialState,
  { type, payload, pagination }
) => {
  switch (type) {
    case 'GROUP_LOADING':
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_GROUP_SUCCESS:
      return updateState(state, payload);
    case GET_GROUP_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case ADD_GROUP_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
        response_status: true,
      };
    case ADD_GROUP_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default groupClientReducer;
