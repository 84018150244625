import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  REQUEST_LOADING_QUOTATION,
  GET_SALES_QUOTATION_SUCCESS,
  GET_SALES_QUOTATION_FAILED,
  ADD_SALES_QUOTATION_SUCCESS,
  ADD_SALES_QUOTATION_FAILED,
  EDIT_SALES_QUOTATION_SUCCESS,
  EDIT_SALES_QUOTATION_FAILED,
  DELETE_SALES_QUOTATION_FAILED,
  DELETE_SALES_QUOTATION_SUCCESS,GET_SALES_QUOTATION_DETAILS_FAILED,GET_SALES_QUOTATION_DETAILS_SUCCESS
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getSalesQuotation = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  sortDirection = null,
  sortBy = null,
  dateFilter = null,
  startDate = dateFilter !== "custom_date" ? null : startDate,
  endDate = dateFilter !== "custom_date" ? null : endDate,
  category = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "sales-quotations",
          page,
          limit,
          search,
          dateFilter: dateFilter == ACTION_TYPE.ALL_DATE ? null : dateFilter,
          startDate: dateFilter !== "custom_date" ? null : startDate,
          endDate: dateFilter !== "custom_date" ? null : endDate,
          category: category == ACTION_TYPE.ALL_CATEGORY ? null : category,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getSalesQuotationSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getSalesQuotationSuccess([], null));
        } else {
          dispatch(getSalesQuotationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getSalesQuotationFailed(error.response.data.message));
          }
        } else {
          dispatch(getSalesQuotationFailed(error.message));
        }
      });
  };
};

const getSalesQuotationSuccess = (data, pagination) => {
  return {
    type: GET_SALES_QUOTATION_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getSalesQuotationFailed = (errorMessage) => {
  return {
    type: GET_SALES_QUOTATION_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */
const transformToJson = (inputJson) => {
  const transformedJson = {
    client_id: inputJson.Client.client_id,
    service_id: inputJson.Service.service_id,
    currency: inputJson.Service.currency,
    date: inputJson.Client.date,
    referral: inputJson.Client.referral,
    price:inputJson["Price Information"].price_services_info_to_rp,
    total_price:inputJson["Price Information"].total_price_info_to_rp,
    total_vat:inputJson["Price Information"].vat_info_to_rp,
    total_discount:inputJson["Price Information"].discount_info_to_rp,
    sub_services: inputJson["Sub Service"].map(subService => ({
      sub_service_id: subService.sub_service_id,
      criteria_id: subService.criteria_id,
      price: subService.price,
      quantity: subService.quantity,
      subtotal: subService.subtotal,
      total: subService.total_to_rp,  
      discount: subService.discount,
      vat_percent: Array.isArray(subService.vat_percent) && subService.vat_percent.length > 0 ? parseInt(subService.vat_percent[0], 10) : 0,
      desc: subService.desc,
    })),
    attachments: inputJson.Attachment.attachment,
    remarks: inputJson["Note Section"].remarks,
    note: inputJson["Note Section"].note,
  };

  debugger;
  return transformedJson;
};
export const addSalesQuotation = (token, formData) => {
  // debugger;
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        "sales-quotations",
        transformToJson(formData),
        {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      }
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "sales quotation"));
          dispatch(addSalesQuotationSuccess(response.data.message));
          localStorage.setItem("sales-management/sales-quotation/formValues", JSON.stringify({}));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          alert(response.data.message);
          dispatch(addSalesQuotationFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(addSalesQuotationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(addSalesQuotationFailed(error.response.data.message));
          }
        } else {
          dispatch(addSalesQuotationFailed(error.message));
        }
      });
  };
};

const addSalesQuotationSuccess = (message) => {
  return {
    type: ADD_SALES_QUOTATION_SUCCESS,
    payload: message,
  };
};

const addSalesQuotationFailed = (message) => {
  return {
    type: ADD_SALES_QUOTATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editSalesQuotation = (token, formData, id) => {
  // debugger;
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        `sales-quotations/${id}/update`,
        transformToJson(formData),
        {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      }
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "sales quotation"));
          dispatch(editSalesQuotationSuccess(response.data.message));
          localStorage.setItem("sales-management/sales-quotation/formValues", JSON.stringify({}));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          alert(response.data.message);
          dispatch(editSalesQuotationFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(editSalesQuotationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editSalesQuotationFailed(error.response.data.message));
          }
        } else {
          dispatch(editSalesQuotationFailed(error.message));
        }
      });
  };
};

const editSalesQuotationSuccess = (message) => {
  return {
    type: EDIT_SALES_QUOTATION_SUCCESS,
    payload: message,
  };
};

const editSalesQuotationFailed = (message) => {
  return {
    type: EDIT_SALES_QUOTATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteSalesQuotation = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`sales-quotations${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "sales quotation"));
          dispatch(deleteSalesQuotationSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteSalesQuotationFailed(response.data.message));
        } else {
          dispatch(deleteSalesQuotationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteSalesQuotationFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteSalesQuotationFailed(error.message));
        }
      });
  };
};

const deleteSalesQuotationSuccess = (message) => {
  return {
    type: DELETE_SALES_QUOTATION_SUCCESS,
    payload: message,
  };
};

const deleteSalesQuotationFailed = (message) => {
  return {
    type: DELETE_SALES_QUOTATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};


/* -------------------------------------------------------------------------- */
/*                                 GET DETAIL                                 */
/* -------------------------------------------------------------------------- */

export const getSalesQuotationDetails = ({
  token,
  id = null,
}) => {
  return (dispatch) => {
    dispatch({ type: REQUEST_LOADING_QUOTATION });
    axios
      .get(
        urlRequest({
          url: `sales-quotations/${id}/detail`,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          debugger
          dispatch(getSalesQuotationDetailsSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getSalesQuotationDetailsSuccess(null));
        } else {
          dispatch(getSalesQuotationDetailsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getSalesQuotationDetailsFailed(error.response.data.message));
          }
        } else {
          dispatch(getSalesQuotationDetailsFailed(error.message));
        }
      });
  };
};

const getSalesQuotationDetailsFailed = (errorMessage) => {
  return {
    type: GET_SALES_QUOTATION_DETAILS_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

const getSalesQuotationDetailsSuccess = (data, pagination) => {
  return {
    type: GET_SALES_QUOTATION_DETAILS_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};
