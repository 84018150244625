import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_ARCHIVE_SUCCESS,
  GET_ARCHIVE_FAILED,
  ADD_ARCHIVE_SUCCESS,
  ADD_ARCHIVE_FAILED,
  EDIT_ARCHIVE_SUCCESS,
  EDIT_ARCHIVE_FAILED,
  DELETE_ARCHIVE_FAILED,
  DELETE_ARCHIVE_SUCCESS,
} from "./state";
import { logout } from "../../../auth/clients/login/actions";

import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../util/function";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getArchive = ({
  token,
  page = 1,
  limit = 10,
  searchValue = null,
  searchBy = null,
  dateFilter = null,
  categoryFilter = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(dateFilter, "dateFilter");

  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "archive",
          page,
          limit,
          searchValue,
          searchBy,
          dateFilter:
            dateFilter == ACTION_TYPE.DEFAULT_DATE ? null : dateFilter,
          categoryFilter:
            categoryFilter == ACTION_TYPE.ALL_CATEGORY ? null : categoryFilter,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getArchiveSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getArchiveSuccess([], null));
        } else {
          dispatch(getArchiveFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getArchiveFailed(error.response.data.message));
          }
        } else {
          dispatch(getArchiveFailed(error.message));
        }
      });
  };
};

const getArchiveSuccess = (data, pagination) => {
  return {
    type: GET_ARCHIVE_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getArchiveFailed = (errorMessage) => {
  return {
    type: GET_ARCHIVE_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addArchive = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`archive`, Object.assign({}, formData), headersAPIToken(token))
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "archive"));
          dispatch(addArchiveSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(RunModal("FAILED", `${response.data.message}`));
          dispatch(addArchiveFailed(response.data.message));
        } else {
          dispatch(RunModal("FAILED", `${response.data.message}`));
          dispatch(addArchiveFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(RunModal("FAILED", `${error.response.data.message}`));
            dispatch(addArchiveFailed(error.response.data.message));
          }
        } else {
          dispatch(RunModal("FAILED", `${error.message}`));
          dispatch(addArchiveFailed(error.message));
        }
      });
  };
};

const addArchiveSuccess = (message) => {
  return {
    type: ADD_ARCHIVE_SUCCESS,
    payload: message,
  };
};

const addArchiveFailed = (message) => {
  return {
    type: ADD_ARCHIVE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editArchive = (token, formData, id) => {
  console.log(formData, "formData");
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .patch(
        `archive/${id}`,
        Object.assign({}, formData),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "archive"));
          dispatch(editArchiveSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(RunModal("FAILED", `${response.data.message}`));
          dispatch(editArchiveFailed(response.data.message));
        } else {
          dispatch(RunModal("FAILED", `${response.data.message}`));
          dispatch(editArchiveFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(RunModal("FAILED", `${error.response.data.message}`));
            dispatch(editArchiveFailed(error.response.data.message));
          }
        } else {
          dispatch(RunModal("FAILED", `${error.message}`));
          dispatch(editArchiveFailed(error.message));
        }
      });
  };
};

const editArchiveSuccess = (message) => {
  return {
    type: EDIT_ARCHIVE_SUCCESS,
    payload: message,
  };
};

const editArchiveFailed = (message) => {
  return {
    type: EDIT_ARCHIVE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteArchive = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`archive${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "archive"));
          dispatch(deleteArchiveSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(RunModal("FAILED", `${response.data.message}`));
          dispatch(deleteArchiveFailed(response.data.message));
        } else {
          dispatch(RunModal("FAILED", `${response.data.message}`));
          dispatch(deleteArchiveFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(RunModal("FAILED", `${error.response.data.message}`));
            dispatch(deleteArchiveFailed(error.response.data.message));
          }
        } else {
          dispatch(RunModal("FAILED", `${error.message}`));
          dispatch(deleteArchiveFailed(error.message));
        }
      });
  };
};

const deleteArchiveSuccess = (message) => {
  return {
    type: DELETE_ARCHIVE_SUCCESS,
    payload: message,
  };
};

const deleteArchiveFailed = (message) => {
  return {
    type: DELETE_ARCHIVE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
