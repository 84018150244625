import { REQUEST_LOADING } from "../../../../util/global_state";

import {
  ADD_CLIENT_FAILED,
  ADD_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILED,
  DELETE_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILED,
  EDIT_CLIENT_SUCCESS,
  GET_CLIENT_FAILED,
  GET_CLIENT_SUCCESS,
} from "./state";

const initState = {
  data: [],
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
};

const clientsReducer = (state = initState, { type, payload, pagination }) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_CLIENT_SUCCESS:
      return {
        data: payload,
        pagination: pagination,
        message: null,
        isLoading: false,
      };
    case GET_CLIENT_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
        response_status: true,
      };
    case ADD_CLIENT_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_CLIENT_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_CLIENT_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default clientsReducer;
