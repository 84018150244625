import axios, { headersAPIToken, urlRequest } from "../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../util/global_state";
import {
  GET_STAGE_SUCCESS,
  GET_STAGE_FAILED,
  ADD_STAGE_SUCCESS,
  ADD_STAGE_FAILED,
  EDIT_STAGE_SUCCESS,
  EDIT_STAGE_FAILED,
  DELETE_STAGE_FAILED,
  DELETE_STAGE_SUCCESS,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../util/function";
import { logout } from "../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getStage = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  sortDirection = null,
  sortBy = null,
  typeStage = null,
}) => {
  console.log(sortDirection, sortBy, typeStage, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "stages",
          page,
          limit,
          search,
          sortDirection,
          sortBy,
          typeStage: typeStage == ACTION_TYPE.ALL_TYPE_STAGE ? null : typeStage,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getStageSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getStageSuccess([], null));
        } else {
          dispatch(getStageFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getStageFailed(error.response.data.message));
          }
        } else {
          dispatch(getStageFailed(error.message));
        }
      });
  };
};

const getStageSuccess = (data, pagination) => {
  return {
    type: GET_STAGE_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getStageFailed = (errorMessage) => {
  return {
    type: GET_STAGE_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addStage = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post("stages", formData, headersAPIToken(token))
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "stage"));
          dispatch(addStageSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addStageFailed(response.data.message));
        } else {
          dispatch(addStageFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addStageFailed(error.response.data.message));
          }
        } else {
          dispatch(addStageFailed(error.message));
        }
      });
  };
};

const addStageSuccess = (message) => {
  return {
    type: ADD_STAGE_SUCCESS,
    payload: message,
  };
};

const addStageFailed = (message) => {
  return {
    type: ADD_STAGE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editStage = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .put(`stages/${id}`, formData, headersAPIToken(token))
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "stage"));
          dispatch(editStageSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editStageFailed(response.data.message));
        } else {
          dispatch(editStageFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editStageFailed(error.response.data.message));
          }
        } else {
          dispatch(editStageFailed(error.message));
        }
      });
  };
};

const editStageSuccess = (message) => {
  return {
    type: EDIT_STAGE_SUCCESS,
    payload: message,
  };
};

const editStageFailed = (message) => {
  return {
    type: EDIT_STAGE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteStage = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`stages${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "stage"));
          dispatch(deleteStageSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteStageFailed(response.data.message));
        } else {
          dispatch(deleteStageFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteStageFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteStageFailed(error.message));
        }
      });
  };
};

const deleteStageSuccess = (message) => {
  return {
    type: DELETE_STAGE_SUCCESS,
    payload: message,
  };
};

const deleteStageFailed = (message) => {
  return {
    type: DELETE_STAGE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
