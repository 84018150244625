import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  APPLICATION_LOADING,
  APPLICATION_DETAILS_LOADING,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILED,
  ADD_APPLICATION_SUCCESS,
  ADD_APPLICATION_FAILED,
  EDIT_APPLICATION_SUCCESS,
  EDIT_APPLICATION_FAILED,
  DELETE_APPLICATION_FAILED,
  DELETE_APPLICATION_SUCCESS,
  GET_APPLICATION_DETAILS_FAILED,
  GET_APPLICATION_DETAILS_SUCCESS
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import dayjs from "dayjs";
import { logout } from "../../../auth/clients/login/actions";


const RequestApplicationLoading = () => {
  return { type: APPLICATION_LOADING };
};

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getApplication = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  searchBy = null,
  status = null,
  sortDirection = null,
  sortBy = null,
  period = null,
  category = null,
}) => {
  console.log(page, limit, search, status, sortDirection, sortBy);
  return (dispatch) => {
    dispatch(RequestApplicationLoading());
    axios
      .get(
        urlRequest({
          url: "applications",
          page,
          limit,
          searchValue: search,
          searchBy: search  ? searchBy : null,
          period,
          categoryId: category == ACTION_TYPE.ALL_CATEGORY ? null : category,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortBy,
          sortDirection,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getApplicationSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getApplicationSuccess([], null));
        } else {
          dispatch(getApplicationFailed(response.data.message));
        }
      })
      .catch((error) => {
//         debugger;
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getApplicationFailed(error.response.data.message));
          }
        } else {
          dispatch(getApplicationFailed(error.message));
        }
      });
  };
};

const getApplicationSuccess = (data, pagination) => {
  return {
    type: GET_APPLICATION_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getApplicationFailed = (errorMessage) => {
  return {
    type: GET_APPLICATION_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

export const RequestApplicationDetailLoading = () => {
  return { type: APPLICATION_DETAILS_LOADING };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addApplication = (token, formData) => {
  delete formData.id;
  return (dispatch) => {
    // // Create a FormData object
    // const formDataObject = new FormData();

    // // Function to recursively append data to the FormData object
    // const appendData = (prefix, data) => {
    //   for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //       const value = data[key];
    //       const newKey = `${prefix}.${key}`;

    //       if (value instanceof File) {
    //         // Handle File objects (actual files selected by the user)
    //         formDataObject.append(newKey, value);
    //       } else if (Array.isArray(value)) {
    //         // Handle arrays
    //         value.forEach((item, index) => {
    //           // Check if the item is an object or a primitive value
    //           if (
    //             typeof item === "object" &&
    //             !(item instanceof File) &&
    //             (newKey.endsWith("date") || newKey.endsWith("year"))
    //           ) {
    //             // Recursively handle nested objects in arrays
    //             formDataObject.append(`${newKey}[${index}]`, formatDate(value));
    //           } else if (
    //             typeof item === "object" &&
    //             !(item instanceof File) &&
    //             (!newKey.endsWith("date") || !newKey.endsWith("year"))
    //           ) {
    //             appendData(`${newKey}[${index}]`, item);
    //           } else {
    //             // Handle primitive values in arrays
    //             formDataObject.append(`${newKey}[${index}]`, item);
    //           }
    //         });
    //       } else if (
    //         typeof value === "object" &&
    //         !(value instanceof File) &&
    //         (newKey.endsWith("date") || newKey.endsWith("year"))
    //       ) {
    //         // Handle nested objects (excluding File objects)
    //         formDataObject.append(newKey, formatDate(value));
    //       } else if (
    //         typeof value === "object" &&
    //         !(value instanceof File) &&
    //         (!newKey.endsWith("date") || !newKey.endsWith("year"))
    //       ) {
    //         appendData(newKey, value);
    //       } else if (newKey.endsWith("date")) {
    //         // Format and append date fields
    //         formDataObject.append(newKey, formatDate(value));
    //       } else {
    //         // Handle other values
    //         formDataObject.append(newKey, value);
    //       }
    //     }
    //   }
    // };

    // // Function to format dates
    // const formatDate = (dateString) => {
    //   return dayjs(dateString).isValid()
    //     ? dayjs(dateString).format("DD-MM-YYYY ")
    //     : dayjs().format("DD-MM-YYYY ");
    // };

    // appendData("StepOne", formData.StepOne);
    // appendData("StepTwo", formData.StepTwo);
    // appendData("StepThree", formData.StepThree);
    // appendData("StepFour", formData.StepTwo);

    // axios
    //   .post("applications", formDataObject, {
    //     headers: {
    //       "Content-Type": "multipart/form-data", // Set the content type for file uploads
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })

    dispatch(RequestApplicationLoading());
    axios
      .post("applications",  {
              status:'Pending',
              data: formData
        }, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "application"));
          dispatch(addApplicationSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addApplicationFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(addApplicationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(addApplicationFailed(error.response.data.message));
          }
        } else {
          dispatch(addApplicationFailed(error.message));
        }
      });
  };
};


export const addApplicationSaveAsDraft = (token, formData) => {
  delete formData.id;
  return (dispatch) => {
    // // Create a FormData object
    // const formDataObject = new FormData();

    // // Function to recursively append data to the FormData object
    // const appendData = (prefix, data) => {
    //   for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //       const value = data[key];
    //       const newKey = `${prefix}.${key}`;

    //       if (value instanceof File) {
    //         // Handle File objects (actual files selected by the user)
    //         formDataObject.append(newKey, value);
    //       } else if (Array.isArray(value)) {
    //         // Handle arrays
    //         value.forEach((item, index) => {
    //           // Check if the item is an object or a primitive value
    //           if (
    //             typeof item === "object" &&
    //             !(item instanceof File) &&
    //             (newKey.endsWith("date") || newKey.endsWith("year"))
    //           ) {
    //             // Recursively handle nested objects in arrays
    //             formDataObject.append(`${newKey}[${index}]`, formatDate(value));
    //           } else if (
    //             typeof item === "object" &&
    //             !(item instanceof File) &&
    //             (!newKey.endsWith("date") || !newKey.endsWith("year"))
    //           ) {
    //             appendData(`${newKey}[${index}]`, item);
    //           } else {
    //             // Handle primitive values in arrays
    //             formDataObject.append(`${newKey}[${index}]`, item);
    //           }
    //         });
    //       } else if (
    //         typeof value === "object" &&
    //         !(value instanceof File) &&
    //         (newKey.endsWith("date") || newKey.endsWith("year"))
    //       ) {
    //         // Handle nested objects (excluding File objects)
    //         formDataObject.append(newKey, formatDate(value));
    //       } else if (
    //         typeof value === "object" &&
    //         !(value instanceof File) &&
    //         (!newKey.endsWith("date") || !newKey.endsWith("year"))
    //       ) {
    //         appendData(newKey, value);
    //       } else if (newKey.endsWith("date")) {
    //         // Format and append date fields
    //         formDataObject.append(newKey, formatDate(value));
    //       } else {
    //         // Handle other values
    //         formDataObject.append(newKey, value);
    //       }
    //     }
    //   }
    // };

    // // Function to format dates
    // const formatDate = (dateString) => {
    //   return dayjs(dateString).isValid()
    //     ? dayjs(dateString).format("DD-MM-YYYY ")
    //     : dayjs().format("DD-MM-YYYY ");
    // };

    // appendData("StepOne", formData.StepOne);
    // appendData("StepTwo", formData.StepTwo);
    // appendData("StepThree", formData.StepThree);
    // appendData("StepFour", formData.StepTwo);

    // axios
    //   .post("applications", formDataObject, {
    //     headers: {
    //       "Content-Type": "multipart/form-data", // Set the content type for file uploads
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })

    dispatch(RequestApplicationLoading());
    axios
      .post("applications",
            {
              status:'Draft',
              data: formData
            }, {
            headers: {
              "Content-Type": "multipart/form-data", // Set the content type for file uploads
              Authorization: `Bearer ${token}`,
            },
      })
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("DRAFT", "application"));
          dispatch(addApplicationSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addApplicationFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(addApplicationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(addApplicationFailed(error.response.data.message));
          }
        } else {
          dispatch(addApplicationFailed(error.message));
        }
      });
  };
};

const addApplicationSuccess = (message) => {
  return {
    type: ADD_APPLICATION_SUCCESS,
    payload: message,
  };
};

const addApplicationFailed = (message) => {
  return {
    type: ADD_APPLICATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editApplication = (token, formData, id) => {
//   debugger;
  return async (dispatch) => {
    dispatch(RequestApplicationLoading());
        // .post(`applications/${id}/update`,
        //     status: 'Pending',
        //     data: formData
        // , {
    axios.post(`applications/${id}/update`, {
         status:'Pending',
         data: formData
        }, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "application"));
          dispatch(editApplicationSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editApplicationFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(editApplicationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(editApplicationFailed(error.response.data.message));
          }
        } else {
          dispatch(editApplicationFailed(error.message));
        }
      });
  };
};

export const editApplicationSaveAsDraft = (token, formData, id) => {
//   debugger;
  return async (dispatch) => {
    dispatch(RequestApplicationLoading());
        // .post(`applications/${id}/update`,
        //     status: 'Pending',
        //     data: formData
        // , {
    axios.post(`applications/${id}/update`, {
         status:'Draft',
         data: formData
        }, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DRAFT", "application"));
          dispatch(editApplicationSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editApplicationFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(editApplicationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(editApplicationFailed(error.response.data.message));
          }
        } else {
          dispatch(editApplicationFailed(error.message));
        }
      });
  };
};


const editApplicationSuccess = (message) => {
  return {
    type: EDIT_APPLICATION_SUCCESS,
    payload: message,
  };
};

const editApplicationFailed = (message) => {
  return {
    type: EDIT_APPLICATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteApplication = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestApplicationLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`applications${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "application"));
          dispatch(deleteApplicationSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteApplicationFailed(response.data.message));
        } else {
          dispatch(deleteApplicationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteApplicationFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteApplicationFailed(error.message));
        }
      });
  };
};

const deleteApplicationSuccess = (message) => {
  return {
    type: DELETE_APPLICATION_SUCCESS,
    payload: message,
  };
};

const deleteApplicationFailed = (message) => {
  return {
    type: DELETE_APPLICATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};



/* -------------------------------------------------------------------------- */
/*                                 GET DETAIL                                 */
/* -------------------------------------------------------------------------- */

export const getApplicationDetails = ({
  token,
  id = null,
}) => {
  return (dispatch) => {
    dispatch(RequestApplicationDetailLoading());
    axios
      .get(
        urlRequest({
          url: `applications/${id}`,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          debugger;
          dispatch(getApplicationDetailsSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getApplicationDetailsSuccess(null));
        } else {
          dispatch(getApplicationDetailsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getApplicationDetailsFailed(error.response.data.message));
          }
        } else {
          dispatch(getApplicationDetailsFailed(error.message));
        }
      });
  };
};

const getApplicationDetailsFailed = (errorMessage) => {
  return {
    type: GET_APPLICATION_DETAILS_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

const getApplicationDetailsSuccess = (data, pagination) => {
  return {
    type: GET_APPLICATION_DETAILS_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};
