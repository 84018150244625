import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  table,
  borderRadius,
  fourColor,
  FacebookCircularProgress,
} from "../../util/style";
import axios from "../../core/axios_config";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../util/function";

import EditIcon from "./../../assets/edit.png";
import CheckIcon from "./../../assets/check.png";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { RunModal } from "../../util/global_state";
import ModalConfirmCancel from "../../components/ConfirmCancel";

const ScheduleAnnualLeave = () => {
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [a, setA] = useState(false);
  const [b, setB] = useState(false);
  const [formValues, setFormValues] = useState({
    schedule: {
      clock_in_time: "00:00:00",
      clock_out_time: "00:00:00",
      delay_tolerance: 30,
    },
    annual_leaves: [],
  });

  const scheduleIsActive = (val) => {
    setA(val);
    return a;
  };

  const annualLeaveIsActive = (val) => {
    setB(val);
    return b;
  };

  const fetchData = async (token) => {
    try {
      setIsLoading(true);
      const response = await axios.get("schedule-annual-leaves", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Directly assign fetched data to formValues
      setFormValues({
        schedule: response.data.schedule,
        annual_leaves: response.data.annual_leaves,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(token); // Call the fetchData function
  }, [token]);

  return (
    <div style={{ ...table }}>
      {isLoading ? (
        <FacebookCircularProgress />
      ) : (
        <>
          {/* -------------------------------- Schedule -------------------------------- */}
          <Schedule
            data={{
              state: formValues.schedule,
              annualLeaveIsActive: b,
            }}
            scheduleIsActive={scheduleIsActive}
          />
          <br />
          {/* -------------------------------- Annual Leave -------------------------------- */}
          <AnnualLeave
            data={{
              state: formValues.annual_leaves,
              scheduleIsActive: a,
            }}
            annualLeaveIsActive={annualLeaveIsActive}
          />
        </>
      )}
    </div>
  );
};

export default ScheduleAnnualLeave;

const Schedule = ({ data, scheduleIsActive }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const isChanged = { clock_in: false, clock_out: false };
  const [editing, setEditing] = useState(false);
  const [formValues, setFormValues] = useState(data.state);
  const [changed, setChanged] = useState(isChanged);
  const dispatch = useDispatch();

  useEffect(() => {
    setFormValues(data.state);
  }, [data.state]);

  const handleEditClick = () => {
    setEditing(true);
    scheduleIsActive(true);
  };

  const handleCancelClick = () => {
    scheduleIsActive(false);
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setEditing(false);
    setConfirmModal(false);
    setFormValues(data.state);
  };

  const getClockInTime = () => {
    if (changed.clock_in) {
      return formValues.clock_in_time;
    } else {
      return dayjs(formValues.clock_in_date);
    }
  };

  const getClockOutTime = () => {
    if (changed.clock_out) {
      return formValues.clock_out_time;
    } else {
      return dayjs(formValues.clock_out_date);
    }
  };

  const handleChange = (field, value) => {
    debugger;
    if (field == "clock_in_time") {
      if (dayjs(value).isValid()) {
        setChanged((prevValues) => ({
          ...prevValues,
          clock_in: true,
        }));
        setFormValues((prevValues) => ({
          ...prevValues,
          [field]: value,
        }));
      }
    } else if (field == "clock_out_time") {
      if (dayjs(value).isValid()) {
        setChanged((prevValues) => ({
          ...prevValues,
          clock_out: true,
        }));
        setFormValues((prevValues) => ({
          ...prevValues,
          [field]: value,
        }));
      }
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [field]: value,
      }));
    }
  };

  const updateSchedule = async () => {
    try {
      const response = await axios.post(
        "update-schedule",
        Object.assign({}, formValues, {
          clock_in_time: dayjs(formValues.clock_in_time).isValid()
            ? dayjs(formValues.clock_in_time).format("HH:mm")
            : dayjs(
                dayjs().format("YYYY-MM-DD") + " " + formValues.clock_in_time
              ).format("HH:mm"),
          clock_out_time: dayjs(formValues.clock_out_time).isValid()
            ? dayjs(formValues.clock_out_time).format("HH:mm")
            : dayjs(
                dayjs().format("YYYY-MM-DD") + " " + formValues.clock_out_time
              ).format("HH:mm"),
        }),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { data, status } = response;
      if (status) {
        dispatch(handleCancelClick);
        dispatch(RunModal("EDITED", "scheduled"));
        setEditing(false);
        setConfirmModal(false);
      } else {
        alert(data.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Card
      style={{
        boxShadow: "unset",
        borderRadius,
        border: "1px solid #ddd",
      }}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div>
            <Typography
              variant="h5"
              style={{ fontSize: "1.4rem", fontWeight: 800 }}
            >
              Schedule
            </Typography>
            <Typography variant="subtitle1">
              Define employee schedule here!
            </Typography>
          </div>
          <div>
            {!editing && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                style={{ marginRight: "10px" }}
                onClick={handleEditClick}
                startIcon={<img src={EditIcon} style={{ width: "20px" }} />}
                disabled={data.annualLeaveIsActive == true}
              >
                Update Schedule
              </Button>
            )}
            {editing && (
              <>
                <Button
                  variant="outlined"
                  disableElevation
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    handleCancelClick();
                    setConfirmModal(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  startIcon={<img src={CheckIcon} style={{ width: "20px" }} />}
                  onClick={updateSchedule}
                >
                  Save Schedule
                </Button>
              </>
            )}
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Card
              style={{
                boxShadow: "unset",
                borderRadius,
                border: "1px solid #ddd",
              }}
            >
              <CardContent>
                <Typography variant="h6">Clock In</Typography>
                {editing ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                      inputProps={{
                        style: {
                          fontSize: "2.125rem",
                          fontWeight: "bold",
                          width: 100,
                        },
                      }}
                      variant="standard"
                      value={getClockInTime()}
                      onChange={(newValue) =>
                        handleChange("clock_in_time", newValue)
                      }
                      format="HH:mm"
                    />
                  </LocalizationProvider>
                ) : (
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", marginTop: ".5rem" }}
                  >
                    {formValues.clock_in_date &&
                    !dayjs(formValues.clock_in_time).isValid()
                      ? dayjs(formValues.clock_in_date)
                          .format("HH:mm")
                          .toString()
                      : dayjs(formValues.clock_in_time)
                          .format("HH:mm")
                          .toString()}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card
              style={{
                boxShadow: "unset",
                borderRadius,
                border: "1px solid #ddd",
              }}
            >
              <CardContent>
                <Typography variant="h6">Clock Out</Typography>
                {editing ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                      inputProps={{
                        style: {
                          fontSize: "2.125rem",
                          fontWeight: "bold",
                          width: 100,
                        },
                      }}
                      variant="standard"
                      value={getClockOutTime()}
                      defaultValue={formValues.clock_out_date}
                      onChange={(newValue) =>
                        handleChange("clock_out_time", newValue)
                      }
                      format="HH:mm"
                    />
                  </LocalizationProvider>
                ) : (
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", marginTop: ".5rem" }}
                  >
                    {formValues.clock_out_date &&
                    !dayjs(formValues.clock_out_time).isValid()
                      ? dayjs(formValues.clock_out_date)
                          .format("HH:mm")
                          .toString()
                      : dayjs(formValues.clock_out_time)
                          .format("HH:mm")
                          .toString()}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card
              style={{
                boxShadow: "unset",
                borderRadius,
                border: "1px solid #ddd",
              }}
            >
              <CardContent>
                <Typography variant="h6">Delay Tolerance</Typography>
                {editing ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <TextField
                      inputProps={{
                        style: {
                          fontSize: "2.125rem",
                          fontWeight: "bold",
                          width: 60,
                        },
                      }}
                      variant="standard"
                      type="number"
                      value={formValues.delay_tolerance}
                      onKeyPress={(event) => {
                        const inputValue = event.key;
                        const currentValue = event.target.value;

                        /*
                         * OLD, dimatiin dulu
                        // Allow numbers and prevent minus sign
                        if (!/[0-9]/.test(inputValue)) {
                          event.preventDefault();
                        }

                        // Prevent negative numbers
                        if (
                          inputValue === "-" ||
                          currentValue === "0" ||
                          inputValue.startsWith("00")
                        ) {
                          event.preventDefault();
                        }
                        */
                      }}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        let newValue;
                        console.log(inputValue, 'SATU')
                        if (inputValue === "") {
                          newValue = 0; // Set to 0 if input is empty
                        } else {
                          newValue = parseInt(inputValue, 10);
                          if (isNaN(newValue) || newValue < 0) {
                            newValue = 0; // Set to 0 if input is not a valid number or negative
                          }
                        }
                        console.log(newValue, 'DUA')
                        handleChange("delay_tolerance", newValue);
                      }}
                    />
                    <span style={{ color: fourColor }}>Minute</span>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      marginTop: ".5rem",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", marginRight: "5px" }}
                    >
                      {formValues.delay_tolerance}
                    </Typography>
                    <span style={{ color: fourColor }}> Minute</span>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Card
              style={{
                boxShadow: "unset",
                borderRadius,
                border: "1px solid #ddd",
              }}
            >
              <CardContent>
                <Typography variant="h6">Radius Tolerance</Typography>
                {editing ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <TextField
                      inputProps={{
                        style: {
                          fontSize: "2.125rem",
                          fontWeight: "bold",
                          width: 60,
                        },
                      }}
                      variant="standard"
                      type="number"
                      value={formValues.radius_tolerance}
                      onKeyPress={(event) => {
                        const inputValue = event.key;
                        const currentValue = event.target.value;

                        /*
                         * OLD, dimatiin dulu
                        // Allow numbers and prevent minus sign
                        if (!/[0-9]/.test(inputValue)) {
                          event.preventDefault();
                        }

                        // Prevent negative numbers
                        if (
                          inputValue === "-" ||
                          currentValue === "0" ||
                          inputValue.startsWith("00")
                        ) {
                          event.preventDefault();
                        }
                        */
                      }}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        let newValue;

                        if (inputValue === "") {
                          newValue = 0; // Set to 0 if input is empty
                        } else {
                          newValue = parseInt(inputValue, 10);
                          if (isNaN(newValue) || newValue < 0) {
                            newValue = 0; // Set to 0 if input is not a valid number or negative
                          }
                        }
                        handleChange("radius_tolerance", newValue);
                      }}
                    />
                    <span style={{ color: fourColor }}>Meter</span>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      marginTop: ".5rem",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ fontWeight: "bold", marginRight: "5px" }}
                    >
                      {formValues.radius_tolerance}
                    </Typography>
                    <span style={{ color: fourColor }}> Meter</span>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </Card>
  );
};

const AnnualLeave = ({ data, annualLeaveIsActive }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [editing, setEditing] = useState(false);
  const [formValues, setFormValues] = useState(data.state);
  const dispatch = useDispatch();

  useEffect(() => {
    setFormValues(data.state);
    localStorage.setItem("annualLeave", JSON.stringify(data.state));
  }, [data.state]);

  const handleEditClick = () => {
    setEditing(true);
    annualLeaveIsActive(true);
    localStorage.setItem("annualLeave", JSON.stringify(data.state));
  };

  const handleCancelClick = (withConfirmCancel = false) => {
    annualLeaveIsActive(false);
    // if (withConfirmCancel) setConfirmModal(true);
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setEditing(false);
    setConfirmModal(false);
    setFormValues(JSON.parse(localStorage.getItem("annualLeave")));
    localStorage.removeItem("annualLeave");
  };

  const handleValueChange = (index, field, newValue) => {
    setFormValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index][field] = newValue;
      return updatedValues;
    });
  };

  const updateAnnualLeave = async () => {
    try {
      const response = await axios.post("update-annual-leave", formValues, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { data, status } = response;
      if (status) {
        dispatch(handleCancelClick);
        dispatch(RunModal("EDITED", "annual leave"));
        setEditing(false);
        localStorage.removeItem("annualLeave");
      } else {
        alert(data.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Card
      style={{
        boxShadow: "unset",
        borderRadius,
        border: "1px solid #ddd",
      }}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div>
            <Typography
              variant="h5"
              style={{ fontSize: "1.4rem", fontWeight: 800 }}
            >
              Annual Leave
            </Typography>
            <Typography variant="subtitle1">
              Define annual leave for all employees here!
            </Typography>
          </div>
          <div>
            {!editing && (
              <Button
                variant="contained"
                color="primary"
                disableElevation
                style={{ marginRight: "10px" }}
                onClick={handleEditClick}
                startIcon={<img src={EditIcon} style={{ width: "20px" }} />}
                disabled={data.scheduleIsActive == true}
              >
                Update Annual Leave
              </Button>
            )}
            {editing && (
              <>
                <Button
                  variant="outlined"
                  disableElevation
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    handleCancelClick();
                    setConfirmModal(true);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  startIcon={<img src={CheckIcon} style={{ width: "20px" }} />}
                  onClick={updateAnnualLeave}
                >
                  Save Annual Leave
                </Button>
              </>
            )}
          </div>
        </div>

        <Grid container spacing={2}>
          {formValues.map((item, index) => (
            <Grid item xs={6} sm={6} key={item.id}>
              <Card
                style={{
                  boxShadow: "unset",
                  borderRadius,
                  border: "1px solid #ddd",
                }}
              >
                <CardContent>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    Employee Working Period {item.min_year}-
                    {item.max_year || "Over"} years
                  </Typography>
                  {editing ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <TextField
                        inputProps={{
                          style: {
                            fontSize: "1.7rem",
                            fontWeight: "bold",
                            width: 30,
                          },
                        }}
                        onKeyPress={(event) => {
                          const inputValue = event.key;
                          const currentValue = item.day.toString();

                          // Allow numbers and prevent minus sign
                          if (!/[0-9]/.test(inputValue)) {
                            event.preventDefault();
                          }

                          // Prevent negative numbers
                          if (inputValue === "-" && currentValue === "") {
                            event.preventDefault();
                          }
                        }}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          let newValue;

                          if (inputValue === "") {
                            newValue = 0; // Set to 0 if input is empty
                          } else {
                            newValue = parseInt(inputValue, 10);
                            if (isNaN(newValue) || newValue < 0) {
                              newValue = 0; // Set to 0 if input is not a valid number or negative
                            } else if (newValue > 31) {
                              newValue = 31; // Set to 31 if input is greater than 31
                            }
                          }
                          handleValueChange(index, "day", newValue);
                        }}
                        variant="standard"
                        value={item.day}
                      />
                      <span
                        style={{
                          color: fourColor,
                          marginLeft: 5,
                          fontSize: "15px",
                          fontWeight: 500,
                        }}
                      >
                        Days Leave Allowances
                      </span>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginTop: ".5rem",
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          marginRight: "5px",
                          fontSize: "1.7rem",
                        }}
                      >
                        {item.day}
                      </Typography>
                      <span
                        style={{
                          color: fourColor,
                          marginLeft: 5,
                          fontSize: "15px",
                          fontWeight: 500,
                        }}
                      >
                        Days Leave Allowances
                      </span>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </Card>
  );
};
