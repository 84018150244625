export const GET_STOCK_IN_SUCCESS = "GET STOCK IN SUCCESS";
export const GET_STOCK_IN_FAILED = "GET STOCK IN FAILED";

export const ADD_STOCK_IN_SUCCESS = "ADD STOCK IN SUCCESS";
export const ADD_STOCK_IN_FAILED = "ADD STOCK IN FAILED";

export const UPDATE_STOCK_IN_SUCCESS = "UPDATE STOCK IN SUCCESS";
export const UPDATE_STOCK_IN_FAILED = "UPDATE STOCK IN FAILED";

export const DELETE_STOCK_IN_SUCCESS = "DELETE STOCK IN SUCCESS";
export const DELETE_STOCK_IN_FAILED = "DELETE STOCK IN FAILED";