import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_EMPLOYEE_TRANSFER_SUCCESS,
  GET_EMPLOYEE_TRANSFER_FAILED,
  ADD_EMPLOYEE_TRANSFER_SUCCESS,
  ADD_EMPLOYEE_TRANSFER_FAILED,
  EDIT_EMPLOYEE_TRANSFER_SUCCESS,
  EDIT_EMPLOYEE_TRANSFER_FAILED,
  DELETE_EMPLOYEE_TRANSFER_FAILED,
  DELETE_EMPLOYEE_TRANSFER_SUCCESS,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getEmployeTransfer = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "employe-transfers",
          page,
          limit,
          search,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getEmployeTransferSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getEmployeTransferSuccess([], null));
        } else {
          dispatch(getEmployeTransferFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getEmployeTransferFailed(error.response.data.message));
          }
        } else {
          dispatch(getEmployeTransferFailed(error.message));
        }
      });
  };
};

const getEmployeTransferSuccess = (data, pagination) => {
  return {
    type: GET_EMPLOYEE_TRANSFER_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getEmployeTransferFailed = (errorMessage) => {
  return {
    type: GET_EMPLOYEE_TRANSFER_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */
const transformToJson = (inputJson) => {
  debugger;
  const transformedJson = {
    user_id:inputJson['Employee Information'].user_id,
    effective_date:inputJson['Employee Information'].effective_date,
    transfer_type_id:inputJson['Employee Information'].transfer_type_id,
    employe_status_id:inputJson['Manage Transfer'].employe_status_id,
    position_id:inputJson['Manage Transfer'].position_id,
    job_level_id:inputJson['Manage Transfer'].job_level_id,
    approval_id:inputJson['Manage Transfer'].approval_id,
    organization_id:inputJson['Manage Transfer'].organization_id,
    notes: inputJson["Notes"].notes,
    attachments: inputJson.Attachment.attachment,
  };

  return transformedJson;
};

export const addEmployeTransfer = (token, formData) => {
  delete formData.id
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        "employe-transfers",
        transformToJson(formData),
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for file uploads
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "employee transfer"));
          dispatch(addEmployeTransferSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addEmployeTransferFailed(response.data.message));
        } else {
          dispatch(addEmployeTransferFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addEmployeTransferFailed(error.response.data.message));
          }
        } else {
          dispatch(addEmployeTransferFailed(error.message));
        }
      });
  };
};

const addEmployeTransferSuccess = (message) => {
  return {
    type: ADD_EMPLOYEE_TRANSFER_SUCCESS,
    payload: message,
  };
};

const addEmployeTransferFailed = (message) => {
  return {
    type: ADD_EMPLOYEE_TRANSFER_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editEmployeTransfer = (token, formData, id) => {
  debugger;
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        `employe-transfers/${id}`,
        transformToJson(formData),
        {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      }
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "employee transfer"));
          dispatch(editEmployeTransferSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editEmployeTransferFailed(response.data.message));
        } else {
          dispatch(editEmployeTransferFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editEmployeTransferFailed(error.response.data.message));
          }
        } else {
          dispatch(editEmployeTransferFailed(error.message));
        }
      });
  };
};

const editEmployeTransferSuccess = (message) => {
  return {
    type: EDIT_EMPLOYEE_TRANSFER_SUCCESS,
    payload: message,
  };
};

const editEmployeTransferFailed = (message) => {
  return {
    type: EDIT_EMPLOYEE_TRANSFER_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteEmployeTransfer = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`employe-transfers${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "employee transfer"));
          dispatch(deleteEmployeTransferSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteEmployeTransferFailed(response.data.message));
        } else {
          dispatch(deleteEmployeTransferFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteEmployeTransferFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteEmployeTransferFailed(error.message));
        }
      });
  };
};

const deleteEmployeTransferSuccess = (message) => {
  return {
    type: DELETE_EMPLOYEE_TRANSFER_SUCCESS,
    payload: message,
  };
};

const deleteEmployeTransferFailed = (message) => {
  return {
    type: DELETE_EMPLOYEE_TRANSFER_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
