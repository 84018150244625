export const GET_GA_ASSET_SUCCESS = "GET GA ASSET SUCCESS";
export const GET_GA_ASSET_FAILED = "GET GA ASSET FAILED";

export const ADD_GA_ASSET_SUCCES = "POST GA ASSET SUCCESS";
export const ADD_GA_ASSET_FAILED = "POST GA ASSET FAILED";

export const UPDATE_GA_ASSET_SUCCESS = "UPDATE GA ASSET SUCCESS";
export const UPDATE_GA_ASSET_FAILED = "UPDATE GA ASSET FAILED";

export const DELETE_GA_ASSET_SUCCESS = "DELETE GA ASSET SUCCES";
export const DELETE_GA_ASSET_FAILED = "DELETE GA ASSET FAILED";

export const DETAIL_GA_ASSET_SUCCESS = "DETAIL GA ASSET SUCCESS";
export const DETAIL_GA_ASSET_FAILED = "DETAIL GA ASSET FAILED";
