import { REQUEST_LOADING } from "../../../../util/global_state";
import {
  ADD_GA_ASSET_FAILED,
  ADD_GA_ASSET_SUCCES,
  DELETE_GA_ASSET_FAILED,
  DELETE_GA_ASSET_SUCCESS,
  DETAIL_GA_ASSET_FAILED,
  DETAIL_GA_ASSET_SUCCESS,
  GET_GA_ASSET_FAILED,
  GET_GA_ASSET_SUCCESS,
  UPDATE_GA_ASSET_FAILED,
  UPDATE_GA_ASSET_SUCCESS,
} from "./state";

const initialState = {
  isLoading: false,
  data: null,
  pagination: null,
  message: null,
  detail: null,
};

const assetsReducer = (state = initialState, { type, payload, pagination }) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_GA_ASSET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        pagination: pagination,
      };
    case DETAIL_GA_ASSET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: payload,
      };
    case GET_GA_ASSET_FAILED:
    case DETAIL_GA_ASSET_FAILED:
    case UPDATE_GA_ASSET_SUCCESS:
    case UPDATE_GA_ASSET_FAILED:
    case DELETE_GA_ASSET_SUCCESS:
    case DELETE_GA_ASSET_FAILED:
    case ADD_GA_ASSET_SUCCES:
    case ADD_GA_ASSET_FAILED:
      return {
        ...state,
        isLoading: false,
        message: payload,
      };
    default:
      return state;
  }
};

export default assetsReducer;
