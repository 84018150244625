import React, { useEffect, useRef, useState } from "react";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
} from "@mui/material";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../../util/style";
import ZoomOutIcon from "./../../../../assets/zoom-out.png";
import ZoomInIcon from "./../../../../assets/zoom-in.png";
import PrinterGrayIcon from "./../../../../assets/printer-gray.png";
import DownloadGrayIcon from "./../../../../assets/download-gray.png";
import boderImg from "./../../../../assets/border.png";
import logo from "./../../../../logo.png";
import Signature from "./Signature";
import { formatValue } from "../detail_payment.hooks";
import {
  createAliasForCategory,
  getTimeFromDate,
} from "../parts/imutable_state";
import ApprovalLine from "./ApprovalLine";

//* Main Function
const PreviewPDF = ({
  useStyles,
  detailPR,
  isDoneFetchHeightDocumentPreview,
  documentPreviewForPrintRef,
  mode,
}) => {
  const [fontSize, setFontSize] = useState(12); // Initial font size
  const [percentage, setPercentage] = useState(100); // Initial percentage text
  const documentPreviewRef = useRef(null);
  const { renderValue } = formatValue(detailPR);

  //* =========== Primitif Var ===============

  const statusColors = {
    pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
    approved: { bgColor: successSurfaceColor, colors: successMainColor },
    rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
    // Add more statuses as needed
  };

  useEffect(() => {
    // Apply the zoom effect to specific elements when fontSize changes
    if (documentPreviewRef.current) {
      const applyZoomToChildren = (element) => {
        if (element.childNodes.length > 0) {
          for (const childNode of element.childNodes) {
            applyZoomToChildren(childNode);
          }
        }
        if (element.style) {
          const tagName = element.tagName.toLowerCase();
          const className = element.className.toLowerCase();
          // if (tagName === 'h1' || tagName === 'h2' || tagName === 'h3' || tagName === 'h4' || tagName === 'h5' || tagName === 'h6') {
          //   // Apply +1px zoom to h1, h2, h3, h4, h5, h6
          //   element.style.fontSize = `${fontSize + 1}px`;
          // } else {
          //   element.style.fontSize = `${fontSize}px`;
          // }

          const hasNotEffectClass =
            className && className.includes("not-effect");

          if (!hasNotEffectClass) {
            // Apply +1px zoom to h1, h2, h3, h4, h5, h6 if it doesn't have the 'not-effect' class
            element.style.fontSize = `${fontSize + 1}px`;
          } else {
            // Apply normal zoom if it has the 'not-effect' class
            element.style.fontSize = `${element.style.fontSize}px`;
          }
        }
      };
      applyZoomToChildren(documentPreviewRef.current);
    }
  }, [fontSize]);

  //* ============= Handle ==============

  const handleZoomIn = () => {
    setFontSize((prevSize) => prevSize + 1);
    setPercentage((prevPercentage) => prevPercentage + 10);
  };

  const handleZoomOut = () => {
    setFontSize((prevSize) => Math.max(prevSize - 1, 1));
    setPercentage((prevPercentage) => Math.max(prevPercentage - 10, 10));
  };

  const validateValue = (key, value) => {
    switch (key) {
      case "Client":
        console.log(value);
        return ": ".concat(value.name);
      case "Create At":
        return `${renderValue(value, key)} ${getTimeFromDate(value)}`;
      case "Category":
        return createAliasForCategory(value);
      default:
        return renderValue(value, key);
    }
  };

  //* ========== Component ===============

  const renderGridItemsFromObject = (object) => {
    const alias = {
      "No. Reference": "No",
      "Created By": "User",
      "Create At": "Date",
    };
    if (object.Category === "Services") {
      alias["Remarks"] = "Remark";
    }

    /**
     * remarks[];
     */

    const includedPropery = Object.keys(alias);
    return Object.entries(object).map(([key, value]) =>
      // Skip rendering "Request to"
      includedPropery.includes(key) && value !== null ? (
        <React.Fragment key={key}>
          <Grid item xs={4}>
            <Typography variant="body1">{alias[key]}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
              {renderValue(value, key)}
            </Typography>
          </Grid>
        </React.Fragment>
      ) : null
    );
  };

  const renderRequestedServices = (object) => {
    const alias = {
      "Due Date": "Due Date",
      "Create At": "Create At",
      Note: "Note",
      Total: "Total",
      "Pay At": "Pay At",
    };

    const listKey = ["Due Date", "Create At", "Note", "Total", "Pay At"];

    if (detailPR.Category === "Services") {
      listKey.unshift("Client", "Service", "Sub Service");
      alias.Service = "Service";
      alias["Sub Service"] = "Sub Service";
      alias.Client = "Client Name";
    }

    // const includedProperty = Object.keys(alias);

    return (
      <>
        {/* {Object.entries(object).map(([key, value]) => {
          // console.log({ key, value, index });
          return value !== undefined &&
            value !== null &&
            includedProperty.includes(key) ? (
            <React.Fragment key={key}>
              <Grid item xs={4}>
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
                    {alias[key]}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    textAlign: key === "Total" ? "right" : "left",
                  }}
                >
                  {validateValue(key, value)}
                </Typography>
              </Grid>
            </React.Fragment>
          ) : null;
        })} */}

        {listKey.map((key) => (
          <React.Fragment key={key}>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
                  {key === "Client" ? "Client Name" : key}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  textAlign: key === "Total" ? "right" : "left",
                }}
              >
                {validateValue(key, object[key])}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
        {/* Render Note in bottom */}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
              Status
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ApprovalLine detailDocument={detailPR} />
          {/* <Typography
            variant="subtitle1".e
            sx={{
              fontWeight: 500,
            }}
          >
            <span>:&nbsp;</span>
            {object?.Note}
          </Typography> */}
        </Grid>
      </>
    );
  };

  const SignatureSectionComp = () => (
    <Grid
      container
      spacing={3}
      sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 1, mb: 2.5 }}
    >
      <Grid item xs={4} sx={{ ...justifyContentCenter }}>
        <Signature companyName="User" signatureText={detailPR["Created By"]} />
      </Grid>
      <Grid item xs={4} sx={{ ...justifyContentCenter }}>
        <Signature companyName="PIC" signatureText={detailPR?.PIC?.name} />
      </Grid>
      <Grid item xs={4} sx={{ ...justifyContentCenter }}>
        {/* TODO: Change to relative to data */}
        <Signature
          companyName="Accounting"
          signatureText={detailPR["Accounting"]}
        />
      </Grid>
    </Grid>
  );

  const PriceInformation = () => (
    <Grid
      container
      spacing={2}
      sx={{
        "& .MuiGrid-item": { paddingTop: "7px" },
        mt: 0,
        padding: "20px",
      }}
    >
      <Grid item xs={8}>
        <Typography
          variant="body2"
          sx={{ textAlign: "right", fontWeight: "bold" }}
        >
          Total
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 800, textAlign: "right" }}
        >
          {renderValue(detailPR.Total, "total", false)}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderFooter = () => (
    <div className={classes.footer}>
      <Box sx={{ display: "flex", gap: ".5rem" }}>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          +6221 2986 5888
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          pacific@pacificpatent.com
        </Typography>
      </Box>
      <Typography variant="subtitle2" sx={{ color: "white" }}>
        www.pacificpatent.com
      </Typography>
    </div>
  );

  //! Not Used
  //   const renderPriceInformation = (object) => {
  //     return Object.entries(object).map(([key, value]) =>
  //       (value !== undefined || value !== null) &&
  //       includedProperty.includes(key) ? (
  //         <React.Fragment key={key}>
  //           <Grid item xs={8}>
  //             <Typography
  //               variant="body2"
  //               sx={{ textAlign: "right", fontWeight: "bold" }}
  //             >
  //               {key}
  //             </Typography>
  //           </Grid>
  //           <Grid item xs={4}>
  //             <Typography
  //               variant="subtitle1"
  //               sx={{ fontWeight: 800, textAlign: "right" }}
  //             >
  //               {value}
  //             </Typography>
  //           </Grid>
  //         </React.Fragment>
  //       ) : null
  //     );
  //   };

  const classes = useStyles();

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: thirdColor,
        position: "relative",
        p: 3,
        overflow: "auto",
      }}
      ref={documentPreviewRef}
    >
      <Grid item xs={12} sx={{ position: "absolute", width: "100%", p: 2 }}>
        {/* Header Control */}
        <Box
          sx={{
            marginBottom: 2,
            alignItems: "baseline",
            ...justifyContentBetween,
            ...alignItemsCenter,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 800, color: neutral70 }}
            className="not-effect"
          >
            Document Preview
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <Box sx={{ ...justifyContentEnd, gap: ".5rem" }}>
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => handleZoomIn()}
              >
                <img src={ZoomInIcon} />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => handleZoomOut()}
              >
                <img src={ZoomOutIcon} />
              </IconButton>
              <Button
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "darkgray", // Change the color when hovered if desired
                  },
                  borderRadius,
                }}
                className="not-effect"
              >
                {percentage}%
              </Button>
            </Box>
          </Box>
        </Box>
        <Paper className={`${classes.root}`} ref={documentPreviewForPrintRef}>
          <img src={boderImg} className={classes.logo} />

          {/* Header */}
          <div className={classes.header}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "800" }}
              className="not-effect"
            >
              {mode || "PAYMENT"}
            </Typography>
            <img src={logo} alt="logo" />
          </div>

          {/* End of Header */}

          <Grid
            container
            display="flex"
            sx={{ ...justifyContentBetween, padding: "20px" }}
          >
            <Grid item xs="6" container>
              {renderGridItemsFromObject(detailPR)}
            </Grid>
            <Box
              sx={{
                width: "50%",
              }}
            >
              <Typography variant="body1" sx={{ textAlign: "right" }}>
                PT Pacific Patent Multiglobal{" "}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "right", color: neutral70 }}
              >
                DIPO Business Center 11th Floor Jl. Jenderal Gatot Subroto kav
                51-52
                <br /> Jakarta Pusat 10260
              </Typography>
            </Box>
          </Grid>

          {/* Content */}
          <div className={classes.content}>
            <Grid
              container
              spacing={2}
              sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 0 }}
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ color: neutral70 }}>
                  Category
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "black", fontWeight: "800" }}
                >
                  {createAliasForCategory(detailPR.Category)}
                </Typography>
              </Grid>
              {renderRequestedServices(detailPR)}
            </Grid>
          </div>
          <Divider />

          {/* Price information and total */}
          <PriceInformation />
          {/* Signatur Component */}
          <SignatureSectionComp />
          {/* Footer */}
          {renderFooter()}
        </Paper>
      </Grid>
    </Box>
  );
};

export default PreviewPDF;
