import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Checkbox,
  Popover,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import SearchIconBlack from "./../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  inputDate,
  CustomOpenPickerButton
} from "../../util/style";
import axios from "../../core/axios_config";
import Form from "../../components/Form";
import { getUserLogs } from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";
import zIndex from "@mui/material/styles/zIndex";

const fields = [];

const UserLogs = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const columns = [
    {
      field: "user",
      headerName: "User Name",
      width: 140,
      disableColumnMenu: true,
      valueGetter: (params) => {
        return params.row.user ? params.row.user ?.fullname : "-";
      },
    },
    {
      field: "created_at",
      headerName: "Date Time",
      width: 200,
      disableColumnMenu: true,
      // sortable: false,
    },
    {
      field: "reference_id",
      headerName: "Reference Number",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "Action",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 90,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => (
        <StatusBadge status={params.value} isinfo={true}>
          {params.value}{" "}
        </StatusBadge>
      ),
    },
    {
      field: "menu",
      headerName: "Menu",
      width: 180,
      disableColumnMenu: true,
      // sortable: false,
      valueGetter: (params) => {
        return params.row.menu ? params.row.menu ?.name : "-";
      },
    },
    {
      field: "note",
      headerName: "Note",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 250,
      disableColumnMenu: true,
    },
  ];

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add New UserLogs");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [dateFilter, setDateFilter] = useState(ACTION_TYPE.ALL_DATE);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showSelectDate, setShowSelectDate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateElm, setStartDateElm] = useState(null);
  const [endDateElm, setEndDateElm] = useState(null);
  const [action, setAction] = useState(ACTION_TYPE.DEFAULT_ACTION);
  const dispatch = useDispatch();

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.userlogs);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  const textFieldRef = useRef(null);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(name, response.data.data);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: response.data.data,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });
  }, []);

  useEffect(() => {
    if (action !== ACTION_TYPE.DEFAULT_ACTION) {
      dispatch(
        getUserLogs({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          dateFilter,
          startDate,
          endDate,
          action,
        })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }

    console.log("ACTIONSS", action);
  }, [action]);

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues("fields"), {
        id: null,
        status: false,
      })
    );
  };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  const initialFormValues = (type = "fields") => {
    if (type === "columns") {
      return columns.reduce(
        (acc, field) => ({ ...acc, [field.field]: "" }),
        {}
      );
    } else {
      return fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
        {}
      );
    }
  };

  const initialErrors = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), { id: null })
  );
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    console.log(formValues, "formValues,");
    // console.log(initialFormValues(), "asdad");
  }, [formValues]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);


  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])
const handleKeyPress = (e) => {
  if (e.key === "Enter" && search) {
    _search();
  }
};
;

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getUserLogs({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          dateFilter,
          startDate,
          endDate,
          action,
        })
      );
    }
  }, [isActive]);

  const setFormValuesFromSelectedData = () => {
    const data = rows.filter((row) => selectedData.includes(row.id))[0];
    for (const key in data) {
      // console.log(key, "key");
      // Check if the property exists in formValues before updating
      if (formValues.hasOwnProperty(key)) {
        // Special handling for the 'status' property
        const value = key === "status" ? data[key] === "active" : data[key];
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [key]: typeof value === "object" ? value ?.id : value,
        }));
      }
    }
  };

  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getUserLogs({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          dateFilter,
          startDate,
          endDate,
          action,
        })
      );
    }
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (dateFilter !== 'custom_date' && dateFilter !== ACTION_TYPE.DEFAULT_DATE) {
      dispatch(
        getUserLogs({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          dateFilter,
          startDate,
          endDate,
          action,
        })
      );
    }
  }, [dateFilter])

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getUserLogs({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        dateFilter,
        startDate,
        endDate,
        action,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getUserLogs({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        dateFilter,
        startDate,
        endDate,
        action,
      })
    );
  }
  function _search() {
    if(action === ACTION_TYPE.DEFAULT_ACTION) {
      dispatch(
        getUserLogs({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          dateFilter,
          startDate,
          endDate,
          action,
        })
      );
    }
  }
  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getUserLogs({ token, limit, search, status, sortDirection, sortBy })
    // );
  }

  function _action(action) {
    setAction(action);
  }

  function _dateFilter(date) {
    if (date === "custom_date") {
      setShowDatePicker(true);
      setDateFilter(date);

    } else {
      setShowSelectDate(false);

      if ((dateFilter == 'custom_date' && (date !== 'custom_date' && date !== undefined))) {
        setShowDatePicker(false);

        setStartDate(null);
        setStartDateElm(null);
        setEndDate(null);
        setEndDateElm(null);
      }

      if (date && action !== ACTION_TYPE.DEFAULT_ACTION) {
        setDateFilter(date);
      }
    }
  }

  const handleStartDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setStartDate(formattedDate);
      setStartDateElm(date);
    } else {
      console.error("Invalid start date:", date);
      // You might want to handle this error case gracefully
    }
  };

  const handleEndDateChange = (date) => {
    if (dayjs(date).isValid()) {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      setEndDate(formattedDate);
      setEndDateElm(date);
    } else {
      console.error("Invalid end date:", date);
      // You might want to handle this error case gracefully
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      setShowSelectDate(false);
      dispatch(
        getUserLogs({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          dateFilter,
          startDate,
          endDate,
          action,
        })
      );
    }
  }, [startDate, endDate]);

  const handleSelectClose = (e) => {
    if (e) e.stopPropagation();
    setShowDatePicker(false);
    setShowSelectDate(false);
  };


  const kk = () => {
    setShowSelectDate(false);
    alert(showSelectDate, 'asda')
    return false
  }
  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder="Search user name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                  open: showSelectDate, // Set the open prop based on showDatePicker
                  onClose: () => handleSelectClose, // Close the menu on selecting an option
                  onOpen: () => setShowSelectDate(true),
                  // onOpen: () => date !== 'custom_date' , // Open the menu when "Custom Date" is
                }}
                sx={{
                  borderRadius,
                }}
                value={dateFilter}
                // inputRef={textFieldRef} // Assign a ref to the TextField
                // onClick={(e) => _dateFilter(e.target.value)}
                onChange={(e) => _dateFilter(e.target.value)}
                MenuProps={{
                  disableCloseOnSelect: false,
                }}
                disabled={action === ACTION_TYPE.DEFAULT_ACTION}
              >
                <MenuItem sx={{
                  outline: '1px solid red', position: 'absolute', right: 0, padding: '1px 5px', fontWeight: '500', borderRadius: 50, color: 'red', zIndex: '999', width: 'auto', height: '16px', display: 'flex',
                  justifyContent: 'center',
                  top: '1.12rem',
                  right: '1rem',
                }}> Close </MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value={ACTION_TYPE.ALL_DATE}>All longs date</MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value="last_30_days">Last 30 days</MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value="last_6_months">Last 6 months</MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value="last_1_year">Last 1 year</MenuItem>
                <MenuItem sx={{ paddingRight: '100px !important' }} value="custom_date">Custom Date</MenuItem>
                {showDatePicker && (
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                    <Grid container spacing={2} p={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">Start From</Typography>
                        <DatePicker
                          selectedDate={startDateElm}
                          onChange={handleStartDateChange}
                          components={{
                            OpenPickerButton: CustomOpenPickerButton,
                          }}
                          format="DD/MM/YYYY"
                          value={startDateElm}
                          sx={{
                            ...inputDate,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">Until</Typography>
                        <DatePicker
                          selectedDate={endDateElm}
                          onChange={handleEndDateChange}
                          components={{
                            OpenPickerButton: CustomOpenPickerButton,
                          }}
                          format="DD/MM/YYYY"
                          value={endDateElm}
                          sx={{
                            ...inputDate,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </LocalizationProvider>
                )}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={action}
                onChange={(e) => _action(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_ACTION}>
                  Select Action
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_ACTION}>All Action</MenuItem>
                <MenuItem value="Create">Create</MenuItem>
                <MenuItem value="Update">Update</MenuItem>
                <MenuItem value="Read">Read</MenuItem>
                <MenuItem value="Delete">Delete</MenuItem>
                <MenuItem value="Login">Login</MenuItem>
                <MenuItem value="Logout">Logout</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            actionChoosed={action}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            search={search}
            showAddToolbar={false}
            showEditToolbar={false}
            showDeleteToolbar={false}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default UserLogs;
