import React, { useEffect, useRef, useState } from "react";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
} from "@mui/material";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../../../util/style";
import ZoomOutIcon from "./../../../../../assets/zoom-out.png";
import ZoomInIcon from "./../../../../../assets/zoom-in.png";
import PrinterGrayIcon from "./../../../../../assets/printer-gray.png";
import DownloadGrayIcon from "./../../../../../assets/download-gray.png";
import boderImg from "./../../../../../assets/border.png";
import logo from "./../../../../../logo.png";
import Signature from "../../../payment-request/componens/Signature";
import { ValidateValueRender } from "../../validation/validateValue";
// import { formatValue } from "../detail_payment.hooks";
// import {
//   createAliasForCategory,
//   getTimeFromDate,
// } from "../parts/imutable_state";

//* Main Function
function DocumentPreview({
  useStyles,
  detailDocument,
  isDoneFetchHeightDocumentPreview,
  documentPreviewForPrintRef,
}) {
  const [fontSize, setFontSize] = useState(12); // Initial font size
  const [percentage, setPercentage] = useState(100); // Initial percentage text
  const documentPreviewRef = useRef(null);
  // const { renderValue } = formatValue(detailPR);

  //* =========== Primitif Var ===============

  console.log({ detailDocument });

  const statusColors = {
    pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
    approved: { bgColor: successSurfaceColor, colors: successMainColor },
    rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
    // Add more statuses as needed
  };

  // console.log({ detailDocument });
  useEffect(() => {
    // Apply the zoom effect to specific elements when fontSize changes
    if (documentPreviewRef.current) {
      const applyZoomToChildren = (element) => {
        if (element.childNodes.length > 0) {
          for (const childNode of element.childNodes) {
            applyZoomToChildren(childNode);
          }
        }
        if (element.style) {
          const tagName = element.tagName.toLowerCase();
          const className = element.className.toLowerCase();
          // if (tagName === 'h1' || tagName === 'h2' || tagName === 'h3' || tagName === 'h4' || tagName === 'h5' || tagName === 'h6') {
          //   // Apply +1px zoom to h1, h2, h3, h4, h5, h6
          //   element.style.fontSize = `${fontSize + 1}px`;
          // } else {
          //   element.style.fontSize = `${fontSize}px`;
          // }

          const hasNotEffectClass =
            className && className.includes("not-effect");

          if (!hasNotEffectClass) {
            // Apply +1px zoom to h1, h2, h3, h4, h5, h6 if it doesn't have the 'not-effect' class
            element.style.fontSize = `${fontSize + 1}px`;
          } else {
            // Apply normal zoom if it has the 'not-effect' class
            element.style.fontSize = `${element.style.fontSize}px`;
          }
        }
      };
      applyZoomToChildren(documentPreviewRef.current);
    }
  }, [fontSize]);

  //* ====================================================== *//
  //*                          HANDLER                       *//
  //* ====================================================== *//

  const handleZoomIn = () => {
    setFontSize((prevSize) => prevSize + 1);
    setPercentage((prevPercentage) => prevPercentage + 10);
  };

  const handleZoomOut = () => {
    setFontSize((prevSize) => Math.max(prevSize - 1, 1));
    setPercentage((prevPercentage) => Math.max(prevPercentage - 10, 10));
  };

  //* ====================================================== *//
  //*                         COMPONENT                      *//
  //* ====================================================== *//

  const renderGridItemsFromObject = () => {
    /**
     * key === key on detail document
     * value === display in UI
     */
    const alias = {
      no_proforma: "No",
      // TODO: create at need customize to display time;
      create_at: "Create At",
      no_reference: "No. Reference",
      remark: "Remark",
    };

    return Object.entries(alias).map(([key, aliasName]) => (
      <React.Fragment key={key}>
        <Grid item xs={4}>
          <Typography variant="body1">{aliasName}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
            {detailDocument?.product[key]}
          </Typography>
        </Grid>
      </React.Fragment>
    ));
  };

  const RenderClientInformation = () => {
    const listRender = {
      bill_to: "Bill To",
      attn: "Attn",
      bill_address: "Bill Address",
    };

    return Object.entries(listRender).map(([key, aliasName]) => (
      <>
        <Box paddingY=".1rem">
          <Typography sx={{ color: neutral70, fontWeight: "600" }}>
            {aliasName}
          </Typography>
        </Box>
        <Box paddingY=".1rem">
          <Typography fontWeight={"500"}>
            {detailDocument?.client[key]}
          </Typography>
        </Box>
      </>
    ));
  };

  const RenderPriceInformation = () => {
    /**
     * Structure of service
     * 
     * service = [
          {
              "name": "Patent Pecahan",
              "id": 2,
              "price": 98789876789
          },
          {
              "name": "Patent Sederhana",
              "id": 4,
              "price": 1324543245
          }
        ]
     */

    /**
     * @typedef {object} SubServiceType
     * @property {string} name - name of service
     * @property {number} id - id
     * @property {number|string} price - price
     */

    return (
      <>
        <Typography variant="subtitle1">Price</Typography>
        <ul style={{ marginBlock: ".1rem", paddingLeft: "1.6rem" }}>
          <li>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1">{`Price (${detailDocument.product.sub_service.length} Services)`}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" textAlign={"right"}>
                  {detailDocument?.product?.price}
                </Typography>
              </Grid>
            </Grid>
          </li>
          {detailDocument?.product?.discount != "Rp 0" && (
            <li>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="body2">Discount</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" textAlign={"right"}>
                    {detailDocument?.product?.discount}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )}

          <Box sx={{ paddingTop: ".5rem" }}>
            <Divider />
          </Box>
          <li>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1">VAT</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" textAlign={"right"}>
                  {detailDocument?.product?.vat}
                </Typography>
              </Grid>
            </Grid>
          </li>
          <li style={{ listStyleType: "none" }}>
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  textAlign={"right"}
                  fontWeight={"800"}
                >
                  Total
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  textAlign={"right"}
                  fontWeight={"800"}
                >
                  {detailDocument?.product?.total}
                </Typography>
              </Grid>
            </Grid>
          </li>
        </ul>
      </>
    );
  };

  const RenderBankTransfer = () => {
    const listRender = {
      acount_name: "Account Name",
      acount_address: "Account address",
      bank_name: "Name of Bank",
      acount_no: "Account No.",
      bank_address: "Bank Address",
      // payment_receive: "Payment Received",
      // payment_conversion_receive: "Payment Conversion Received",
    };
    return (
      <>
        <Typography
          variant="subtitle1"
          sx={{ color: neutral70, fontWeight: "600", paddingY: ".5rem" }}
        >
          Bank Transfer
        </Typography>
        {Object.entries(listRender).map(([key, aliasName]) => (
          <Grid container>
            <Grid item xs={6}>
              <Typography>{aliasName}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {/* {validation.renderValue(detailDocument?.bank[key], key, true)} */}
                {detailDocument?.bank[key]}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </>
    );
  };

  // console.log({detailDocument});

  const SignatureSectionComp = () => (
    <Grid
      container
      spacing={3}
      sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 1, mb: 2.5 }}
    >
      <Grid item xs={8} sx={{ ...justifyContentCenter }}>
        {/* <Signature companyName="User" signatureText={detailPR["Created By"]} /> */}
      </Grid>
      <Grid item xs={4} sx={{ ...justifyContentCenter }}>
        {/* TODO: Change to relative to data */}
        <Signature
          companyName="Accounting"
          signatureText={detailDocument["approval"]}
        />
      </Grid>
    </Grid>
  );

  const RenderFooter = () => (
    <div className={classes.footer}>
      <Box sx={{ display: "flex", gap: ".5rem" }}>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          +6221 2986 5888
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "white" }}>
          pacific@pacificpatent.com
        </Typography>
      </Box>
      <Typography variant="subtitle2" sx={{ color: "white" }}>
        www.pacificpatent.com
      </Typography>
    </div>
  );

  const classes = useStyles();

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: thirdColor,
        position: "relative",
        p: 3,
        overflow: "auto",
      }}
      ref={documentPreviewRef}
    >
      <Grid item xs={12} sx={{ position: "absolute", width: "100%", p: 2 }}>
        {/* Header Control */}
        <Box
          sx={{
            marginBottom: 2,
            alignItems: "baseline",
            ...justifyContentBetween,
            ...alignItemsCenter,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: 800, color: neutral70 }}
            className="not-effect"
          >
            Document Preview
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
            <Box sx={{ ...justifyContentEnd, gap: ".5rem" }}>
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => handleZoomIn()}
              >
                <img src={ZoomInIcon} />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => handleZoomOut()}
              >
                <img src={ZoomOutIcon} />
              </IconButton>
              <Button
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "darkgray", // Change the color when hovered if desired
                  },
                  borderRadius,
                }}
                className="not-effect"
              >
                {percentage}%
              </Button>
            </Box>
          </Box>
        </Box>
        <Paper className={`${classes.root}`} ref={documentPreviewForPrintRef}>
          <img src={boderImg} className={classes.logo} />

          {/* Header */}
          <div className={classes.header}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "800" }}
              className="not-effect"
            >
              PROFORMA INVOICE
            </Typography>
            <img src={logo} alt="logo" />
          </div>

          {/* End of Header */}

          <Grid
            container
            display="flex"
            sx={{ ...justifyContentBetween, padding: "20px" }}
          >
            <Grid item xs="6" container>
              {renderGridItemsFromObject()}
            </Grid>
            <Box
              sx={{
                width: "50%",
              }}
            >
              <Typography variant="body1" sx={{ textAlign: "right" }}>
                PT Pacific Patent Multiglobal{" "}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ textAlign: "right", color: neutral70 }}
              >
                DIPO Business Center 11th Floor Jl. Jenderal Gatot Subroto kav
                51-52
                <br /> Jakarta Pusat 10260
              </Typography>
            </Box>
          </Grid>

          {/* Content */}
          <div className={classes.content}>
            <Box marginTop={"1rem"}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: neutral70,
                  fontWeight: "600",
                  paddingY: ".5rem",
                }}
              >
                Description
              </Typography>
              <Typography>{detailDocument?.product?.description}</Typography>
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, mt: 0 }}
            >
              <Grid item xs={8} paddingRight={".5rem"}>
                <Divider />
                <RenderPriceInformation />

                <RenderBankTransfer />
              </Grid>
              <Grid item xs={4}>
                <RenderClientInformation />
              </Grid>
              <Box marginLeft={"1.2rem"}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: neutral70,
                    fontWeight: "600",
                    paddingY: ".5rem",
                  }}
                >
                  Note
                </Typography>
                <Typography>
                  1. Metode Pembayaran – via bank transfer sesuai dengan bank
                  details yang tercantum pada invoice
                </Typography>
                <Typography>
                  2. Mohon cantumkan nomor invoice / proforma invoice pada saat
                  pembayaran dan bukti pembayaran di email ke
                  accounting@pacificpatent.com atau via WhatsApp ke 0877 2245
                  8585
                </Typography>
              </Box>
            </Grid>
            <SignatureSectionComp />
          </div>
          {/* <Divider /> */}

          {/* Price information and total */}
          {/* <PriceInformation /> */}
          {/* Signatur Component */}
          {/* Footer */}
          <RenderFooter />
        </Paper>
      </Grid>
    </Box>
  );
}

export default DocumentPreview;
