import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Select,
  Autocomplete,
  Checkbox,
  IconButton,
  FormHelperText,
  Divider,
  CardActions,
} from "@mui/material";
import { Stepper, Step, StepLabel, Container, Paper } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import CalendarIcon from "./../../assets/calendar.png";
import SearchIconBlack from "./../../assets/search-black.svg";
// import RemoveIcon from "@mui/icons-material/Remove";
import RemoveIcon from "./../../assets/minus.svg";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "./../../assets/back.svg";
import { AccessRestrictedAlert } from "../../components/Alert";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  FacebookCircularProgress,
  removeButton,
  justifyContentEnd,
} from "../../util/style";
import axios from "../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
} from "../../components/Form";
import {
  addEmployee,
  deleteEmployee,
  editEmployee,
  getEmployee,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";
import { modalActionButton } from "../../util/style";
import { useNavigate, useParams } from "react-router-dom";
import Wizard from "./add/wizard";

const AddEmployee = () => {
  /* -------------------------------------------------------------------------- */
  /*                           USER HAVE PERMISSION ???                          */
  /* -------------------------------------------------------------------------- */
  const [title, setTitle] = useState("Add Employee");
  const [actionType, setActionType] = useState();
  const [currentPath, setCurrentPath] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userAccess, setUserAccess] = useState([
    _.CREATE,
    _.READ,
    _.UPDATE,
    _.DELETE,
  ]);
  const { menus } = useSelector((state) => state.auth);

  const { id } = useParams();

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    if (menus.length > 0) {
      // Find the menu item with the matching pathname
      const menuItem = findMenuItemByLink(menus, currentPath);

      if (menuItem) {
        // Filter permissions with has_access set to true
        const filteredPermissions = menuItem.permission.filter(
          (permission) => permission.has_access
        );

        // Extract the permission actions and update userAccess state
        const accessNames = filteredPermissions.map((permission) => {
          const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
          return action.toUpperCase(); // Convert to uppercase
        });
        setUserAccess(accessNames);
      }
    }
  }, [currentPath, menus]);

  /* ------------------- Check if user has CREATE permission ------------------ */

  const hasCreatePermission = userAccess.includes(_.CREATE);

  const formRef = useRef(null);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "85vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ marginRight: ".5rem" }}
              >
                <img src={ArrowBackIcon} />
              </IconButton>
              {title}
            </div>
          }
        />
        <Divider />

        <CardContent sx={{}}>
          {isLoading ? (
            <FacebookCircularProgress />
          ) : hasCreatePermission ? (
            <form>
              <Wizard />
            </form>
          ) : (
            <AccessRestrictedAlert />
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default AddEmployee;
