import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import "dayjs/locale/en";
import CalendarIcon from "./../../../assets/calendar.png";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import RemoveIcon from "../../../assets/minus.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
} from "../../../util/style";
import axios, { headersAPIToken } from "./../../../core/axios_config";
import OriginAxios from "axios";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
} from "./../../../components/Form";
import {
  addPaymentRequest,
  clearDetailPaymentRequest,
  deletePaymentRequest,
  editPaymentRequest,
  getPaymentRequest,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  createEmptyErrors,
  localToUTC,
} from "./../../../util/function";
import DataTable from "./../../../components/Table";
import ConfirmDelete from "./../../../components/ConfirmDelete";
import ModalConfirmCancel from "./../../../components/ConfirmCancel";
import LeftDrawer from "./../../../components/LeftDrawer";
import { TransitionAlerts } from "../../../components/Alert";
import { fields, columns, ANOTHER_INPUT_TYPE } from "./parts/imutable_state";
import FormContent from "./componens/FormContent";

//* Main function
const PaymentRequest = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  /**@typedef {[Number, Function]} numState */
  /**@typedef {[String, Function]} stringState */
  /**@typedef {[Boolean, Function]} boolState */
  /**@typedef {[Object, Function]} objState */
  /**@typedef {[ArrayConstructor, Function]} arrState */

  /**
   * @typedef {object} optionSearchType
   * @property {string} token
   * @property {number} limit
   * @property {string} search
   * @property {string} sortDirection
   * @property {string} sortBy
   * @property {number} page
   */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const ANOTHER_ACTION_TYPE = {
    ADD_NEW_GROUP: "ADD_NEW_GROUP",
    PREVIEW: "PREVIEW",
  };
  const { token, user } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setClientDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortModel, setSortModel] = useState([]);

  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add Payament Request");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const [chooseCategory, setChooseCategory] = useState(0);
  const [choosedStatus, setChoosedStatus] = useState(
    ACTION_TYPE.DEFAULT_STATUS
  );
  const [selectedServiceId, setSelectedServiceId] = useState(0);

  //* Type as Category
  const [type, setType] = useState(ACTION_TYPE.DEFAULT_TYPE);
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  //* State Selector
  const {
    data: rows,
    message: messageState,
    isLoading,
    pagination,
  } = useSelector((state) => state.financePaymentRequest);

  /**
   * @typedef detailSelectorType
   * @type {object}
   * @property {object} data
   * @property {object} pagination
   * @property {string} message
   * @property {boolean} isLoading
   * @property {boolean} response_status
   * @property {number} id_for_edit
   */

  /**@type {detailSelectorType} */
  const detailSelector = useSelector(
    (state) => state.financePaymentRequestDetail
  );

  useEffect(() => {
    //* Watch detail state, if exist get id in detail state for edit;
    if (detailSelector.data !== null && detailSelector.id_for_edit !== null) {
      // console.log(detailSelector.id_for_edit, 'id want to edit');
      setFormValuesFromSelectedData(detailSelector.id_for_edit);
    } else {
      // console.log(detailSelector);
    }
  }, [detailSelector]);

  //* =============== Global modal ===========
  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  //* buat disabled button toolbar
  useEffect(() => {
    setSelectedData([]);
  }, [dispatch]);

  //* ==================== Status watcher ===============
  // useEffect(() => {
  //   if (status === ACTION_TYPE.DEFAULT_STATUS) {
  //     setType(ACTION_TYPE.DEFAULT_TYPE);
  //   }
  // }, [status]);

  useEffect(() => {
    if (messageState) {
      console.log(messageState);
    }
  }, [messageState]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const { data: response } = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: response.data,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      // Check if the field has a endpoint
      if (field.apiEndpoint) {
        const { name, apiEndpoint } = field;
        if (!options.hasOwnProperty(name)) {
          fetchOptions(apiEndpoint, name);
        }
      }
    });
  }, [options]);

  const initialAttachment = [""];
  const initialErrorAttachment = [""];

  const initialFormValues = () => {
    let initialValues = { created_by: user.id, approval_id: user.id };
    fields.forEach((field) => {
      if (field.children && field.title === "Attachment") {
        let { name } = field.children[0];
        initialValues = {
          ...initialValues,
          [name]: initialAttachment,
        };
      } else {
        initialValues = {
          ...initialValues,
          [field.name]: field["defaultValue"],
        };
      }
    });
    return initialValues;
  };

  const initialErrors = () => {
    let initialErrorValues = {};
    fields.forEach((field) => {
      if (field.children && field.title === "Attachment") {
        let { name } = field.children[0];
        initialErrorValues = {
          ...initialErrorValues,
          [name]: initialErrorAttachment,
        };
      } else {
        initialErrorValues = {
          ...initialErrorValues,
          [field.name]: "",
        };
      }
    });
    // console.log(initialErrorValues, Date.now());
    return initialErrorValues;
  };

  //*  ================ Initial State ====================== */
  const [formValues, setFormValues] = useState(initialFormValues());

  /** ======== Debugger ========== */
  // useEffect(() => {
  //   console.log(formValues, "=============== form values");
  // }, [formValues]);
  // useEffect(() => {
  //   console.log(isLoading, 'is loading redux');
  // },[isLoading])

  //* ================= Reseter ====================
  const resetFormValues = () => {
    setFormValues(initialFormValues());
    setChooseCategory(0);
  };

  //* =============== Service Section ==============
  //* reset value sub service id
  useEffect(() => {
    setFormValues((prev) => ({ ...prev, sub_service_id: 0 }));
  }, [selectedServiceId]);

  //* fetch sub service depend on service id chosed
  useEffect(() => {
    const fetchSubservice = async () => {
      try {
        setIsLoadedFormValues(true);
        const { data: response } = await axios.get(
          "/master/services?main_service_id=" + formValues.service_id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOptions((prev) => {
          console.log(prev, "prev value of option");
          return {
            ...prev,
            sub_service_id: response.data,
          };
        });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadedFormValues(false);
      }
    };

    //*  Watch for service choose
    if (chooseCategory === 1 && formValues.service_id !== 0) {
      if (formValues.sub_service_id === 0) {
        fetchSubservice();
        console.log("effect watch formValues");
      }
    }
  }, [formValues, chooseCategory]);

  // const [detailRow, setDetailRow] = useState({});
  const [errors, setErrors] = useState(initialErrors);

  //* ==================== Form Watcher ================== */

  // useEffect(() => {
  //   // if (
  //   //   actionType !== ANOTHER_ACTION_TYPE.ADD_NEW_GROUP &&
  //   //   actionType !== actionType.DELETE
  //   // ) {
  //   //   localStorage.setItem(
  //   //     "sales-management/client/formValues",
  //   //     JSON.stringify(formValues)
  //   //   );
  //   // }
  //   // console.log(formValues, "GANTIIII");
  // }, [formValues, actionType]);

  // useEffect(() => {
  //   const filteredRows = rows.filter((row) => selectedData.includes(row.id));
  //   // console.log(filteredRows, "filteredRows");
  // }, [selectedData]);

  /**
   *
   * @param {optionSearchType} optionSearch
   */
  function getDataPayment(optionSearch) {
    if (type !== ACTION_TYPE.DEFAULT_TYPE) {
      optionSearch.category = type;
    }

    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      optionSearch.status = status;
    }

    dispatch(getPaymentRequest(optionSearch));
  }

  useEffect(() => {
    if (
      type !== ACTION_TYPE.DEFAULT_TYPE ||
      status !== ACTION_TYPE.DEFAULT_STATUS
    ) {
      getDataPayment({
        limit,
        search,
        sortBy,
        sortDirection,
        token,
        page,
      });
      // console.log(status);
      setChoosedStatus("Filter by status");
    } else {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [type, status]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      getDataPayment({
        limit,
        search,
        sortBy,
        sortDirection,
        token,
        page,
      });
    }
  }, [isActive]);

  //* ================= Edit Action =====================

  //TODO: Need improve
  /**
   * @param {number} detailId - detail payment id
   */
  const setFormValuesFromSelectedData = async (detailId) => {
    let data;
    if (!detailId) {
      data = rows.filter((row) => selectedData.includes(row.id))[0];
      console.log("a");
    }

    //* ID payment request
    let id = data ? data.id : detailId;
    setIsLoadedFormValues(true);
    setFormModal(true);
    fetchDetaiForEdit(id);
  };

  const fetchDetaiForEdit = async function (id) {
    let apiUri = `payment-requests/${id}/detail-for-edit`;
    try {
      let { data: responseAxios } = await axios.get(
        apiUri,
        headersAPIToken(token)
      );
      //* Check if respons is array
      if (Array.isArray(responseAxios) && responseAxios.length > 0) {
        responseAxios = responseAxios[0];
      }
      if (responseAxios.status === true && responseAxios.data !== null) {
        setFormValueFromDoc(responseAxios);
      } else {
        console.log(responseAxios.message);
      }

      setActionType(ACTION_TYPE.EDITED);
      //* Set Title to edit
      setTitle("Edit Payment Request");
      //* Show modal drawer
    } catch (error) {
      console.error(error);
    }
  };

  const fetchOption = async (url, nameOption) => {
    try {
      const { data: response } = await axios.get(url, headersAPIToken(token));
      setOptions((prev) => ({ ...prev, [nameOption]: response.data }));
    } catch (error) {
      console.error("Failed fetch option");
    }
  };

  const setFormValueFromDoc = async (dataDetailPR) => {
    const { data: responData } = dataDetailPR;
    //* Reset Form value
    resetFormValues();

    let filledValue = {
      category_id: responData.Category.category_id,
      attachments: responData.Attachment.attachment,
      id: responData.id,
      due_date: responData["Due Date"],
      currency: responData["Currency"],
      total_price: responData["Total"],
      note: responData["Note Section"].note,
    };

    //* Set Choosed category
    setChooseCategory(filledValue.category_id);
    //* Check if categori is service;
    if (filledValue.category_id === 1) {
      filledValue.service_id = responData.Service.service_id;
      filledValue.sub_service_id = responData["Sub Service"].sub_service_id;
      filledValue.client_id = responData.Client.id;
      //* Get Subservices
      await fetchOption(
        `/master/services?main_service_id=${filledValue.service_id}`,
        "sub_service_id"
      );
    }
    //* Set value to formValues;
    setFormValues((prev) => ({ ...prev, ...filledValue }));
    setIsLoadedFormValues(false);
  };

  const isFormFilled = () => {
    return Object.values(formValues).some((value) => value.trim() !== "");
  };

  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleFileSelect = (file, fieldName, index) => {
    setFormValues((prevValues) => {
      const prevValueFile = formValues[fieldName];
      if (!isNaN(index)) {
        prevValueFile[index] = file;
      }
      return { ...prevValues, [fieldName]: prevValueFile };
    });
  };

  const handleInputChange = (event, field, index, fieldTitle) => {
    const { name, value, type } = event.target;
    const newErrors = { ...errors };
    if (name === "category_id") {
      setChooseCategory(value);
    }
    if (name === "service_id") {
      setSelectedServiceId(value);
    }
    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const childField = field.children[0];
        const fileError = validateFileInput(file, childField);
        // console.log(fileError);
        if (Array.isArray(newErrors[childField?.name])) {
          let curentErorrFile = newErrors[childField?.name];
          curentErorrFile[index] = fileError;
          newErrors[childField?.name] = curentErorrFile;
          setErrors(newErrors);
        } else {
          console.error("Curent Error not array");
        }
        return;
      }
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateFileInput = (fieldValue, field) => {
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB
      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }
    return "";
  };

  const handleDetailPaymentRequest = ({ row }) => {
    const detailPage = location.pathname.concat("/detail/", row.id);
    navigate(detailPage);
  };

  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const {
        name,
        required,
        validation,
        errorMessage,
        label,
        type,
        defaultValue,
      } = field;
      let valueOnForm = formValues[name];
      if (typeof valueOnForm === "string") {
        valueOnForm = valueOnForm.trim();
      }
      if (
        required &&
        (valueOnForm === "" ||
          valueOnForm === defaultValue ||
          valueOnForm === undefined ||
          valueOnForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          valueOnForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
          valueOnForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = `Please enter ${String(field.label).toLowerCase()}`;
      } else if (
        formValues[name] &&
        formValues[name].length &&
        validation &&
        !validation(formValues[name])
      ) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      // if (validation !== undefined) {
      //   console.log("Result validation", validation(formValues[name]));
      // } else {
      //   console.log("Tidak ada valudationd");
      // }
      setErrors(newErrors);
      // console.log("Validate field", { name, newErrors, formValues });
    }
  };

  const validationNonFileType = (field, curError, curentValid) => {
    const { name, required, validation, errorMessage, defaultValue } = field;
    const ignoreList = ["service_id", "sub_service_id", "client_id"];
    let valid = curentValid;
    let isRequired = required;
    let valueForm = formValues[name];

    if (chooseCategory !== 1 && ignoreList.includes(field.name)) {
      isRequired = false;
    }

    if (typeof valueForm === "string") {
      valueForm = valueForm.trim();
    }
    // console.log("Required ?", { isRequired, name });
    if (
      isRequired &&
      (valueForm === "" ||
        valueForm === 0 ||
        valueForm === defaultValue ||
        valueForm === undefined ||
        valueForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
        valueForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        valueForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      // console.log(field, "field nya");
      curError[name] = `Please enter ${String(
        field.aliasLabel ? field.aliasLabel : field.label
      ).toLowerCase()}`;
      valid = false;
    }

    if (
      formValues[name] &&
      formValues[name].length &&
      validation &&
      !validation(formValues[name])
    ) {
      curError[name] = errorMessage || `${field.label} is invalid`;
      valid = false;
    }
    // console.log("Result? ", { name, valid, curError });
    return [valid, curError];
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = { ...initialErrors() };

    fields.forEach((field) => {
      const { name, required, validation, errorMessage, type } = field;

      if (type === ANOTHER_INPUT_TYPE.FILE) {
        // Handle file input validation
        let fileField = field.children[0];
        let fileValue = formValues[fileField.name];

        //* Loop throug value in form values;
        fileValue.forEach((fileVal, index) => {
          const fileError = validateFileInput(fileVal, fileField);
          if (fileError) {
            //* set error to file depending index
            newErrors[fileField.name][index] = fileError;
            valid = false;
          }
        });
      } else if (required) {
        const [returnValid, returnError] = validationNonFileType(
          field,
          newErrors,
          valid
        );
        // set Returned value
        newErrors = returnError;
        valid = returnValid;
        // console.log("Return non file", { name, newErrors, valid });
      }
    });

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddPaymentRequest();
        }
        setSelectedData([]);
        setChooseCategory(0);
      }
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD, dataSelected) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Payment Request");
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete");
      // const data = rows.filter((row) => selectedData.includes(row.id));
      setClientDeleted(`payment request`);
    } else if (actionType === "PREVIEW") {
      setTitle("Preview NPWP");
    } else if (actionType === ACTION_TYPE.DETAIL) {
      setTitle(`Detail Payment Request`);
      //   setDetailRowFromSelectedData(dataSelected);
    } else {
      // console.log(formValues, "form values add");
      resetFormValues();
      setTitle("Add Payment Request");

      //* Method that get value from local storage
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      // console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        console.log("b", important);
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
      if (actionType === ACTION_TYPE.EDITED) {
        dispatch(clearDetailPaymentRequest());
      }
    }
  };

  const handleRemoveFile = (fieldName, index) => {
    // remove from form values
    setFormValues((prevVal) => {
      let updatedValue = { ...prevVal };
      if (!isNaN(index)) {
        updatedValue[fieldName].splice(index, 1);
      } else {
        console.error("Failed delete, invalid index");
      }
      return updatedValue;
    });

    //remove from errors values
    setErrors((prev) => {
      let updatedErrorVal = { ...prev };
      if (!isNaN(index)) {
        updatedErrorVal[fieldName].splice(index, 1);
      } else {
        console.error("invalid index");
      }
      return updatedErrorVal;
    });
  };

  const handleAddFile = (fieldName) => {
    setFormValues((prevValue) => {
      let updatedValue = { ...prevValue };
      updatedValue[fieldName].push("");
      return updatedValue;
    });
  };

  //* ============= Modal Handler ================

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
    console.log("on a");
  };

  const handleYesConfirmModal = () => {
    if (actionType === ACTION_TYPE.EDITED) {
      dispatch(clearDetailPaymentRequest());
    }
    setConfirmModal(false);
    setFormModal(false);
    resetFormValues();
    setChooseCategory(0);
    console.log("on b");
    dispatch(clearDetailPaymentRequest());
    setSelectedData([]); //buat disabled button toolbar
  };

  //* =========== [ C-R-U-D ] =============
  const handleAddPaymentRequest = () => {
    const modifiedPayload = modifiedDataBeforeSend(formValues);
    setIsLoadedFormValues(true);
    dispatch(addPaymentRequest(token, modifiedPayload)).then(() => {
      setIsLoadedFormValues(false);
      handleCloseFormModal(true);
    });
  };

  const handleEditRow = () => {
    const modifiedPayload = modifiedDataBeforeSend(formValues);
    dispatch(editPaymentRequest(token, modifiedPayload, modifiedPayload.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deletePaymentRequest(token, ids, true));
  };

  const modifiedDataBeforeSend = (payload) => {
    //* Copy the original data
    let copyForm = { ...payload };

    //* delete unuse property
    if (copyForm.hasOwnProperty("unit_type")) {
      delete copyForm["unit_type"];
    }

    let strTotalPrice = formValues["total_price"].split(",").join("");
    copyForm["total_price"] = strTotalPrice;

    //* Set Property date
    copyForm.date = localToUTC(new Date());

    //* Set null value if category non service
    if (copyForm.category_id !== 1) {
      copyForm.client_id = null;
      copyForm.service_id = null;
      copyForm.sub_service_id = null;
    }

    if (actionType === ACTION_TYPE.ADD) {
      const attachments = copyForm["attachments"];
      if (attachments.length == 1 && attachments[0] === "") {
        copyForm["attachments"] = null;
      }
    } else if (actionType === ACTION_TYPE.EDITED) {
    }

    const cc = copyForm;
    debugger;
    return copyForm;
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  //* ============= Component ==================
  //!Move to other component
  const renderSelectOneAutocomplite = (field, fieldOptions) => {
    if (field.name === "currency") {
      return (
        <React.Fragment key={field.name}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <SelectOneAutocomplete
                field={field}
                formValues={formValues}
                errors={errors}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                fieldOptions={fieldOptions}
                tip={field?.tip}
              />
            </Grid>
            <Grid item xs={10} style={{ marginTop: "1.4rem" }}>
              <TextInput
                field={fields.find((fiel) => fiel.type === INPUT_TYPE.CURRENCY)}
                formValues={formValues}
                errors={errors}
                handleInputChange={handleInputChange}
                handleInputBlur={handleInputBlur}
                tip={field?.tip}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else if (field.name === "service_id" || field.name === "sub_service_id") {
      return chooseCategory === 1 ? (
        <React.Fragment key={field.name}>
          <SelectOneAutocomplete
            field={field}
            formValues={formValues}
            errors={errors}
            handleInputChange={handleInputChange}
            handleInputBlur={handleInputBlur}
            fieldOptions={fieldOptions}
            tip={field?.tip}
          />
        </React.Fragment>
      ) : null;
    }
    return (
      <React.Fragment key={field.name}>
        <SelectOneAutocomplete
          field={field}
          formValues={formValues}
          errors={errors}
          handleInputChange={handleInputChange}
          handleInputBlur={handleInputBlur}
          fieldOptions={fieldOptions}
          tip={field?.tip}
        />
      </React.Fragment>
    );
  };

  const renderFormContent = () => (
    <FormContent
      actionType={actionType}
      errors={errors}
      formValues={formValues}
      handleAddFile={handleAddFile}
      handleFileSelect={handleFileSelect}
      handleInputBlur={handleInputBlur}
      handleInputChange={handleInputChange}
      handleRemoveFile={handleRemoveFile}
      isLoadedFormValues={isLoadedFormValues}
      options={options}
      selectedData={selectedData}
      userDeleted={userDeleted}
      chooseCategory={chooseCategory}
    />
  );

  const renderActionButtons = () => {
    return (
      <Box>
        {actionType !== ACTION_TYPE.DETAIL &&
        actionType !== ANOTHER_ACTION_TYPE.PREVIEW ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
            >
              {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
            </Button>
          </>
        ) : actionType === ANOTHER_ACTION_TYPE.PREVIEW ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => handleCloseFormModal()}
            >
              Yes
            </Button>
          </>
        ) : (
          <>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              sx={{ ...btnWhite }}
              onClick={() => handleCloseFormModal()}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              disableElevation
              type="submit"
              //   onClick={() => handleFormModal(ACTION_TYPE.EDITED, detailRow)}
            >
              Edit
            </Button>
          </>
        )}
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      getDataPayment({
        limit,
        search,
        sortBy,
        sortDirection,
        token,
        page,
      });
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    getDataPayment({
      limit,
      search,
      sortBy,
      sortDirection,
      token,
      page,
    });
  }

  function _getByLimit(limit) {
    getDataPayment({
      limit,
      search,
      sortBy,
      sortDirection,
      token,
      page,
    });
  }

  function _search() {
    getDataPayment({
      limit,
      search,
      sortBy,
      sortDirection,
      token,
      page,
    });

    if (search) {
      setChoosedStatus("search by no refrence");
    } else {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
    }
  }

  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getPaymentRequest({ token, limit, search, status, sortDirection, sortBy, type })
    // );
  }

  function _type(val) {
    setType(val);
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    // console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder="Search name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                  onKeyDown: handleKeyPress,
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={type}
                onChange={(e) => _type(e.target.value)}
                // disabled={type === ACTION_TYPE.DEFAULT_TYPE}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_TYPE}>
                  Select category
                </MenuItem>
                <MenuItem value="Services">Service</MenuItem>
                <MenuItem value="Non Service">Non Service</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Waiting">Waiting</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Paid">Paid</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Payment Request"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={choosedStatus}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            // userAccess={userAccess}
            handleRowDoubleClick={handleDetailPaymentRequest}
          />
        </CardContent>
      </Card>

      {actionType !== ACTION_TYPE.DELETED &&
      actionType !== ACTION_TYPE.DETAIL ? (
        <LeftDrawer open={formModal}>
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
              workingWithDrawer={true}
            />
          </Box>
        </LeftDrawer>
      ) : (
        <Modal
          open={formModal}
          onClose={handleCloseFormModal}
          disableEscapeKeyDown={true}
        >
          <Box>
            <Form
              title={title}
              onCloseModal={() => handleCloseFormModal()}
              elementForm={renderFormContent}
              elementActionButton={renderActionButtons}
              onSubmit={handleSubmit}
            />
          </Box>
        </Modal>
      )}
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleYesConfirmModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default PaymentRequest;
