/* -------------------------------------------------------------------------- */
/*                                    STATE                                   */
/* -------------------------------------------------------------------------- */
export const REQUEST_LOADING = "REQUEST_LOADING";
export const RUN_MODAL_SAVED = "RUN_MODAL_SAVED";
export const RUN_MODAL_EDITED = "RUN_MODAL_EDITED";
export const RUN_MODAL_DELETED = "RUN_MODAL_DELETED";
export const RUN_MODAL_REJECTED = "RUN_MODAL_REJECTED";
export const RUN_MODAL_DRAFT = "RUN_MODAL_DRAFT";
export const RUN_MODAL_APPROVED = "RUN_MODAL_APPROVED";
export const RUN_MODAL_FAILED = "RUN_MODAL_FAILED";
export const RUN_MODAL_CANCELED = "RUN_MODAL_CANCELED";
export const RUN_MODAL_RENEWED = "RUN_MODAL_RENEWED";
export const RUN_MODAL_FINISHED = "RUN_MODAL_FINISHED";
export const CLOSE_MODAL = "CLOSE_MODAL";

/* -------------------------------------------------------------------------- */
/*                                   ACTION                                   */
/* -------------------------------------------------------------------------- */
export const RequestLoading = () => {
  return { type: REQUEST_LOADING };
};

/**
 *
 * @param {'SAVED'| 'EDITED'|'DELETED'|'REJECTED'|'APPROVED'|'FAILED'|'CANCELED'|'FINISHED'} type
 * @param {any} payload
 * @returns
 */
export const RunModal = (type, payload) => {
  const actionType = `RUN_MODAL_${type}`;
  return {
    type: actionType,
    payload,
  };
};

export const CloseModal = () => {
  return {
    type: CLOSE_MODAL,
  };
};

/* -------------------------------------------------------------------------- */
/*                                   REQUEST                                  */
/* -------------------------------------------------------------------------- */

const initState = {
  modal: {
    isActive: false,
    title: "Title",
    subtitle: "Subtitle",
  },
};

const globalReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case RUN_MODAL_SAVED:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: true,
          title: "Saved!",
          subtitle: `The new ${payload} has been successfully saved`,
        },
      };
    case RUN_MODAL_CANCELED:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: true,
          title: "Canceled!",
          subtitle: `The ${payload} has been successfully canceled`,
        },
      };
    case RUN_MODAL_FINISHED:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: true,
          title: "Finished!",
          subtitle: `The ${payload} has been successfully finished`,
        },
      };
    case RUN_MODAL_EDITED:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: true,
          title: "Saved!",
          subtitle: `The ${payload} edit has been successfully saved`,
        },
      };
    case RUN_MODAL_DELETED:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: true,
          title: "Deleted!",
          subtitle: `${payload} has been successfully deleted`,
        },
      };
    case RUN_MODAL_APPROVED:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: true,
          title: "Approved!",
          subtitle: `You have just approved a ${payload}`,
        },
      };
    case RUN_MODAL_REJECTED:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: true,
          title: "Rejected!",
          subtitle: `You have just rejected a ${payload}`,
        },
      };
    case RUN_MODAL_DRAFT:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: true,
          title: "Saved!",
          subtitle: `The ${payload} successfully saved to draft`,
        },
      };
    case RUN_MODAL_RENEWED:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: true,
          title: "Renewed!",
          subtitle: `The ${payload} has been successfully canceled`,
        },
      };
    case RUN_MODAL_FAILED:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: true,
          title: "Failed!",
          subtitle: `${payload}`,
        },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          isActive: false,
        },
      };
    default:
      return state;
  }
};

export default globalReducer;
