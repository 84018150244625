export const GET_LEAVE_REQUEST_SUCCESS = "GET_LEAVE_REQUEST_SUCCESS";
export const GET_LEAVE_REQUEST_FAILED = "GET_LEAVE_REQUEST_FAILED";
export const ADD_LEAVE_REQUEST_SUCCESS = "ADD_LEAVE_REQUEST_SUCCESS";
export const ADD_LEAVE_REQUEST_FAILED = "ADD_LEAVE_REQUEST_FAILED";
export const EDIT_LEAVE_REQUEST_SUCCESS = "EDIT_LEAVE_REQUEST_SUCCESS";
export const EDIT_LEAVE_REQUEST_FAILED = "EDIT_LEAVE_REQUEST_FAILED";
export const DELETE_LEAVE_REQUEST_SUCCESS = "DELETE_LEAVE_REQUEST_SUCCESS";
export const DELETE_LEAVE_REQUEST_FAILED = "DELETE_LEAVE_REQUEST_FAILED";
export const GET_LEAVE_REQUEST_DETAILS_SUCCESS = "GET_LEAVE_REQUEST_DETAILS_SUCCESS";
export const GET_LEAVE_REQUEST_DETAILS_FAILED = "GET_LEAVE_REQUEST_DETAILS_FAILED";
