import React from 'react';
import { Grid, TextField, Box, Typography, IconButton, Button, MenuItem } from "@mui/material";

function TextFieldCom({ label, hint, type, suffixIcon, onSuffixIconClick, required, onChange, status, message, id }) {
  const getOutlineColor = () => {
    if (status === 'error') {
      return 'red';
    } else if (status === 'success') {
      return 'green';
    }
    return undefined;
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ fontWeight: 'bold', mb: 1 }}>
        {label}
        {required && <span style={{ color: 'red' }}>*</span>}
      </Box>
      <TextField
        fullWidth
        type={type}
        sx={{
          mt: 0,
          cursor: onSuffixIconClick != null ? 'cursor' : 'default',
          '& input:-webkit-autofill': {
            caretColor: 'transparent',
            WebkitTextFillColor: 'currentColor',
          },
          borderRadius: '8px',
          outlineColor: getOutlineColor(),
          outlineWidth: '2px',
          outlineStyle: 'solid',
        }}
        id={id}
        placeholder={hint}
        size="small"
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <Box onClick={onSuffixIconClick} sx={{ position: 'absolute', right: 6, bottom: 0, color: getOutlineColor() }}>
              {suffixIcon}
            </Box>
          ),
        }}
      />
      {message && <Box sx={{ color: status === 'error' ? 'red' : 'green', fontStyle: 'italic', mt: .5 }}>{message}</Box>}
    </Box>
  );
}

  
export default TextFieldCom;