import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import SearchIconBlack from "./../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
} from "../../util/style";
import axios from "../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea
} from "../../components/Form";
import { addMenu, editMenu, getMenu } from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";
import clipboardIMG from "../../assets/clipboard.png";

const Menu = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const [copiedText, setCopiedText] = useState('');
  const handlePaste = async (e) => {
    try {
      const text = await navigator.clipboard.readText();
      setCopiedText(text);
    } catch (err) {
      alert('Failed to read text from clipboard:', err);
    }
  };

  const fields = [
    {
      name: "name",
      type: INPUT_TYPE.TEXT,
      label: "Menu Name",
      placeholder: 'Input menu name',
      defaultValue: "",
      required: true,
      tip: 'Example: Service Management',
      errorMessage: 'Please enter a menu name. Example: Service Management',
    },
    {
      name: "parent_id",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      label: "Main Menu",
      defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
      apiEndpoint: "/master/menus",
      placeholder: 'Select main menu ( Ops )',
      // required: true,
    },
    {
      name: "link",
      type: INPUT_TYPE.TEXT,
      label: "Link",
      placeholder: 'Paste Link',
      defaultValue: "",
      required: true,
      tip: 'Example: sales-management/list',
      additionalProps: {
        inputGroup: {
          icon: clipboardIMG,
          textContent: 'Paste',
          handleAction: handlePaste,
        },

      }
    },
  ];


  const columns = [
    {
      field: "name",
      headerName: "Menu Name",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "parent",
      headerName: "Main Menu",
      flex: 1,
      disableColumnMenu: true,
      // sortable: false,
      valueGetter: (params) => {
        return params.row.parent ? params.row.parent ?.name : "-";
      },
    },
    {
      field: "link",
      headerName: "Link",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 80,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => (
        <StatusBadge status={params.value}> {params.value} </StatusBadge>
      ),
    },
  ];

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSelectedTerm, setIsSelectedTerm] = useState(true);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Menu");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const dispatch = useDispatch();

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.menus);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    if (copiedText) {
      setFormValues((prevValues) => ({
        ...prevValues,
        link: copiedText,
      }));
    }
  }, [copiedText]);


  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(name, response.data.data);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: response.data.data,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });
  }, []);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getMenu({ token, limit, search, status, sortDirection, sortBy })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [status]);

  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues("fields"), {
        id: null,
        status: false,
      })
    );
  };
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  const initialFormValues = (type = "fields") => {
    if (type === "columns") {
      return columns.reduce(
        (acc, field) => ({ ...acc, [field.field]: "" }),
        {}
      );
    } else {
      return fields.reduce(
        (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
        {}
      );
    }
  };

  const initialErrors = fields.reduce(
    (acc, field) => ({ ...acc, [field.name]: "" }),
    {}
  );

  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"), { id: null, status: false })
  );
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    console.log(formValues, "formValues,");
    // console.log(initialFormValues(), "asdad");
  }, [formValues]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    // console.log(filteredRows, "filteredRows");
  }, [selectedData]);

  useEffect(() => {

    if (!search) {
      _search();
    }


  }, [search])
const handleKeyPress = (e) => {
  if (e.key === "Enter" && search) {
    _search();
  }
};
;

  useEffect(() => {
    if (searchTerm.length >= 2) {
      axios.get(`/menus?search=${searchTerm}&limit=999`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          setSuggestedItems(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching suggestions:', error);
        });
    } else {
      setSuggestedItems([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getMenu({ token, limit, search, status, sortDirection, sortBy })
      );
    }
  }, [isActive]);

  const setFormValuesFromSelectedData = () => {
    const data = rows.filter((row) => selectedData.includes(row.id))[0];
    for (const key in data) {
      // console.log(key, "key");
      // Check if the property exists in formValues before updating
      if (formValues.hasOwnProperty(key)) {
        // Special handling for the 'status' property
        const value = key === "status" ? data[key] === "active" : data[key];
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [key]: typeof value === "object" ? value ?.id : value,
        }));
      }
    }
  };

  const isFormFilled = () => {
    // return Object.values(formValues).every((value) => value.trim() !== "");
    return Object.values(formValues).some((value) => value.trim() !== "");
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */
  const checkMenuExistence = async (menuName, id = null) => {
    try {

      let apiUrl = `/menus/check-menu-exist?name=${menuName}`;
      if (id) {
        apiUrl += `&id=${id}`;
      }

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.status == false) {
        setErrors((prevErrors) => ({ ...prevErrors, name: response.data.message })); // Assuming the API returns a JSON object with a property 'exists'
        return {
          status: false,
          message: response.data.message
        }
      } else {
        return {
          status: true,
          message: response.data.message
        }
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value, "name, value");
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateForm = async () => {
    let valid = true;
    const newErrors = Object.assign({}, { ...initialErrors });

    // delete newErrors.name

    for (const field of fields) {
      const { name, required, validation, errorMessage } = field;

      if (required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE) && name !== 'name'
      ) {
        newErrors[name] = errorMessage || `Please enter ${String(field.label).toLowerCase()}`;
        valid = false;
      } else if (name === 'name' && required) {
        if (formValues[name] && formValues[name].length) {
          const isMenuValid = await checkMenuExistence(formValues.name, formValues.id);
          newErrors[name] = isMenuValid.status == false ? isMenuValid.message : '';
          valid = isMenuValid.status;
        } else {
          newErrors[name] = errorMessage || `Please enter ${String(field.label).toLowerCase()}`;
          valid = false;
        }
      }

      if (formValues[name] &&
        formValues[name].length && validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${field.label} is invalid`;
        valid = false;
      }
    }


    setErrors(newErrors);
    return valid;
  };




  const validateField = (fieldName) => {
    const field = fields.find((field) => field.name === fieldName);
    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label, type } = field;

      if (name === 'name' && required) {
        // Check for menu existence only for the "Menu Name" field
        if (formValues[name] && formValues[name].length) {
          checkMenuExistence(formValues.name, formValues.id);
        } else {
          newErrors[name] = errorMessage || `Please enter ${String(field.label).toLowerCase()}`
        }
      }

      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE) && name !== 'name'
      ) {
        newErrors[name] = errorMessage || `Please enter ${String(field.label).toLowerCase()}`
      } else if (formValues[name] &&
        formValues[name].length && validation && !validation(formValues[name])) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
      console.log(newErrors, "newErrors");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(
      errors,
      await validateForm(),
      "kukuruyuk",
      actionType,
      selectedData.length,
      actionType === ACTION_TYPE.EDITED
    );
    // console.log(formValues);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (await validateForm()) {
        if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          handleCloseFormModal(true);
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
          handleCloseFormModal(true);
        }
        setSelectedData([]);
      }
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    setErrors(initialErrors);
    if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
      setTitle("Edit Menu");
      setFormValuesFromSelectedData();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(data.map((row) => row.name).join(", "));
    } else {
      resetFormValues();
      setTitle("Add New Menu");
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        console.log("b", important);
        if (isFormFilled) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    resetFormValues();
    // setSelectedData([]); //buat disabled button toolbar
  };

  const handleExitedModal = () => {
    resetFormValues();
  };

  const handleAddRow = () => {
    dispatch(addMenu(token, formValues));
  };

  const handleEditRow = () => {
    dispatch(editMenu(token, formValues, formValues.id));
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    // dispatch(deleteMenu(token, { data: { ids } }, true));
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearch(suggestion.name);
    setSuggestedItems([]);
    setIsSelectedTerm(true);
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

  const renderFormContent = () => {
    if (
      (selectedData.length && actionType === ACTION_TYPE.EDITED) ||
      (!selectedData.length && actionType === ACTION_TYPE.ADD)
    ) {
      return (
        <>
          {fields.map((field) => {

            let optionsData;
            if (Array.isArray(options[field.name])) {
              const isRequired = field.required &&
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  name: "Choose Option",
                };

              if (isRequired) {
                optionsData = [
                  // field.required &&
                  //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  //     name: "Choose Option",
                  //   },
                  ...options[field.name],
                ];
              } else {
                optionsData = [...options[field.name]];
              }
            }

            const fieldOptions = Array.isArray(options[field.name])
              ? optionsData
              : [];

            switch (field.type) {
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.NUMBER:
              case INPUT_TYPE.PHONE_NUMBER:
              case INPUT_TYPE.POINT:
              case INPUT_TYPE.CURRENCY:
                return (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    tip={field ?.tip}
                  />
                );
              case INPUT_TYPE.TEXTAREA:
                return (
                  <TextArea
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    tip={field.tip}
                  />
                );
              case INPUT_TYPE.SELECT_ONE:
                return (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                return (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE:
                return (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                return (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={formValues}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleInputBlur={handleInputBlur}
                    fieldOptions={fieldOptions}
                    tip={field ?.tip}
                  />
                );
              default:
                return null;
            }
          })}
          {/* OLD */}
          {/* {fields.map((field) => {
            const fieldOptions = options[field.name] || [];
            switch (field.type) {
              case "text":
                return (
                  <React.Fragment key={field.name}>
                    <InputLabel>{field.label}</InputLabel>
                    <TextField
                      fullWidth
                      placeholder={`Input ${field.label}`}
                      margin="normal"
                      size="small"
                      variant="outlined"
                      value={formValues[field.name]}
                      name={field.name}
                      type={field.type}
                      onChange={handleInputChange}
                      onKeyUp={handleInputBlur}
                      onBlur={handleInputBlur}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]}
                      {...(field.additionalProps || {})}
                      sx={{ ...formGroup }}
                    />
                  </React.Fragment>
                );
              case "select":
                return (
                  <React.Fragment key={field.name}>
                    <InputLabel>{field.label}</InputLabel>
                    <Select
                      fullWidth
                      margin="normal"
                      size="small"
                      variant="outlined"
                      value={formValues[field.name]}
                      name={field.name}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]}
                      {...(field.additionalProps || {})}
                      sx={{ ...formGroup }}
                    >
                      {fieldOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </React.Fragment>
                );
              case "select-multiple":
                return (
                  <React.Fragment key={field.name}>
                    <InputLabel>{field.label}</InputLabel>
                    <Select
                      fullWidth
                      multiple
                      margin="normal"
                      size="small"
                      variant="outlined"
                      value={formValues[field.name]}
                      name={field.name}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      error={!!errors[field.name]}
                      helperText={errors[field.name]}
                      {...(field.additionalProps || {})}
                      sx={{ ...formGroup }}
                    >
                      {fieldOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  formValues[field.name]?.includes(option.id) ||
                                  false
                                }
                              />
                            }
                            label={option.name}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </React.Fragment>
                );
              default:
                return null;
            }
          })} */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...formGroup,
              padding: "0 1rem",
            }}
          >
            <FormControlLabel
              control={
                <StatusSwitch
                  checked={formValues.status}
                  name="status"
                  onChange={handleSwitchChange}
                />
              }
              label={
                <Typography
                  variant="h7"
                  sx={{ marginLeft: 1, fontWeight: "500" }}
                >
                  {formValues.status ? "Active" : "Inactive"}
                </Typography>
              }
            />
          </Box>
        </>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        >
          {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
        </Button>
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getMenu({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getMenu({ token, page, limit, search, status, sortDirection, sortBy })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getMenu({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
      })
    );
  }
  function _search() {
    if(status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(getMenu({ token, limit, search, status, sortDirection, sortBy }));
      setIsSelectedTerm(true);
    }
  }
  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getMenu({ token, limit, search, status, sortDirection, sortBy })
    // );
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      setIsSelectedTerm(false);
      setSearchTerm(value);
    }
  };


  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd", }} sx={{ overflow: 'unset' }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Box sx={{ position: 'relative' }}>
                <TextField
                  fullWidth
                  placeholder="Search menu name"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <img
                        src={SearchIconBlack}
                        style={{ width: "20px", margin: " 0 8px 0 0" }}
                      />
                    ),
                  }}
                  sx={{
                    borderRadius,
                  }}
                  // onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
                  value={search}
                  onChange={handleSearchChange}
                />
                {suggestedItems.length < 1 && searchTerm.length >= 2 && !isSelectedTerm && (
                  <Paper elevation={3} style={{ position: 'absolute', zIndex: 1, width: '100%' }}>
                    <List>
                      <ListItem>
                        <ListItemText primary="No Data Found" />
                      </ListItem>
                    </List>
                  </Paper>
                )}
                {suggestedItems.length > 0 && !isSelectedTerm && searchTerm.length >= 2 && (
                  <Paper elevation={3} style={{ position: 'absolute', zIndex: 1, width: '100%' }}>
                    <List>
                      {suggestedItems.map((suggestion) => (
                        <ListItem key={suggestion.id} onClick={() => handleSuggestionClick(suggestion)}>
                          <ListItemText primary={suggestion.name} />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                )}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={'Menu'}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            statusChoosed={status}
            search={search}
            showDeleteToolbar={false}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
          />
        </CardContent>
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Menu;
