import { Box, Button, Card, CardContent, Grid, InputLabel, List, ListItem, ListItemText, Modal, Paper, TextField, Typography } from "@mui/material"
import { borderRadius, btnWhite, formGroup, defaultStylePage } from "../../../../util/style";
import { useEffect, useState } from "react";
import axios, { headersAPIToken } from "../../../../core/axios_config";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../components/Table";
import { useTheme } from "@mui/styles";
import { addSetupReward, deleteSetupReward, editSetupReward, getSetupReward } from "./clients/actions";
import { ACTION_TYPE } from "../../../../util/function";
import Form, { SelectOneAutocompleteSearch } from "../../../../components/Form";
import ModalConfirmCancel from "../../../../components/ConfirmCancel";
import ConfirmDelete from "../../../../components/ConfirmDelete";

import { RunModal } from "../../../../util/global_state";

const columns = [
    {
        field: "code",
        headerName: "No. Invoice",
        flex: 1,
        disableColumnMenu: true,
    },
    {
        field: "service_category",
        headerName: "Service Category",
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params) => {
            return params.value.name;
        }
    },
    {
        field: "sub_service_category",
        headerName: "Sub Service Category",
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params) => {
            return params.value.name;
        }
    },
    {
        field: "particular",
        headerName: "Particular",
        flex: 1,
        disableColumnMenu: true,
        valueGetter: (params) => {
            return params.value === null ? "-" : params.value.name
        }
    },
    {
        field: "point",
        headerName: "Point",
        flex: 1,
        disableColumnMenu: true,
    },
]

const SetupRewardPoint = () => {
    const theme = useTheme();
    const useStyles = defaultStylePage;
    const dispatch = useDispatch();

    const { token } = useSelector((state) => state.auth);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pricePoint, setPricePoint] = useState(0);
    const [sortDirection, setSortDirection] = useState("desc");
    const [sortBy, setSortBy] = useState("id");
    const [sortModel, setSortModel] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmModals, setConfirmModals] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [title, setTitle] = useState("Add Service Category");
    const [setupRewardDeleted, setSetupRewardDeleted] = useState(null);
    const [searchServCat, setSearchServCat] = useState('');
    const [suggestServCat, setSuggestServCat] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState("");

    const [formValues, setFormValues] = useState(
        Object.assign({}, {
            id: null,
            service_category_id: null,
            sub_service_category_id: null,
            particular_id: null,
            point: null,
        })
    )
    const [errors, setErrors] = useState(
        Object.assign({}, {
            service_category_id: "",
            sub_service_category_id: "",
            particular_id: "",
            point: "",
        })
    )

    const {
        data: rows,
        message,
        isLoading,
        pagination,
    } = useSelector((state) => state.setupRewardPoint);

    const {
        modal: { isActive },
    } = useSelector((state) => state.global);

    useEffect(() => {
        if (isActive === true) {
            dispatch(
                getSetupReward({
                    token,
                    page,
                    limit,
                    sortDirection,
                    sortBy,
                    searchValue,
                    categoryFilter,
                })
            );
        }
    }, [isActive]);

    useEffect(() => {
        dispatch(
            getSetupReward({
                token,
                page,
                limit,
                sortDirection,
                sortBy,
                searchValue,
                categoryFilter,
            })
        );
    }, [sortDirection, sortBy])

    useEffect(() => {
        console.log(token);
        axios.get(`/setup-reward/rules`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            setPricePoint(formatWithCommas(`${response.data.data.amount}`));
        })
    }, [])

    const searchServCatFunc = () => {
        axios.get(`/master/services?search=${searchServCat}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                console.log("testing");
                setSuggestServCat(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching suggestions:', error);
            });
    }

    useEffect(() => {
        searchServCatFunc();
    }, [])


    const handlePricePoint = (e) => {
        const newValue = e.target.value;
        const formattedValue = formatWithCommas(newValue);
        setPricePoint(formattedValue);
    }

    const formatWithCommas = (e) => {
        const formattedValue = e.replace(/^0+(?=\d{0,})/, "0");
        const formattedValue2 = formattedValue.replace(/\D/g, "");
        const formattedValue3 = formattedValue2.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedValue3;
    }
    const handleSubmitPricePoint = (e) => {
        e.preventDefault();
        setConfirmModals(true);

    }

    const handleOnConfirmCancelModals = () => {
        setConfirmModals(false);
        console.log("handleOnConfirmCancelModals");
    }
    const handleOnCloseConfirmCancelModals = () => {
        setConfirmModals(false);
        axios.post(`setup-reward/rules_change`, { amount: pricePoint.replaceAll(",", "") }, headersAPIToken(token)).then((response) => {
            dispatch(RunModal("EDITED", "Reward Rules"));
        })
    }
    const handlePageChange = (newPage) => {
        setPage(newPage);
        _getByPage(newPage + 1);
    };

    const handleLimitChange = (newLimit) => {
        setPage(0);
        setLimit(newLimit);
        _getByLimit(newLimit);
    };

    const handleSelectionChange = (selection) => {
        console.log(selection, "selection");
        setSelectedData(selection || []);
    };

    const handleSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
        setActionType(actionType);
        setFormModal(true);
        if (actionType === ACTION_TYPE.ADD) {
            setTitle(`Add Service Category`);
        } else if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
            setTitle(`Edit Service Category`);
            setFormValuesFromSelectedData();
        } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            setTitle(`Delete Service Category`);
        }
    }

    const setFormValuesFromSelectedData = () => {
        const data = rows.filter((row) => selectedData.includes(row.id))[0];
        for (const key in data) {
            // console.log(key, "key");
            // Check if the property exists in formValues before updating
            if (formValues.hasOwnProperty(key)) {
                // Special handling for the 'status' property
                const value = key === "status" ? data[key] === "Active" : data[key];
                setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    [key]: typeof value === "object" ? value?.id : value,
                }));
            }
        }
    };

    const handleCloseFormModal = (important = false) => {
        console.log("1");
        if (!important) {
            if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
                console.log("2");
                if (true) setConfirmModal(true)
            } else {
                console.log("3");
                setFormModal(false)
            }
        } else {
            console.log("4");
            setFormModal(false);
        }
    }

    const handleOnConfirmCancelModal = () => {
        setConfirmModal(false);
    };

    const handleOnCloseConfirmCancelModal = () => {
        setConfirmModal(false);
        setFormModal(false);
        resetForm();
    }

    const resetForm = () => {
        const newErrors = { ...errors }
        const newFormValues = { ...formValues }
        newErrors.service_category_id = "";
        newErrors.sub_service_category_id = "";
        newErrors.particular_id = "";
        newErrors.point = "";
        newFormValues.service_category_id = "";
        newFormValues.sub_service_category_id = "";
        newFormValues.particular_id = "";
        newFormValues.point = "";
        setFormValues(newFormValues);
        setErrors(newErrors);
    }

    function _getByPage(page) {
        if (pagination === null || pagination === undefined) {
            return;
        }
        dispatch(
            getSetupReward({
                token,
                page,
                limit,
                searchValue,
                sortDirection,
                sortBy,
                categoryFilter,
            })
        );
    }

    function _getByLimit(limit) {
        dispatch(
            getSetupReward({
                token,
                limit,
                searchValue,
                sortDirection,
                sortBy,
                categoryFilter,
            })
        );
    }

    function _categoryFilter(category) {
        setCategoryFilter(category);
        dispatch(
            getSetupReward({
                token,
                limit,
                searchValue,
                sortDirection,
                sortBy,
                categoryFilter: category,
            })
        )
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };


    const renderFormContent = () => {
        if ((selectedData.length && actionType === ACTION_TYPE.EDITED) ||
            (!selectedData.length && actionType === ACTION_TYPE.ADD)) {
            return (
                <div>
                    <SelectOneAutocompleteSearch
                        errors={errors}
                        disabled={false}
                        isUseId={true}
                        value={formValues.service_category_id}
                        url={"/master/services"}
                        name={"service_category_id"}
                        title={"Service Category"}
                        handleInputChange={handleInputChange}
                    />
                    <Box sx={{ height: "24px" }} />
                    <SelectOneAutocompleteSearch
                        errors={errors}
                        disabled={false}
                        isUseId={true}
                        value={formValues.sub_service_category_id}
                        url={`/master/sub-services?main_service_id=${formValues.service_category_id ?? ''}`}
                        name={"sub_service_category_id"}
                        title={"Sub Service Category"}
                        handleInputChange={handleInputChange}
                    />
                    <Box sx={{ height: "24px" }} />
                    <SelectOneAutocompleteSearch
                        errors={errors}
                        disabled={false}
                        value={formValues.particular_id}
                        url={`/master/criteria/${formValues.sub_service_category_id}`}
                        name={"particular_id"}
                        title={"Particular"}
                        handleInputChange={handleInputChange}
                    />
                    <Box sx={{ height: "24px" }} />
                    <InputLabel>Point</InputLabel>
                    <TextField
                        fullWidth
                        placeholder={`Point`}
                        value={formValues.point}
                        margin="normal"
                        size="small"
                        type="number"
                        variant="outlined"
                        name="point"
                        error={errors.point}
                        sx={{ ...formGroup }}
                        onChange={(event) => {
                            const { name, value } = event.target;
                            console.log(name, value);
                            setFormValues((prevValues) => ({
                                ...prevValues,
                                [name]: value,
                            }));
                        }}
                    />
                </div>
            )
        } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            return <ConfirmDelete userDeleted={setupRewardDeleted} />;
        }
    }
    const renderActionButtons = () => {
        return (
            <Box>
                <Button
                    variant="outlined"
                    sx={{ ...btnWhite }}
                    onClick={() => {
                        setConfirmModal(true)
                        // navigate(-1)
                    }}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    type="submit" >
                    Save
                </Button>
            </Box>
        );
    }

    const handleAddRow = () => {
        dispatch(addSetupReward(token, formValues));
    };

    const handleEditRow = () => {
        dispatch(editSetupReward(token, formValues, formValues.id));
    };

    const handleDeleteRow = () => {
        const data = rows.filter((row) => selectedData.includes(row.id));
        const ids = data.map((row) => row.id);
        dispatch(deleteSetupReward(token, { data: { ids } }, true));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
            handleDeleteRow();
            handleCloseFormModal(true);
            setSelectedData([]);
        } else {
            if (validateForm()) {
                if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
                    handleEditRow();
                    handleCloseFormModal(true);
                } else if (actionType === ACTION_TYPE.ADD) {
                    handleAddRow();
                    handleCloseFormModal(true);
                }
                resetForm();
                setSelectedData([]);
            }
        }

    }

    const validateForm = () => {
        let valid = true;
        const newErrors = { ...errors };

        if (!formValues.service_category_id) {
            newErrors.service_category_id = "Service Category is required"
            valid = false;
        } else {
            newErrors.service_category_id = null
        }
        if (!formValues.sub_service_category_id) {
            newErrors.sub_service_category_id = "Sub Service Category is required"
            valid = false;
        } else {
            newErrors.sub_service_category_id = null
        }
        if (!formValues.particular_id) {
            newErrors.particular_id = "Particular is required"
            valid = false;
        } else {
            newErrors.particular_id = null
        }
        if (!formValues.point) {
            newErrors.point = "Point is required"
            valid = false;
        } else {
            newErrors.point = null
        }

        setErrors(newErrors);
        return valid;
    }

    return (
        <>
            <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
                <CardContent sx={{ padding: "53px, 23px" }}>
                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                        <Grid item xs={10}>
                            <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                                Reward Rules
                            </Typography>
                            <Typography sx={{ fontWeight: 400, fontSize: "16px" }}>
                                Set rewards per point for all services here!
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={2} sx={{ marginY: 1 }}>
                                    <Typography sx={{ fontWeight: 400, fontSize: "16px" }}>
                                        1 Points
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sx={{ marginY: 1 }}>
                                    <Typography sx={{ fontWeight: 400, fontSize: "16px" }}>
                                        =
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        placeholder={"0"}
                                        size="small"
                                        type="currency"
                                        value={pricePoint}
                                        fullWidth
                                        sx={{ borderRadius }}
                                        onChange={handlePricePoint}
                                        InputProps={{
                                            startAdornment: (<Typography sx={{ paddingRight: 1 }}>Rp</Typography>),
                                            onKeyDown: () => { },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                disableElevation
                                onClick={handleSubmitPricePoint}>
                                Update Reward Rules
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
                <CardContent>
                    <SelectOneAutocompleteSearch
                        errors={errors}
                        disabled={false}
                        isUseId={true}
                        isUseTitle={false}
                        url={"/master/services"}
                        name={"category_filter"}
                        title={"Service Category"}
                        handleInputChange={(e) => _categoryFilter(e.target.value)}
                    />
                </CardContent>
            </Card>
            <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
                <CardContent sx={{ paddingTop: "unset" }}>
                    <DataTable
                        title={"Reward Rules"}
                        useStyles={useStyles}
                        rows={rows}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        limit={limit}
                        page={page}
                        handlePageChange={handlePageChange}
                        handleLimitChange={handleLimitChange}
                        handleFormModal={handleFormModal}
                        selectedData={selectedData}
                        handleSelectionChange={handleSelectionChange}
                        theme={theme}
                        sortModel={sortModel}
                        search={searchValue}
                        onSortModelChange={handleSortModelChange}
                    />
                </CardContent>
            </Card>
            <Modal open={formModal} onClose={() => handleCloseFormModal()}>
                <Box>
                    <Form
                        title={title}
                        onCloseModal={() => handleCloseFormModal()}
                        elementForm={renderFormContent}
                        elementActionButton={renderActionButtons}
                        onSubmit={handleSubmit}
                    />
                </Box>
            </Modal>
            <ModalConfirmCancel
                confirmModal={confirmModal}
                onConfirmModal={handleOnConfirmCancelModal}
                onCloseModal={handleOnCloseConfirmCancelModal}
            ></ModalConfirmCancel>
            <ModalConfirmCancel
                confirmModal={confirmModals}
                title={"Update Reward Rules"}
                message={"You want to update reward rules"}
                onConfirmModal={handleOnConfirmCancelModals}
                onCloseModal={handleOnCloseConfirmCancelModals}
            ></ModalConfirmCancel>
        </>
    )
}

export default SetupRewardPoint