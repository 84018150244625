import axios, { headersAPIToken, urlRequest } from "../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../util/global_state";
import { GET_USER_LOGS_SUCCESS, GET_USER_LOGS_FAILED } from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../util/function";
import { logout } from "../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getUserLogs = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  sortDirection = null,
  sortBy = null,
  dateFilter = null,
  startDate = dateFilter !== "custom_date" ? null : startDate,
  endDate = dateFilter !== "custom_date" ? null : endDate,
  action = action == ACTION_TYPE.ALL_ACTION ? null : action,
}) => {
  console.log(
    sortDirection,
    sortBy,
    "sortDirection, sortBy,",
    action,
    "action"
  );
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "user-logs",
          page,
          limit,
          search,
          sortDirection,
          sortBy,
          dateFilter: dateFilter == ACTION_TYPE.ALL_DATE ? null : dateFilter,
          startDate: dateFilter !== "custom_date" ? null : startDate,
          endDate: dateFilter !== "custom_date" ? null : endDate,
          action: action == ACTION_TYPE.ALL_ACTION ? null : action,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getUserLogsSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getUserLogsSuccess([], null));
        } else {
          dispatch(getUserLogsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getUserLogsFailed(error.response.data.message));
          }
        } else {
          dispatch(getUserLogsFailed(error.message));
        }
      });
  };
};

const getUserLogsSuccess = (data, pagination) => {
  return {
    type: GET_USER_LOGS_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getUserLogsFailed = (errorMessage) => {
  return {
    type: GET_USER_LOGS_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};
