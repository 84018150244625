"use strict";
import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import "dayjs/locale/en";
import ExportIcon from "./../../../assets/export.png";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import RemoveIcon from "../../../assets/minus.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  // StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
  btnTableToolbar,
  imgBtnToolbar,
} from "../../../util/style";
import axios, { headersAPIToken } from "./../../../core/axios_config";
import OriginAxios from "axios";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
  SelectOneAutocompleteSearch,
  SelectOneAutocompleteSearchManageble,
} from "./../../../components/Form";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  createEmptyErrors,
  localToUTC,
} from "./../../../util/function";
import DataTable from "./../../../components/Table";
import ConfirmDelete from "./../../../components/ConfirmDelete";
import ModalConfirmCancel from "./../../../components/ConfirmCancel";
import LeftDrawer from "./../../../components/LeftDrawer";
import { TransitionAlerts } from "../../../components/Alert";
import { ANOTHER_INPUT_TYPE, columns, fields } from "./part/imutalbe_state";
import {
  addStockOut,
  deleteStockOut,
  getStockOut,
  updateStockOut,
} from "./client/action";

function StockOut() {
  const theme = useTheme();
  const useStyles = defaultStylePage;

  const searchByKey = Object.freeze({
    "No References": "code",
    "Stock Name": "name",
    "Brand Name": "brand",
  });

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setClientDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [category, setCategory] = useState(ACTION_TYPE.DEFAULT_CATEGORY);
  const [sortModel, setSortModel] = useState([]);

  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add Stock In");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [choosedStatus, setChoosedStatus] = useState(
    ACTION_TYPE.DEFAULT_STATUS
  );
  const [searchBy, setSearchBy] = useState(searchByKey["No References"]);
  const [dbQuantity, setDbQuantity] = useState(0);
  const [menuCategory, setMenuCategory] = useState([]);
  //* Type as Category
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(true);
  const [selectedId, setSelectedId] = useState(0);
  const [options, setOptions] = useState({});
  // const

  const dispatch = useDispatch();

  const {
    isLoading,
    data: rows,
    pagination,
    message,
  } = useSelector((state) => state.stockOut);

  /** ======================================================================================= */
  /**                                          FORM                                           */
  /** ======================================================================================= */

  const initialAttachment = [null];

  /**@param {'form'|'error'} type */
  const initialValues = (type) => {
    const newValue = {};
    fields.forEach((field) => {
      if (field.type === ANOTHER_INPUT_TYPE.FILE) {
        newValue[field.name] = initialAttachment;
      } else {
        newValue[field.name] = type === "form" ? field.defaultValue : "";
      }
    });
    return newValue;
  };

  const [formValues, setFormValues] = useState(initialValues("form"));
  const [errors, setErrors] = useState(initialValues("error"));

  const resetForm = () => {
    setFormValues(initialValues("form"));
    setErrors(initialValues("error"));
    setDbQuantity(0);
    setOptions({});
  };

  //* --------------------------------- [ OPTIONS ] ---------------------------------- */

  useEffect(() => {
    const fetchOption = (endpoint, name) => {
      return axios
        .get(endpoint, headersAPIToken(token))
        .then((response) => {
          if (response.data.status === true) {
            setOptions((prev) => ({ ...prev, [name]: response.data.data }));
          }
        })
        .catch((error) => {
          console.log(`Failed get option for ${name}: ${error}`);
        });
    };

    if (!options.hasOwnProperty("category_id")) {
      fetchOption("/master/asset-categories", "category_id");
    }
    if (
      !options.hasOwnProperty("brand_id") &&
      formValues["category_id"] !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE
    ) {
      fetchOption(
        "/master/asset-brands".concat(
          "?category_id=",
          formValues["category_id"]
        ),
        "brand_id"
      );
    }
    if (
      !options.hasOwnProperty("asset_id") &&
      formValues["category_id"] !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE &&
      formValues["brand_id"] !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE
    ) {
      fetchOption(
        "/master/assets".concat(
          "?category_id=",
          formValues["category_id"],
          "&brand_id=",
          formValues["brand_id"]
        ),
        "asset_id"
      );
    }
  }, [formValues, options]);

  const fetchOptions = async (endpoint, name) => {
    try {
      const response = await axios.get(endpoint, headersAPIToken(token));
      if (response.data.status === true) {
        setOptions((prev) => ({ ...prev, [name]: response.data.data }));
      }
    } catch (error) {
      console.error(`Failed get option for ${name} : ${error}`);
    }
  };

  /** ======================================================================================= */
  /**                                        VALIDATION                                       */
  /** ======================================================================================= */

  const validateField = (fieldName) => {
    const copyError = { ...errors };
    const field = fields.find((fiel) => fiel.name === fieldName);
    if (field) {
      const { name, defaultValue, label, errorMessage, validation, required } =
        field;
      const valueInForm = formValues[name];
      let messageError = "";
      if (
        required &&
        (valueInForm === "" ||
          valueInForm === undefined ||
          valueInForm === null ||
          valueInForm === defaultValue ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE)
      ) {
        messageError = `Please enter ${label.toLowerCase()}`;
      }

      if (
        valueInForm &&
        valueInForm?.length &&
        validation &&
        !validation(valueInForm)
      ) {
        messageError = errorMessage || `${label} is invalid`;
      }
      copyError[name] = messageError;
    }
    setErrors(copyError);
  };

  const validateForm = () => {
    const copyError = { ...errors };
    let valid = true;
    fields.forEach((field) => {
      let messageError = "";
      const {
        name,
        label,
        errorMessage,
        validation,
        defaultValue,
        required,
        type,
      } = field;
      const valueInForm = formValues[name];
      if (type === ANOTHER_INPUT_TYPE.FILE) {
        formValues[name].forEach((valueFile, index) => {
          const messageError = validateFileInput(valueFile, field);
          if (messageError) {
            copyError[name][index] = messageError;
            valid = false;
          }
        });
      } else {
        if (
          required &&
          (valueInForm === "" ||
            valueInForm === undefined ||
            valueInForm === null ||
            valueInForm === defaultValue ||
            valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE)
        ) {
          messageError = `Please enter ${label.toLowerCase()}`;
          valid = false;
        }

        if (
          valueInForm &&
          valueInForm?.length &&
          validation &&
          !validation(valueInForm)
        ) {
          messageError = errorMessage || `${label} is invalid`;
          valid = false;
        }

        copyError[name] = messageError;
      }
    });
    setErrors(copyError);
    return valid;
  };

  /** ======================================================================================= */
  /**                                         HANDLER                                         */
  /** ======================================================================================= */

  const onUnMounted = () => {
    console.log("Componen unMount");
  };

  const onMounted = () => {
    console.info("Component mounted");
    axios
      .get("/master/asset-categories", headersAPIToken(token))
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          setMenuCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Failed get menu category :", error);
      });
    return onUnMounted;
  };

  useEffect(() => {
    //* Get Detail Assets;
    if (formValues["asset_id"] !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE) {
      getDetailAssets(formValues["asset_id"]);
    }
  }, [formValues]);

  /**
   *
   * @param {import("react").SyntheticEvent} event
   * @param {import("./part/imutalbe_state").FieldType} field
   * @param {number} index
   */
  const handleInputChange = (event, field, index) => {
    const { name, value, type } = event.target;
    const payloadAdd = {
      [name]: value,
    };

    //* If input === file. validate
    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const errorFile = validateFileInput(file, field);
        if (Array.isArray(errors[field.name])) {
          setErrors((prev) => {
            const currError = { ...prev };
            currError[field.name][index] = errorFile;
            return currError;
          });
        }
      }
      return;
    }
    // Set the plain input type
    setFormValues((prev) => ({ ...prev, ...payloadAdd }));
  };
  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const handleFormModal = (actionParam = ACTION_TYPE.ADD) => {
    setActionType(actionParam);
    switch (actionParam) {
      case ACTION_TYPE.ADD:
        resetForm();
        setTitle("Add Stock Out");
        setFormModal(true);
        break;
      case ACTION_TYPE.EDITED:
        setTitle("Edit Stock Out");
        setIsLoadedFormValues(false);
        setFormModal(true);
        getPayloadEdit().then(() => {
          setTimeout(() => {
            console.log("TimeOut");
            setIsLoadedFormValues(true);
          }, 1000);
        });
        break;
      case ACTION_TYPE.DELETED:
        setTitle("Delete");
        setFormModal(true);
        break;
      default:
        return;
    }
  };
  const handleSubmit = (/**@type {import("react").SyntheticEvent} */ event) => {
    event.preventDefault();
    if (actionType === ACTION_TYPE.DELETED) {
      handleDeletRow();
      console.log("delete");
    } else {
      if (validateForm()) {
        if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
        } else if (actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
        }
      }
    }
  };

  // ------------------------------------- [ FILE ] ----------------------------------------- */

  /**
   *
   * @param {File} file
   * @param {import("./part/imutalbe_state").FieldType} field
   */
  const validateFileInput = (file, field) => {
    if (file instanceof File) {
      const allowExtension = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowExtension.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (file.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }
    return "";
  };

  const handleFileSelect = (file, fieldName, index) => {
    // const arr = [""];
    // console.log("before", { arr, file, index });
    // arr.splice(index, 1, file);
    // console.log("after", { arr, file, index });
    // console.log("On select file", { file, name, index });
    // setFormValues((prev) => {
    //   const attach = prev[name];
    //   console.log("Before :", attach);
    //   attach[index] = { filename: "ASyu" };
    //   console.log("after :", attach);
    //   return { ...prev, [name]: attach };
    // });
    // setFormValues((prevValues) => {
    //   const prevValueFile = formValues[fieldName];
    //   if (!isNaN(index)) {
    //     prevValueFile[index] = file;
    //   }
    //   return { ...prevValues, [fieldName]: prevValueFile };
    // });
    const copyForm = { ...formValues };
    const attach = [...copyForm[fieldName]];
    // console.log("Before", { attach, index, file });
    attach.splice(index, 1, file);
    // console.log("After  ", { attach, isSame: copyForm === formValues, index });
    setFormValues((prev) => ({ ...prev, [fieldName]: attach }));
    // setFormValues(copyForm);
  };

  const handleAddFile = (name) => {
    //* Add field in form
    setFormValues((prev) => {
      const newValue = { ...prev };
      newValue[name].push(null);
      return newValue;
    });
  };

  const handleRemoveFile = (name, index) => {
    if (isNaN(index)) {
      console.error(new Error("Invalid index"));
      return;
    }
    //* Remove from form
    setFormValues((prev) => {
      const newVal = { ...prev };
      const attach = [...newVal[name]];
      console.log("Before remove", { index, attach });
      const removed = attach.splice(index, 1);
      console.log("After remove", { removed, attach });
      newVal[name] = attach;
      return newVal;
    });
    //* Remove from Errors
    setErrors((prev) => {
      const newErr = { ...prev };
      newErr[name].splice(index, 1);
      return newErr;
    });
  };

  // --------------------------------- [ EDIT SECTION ] ------------------------------------- */

  const getDetailAssets = async (assetId) => {
    try {
      const endpoint = "assets/".concat(assetId, "/edit");
      const response = await axios.get(endpoint, headersAPIToken(token));
      if (response.status === 200 && response.data.status === true) {
        setDbQuantity(response.data?.data?.qty);
      }
    } catch (error) {
      console.error("Failed get detail assets", error);
    }
  };

  const getPayloadEdit = () => {
    let id;
    if (selectedData.length > 0) {
      id = selectedData[0];
    }
    if (id) {
      setSelectedId(id);
      
      let payloadEdit, detailAssets;
      const endpoint = "/stocks/".concat(id, "/edit?type=out");
      return axios
        .get(endpoint, headersAPIToken(token))
        .then((resPayloadEdit) => {
          if (resPayloadEdit.data.status === true) {
            // console.log(resPayloadEdit);
            payloadEdit = resPayloadEdit.data.data;
            const assetId = payloadEdit?.asset_id;

            //* ---------- Return new promises; --------------------;
            return axios.get(
              "assets/".concat(assetId, "/edit"),
              headersAPIToken(token)
            );
          }
        })
        .then((respAssest) => {
          detailAssets = respAssest.data.data;
          const newFormValues = {
            category_id: detailAssets["category_id"],
            brand_id: detailAssets["brand_id"],
            asset_id: detailAssets["id"],
            qty: payloadEdit["qty"],
            attachments: payloadEdit["attachments"]?.map((file) => {
              file["filename"] = file["name"];
              return file;
            }),
          };
          setDbQuantity(detailAssets["qty"]);
          setFormValues(newFormValues);
          setErrors(initialValues("error"));
        })
        .catch((error) => {
          console.error(`Failed get payload for id ${id} : ${error}`);
        });
    } else {
      console.error("Id not found");
    }
  };

  /** ======================================================================================= */
  /**                                    HANDLER REDUCER                                      */
  /** ======================================================================================= */

  const handleAddRow = () => {
    const manipulated = manipulatePayload();
    //TODO: Dispatch
    setIsLoadedFormValues(false);
    dispatch(addStockOut(token, manipulated)).then(() => {
      setFormModal(false);
      setIsLoadedFormValues(true);
      resetForm();
      dispatch(
        getStockOut(token, {
          sortBy,
          sortDirection,
          category,
          limit,
          page,
          search,
          searchBy,
        })
      );
    });
  };

  const handleEditRow = () => {
    const manipulated = manipulatePayload();
    setIsLoadedFormValues(false);
    //TODO: Dispatch
    dispatch(updateStockOut(token, manipulated, selectedId)).then(() => {
      setSelectedData([]);
      setFormModal(false);
      setIsLoadedFormValues(true);
      resetForm();
      dispatch(
        getStockOut(token, {
          sortBy,
          sortDirection,
          category,
          limit,
          page,
          search,
          searchBy,
        })
      );
    });
  };

  const handleDeletRow = () => {
    const ids = [...selectedData];
    const joinedIds = ids.join(",");
    //TODO: Dispatch
    dispatch(deleteStockOut(token, joinedIds)).then(() => {
      setFormModal(false);
      setSelectedData([]);
      dispatch(
        getStockOut(token, {
          sortBy,
          sortDirection,
          category,
          limit,
          page,
          search,
          searchBy,
        })
      );
    });
  };

  const manipulatePayload = () => {
    const copyForm = {
      asset_id: formValues["asset_id"],
      qty: parseInt(formValues["qty"]),
    };
    const attachments = formValues["attachments"];
    let resultAttachments = [];
    console.log({ attachments });
    if (Array.isArray(attachments)) {
      attachments.forEach((file) => {
        if (file !== "") {
          resultAttachments.push(file);
        } else {
          // console.log(file);
        }
      });
    }
    // console.log("if ke 2", resultAttachments);
    if (resultAttachments.length > 0) {
      copyForm["attachments"] = attachments;
    }
    return copyForm;
  };

  /** ======================================================================================= */
  /**                                       COMPONENTS                                        */
  /** ======================================================================================= */

  const ButtonAddMore = ({ fieldName }) => (
    <Box sx={{ ...justifyContentEnd }}>
      <Button
        onClick={() => handleAddFile(fieldName)}
        disabled={actionType === ACTION_TYPE.EDITED}
      >
        <span
          style={{
            fontSize: "1.5rem",
            marginRight: ".6rem",
            display: "inline-block",
          }}
        >
          +
        </span>
        Add More
      </Button>
    </Box>
  );

  const ButtonRemove = ({ fieldName, index }) => (
    <Button
      variant="contained"
      disableElevation
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        ...removeButton,
      }}
      onClick={() => handleRemoveFile(fieldName, index)}
      disabled={
        (actionType === ACTION_TYPE.ADD &&
          formValues[fieldName].length === 1) ||
        actionType === ACTION_TYPE.EDITED
      }
    >
      <img src={RemoveIcon} alt={`Remove ${fieldName}`} />
    </Button>
  );

  const HeaderText = () => (
    <Box sx={{ marginBottom: 2 }}>
      <Typography variant="h6" sx={{ fontWeight: 800 }}>
        Attachment
      </Typography>
      <Typography variant="subtitle2">
        You can upload the documents required for this process (optional)
      </Typography>
    </Box>
  );

  const RenderFileInput = (
    /** @type {import("./part/imutalbe_state").FieldType} */ field
  ) => {
    if (
      actionType === ACTION_TYPE.EDITED &&
      formValues[field.name].length < 1
    ) {
      return null;
    }
    return (
      <>
        <Grid>
          <HeaderText />
          {formValues[field.name]?.map((fileValue, index) => (
            <div>
              <Grid container spacing={0.3}>
                <Grid item xs={11}>
                  <FilePicker
                    errors={errors}
                    field={field}
                    formValues={fileValue}
                    handleInputChange={(event) =>
                      handleInputChange(event, field, index)
                    }
                    onFileSelect={(file) =>
                      handleFileSelect(file, field.name, index)
                    }
                    tip={"Max size: 5 MB. Format file: PDF, JPG, PNG."}
                    indexFile={index}
                    URIForViewDocument={fileValue?.url}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <ButtonRemove fieldName={field.name} index={index} />
                </Grid>
              </Grid>
            </div>
          ))}
          {actionType !== ACTION_TYPE.EDITED && (
            <ButtonAddMore fieldName={field.name} />
          )}
        </Grid>
      </>
    );
  };

  const renderFormContent = () => {
    if (!isLoadedFormValues) return <LoadingComponent />;
    return (
      <>
        {actionType === ACTION_TYPE.DELETED ? (
          <ConfirmDelete userDeleted={"stock out"} />
        ) : (
          fields.map((field) => {
            if (field.name === "quantity") {
              formValues[field.name] = dbQuantity;
            }
            switch (field.type) {
              case INPUT_TYPE.NUMBER:
                return (
                  <Box width="5rem" key={field.name}>
                    <TextInput
                      errors={errors}
                      field={field}
                      formValues={formValues}
                      handleInputBlur={handleInputBlur}
                      handleInputChange={handleInputChange}
                    />
                  </Box>
                );
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                let localEndpoint = field.apiEndpoint;
                let disabled = false;
                const fieldOption = options[field.name] || [];
                if (field.name === "brand_id") {
                  if (
                    formValues["category_id"] ===
                    SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE
                  ) {
                    disabled = true;
                  } else {
                    disabled = false;
                    localEndpoint = "/master/asset-brands?category_id=".concat(
                      formValues["category_id"]
                    );
                  }
                } else if (field.name === "asset_id") {
                  if (
                    formValues["brand_id"] ===
                    SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE
                  ) {
                    disabled = true;
                  } else {
                    disabled = false;
                    localEndpoint = "/master/assets?category_id=".concat(
                      formValues["category_id"],
                      "&brand_id=",
                      formValues["brand_id"]
                    );
                  }
                }
                const modifiedSelecOption = (item) => {
                  item["id"] = item.id;
                  return item;
                };
                //* Disabled field when action mode is edit
                if (actionType === ACTION_TYPE.EDITED) {
                  field.apiEndpoint = localEndpoint;
                  field.additionalProps.disabled = true;
                }
                return (
                  <React.Fragment key={field.name}>
                    {actionType === ACTION_TYPE.ADD ? (
                      <SelectOneAutocompleteSearch
                        errors={errors}
                        handleInputChange={handleInputChange}
                        name={field.name}
                        title={field.label}
                        url={localEndpoint}
                        disabled={disabled}
                        manipulateResponse={modifiedSelecOption}
                      />
                    ) : (
                      <SelectOneAutocomplete
                        errors={errors}
                        field={field}
                        formValues={formValues}
                        handleInputChange={handleInputChange}
                        handleInputBlur={handleInputBlur}
                        fieldOptions={fieldOption}
                      />
                    )}
                  </React.Fragment>
                );
              case ANOTHER_INPUT_TYPE.FILE:
                if (actionType === ACTION_TYPE.EDITED) {
                  field.additionalProps.disabled = true;
                }
                return RenderFileInput(field);
              default:
                return null;
            }
          })
        )}
      </>
    );
  };

  const renderActionButtons = () => {
    if (isLoadedFormValues) {
      return (
        <Box>
          <Button
            variant="outlined"
            sx={{ ...btnWhite }}
            onClick={() => handleCloseFormModal()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            type="submit"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      );
    } else return null;
  };

  const ModifiedLeftToolbar = () => {
    let urlExport = process.env.REACT_APP_API_URL.concat(
      "/api/stocks/export?type=out"
    );
    if (category !== ACTION_TYPE.ALL_CATEGORY) {
      urlExport += "&category_id=".concat(category);
    }
    if (search) {
      urlExport = urlExport.concat(
        "&search_by=",
        searchBy,
        "&search_value=",
        search
      );
    }
    return (
      <span>
        <Button
          size="medium"
          color="primary"
          disabled={search === "" && category === ACTION_TYPE.DEFAULT_CATEGORY}
          sx={{
            ...btnTableToolbar,
            borderRadius: "5px !important",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={() => {
            window.open(urlExport, "_blank");
          }}
        >
          <img
            src={ExportIcon}
            style={{
              ...imgBtnToolbar,
              marginRight: 5,
            }}
          />
          Export
        </Button>
      </span>
    );
  };

  const LoadingComponent = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <FacebookCircularProgress />
    </Box>
  );
  /** ======================================================================================= */
  /**                                         MODAL                                           */
  /** ======================================================================================= */

  const handleCloseFormModal = (important = false) => {
    if (important) {
      setFormModal(false);
      resetForm();
    } else {
      setConfirmModal(true);
    }
  };
  const onCloseConfirmModal = () => {
    setConfirmModal(false);
  };
  const onConfirmModal = () => {
    setFormModal(false);
    setConfirmModal(false);
    setSelectedData([]);
    resetForm();
  };

  /** ======================================================================================= */
  /**                                     HANDLE PAGING                                       */
  /** ======================================================================================= */

  useEffect(() => {
    if (!search && search !== "") {
      // console.log({search});
      _search();
    }
  }, [search]);

  useEffect(() => {
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      sortBy(field);
      sortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (category !== ACTION_TYPE.DEFAULT_CATEGORY) {
      //TODO: Dispatch
      dispatch(
        getStockOut(token, {
          sortBy,
          sortDirection,
          category,
          limit,
          page,
          search,
          searchBy,
        })
      );
    }
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (category !== ACTION_TYPE.DEFAULT_CATEGORY) {
      //TODO: Dispatch
      dispatch(
        getStockOut(token, {
          sortBy,
          sortDirection,
          category,
          limit,
          page,
          search,
          searchBy,
        })
      );
      setChoosedStatus("SHOW DATA");
    } else {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
    }
  }, [category]);

  const handleKeyPress = (/** @type {KeyboardEvent} */ event) => {
    if (event.key === "Enter") {
      _search();
    }
  };
  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };
  const handleSelectionChange = (selection) => {
    setSelectedData(selection || []);
  };
  const handleLimitChange = (newLimit) => {
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit + 1);
  };
  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };
  function _search() {
    if (search !== "") {
      //TODO: Dispatch
      dispatch(
        getStockOut(token, {
          sortBy,
          sortDirection,
          category,
          limit,
          page,
          search,
          searchBy,
        })
      );
      setChoosedStatus("SEARCH");
    } else {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
    }
  }
  function _category(cat) {
    setCategory(cat);
  }

  function _getByPage(newPage) {
    //TODO: Dispatch
    dispatch(
      getStockOut(token, {
        sortBy,
        sortDirection,
        category,
        limit,
        page: newPage,
        search,
        searchBy,
      })
    );
  }
  function _getByLimit(newLimit) {
    //TODO: Dispatch
    dispatch(
      getStockOut(token, {
        sortBy,
        sortDirection,
        category,
        limit: newLimit,
        page,
        search,
        searchBy,
      })
    );
  }

  const getNameForPlaceholder = () => {
    let result = "";
    Object.entries(searchByKey).forEach(([key, val]) => {
      if (searchBy === val) {
        result = String(key).toLowerCase();
      }
    });
    return result;
  };

  //* On component mounted
  useEffect(onMounted, []);
  /** ======================================================================================= */
  /**                                         WATCHER                                         */
  /** ======================================================================================= */

  useEffect(() => {
    console.log("Form vales: ", formValues);
  }, [formValues]);
  useEffect(() => {
    console.log("Errors : ", errors);
  }, [errors]);
  useEffect(() => {
    console.log("Options", options);
  }, [options]);

  //! -------------------------------- [ NOTE ] -------------------------------------
  /**
   * - Get curent stock when add new "stock_in";
   */

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder={`Search by ${getNameForPlaceholder()}`}
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                  endAdornment: (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      SelectProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      sx={{
                        borderRadius,
                      }}
                      value={searchBy}
                      sx={{
                        "& fieldset": {
                          border: "unset",
                        },
                        borderLeft: `2px solid #ddd`,
                        width: "14rem",
                      }}
                      onChange={(e) => setSearchBy(e.target.value)}
                    >
                      {/* Search By */}
                      {Object.entries(searchByKey).map(([key, val]) => (
                        <MenuItem value={val}>{key}</MenuItem>
                      ))}
                    </TextField>
                  ),
                  onKeyDown: handleKeyPress,
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={category}
                onChange={(e) => _category(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_CATEGORY}>
                  Select Category
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_CATEGORY}>
                  All Category
                </MenuItem>
                {/* <MenuItem value="Pending">Dynamic value</MenuItem> */}
                {menuCategory.length &&
                  menuCategory.map((cat) => {
                    return <MenuItem value={cat.id}>{cat.name}</MenuItem>;
                  })}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* ------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Stock In"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={choosedStatus}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            componentModifyToolbarLeft={ModifiedLeftToolbar}
            // userAccess={userAccess}
            // handleRowDoubleClick={handleDetailAsset}
          />
        </CardContent>
      </Card>

      <Modal open={formModal} onClose={() => {}}>
        <Form
          title={title}
          onCloseModal={() => handleCloseFormModal()}
          elementForm={renderFormContent}
          elementActionButton={renderActionButtons}
          onSubmit={handleSubmit}
        />
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={onCloseConfirmModal}
        onCloseModal={onConfirmModal}
      ></ModalConfirmCancel>
    </>
  );
}

export default StockOut;
