import { REQUEST_LOADING } from "../../../util/global_state";

import {
  ADD_STAGE_FAILED,
  ADD_STAGE_SUCCESS,
  DELETE_STAGE_FAILED,
  DELETE_STAGE_SUCCESS,
  EDIT_STAGE_FAILED,
  EDIT_STAGE_SUCCESS,
  GET_STAGE_FAILED,
  GET_STAGE_SUCCESS,
} from "./state";

const initState = {
  data: [],
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
};

const stagesReducer = (state = initState, { type, payload, pagination }) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_STAGE_SUCCESS:
      return {
        data: payload,
        pagination: pagination,
        message: null,
        isLoading: false,
      };
    case GET_STAGE_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case ADD_STAGE_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
        response_status: true,
      };
    case ADD_STAGE_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_STAGE_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_STAGE_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_STAGE_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_STAGE_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default stagesReducer;
