import React, { useRef, useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";

const OtpCom = ({ onOtpChange, isDisabled, otpValues, isWrong, msg }) => {
  const inputRefs = useRef([]);
  const otpLength = 6;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (otpValues.every((value) => value === "")) {
      inputRefs.current[0]?.focus();
    }
  }, [otpValues]);

  const handleOtpChange = () => {
    const otpValues = inputRefs.current.map((inputRef) => inputRef.value);
    onOtpChange(otpValues);
  };

  const handleInputClick = (index) => {
    if (isDisabled) {
      return;
    }

    const emptyInput = inputRefs.current.find(
      (inputRef, i) => i < index && !inputRef.value
    );
    if (emptyInput) {
      emptyInput.focus();
    }
  };

  const handleChangeInput = (event, index) => {
    console.log(`index euy ${index}`);
    if (index == 5) {
      handleOtpChange();
      const lastInput = inputRefs.current[inputRefs.current.length - 1];
      lastInput?.blur();
    }
  };

  const handleKeyDown = (event, index) => {
    console.log("1");
    if (event.key === "Backspace") {
      console.log("2");
      const currentInput = inputRefs.current[index];
      if (currentInput.value === "") {
        if (index > 0) {
          const previousInput = inputRefs.current[index - 1];
          previousInput.focus();
        }
      } else {
        return;
      }
    }
    console.log("3");

    if (isDisabled) {
      return;
    }
    console.log("4");

    const { value } = event.target;
    const sanitizedValue = value.replace(/\D/g, "");
    const currentInput = inputRefs.current[index];

    if (sanitizedValue.length > 0) {
      console.log(`index: ${index}, otpLength: ${otpLength}`);
      if (index < otpLength - 1) {
        console.log("6");
        const nextInput = inputRefs.current[index + 1];
        nextInput?.focus();
      } else {
      }
    } else {
      console.log("8");
      if (index > 0) {
        console.log("9");
        const previousInput = inputRefs.current[index - 1];
        previousInput.focus();
      }
    }

    const otpValue = sanitizedValue.slice(0, otpLength).padEnd(otpLength, "");
    currentInput.value = otpValue;
  };

  useEffect(() => {
    if (otpValues && otpValues.length === otpLength) {
      inputRefs.current.forEach((inputRef, index) => {
        inputRef.value = otpValues[index] || "";
      });
    }
  }, [otpValues]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(false);
    }
  }, [isLoading]);

  return (
    <Box>
      <Box sx={{ display: "flex", marginBottom: 2 }}>
        {Array.from({ length: otpLength }, (_, index) => (
          <TextField
            key={index}
            type="text"
            autoComplete="off"
            inputRef={(ref) => (inputRefs.current[index] = ref)}
            onKeyDown={(event) => handleKeyDown(event, index)}
            onClick={() => handleInputClick(index)}
            onChange={(event) => handleChangeInput(event, index)}
            sx={{
              width: 40,
              marginRight: 1,
              textAlign: "center",
              borderRadius: "8px",
              ...(isWrong && {
                outlineColor: "red",
                outlineWidth: "1px",
                outlineStyle: "solid",
              }),
            }}
            inputProps={{
              maxLength: 1,
              disabled: isDisabled,
            }}
          />
        ))}
      </Box>
      {isDisabled && msg == "" && (
        <Box sx={{}}>Wait for verifying your OTP code</Box>
      )}
      {msg != "" && <Box sx={{}}>{msg}</Box>}
    </Box>
  );
};

export default OtpCom;
