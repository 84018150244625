import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_RETURN_SUCCESS,
  GET_RETURN_FAILED,
  ADD_RETURN_SUCCESS,
  ADD_RETURN_FAILED,
  EDIT_RETURN_SUCCESS,
  EDIT_RETURN_FAILED,
  DELETE_RETURN_FAILED,
  DELETE_RETURN_SUCCESS,
} from "./state";
import { logout } from "../../../auth/clients/login/actions";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../util/function";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getReturn = ({
  token,
  page = 1,
  limit = 10,
  searchValue = null,
  searchBy = null,
  dateFilter = null,
  categoryFilter = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "return",
          page,
          limit,
          searchValue,
          searchBy,
          dateFilter:
            dateFilter == ACTION_TYPE.DEFAULT_DATE ? null : dateFilter,
          categoryFilter:
            categoryFilter == ACTION_TYPE.ALL_CATEGORY ? null : categoryFilter,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getReturnSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getReturnSuccess([], null));
        } else {
          dispatch(getReturnFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getReturnFailed(error.response.data.message));
          }
        } else {
          dispatch(getReturnFailed(error.message));
        }
      });
  };
};

const getReturnSuccess = (data, pagination) => {
  return {
    type: GET_RETURN_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getReturnFailed = (errorMessage) => {
  return {
    type: GET_RETURN_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addReturn = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`return`, Object.assign({}, formData), headersAPIToken(token))
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "Return"));
          dispatch(addReturnSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addReturnFailed(response.data.message));
        } else {
          dispatch(addReturnFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addReturnFailed(error.response.data.message));
          }
        } else {
          dispatch(addReturnFailed(error.message));
        }
      });
  };
};

const addReturnSuccess = (message) => {
  return {
    type: ADD_RETURN_SUCCESS,
    payload: message,
  };
};

const addReturnFailed = (message) => {
  return {
    type: ADD_RETURN_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editReturn = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .patch(
        `return/${id}`,
        Object.assign({}, formData),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "Return"));
          dispatch(editReturnSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editReturnFailed(response.data.message));
        } else {
          dispatch(editReturnFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editReturnFailed(error.response.data.message));
          }
        } else {
          dispatch(editReturnFailed(error.message));
        }
      });
  };
};

const editReturnSuccess = (message) => {
  return {
    type: EDIT_RETURN_SUCCESS,
    payload: message,
  };
};

const editReturnFailed = (message) => {
  return {
    type: EDIT_RETURN_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteReturn = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`return${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "Return"));
          dispatch(deleteReturnSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteReturnFailed(response.data.message));
        } else {
          dispatch(deleteReturnFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteReturnFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteReturnFailed(error.message));
        }
      });
  };
};

const deleteReturnSuccess = (message) => {
  return {
    type: DELETE_RETURN_SUCCESS,
    payload: message,
  };
};

const deleteReturnFailed = (message) => {
  return {
    type: DELETE_RETURN_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
