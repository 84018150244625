export const GET_PAYMENT_REQUEST_SUCCESS = "GET_PAYMENT_REQUEST_SUCCESS";
export const GET_PAYMENT_REQUEST_FAILED = "GET_PAYMENT_REQUEST_FAILED";

export const ADD_PAYMENT_REQUEST_SUCCESS = "ADD_PAYMENT_REQUEST_SUCCESS";
export const ADD_PAYMENT_REQUEST_FAILED = "ADD_PAYMENT_REQUEST_FAILED";

export const EDIT_PAYMENT_REQUEST_SUCCESS = "EDIT_PAYMENT_REQUEST_SUCCESS";
export const EDIT_PAYMENT_REQUEST_FAILED = "EDIT_PAYMENT_REQUEST_FAILED";

export const DELETE_PAYMENT_REQUEST_SUCCESS = "DELETE_PAYMENT_REQUEST_SUCCESS";
export const DELETE_PAYMENT_REQUEST_FAILED = "DELETE_PAYMENT_REQUEST_FAILED";

export const GET_PAYMENT_REQUEST_DETAIL_SUCCESS = "GET PAYMENT REQUEST DETAIL SUCCESS"
export const GET_PAYMENT_REQUEST_DETAIL_FAILED = "GET PAYMENT REQUEST DETAIL FAILED"

export const CLEAR_DATA_DETAIL_PAYMENT_REQUEST = " CLEAR DATA DETAIL PAYMENT REQUEST";
export const CLEAR_DATA_PAYMENT_REQUEST = "CLEAR_DATA_PAYMENT_REQUEST"
export const MARK_DETAIL_FOR_EDIT = "MARK DETAIL FOR EDIT";


export const STOP_LOADING = "STOP LOADING PROGRESS"