//@ts-check
import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../util/function";
import {
  RequestLoading,
  REQUEST_LOADING,
  RunModal,
} from "../../../../util/global_state";
import { logout } from "../../../auth/clients/login/actions";

import {
  ADD_STOCK_OUT_FAILED,
  ADD_STOCK_OUT_SUCCESS,
  DELETE_STOCK_OUT_FAILED,
  DELETE_STOCK_OUT_SUCCESS,
  GET_STOCK_OUT_FAILED,
  GET_STOCK_OUT_SUCCESS,
  UPDATE_STOCK_OUT_FAILED,
  UPDATE_STOCK_OUT_SUCCESS,
} from "./state";
/**
 * @typedef {import("@reduxjs/toolkit").Dispatch} dispatch
 * @typedef {'GET'|'POST'|'EDIT'|'DELETE'|'DETAIL'} MethodType
 */

const stockInBaseUrl = "stocks";

const typedUrl = (/** @type {string} */ baseUrl) => {
  const pattern = new RegExp("[?]", "g");
  const alreadyHaveQuery = pattern.test(baseUrl);
  if (alreadyHaveQuery) {
    baseUrl = baseUrl.concat("&type=out");
  } else {
    baseUrl = baseUrl.concat("?type=out");
  }
  return baseUrl;
};

/** --------------------------------------------------------------------- */
/**                                 HELPER                                */
/** --------------------------------------------------------------------- */

/**
 *
 * @param {import("axios").AxiosResponse} response
 * @param {dispatch} dispatch
 * @param {Function} successHandler
 * @param {Function} errorHandler
 * @param {MethodType} type
 */

const responseHandler = (
  response,
  dispatch,
  successHandler,
  errorHandler,
  type
) => {
  if (
    typeof successHandler !== "function" ||
    typeof errorHandler !== "function"
  ) {
    throw new Error("Handler is not function");
  }
  //   const listActionRunModal = ["POST", "EDIT", "DELETE"];
  if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === true
  ) {
    if (type === "GET") {
      dispatch(successHandler(response.data.data, response.data));
    } else if (type === "DETAIL") {
      dispatch(successHandler(response.data.data));
    } else {
      dispatch(
        RunModal(
          type === "POST" ? "SAVED" : type === "EDIT" ? "EDITED" : "DELETED",
          "Stock in"
        )
      );
      dispatch(successHandler(response.data.message));
    }
  } else if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === false
  ) {
    if (type === "GET") {
      dispatch(successHandler([], null));
    } else {
      dispatch(errorHandler(response.data.message));
      dispatch(RunModal("FAILED", response.data.message));
    }
  } else {
    dispatch(errorHandler(response.data.message));
    dispatch(RunModal("FAILED", response.data.message));
  }
};

/**
 *
 * @param {import('axios').AxiosError} errorResposense
 * @param {dispatch} dispatch
 * @param {Function} failHandler
 */
const errorHandler = (errorResposense, dispatch, failHandler) => {
  let messageError = "";
  if (
    errorResposense.response !== undefined &&
    errorResposense.response.data !== undefined
  ) {
    if (errorResposense.response.status === 401) {
      dispatch(logout());
      return;
    } else {
      dispatch(failHandler(errorResposense.response.data.message));
      messageError = errorResposense.response.data.message;
    }
  } else {
    messageError = formatNetworkErrorMessage(errorResposense.message);
    dispatch(failHandler(messageError));
  }

  dispatch(RunModal("FAILED", messageError));
};

const headerMultipartToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
};

/** --------------------------------------------------------------------- */
/**                                   GET                                 */
/** --------------------------------------------------------------------- */
export const getStockOut = (
  token,
  { search, searchBy, category, page = 1, limit = 10, sortDirection, sortBy }
) => {
  return (/** @type {dispatch} */ dispatch) => {
    dispatch(RequestLoading());
    //@ts-ignore
    const craftedUrl = urlRequest({
      limit,
      page,
      searchValue: search,
      searchBy: search === "" ? null : searchBy,
      categoryId:
        category === ACTION_TYPE.DEFAULT_CATEGORY ||
        category === ACTION_TYPE.ALL_CATEGORY
          ? null
          : category,
      sortDirection,
      sortBy,
      url: stockInBaseUrl,
    });

    // const typedUrl = craftedUrl.concat("&type=stock-in");
    axios
      .get(typedUrl(craftedUrl), headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          getStockOutSuccess,
          getStockOutFailed,
          "GET"
        );
      })
      .catch((error) => {
        errorHandler(error, dispatch, getStockOutFailed);
      });
  };
};

function getStockOutSuccess(data, pagination) {
  return {
    type: GET_STOCK_OUT_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: parseInt(pagination.limit),
            totalData: pagination.total_data,
            totalPage: pagination.total_data,
          },
  };
}

function getStockOutFailed(message) {
  return {
    type: GET_STOCK_OUT_FAILED,
    payload: message,
  };
}

/** --------------------------------------------------------------------- */
/**                                   ADD                                 */
/** --------------------------------------------------------------------- */

export const addStockOut = (token, payload) => {
  return (/** @type {dispatch} */ dispatch) => {
    dispatch(RequestLoading());
    // const typedUrl = stockInBaseUrl.concat("?type=stock-in");
    let header = {};
    if (payload?.attachments?.length) {
      header = headerMultipartToken(token);
    } else {
      header = headersAPIToken(token);
    }
    return axios
      .post(typedUrl(stockInBaseUrl), payload, header)
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          addStockOutSuccess,
          addStockOutFailed,
          "POST"
        );
      })
      .catch((error) => {
        errorHandler(error, dispatch, addStockOutFailed);
      });
  };
};

function addStockOutSuccess(message) {
  return {
    type: ADD_STOCK_OUT_SUCCESS,
    payload: message,
  };
}

function addStockOutFailed(message) {
  return {
    type: ADD_STOCK_OUT_FAILED,
    payload: message,
  };
}

/** --------------------------------------------------------------------- */
/**                                 UPDATE                                */
/** --------------------------------------------------------------------- */
export const updateStockOut = (token, payload, id) => {
  return (/**@type {dispatch} */ dispacth) => {
    dispacth(RequestLoading());
    let localUrl = stockInBaseUrl.concat("/", id);
    return axios
      .post(typedUrl(localUrl), payload, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispacth,
          updateStockOutSuccess,
          updateStockOutFailed,
          "EDIT"
        );
      })
      .catch((error) => {
        errorHandler(error, dispacth, updateStockOutFailed);
      });
  };
};

function updateStockOutSuccess(message) {
  return {
    type: UPDATE_STOCK_OUT_SUCCESS,
    payload: message,
  };
}

function updateStockOutFailed(message) {
  return {
    type: UPDATE_STOCK_OUT_FAILED,
    payload: message,
  };
}
/** --------------------------------------------------------------------- */
/**                                 DELETE                                */
/** --------------------------------------------------------------------- */
export const deleteStockOut = (token, ids) => {
  return (/**@type {dispatch} */ dispacth) => {
    dispacth(RequestLoading());
    const localUrl = stockInBaseUrl.concat("/", ids);
    return axios
      .delete(typedUrl(localUrl), headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispacth,
          deleteStockOutSuccess,
          deleteStockOutFailed,
          "DELETE"
        );
      })
      .catch((error) => {
        errorHandler(error, dispacth, deleteStockOutFailed);
      });
  };
};

function deleteStockOutSuccess(message) {
  return {
    type: DELETE_STOCK_OUT_SUCCESS,
    payload: message,
  };
}

function deleteStockOutFailed(message) {
  return {
    type: DELETE_STOCK_OUT_SUCCESS,
    payload: message,
  };
}
