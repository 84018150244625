//@ts-check

import React from "react";
import { Typography } from "@mui/material";
import { INPUT_TYPE, SELECT_OPTION } from "../../../../util/function";

/**
 * @typedef {object} AdditionalPropsType
 * @property {boolean} [disabled]
 * @property {boolean} [showLable]
 * @property {boolean} [showError]
 * @property {boolean} [readOnly]
 */

/**
 * @typedef {object} OptionType
 * @property {string} name
 * @property {number} id
 */

/**
 * @typedef {object} FieldType
 * @property {string} name - name of field
 * @property {string} type - type field
 * @property {string} label - label on field
 * @property {boolean} required - flag for field require
 * @property {string|number|boolean} defaultValue - default value
 * @property {string} [placeholder] - placeholder
 * @property {string} [apiEndpoint] - endpoint for get option
 * @property {string} [errorMessage] - custom error message
 * @property {string} [tip] - tip for field
 * @property {boolean} [autoFill] - flag for autoFill value
 * @property {AdditionalPropsType} [additionalProps] - object for aditional props
 * @property {Function} [validation] - custom function validation
 * @property {Array<OptionType>} [options] - custom options when cannot get from server
 *
 */

/**
 * @typedef {object} ColumnType
 * @property {string} field
 * @property {string} headerName
 * @property {number} width
 * @property {boolean} disableColumnMenu
 * @property {boolean} sortable
 * @property {Function} [valueGetter]
 * @property {Function} [renderCell]
 *
 * */

/**@type {FieldType[]} */
export const fields = [
  {
    name: "category_id",
    defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
    label: "Category",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    required: true,
    apiEndpoint: "master/asset-categories",
    additionalProps: { showError: true },
  },
  {
    name: "brand_id",
    defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
    label: "Brand Name",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    required: true,
    apiEndpoint: "/master/asset-brands",
    additionalProps: { showError: true },
  },
  {
    name: "asset_id",
    defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
    label: "Category",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    required: true,
    apiEndpoint: "/master/assets",
    additionalProps: { showError: true },
  },
  {
    name: "quantity",
    defaultValue: 0,
    label: "Quantity",
    type: INPUT_TYPE.NUMBER,
    required: false,
    additionalProps: { disabled: true },
  },
  {
    name: "qty",
    defaultValue: 0,
    label: "Stock In",
    required: true,
    type: INPUT_TYPE.NUMBER,
  },
];

/**@type {ColumnType[]} */
export const columns = [
  {
    field: "code",
    disableColumnMenu: true,
    headerName: "No. References",
    sortable: true,
    width: 160,
  },
  {
    field: "category_name",
    disableColumnMenu: true,
    headerName: "Category",
    sortable: true,
    width: 160,
  },
  {
    field: "brand_name",
    disableColumnMenu: true,
    headerName: "Brand Name",
    sortable: true,
    width: 160,
  },
  {
    field: "asset_name",
    disableColumnMenu: true,
    headerName: "Stock Name",
    sortable: true,
    width: 160,
  },
  {
    field: "asset_qty",
    disableColumnMenu: true,
    headerName: "Quantity",
    sortable: true,
    width: 160,
  },
  {
    field: "qty",
    disableColumnMenu: true,
    headerName: "Stock In",
    sortable: true,
    width: 160,
  },
  {
    field: "created_by",
    disableColumnMenu: true,
    headerName: "Created By",
    sortable: true,
    width: 200,
    renderCell: ({ row }) => {
      // console.log(row);
      const { user_name, position_name } = row;
      return (
        <>
          <Typography variant="subtitle2">{user_name}</Typography>&nbsp;&nbsp;
          {position_name !== "-" && position_name !== "" && (
            <Typography variant="subtitle2" sx={{ opacity: 0.5 }}>
              {" "}
              as {position_name}
            </Typography>
          )}
        </>
      );
    },
  },
  {
    field: "created_at_formatted",
    disableColumnMenu: true,
    headerName: "Created At",
    sortable: true,
    width: 160,
  },
];
