export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILED = "GET_CLIENT_FAILED";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILED = "ADD_CLIENT_FAILED";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
export const EDIT_CLIENT_FAILED = "EDIT_CLIENT_FAILED";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILED = "DELETE_CLIENT_FAILED";

/* -------------------------------------------------------------------------- */
/*                                    GROUP                                   */
/* -------------------------------------------------------------------------- */

export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAILED = "GET_GROUP_FAILED";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAILED = "ADD_GROUP_FAILED";