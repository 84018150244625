import { Drawer, Backdrop } from "@mui/material";
import { defaultStylePage } from "../util/style";

const useStyles = defaultStylePage;

const LeftDrawer = ({ open, children }) => {
  const classes = useStyles();

  function preventClose(event) {
    event.stopPropagation();
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={preventClose} // Prevent closing from clicking on the backdrop
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          onClose: open, // Enable closing by clicking the close button
          disablePortal: true,
          disableEnforceFocus: true,
          disableAutoFocus: true,
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
          onClick: preventClose, // Prevent closing from clicking on the backdrop
        }}
        sx={{ '& .MuiPaper-root.MuiDrawer-paper': { overflow: 'hidden', } }}
      >
        {children}
      </Drawer>
    </>
  );
};

export default LeftDrawer;
