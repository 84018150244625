/**
 * structure Dummyd data
 * product: {
      status: "Pending",
      no_proforma: "12782",
      create_at: new Date().toLocaleDateString(),
      no_reference: "123321",
      service_category: "Patent",
      created_by: "Json miranti",
      sub_service: [
        { name: "Patent Pecahan", id: 2, price:2_000_000 },
        { name: "Patent Sederhana", id: 4, price: 4_000_000 },
      ],
      price: 12323121,
      discount: 2_000_000,
      vat: 50_000,
      total: 2536273,
      description: "Lorem ipsum dolor sit amet consectetur. Auctor dolor pellentesque viverra enim posuere elementum iaculis a",
      remark: "Microsoft Office",
    },
    bank: {
      acount_name: "eric",
      acount_address: "Jl. Gatot",
      acount_no: 1232321,
      bank_name: "Bank XYZ",
      bank_address: "Jalan Sudirman",
      payment_receive: 99999999,
      payment_conversion_receive: 990099,
    },
    client: {
      bill_to:"Andre",
      attn:"Avon product",
      bill_address:"Jl. Tomang No. 123 Jakarta 456"
    },
    Accounting: "Markus jhonson",
 */

import { formatDate } from "../../../../util/function";
import { infoSurfaceColor } from "../../../../util/style";

/**
 * @typedef {object} OptionDateType
 * @property {Intl.LocalesArgument} [location]
 * @property {Intl.DateTimeFormatOptions} [options]
 */

/**
 * @typedef {object} OptionCurrencyType
 * @property {string} [currency]
 * @property {number} [fractionDigits]
 */

export class ValidateValueRender {
  data;
  constructor(documentDetail) {
    this.data = documentDetail;
  }

  /**
   * @param {string} val 
   * @param {OptionCurrencyType} [options] 
   * 
   * */
  formatCurency(val, options) {
    let clearValue = String(val);
    if (clearValue.includes(",")) {
      clearValue = clearValue.split(",").join("");
    }
    clearValue = parseInt(clearValue);
    if (isNaN(clearValue)) {
      throw new Error("Value in not integer");
    }
    const currency = this.data.product.currency || "Rp";
    let localeByCurrency = currency === "Rp" ? "id-ID" : "en-EN";
    let spesificCurrency = options?.currency || (currency === "Rp" ? "IDR" : "USD");
    const formatedValue = new Intl.NumberFormat(localeByCurrency, {
      style: "currency",
      currency: spesificCurrency,
      minimumFractionDigits: options?.fractionDigits || 0,
    });
    return formatedValue.format(clearValue);
  }

  /**
   *
   * @param {any} value
   * @param {string} key
   * @param {boolean} [semicolon]
   * @param {OptionDateType} [optionDate]
   * @param {OptionCurrencyType} [optionCurrency]
   * 
   */
  renderValue(value, key, semicolon = false, optionDate, optionCurrency) {
    //* List key format in date, add as needed
    // const listFormatedDate = ["create_at"];
    const listFormatedDate = [];
    const listFormatCurrency = [
      // "price",
      // "discount",
      // "vat",
      // "total",
      // "payment_receive",
      // "payment_conversion_receive",
    ];
    /**@type {string} */
    let result;

    if (
      value !== "" ||
      value !== undefined ||
      value !== "-" ||
      value !== null
    ) {
      if (listFormatCurrency.includes(key)) {
        result = semicolon
          ? `: ${this.formatCurency(value, optionCurrency)}`
          : this.formatCurency(value, optionCurrency);
      } else if (listFormatedDate.includes(key)) {
        if (optionDate !== null || optionDate !== undefined) {
          result = new Date(value).toLocaleDateString(
            optionDate.location,
            optionDate.options
          );
        } else {
          result = semicolon ? `: ${formatDate(value)}` : formatDate(value);
        }
      } else if (key === "sub_service" && Array.isArray(value)) {
        result = value.map((el) => (
          <span
            style={{
              background: "#B0CDE7",
              color: "#1268B8",
              marginInline: ".1rem",
              padding: ".1rem",
              borderRadius: "5px",
            }}
          >
            {el.name}
          </span>
        ));
      } else {
        result = semicolon ? ": ".concat(value) : value;
      }
    } else {
      result = semicolon ? ": -" : "-";
    }
    return result;
  }
}
