import { Avatar, Box, Button, Card, CardContent, CardMedia, Grid, MenuItem, Modal, TextField, Typography } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "../../../../components/Table";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Form, { DatePickerInput, FilePicker, FileSelect } from "../../../../components/Form";
import ModalConfirmCancel from "../../../../components/ConfirmCancel";
import { useTheme } from "@mui/styles";
import {
    StatusBadge,
    defaultStylePage,
    btnWhite,
    borderRadius,
    mainColor,
    removeButton,
    justifyContentEnd
} from "../../../../util/style";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DocumentActive from "./../../../../assets/document_active.svg";
import CalendarIcon from "./../../../../assets/calendar.png";
import RemoveIcon from "./../../../../assets/minus.svg";
import { ACTION_TYPE, INPUT_TYPE, formatCurrency, formatCurrencyWithoutLable, formatDate, formatDateTime } from "../../../../util/function";
import { addClaimReward, getClaimReward } from "./clients/actions";
import axios from "../../../../core/axios_config";
import dayjs from "dayjs";

const columns = [
    {
        field: "code",
        headerName: "No. Reference",
        disableColumnMenu: true,
        width: 135,
    },
    {
        field: "total_point",
        headerName: "Total Point",
        disableColumnMenu: true,
        width: 135,
        valueGetter: (params) => {
            return formatCurrencyWithoutLable(params.value);
        }
    },
    {
        field: "total_price",
        headerName: "Total Price",
        disableColumnMenu: true,
        width: 135,
        valueGetter: (params) => {
            return formatCurrency(params.value);
        }
    },
    {
        field: "request_date",
        headerName: "Request Date",
        disableColumnMenu: true,
        width: 135,
        valueGetter: (params) => {
            return formatDateTime(params.value);
        }
    },
    {
        field: "pay_at",
        headerName: "Request Date",
        disableColumnMenu: true,
        width: 135,
        valueGetter: (params) => {
            if (params.value) {
                return formatDate(params.value);
            }
            return "-";
        }
    },
    {
        field: "request_by",
        headerName: "Request By",
        disableColumnMenu: true,
        width: 135,
        valueGetter: (params) => {
            return params.value;
        }
    },

    {
        field: "status",
        headerName: "Status",
        disableColumnMenu: true,
        width: 200,
        renderCell: (params) => (
            <StatusBadge
                bgColor={params.value === 'Pending' ? '#FFF1CF' : params.value === 'Approved' ? '#D2F0CC' : params.value === 'Canceled' ? '#EDEDED' : params.value === 'Paid' ? '#B0CDE7' : '#FFCDCC'}
                colors={params.value === 'Pending' ? '#FFBA10' : params.value === 'Approved' ? '#1DB200' : params.value === 'Canceled' ? '#757575' : params.value === 'Paid' ? '#1268B8' : '#FF0400'}
                status={params.value}>
                {params.value}
            </StatusBadge>
        ),
    },
]

const ClaimRewardPoint = () => {
    const theme = useTheme();
    const useStyles = defaultStylePage;
    const dispatch = useDispatch();

    const { token } = useSelector((state) => state.auth);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");
    const [searchBy, setSearchBy] = useState("no_reference");
    const [status, setStatus] = useState(ACTION_TYPE.ALL_STATUS);
    const [periodFilter, setPeriodFilter] = useState("0");
    const [sortDirection, setSortDirection] = useState("desc");
    const [sortBy, setSortBy] = useState("id");
    const [sortModel, setSortModel] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedDataRow, setSelectedDataRow] = useState(null);
    const [open, setOpen] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [title, setTitle] = useState("Detail Claim Reward Point");
    const [cancelType, setCancelType] = useState("Cancel");
    const [confirmModal, setConfirmModal] = useState(false);
    const [detail, setDetail] = useState({});
    const [typeForm, setTypeForm] = useState("detailForm");
    const fields = [
        {
            title: "Date",
            isDisplayTitle: false,
            children: [
                {
                    name: "pay_at",
                    type: INPUT_TYPE.DATE,
                    label: "Pay At",
                    endIcon: <img src={CalendarIcon} />,
                    formatDate: "DD/MM/YYYY", // Custom date format
                    defaultValue: dayjs(),
                    required: true,
                },
            ]
        },
        {
            title: "Attachment",
            subtitle: 'You can upload the documents required for this proses ',
            isDisplayTitle: true,
            children: [
                {
                    name: "attachment",
                    type: INPUT_TYPE.FILE,
                    label: "Upload File",
                    tip: "Max size: 5 MB. Supported file: PDF, JPG, PNG",
                    allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
                    maximumSizeInMB: 5, //MB
                    defaultValue: '',
                    required: true,
                },
            ]
        },

    ]

    const initialAttachment = { attachment: [""] };
    const initialErrorAttachment = { attachment: [""] };

    const initialFormValues = () => {
        const initialValues = {};
        fields.forEach((fieldGroup) => {
            if (fieldGroup.children) {
                initialValues[fieldGroup.title] =
                    fieldGroup.title === "Attachment"
                        ? initialAttachment
                        : fieldGroup.children.reduce(
                            (acc, field) => ({ ...acc, [field.name]: field.defaultValue, point_id: "" }),
                            {}
                        );
            }
        });

        return {
            ...initialValues,
        };
    };

    const initialErrors = () => {
        const initialErrorValues = {};
        fields.forEach((fieldGroup) => {
            if (fieldGroup.children) {
                initialErrorValues[fieldGroup.title] =
                    fieldGroup.title === "Attachment"
                        ? initialErrorAttachment
                        : fieldGroup.children.reduce(
                            (acc, field) => ({ ...acc, [field.name]: "", point_id: "" }),
                            {}
                        );
            }
        });
        return initialErrorValues;
    }
    const [formValues, setFormValues] = useState(initialFormValues());
    const [errors, setErrors] = useState(initialErrors());

    const {
        data: rows,
        message,
        isLoading,
        pagination,
    } = useSelector((state) => state.claimRewardPoint);
    const {
        modal: { isActive },
    } = useSelector((state) => state.global);

    const handleAddFile = (sectionTitle, fieldName) => {
        setFormValues((prevValues) => {
            const updatedValues = { ...prevValues };
            updatedValues[sectionTitle][fieldName].push("");
            return updatedValues;
        })
    }

    const handleRemoveFile = (sectionTitle, fileIndex, fieldName) => {
        setFormValues((prevValues) => {
            const updatedValues = { ...prevValues };
            updatedValues[sectionTitle][fieldName].splice(fileIndex, 1);
            return updatedValues;
        });
    }

    useEffect(() => {
        console.log(token, "token");
        dispatch(
            getClaimReward({
                status,
                token,
                page,
                limit,
                search,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }, [sortDirection, sortBy]);

    useEffect(() => {
        if (isActive === true) {
            dispatch(
                getClaimReward({
                    status,
                    token,
                    page,
                    limit,
                    search,
                    searchBy,
                    sortDirection,
                    sortBy,
                })
            );
        }
    }, [isActive]);

    const handleKeyPress = (e) => {
        if (e.key === "Enter" && search) {
            _search();
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
        _getByPage(newPage + 1);
    };

    const handleLimitChange = (newLimit) => {
        setPage(0);
        setLimit(newLimit);
        _getByLimit(newLimit);
    };

    const handleSortModelChange = (newSortModel) => {
        setSortModel(newSortModel);
    };

    const handleSelectionChange = (selection) => {
        console.log(selection, "selection");
        setSelectedData(selection || []);
    };

    const handleRowDoubleClick = (selection) => {
        // navigate(`detail/${selection.id}`, { state: { type: typeData } });
        console.log(selection.row);
        setTypeForm("detailForm");
        setTitle("Detail Claim Reward Point");
        setSelectedDataRow(selection.row);
        const newFormValues = { ...formValues }
        newFormValues.point_id = selection.row.id;
        setFormValues(newFormValues);
        axios.get(`/claim-reward/detail-by-claim?point_id=${selection.row.id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            console.log("response.data");
            console.log(response.data);
            setDetail(response.data);
        })
        setFormModal(true);
    }

    function _search() {
        dispatch(
            getClaimReward({
                status,
                token,
                limit,
                search,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }

    function _statusFilter(status) {
        setStatus(status);
        dispatch(
            getClaimReward({
                status: status,
                token,
                limit,
                search,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }
    function _periodFilter(period) {
        setPeriodFilter(period);
        dispatch(
            getClaimReward({
                year: period,
                status,
                token,
                limit,
                search,
                searchBy,
                sortDirection,
                sortBy,
            })
        )
    }

    function _getByPage(page) {
        if (pagination === null || pagination === undefined) {
            return;
        }
        dispatch(
            getClaimReward({
                status,
                token,
                page,
                limit,
                search,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }

    function _getByLimit(limit) {
        dispatch(
            getClaimReward({
                status,
                token,
                limit,
                search,
                searchBy,
                sortDirection,
                sortBy,
            })
        );
    }
    const handleFormModal = (actionType = ACTION_TYPE.ADD) => { }

    const handleCloseFormModal = (important = false) => {
        if (!important) {
            setSelectedDataRow(null)
            setFormModal(false)
        }
    };

    const detailData = (props) => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography>
                            {props.key}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>
                            {props.value}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        {
                            props.status && (
                                <StatusBadge
                                    bgColor={props.status === 'Pending' ? '#FFF1CF' : props.status === 'Approved' ? '#D2F0CC' : props.status === 'Canceled' ? '#EDEDED' : props.status === 'Paid' ? '#B0CDE7' : '#FFCDCC'}
                                    colors={props.status === 'Pending' ? '#FFBA10' : props.status === 'Approved' ? '#1DB200' : props.status === 'Canceled' ? '#757575' : props.status === 'Paid' ? '#1268B8' : '#FF0400'}
                                    status={props.status}>
                                    {props.status}
                                </StatusBadge>
                            )
                        }
                    </Grid>
                </Grid>
                <Box sx={{ height: '16px' }}></Box>
            </>
        );
    }

    const truncateString = (str, length) => {
        return str.length > length ? str.substring(0, length) + '...' : str;
    };


    const detailInvoice = (props) => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={1}>
                        <Typography>
                            <Avatar sx={{ bgcolor: mainColor, width: "24px", height: "24px", marginRight: "8px" }} ><Typography>{`${props.key}`}</Typography></Avatar>
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        {detailData({ key: "No. Invoice", value: props.value.no_invoice })}
                        {detailData({ key: "Client Name", value: props.value.client_name })}
                        {detailData({ key: "Service Category", value: props.value.service_name })}
                        {detailData({ key: "Point", value: props.value.point })}
                        {detailData({ key: "Price", value: formatCurrency(props.value.price) })}
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
                <Box sx={{ height: '16px' }}></Box>
            </>
        );
    }
    const detailFile = (props) => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography>
                            {props.key}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        {
                            props.value && props.value.map((d) => {
                                let url = "";
                                if (props.name === "avidance") {
                                    url = d.avidance_url;
                                } else {
                                    url = d.attachment_url;

                                }
                                return (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={1}>
                                                <CardMedia
                                                    image={DocumentActive}
                                                    component="img"
                                                    alt="Sample Image" />
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>{`${truncateString(url.split('/')[url.split("/").length - 1], 15)} ${d.size} Mb`}</Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography sx={{ color: mainColor, fontSize: 16, fontWeight: 700 }}>Open</Typography>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            })
                        }
                    </Grid>
                </Grid>
                <Box sx={{ height: '16px' }}></Box>
            </>
        );
    }

    const handleInputChange = (
        event,
        sectionTitle,
        field,
        fileIndex
    ) => {
        const { name, value, type } = event.target;
        if (type === "file") {
            const file = event.target.files[0];
            if (file) {
                const fileError = validateFileInput(file, field);
                setErrors((prevErrors) => {
                    const updatedErrors = { ...prevErrors };
                    updatedErrors[sectionTitle][field.name][fileIndex] = fileError;
                    return updatedErrors;
                });
                return;
            } else {
                console.error("there is no file");
            }
        }
    }
    const handleInputBlur = (e) => { }
    const handleFileSelect = (
        file,
        sectionTitle,
        fieldName,
        fileIndex
    ) => {
        setFormValues((prevValues) => {
            const updatedValues = { ...prevValues };
            const fileColumn = updatedValues[sectionTitle][fieldName];
            // debugger;
            // Check if the certificate at certIndex already exists
            if (fileColumn[fileIndex] !== undefined) {
                // Update the existing certificate at fileIndex
                fileColumn[fileIndex] = file;
            }

            return updatedValues;
        });
    };


    const renderFormContent = () => {
        const currentFormValues = formValues
        if (typeForm === "detailForm") {
            return (
                <>
                    {detailData({ key: 'No. Reference', value: selectedDataRow.code, status: selectedDataRow.status })}
                    {detailData({ key: 'Request By', value: selectedDataRow.request_by })}
                    {
                        detail.detail_claim && detail.detail_claim.map((d, dIndex) => (
                            detailInvoice({ key: dIndex + 1, value: d })
                        ))
                    }
                    {detailData({ key: 'Total Point', value: selectedDataRow.total_point })}
                    {detailData({ key: 'Total Price', value: selectedDataRow.total_price })}
                    {detailData({ key: 'Request Date', value: formatDateTime(selectedDataRow.request_date) })}
                    {detailData({ key: 'Pay At', value: selectedDataRow.pay_at === null ? "-" : formatDate(selectedDataRow.pay_at) })}
                    {detailFile({ key: "Evidance", value: detail.avidance, name: "avidance" })}
                    {detailFile({ key: "Attachment", value: detail.attachment, name: "attachment" })}
                </>
            )
        }
        return (

            <Grid
                container
                spacing={1.5}
                sx={{
                }}>
                {fields.map((section) => {
                    const currentFormValues = formValues[section.title];
                    const currentErrors = errors[section.title];
                    return (
                        <Grid item xs={12} key={section.title}>
                            {section.isDisplayTitle !== false && (
                                <Box sx={{ marginBottom: 2 }}>
                                    {/* Title */}
                                    <Typography variant="h6" sx={{ fontWeight: 800 }}>
                                        {section.title}
                                    </Typography>
                                    {/* Subtitle */}
                                    {section.subtitle && (
                                        <Typography variant="subtitle2">{section.subtitle}</Typography>
                                    )}
                                </Box>
                            )}
                            <Grid container spacing={1}>

                                {section.children?.map((field) => {
                                    if (!currentFormValues[field.name]) {
                                        currentFormValues[field.name] = field.defaultValue
                                    }
                                    let fieldComponent = null;
                                    switch (field.type) {
                                        case INPUT_TYPE.DATE:
                                            fieldComponent = (
                                                <DatePickerInput
                                                    key={field.name}
                                                    field={field}
                                                    formValues={currentFormValues}
                                                    errors={currentErrors}
                                                    handleInputChange={handleInputChange}
                                                    handleInputBlur={handleInputBlur}
                                                />);
                                            break;
                                        case INPUT_TYPE.FILE:
                                            fieldComponent = (
                                                <div>
                                                    {currentFormValues[field.name].map((_, fileIndex) => (
                                                        <div key={fileIndex}>
                                                            <Grid container spacing={0.3}>
                                                                <Grid item xs={11.3}>
                                                                    <FilePicker
                                                                        indexFile={fileIndex}
                                                                        field={field}
                                                                        formValues={
                                                                            currentFormValues[field.name][fileIndex]
                                                                        }
                                                                        errors={currentErrors}
                                                                        onFileSelect={(file) =>
                                                                            handleFileSelect(
                                                                                file,
                                                                                section.title,
                                                                                field.name,
                                                                                fileIndex
                                                                            )
                                                                        }
                                                                        // handleInputChange={(e) =>
                                                                        //     handleInputChange(
                                                                        //         e,
                                                                        //         index,
                                                                        //         section.title,
                                                                        //         field,
                                                                        //         fileIndex
                                                                        //     )
                                                                        // }
                                                                        tip={field?.tip}
                                                                        URIForViewDocument={currentFormValues[field.name][fileIndex]['url']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={0.5} sx={{ marginTop: "2.25rem" }}>
                                                                    <Button
                                                                        variant="contained"
                                                                        disableElevation
                                                                        sx={{
                                                                            backgroundColor: (theme) =>
                                                                                theme.palette.primary.main,
                                                                            ...removeButton,
                                                                        }}
                                                                        onClick={() =>
                                                                            handleRemoveFile(
                                                                                section.title,
                                                                                fileIndex,
                                                                                field.name
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            currentFormValues[field.name].length === 1
                                                                        }
                                                                    >
                                                                        <>
                                                                            <img
                                                                                src={RemoveIcon}
                                                                                alt={`Remove ${section.title}`}
                                                                            />

                                                                        </>
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    ))}
                                                    <Box sx={{ ...justifyContentEnd }}>
                                                        <Button
                                                            onClick={() => handleAddFile(section.title, field.name)} >
                                                            + Add More
                                                        </Button>
                                                    </Box>
                                                </div>
                                            )
                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        <Grid
                                            item
                                            xs={field.gridWidth || 12}
                                            key={field.name}
                                            sx={{ paddingTop: "unset !important", ...field.styleForGrid }}
                                        >
                                            {fieldComponent}
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid >
                    )
                })}

            </Grid>
        )
    }

    const selectCancel = (e) => {
        setCancelType(e);
        setConfirmModal(true);
    }

    const renderActionButtons = () => {
        if (selectedDataRow.status === 'Pending' || selectedDataRow.status === 'Approved') {
            return (
                <>
                    <Box>
                        {
                            selectedDataRow.status === 'Approved' && (
                                <>
                                    <Button
                                        variant="outlined"
                                        sx={{ ...btnWhite }}
                                        onClick={typeForm === "detailForm" ? () => handleCloseFormModal() : () => { setTypeForm("detailForm"); setTitle("Detail Claim Reward Point"); }} >
                                        Cancel
                                    </Button>
                                    {
                                        typeForm === "detailForm" ? (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => { setTypeForm("Pay"); setTitle("Pay Claim Reward Point"); }}
                                                disableElevation >
                                                Pay
                                            </Button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                                disableElevation >
                                                Pay
                                            </Button>
                                        )
                                    }
                                </>
                            )
                        }
                    </Box>
                </>
            );
        }

        return (
            <>
                <Box>
                    <Button
                        variant="outlined"
                        sx={{ ...btnWhite }}
                        onClick={() => handleCloseFormModal()}
                        disableElevation>
                        Close
                    </Button>
                </Box>
            </>
        );
    }

    const handleOnCloseConfirmCancelModal = () => {
        setConfirmModal(false);
        setFormModal(false);
        formValues.booking_id = selectedDataRow.id
        // dispatch(canceledBookingSubmission(token, formValues, `?type=${cancelType}`))
        setSelectedDataRow(null)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formValues);
        console.log(validateForm());

        if (validateForm()) {
            dispatch(addClaimReward(token, formValues))
        }
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        fields.forEach((fieldGroup) => {
            if (fieldGroup.children) {
                const { title } = fieldGroup;
                const sectionErrors = {};
                // debugger;
                fieldGroup.children.forEach((field) => {

                    const { name, type } = field;
                    console.log(name, type)
                    console.log(field)
                    // debugger;
                    if (type === INPUT_TYPE.FILE && Array.isArray(formValues[title][name])) {
                        console.log(1)
                        // debugger;
                        formValues[title][name].forEach((file, subIndex) => {
                            const fileError = validateFileInput(file, field);
                            if (!sectionErrors[name]) {
                                sectionErrors[name] = [];
                            }

                            sectionErrors[name][subIndex] = fileError;

                            if (fileError) {
                                valid = false;
                            }
                        })
                    } else if (type !== "file") {
                        console.log(2)
                        // debugger;
                        const fieldValue =
                            formValues[title] && formValues[title][name] !== undefined
                                ? formValues[title][name]
                                : "";
                        const error = validateFieldHelper(fieldValue, field);

                        sectionErrors[name] = error;

                        if (error) {
                            valid = false;
                        }
                    }
                })
                newErrors[title] = sectionErrors;
            }
        })
        setErrors(newErrors);
        return valid;
    }
    const validateFileInput = (
        fieldValue,
        field,
    ) => {
        console.log("validateFileInput");
        console.log(fieldValue);
        console.log(field);
        console.log(fieldValue instanceof File);
        if (!fieldValue) {
            return "Please select file"
        }
        if (fieldValue instanceof File) {
            console.log(1);
            const allowedExtensions = field.allowedExtensions || [
                ".pdf",
                ".jpeg",
                ".jpg",
                ".png",
            ];
            const size = field.maximumSizeInMB || 10;
            const maxSizeInBytes = size * 1024 * 1024; // MB

            const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
            if (!allowedExtensions.includes(`.${fileExtension}`)) {
                return "Invalid file extension. Please select a file with a valid extension.";
            } else if (fieldValue.size > maxSizeInBytes) {
                return `File size exceeds the maximum allowed size of ${size} MB.`;
            }
        }
        console.log(2)
        return "";
    };

    const validateFieldHelper = (fieldValue, field) => {
        const { required, validation, errorMessage, label, type } = field;
        if (type === INPUT_TYPE.DATE) {
            fieldValue = dayjs(fieldValue).isValid()
                ? dayjs(fieldValue).format("DD/MM/YYYY")
                : "";
            console.log(fieldValue, "fieldValue");
        }

        if (required && (String(fieldValue).trim() === "")) {
            return `${label} is required`;
        } else if (
            fieldValue &&
            fieldValue.length &&
            validation &&
            !validation(fieldValue)
        ) {
            return errorMessage || `${label} is invalid`;
        } else {
            return "";
        }
    }

    return (
        <>
            <Card style={{ boxShadow: "unset" }}>
                <CardContent>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={6}> */}
                        <Grid item xs={7}>
                            <TextField
                                fullWidth
                                placeholder={`Search No. Reference`}
                                size="small"
                                sx={{
                                    borderRadius,
                                }}
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    onKeyDown: handleKeyPress,
                                    startAdornment: <SearchIcon />,
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                disableElevation
                                sx={{ height: "100%" }}
                                onClick={_search}
                                onKeyPress={handleKeyPress}
                            >
                                <SearchIcon />
                            </Button>
                        </Grid>

                        <Grid item xs={2}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                SelectProps={{ IconComponent: ExpandMoreIcon }}
                                value={periodFilter}
                                onChange={(e) => _periodFilter(e.target.value)}
                                sx={{ borderRadius }} >
                                <MenuItem value="0">Select Period</MenuItem>
                                <MenuItem value="2024">2024</MenuItem>
                                <MenuItem value="2023">2023</MenuItem>
                                <MenuItem value="2022">2022</MenuItem>
                                <MenuItem value="2021">2021</MenuItem>
                                <MenuItem value="2020">2020</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                select
                                fullWidth
                                size="small"
                                SelectProps={{ IconComponent: ExpandMoreIcon }}
                                value={status}
                                onChange={(e) => _statusFilter(e.target.value)}
                                sx={{ borderRadius }} >
                                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                                <MenuItem value="Pending">Pending</MenuItem>
                                <MenuItem value="Approved">Approved</MenuItem>
                                <MenuItem value="Rejected">Rejected</MenuItem>
                                <MenuItem value="Paid">Paid</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
                <CardContent sx={{ paddingTop: "unset" }}>
                    <DataTable
                        title={"Claim Reward"}
                        useStyles={useStyles}
                        rows={rows}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        limit={limit}
                        page={page}
                        handlePageChange={handlePageChange}
                        handleLimitChange={handleLimitChange}
                        handleFormModal={handleFormModal}
                        selectedData={selectedData}
                        handleSelectionChange={handleSelectionChange}
                        handleRowDoubleClick={handleRowDoubleClick}
                        theme={theme}
                        sortModel={sortModel}
                        search={search}
                        onSortModelChange={handleSortModelChange}
                        showAddToolbar={false}
                        showEditToolbar={false}
                        showDeleteToolbar={false}
                    // modeCRUD={MODE_CRUD_WITH.PAGING}
                    // linkCRUD={{ create: "add", update: `edit/${selectedData}`, createOption: "hybrid" }}
                    />
                </CardContent>
            </Card>
            <Modal open={formModal} onClose={() => handleCloseFormModal()}>
                <Box>
                    <Form
                        title={title}
                        onCloseModal={() => handleCloseFormModal()}
                        elementForm={renderFormContent}
                        elementActionButton={renderActionButtons}
                        onSubmit={handleSubmit}
                    />
                </Box>
            </Modal>
            <ModalConfirmCancel
                confirmModal={confirmModal}
                message={'You want to cancel the process booking submission'}
                onConfirmModal={() => { setConfirmModal(false) }}
                onCloseModal={handleOnCloseConfirmCancelModal}
            ></ModalConfirmCancel>
        </>
    )
}

export default ClaimRewardPoint