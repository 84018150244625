import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Select,
  Autocomplete,
  Checkbox,
  IconButton,
  FormHelperText,
  Divider,
  CardActions,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
// import RemoveIcon from "@mui/icons-material/Remove";
import RemoveIcon from "./../../../assets/minus.svg";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "./../../../assets/back.svg";
import CalendarIcon from "./../../../assets/calendar.png";
import { AccessRestrictedAlert } from "./../../../components/Alert";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  FacebookCircularProgress,
  removeButton,
} from "./../../../util/style";
import axios from "./../../../core/axios_config";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
} from "./../../../components/Form";
import {
  addEmployeTransfer,
  deleteEmployeTransfer,
  editEmployeTransfer,
  getEmployeTransfer,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  cloneObjectWithEmptyStrings,
  formatCurrencyWithoutLable,
} from "./../../../util/function";
import DataTable from "./../../../components/Table";
import ConfirmDelete from "./../../../components/ConfirmDelete";
import ModalConfirmCancel from "./../../../components/ConfirmCancel";
import { modalActionButton,justifyContentEnd, } from "./../../../util/style";
import { useNavigate, useParams } from "react-router-dom";

const AddEmployeeTransfer = () => {

  /* -------------------------------------------------------------------------- */
  /*                           USER HAVE PERMISSION ???                          */
  /* -------------------------------------------------------------------------- */
  const [title, setTitle] = useState("Add Employee Transfer");
  const [actionType, setActionType] = useState();
  const [currentPath, setCurrentPath] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(false);
  const [userDeleted, setUserDeleted] = useState(null);
  const [userAccess, setUserAccess] = useState([
    _.CREATE,
    _.READ,
    _.UPDATE,
    _.DELETE,
  ]);
  const { menus } = useSelector((state) => state.auth);
 const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  const { id } = useParams();

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);

    setTimeout(() => setIsLoadedFormValues(true))
  }, []);

  useEffect(() => {
    const fetchEmployeeTransferData = async () => {
      setIsLoading(true);
      setIsLoadedFormValues(false)
      try {
        const {data} = await(await axios.get(`/employe-transfers/${id}/edit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          }))?.data;

        setFormValues(data)
        setErrors(cloneObjectWithEmptyStrings(data, 'attachment'))
        setIsLoading(false);
        setIsLoadedFormValues(true)
      } catch (error) {
        console.error("Error fetching attachment data:", error);
        setIsLoading(false);
        setIsLoadedFormValues(true)
      }
    };

    if (id) {
      setTitle("Edit Employee Transfer");
      fetchEmployeeTransferData();
    } else {
      setActionType(ACTION_TYPE.ADD); // Set the action type to add
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (menus.length > 0) {
      // Find the menu item with the matching pathname
      const menuItem = findMenuItemByLink(menus, currentPath);

      if (menuItem) {
        // Filter permissions with has_access set to true
        const filteredPermissions = menuItem.permission.filter(
          (permission) => permission.has_access
        );

        // Extract the permission actions and update userAccess state
        const accessNames = filteredPermissions.map((permission) => {
          const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
          return action.toUpperCase(); // Convert to uppercase
        });
        setUserAccess(accessNames);
      }
    }
  }, [currentPath, menus]);

  /* ------------------- Check if user has CREATE permission ------------------ */

  const hasCreatePermission = userAccess.includes(_.CREATE);

  const formRef = useRef(null);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedServiceCategoryId, setSelectedServiceCategoryId] = useState(
    []
  );
  const [options, setOptions] = useState({});

  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */
  const fields  = [
      {
        title: "Employee Information",
        isDisplayTitle: false,
        children: [
            {
            name: "user_id",
            type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
            label: "Employee",
            apiEndpoint: "/master/employes",
            required: true,
            gridWidth:12
          },
          {
            name: "effective_date",
            type: INPUT_TYPE.DATE,
            label: "Effective Date",
            endIcon: <img src={CalendarIcon} />,
            formatDate: "DD/MM/YYYY", // Custom date format
            defaultValue: '',
            required: true,
            gridWidth:6
          },
          {
            name: "transfer_type_id",
            type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
            label: "Transfer Type",
            apiEndpoint: "/master/transfer-types",
            required: true,
            gridWidth:6
          },
        ],
      },
      {
        title: "Manage Transfer",
        subtitle: 'You can upload the documents for this process ( Optional )',
        isDisplayTitle: true,
        children: [
            {
            name: "position_id",
            type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
            label: "Job Position",
            apiEndpoint: "/master/positions",
            required: false,
            gridWidth:6
          },
          {
            name: "organization_id",
            type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
            label: "Organization Name",
            apiEndpoint: "/master/organizations",
            required: false,
            gridWidth:6
          },
          {
            name: "job_level_id",
            type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
            label: "Job Level",
            apiEndpoint: "/master/job-levels",
            required: false,
            gridWidth:6
          },
          {
            name: "employe_status_id",
            type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
            label: "Employment Status",
            apiEndpoint: "/master/employe-statuses",
            required: false,
            gridWidth:6
          },
          {
            name: "approval_id",
            type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
            label: "Approval Line",
            apiEndpoint: "/master/approval",
            required: false,
            gridWidth:6
          },
        ],
      },
      {
        title: "Attachment",
        subtitle: 'You can upload the documents for this process ( Optional )',
        isDisplayTitle: true,
        children: [
          {
            name: "attachment",
            type: INPUT_TYPE.FILE,
            label: "Upload File",
            tip: "Max size: 2 MB. Supported file: DOCX, PDF",
            allowedExtensions: [".pdf", ".doc", ".docx",],
            maximumSizeInMB: 2, //MB
            defaultValue: '',
            // required: true,
          },
        ],
      },
      {
        title: "Notes",
        isDisplayTitle: false,
        children: [
            {
            name: "notes",
            type: INPUT_TYPE.TEXTAREA,
            label: "Note (Optional)",
            placeholder: "Input notes",
            defaultValue: "",
            required: false,
            additionalProps: {
              // disabled: actionType === ACTION_TYPE.EDITED
            }
          },
        ],
      },
  ]


  const initialSubService = {
    sub_service_id: "",
    subtotal: 0,
    discount: 0,
    vat_percent: '',
    vat_to_rp: 0,
    total_to_rp: 0,
    criteria_id: "",
    desc: "",
  };

  const initialAttachment = {
    attachment: [""],
  };

  const initialErrorSubService = {
    sub_service_id: "",
    subtotal: 0,
    discount: "",
    vat_percent: "",
    criteria_id: "",
    desc: "",
  };
  const initialErrorAttachment = {
    attachment: [""],
  };

  const initialFormValues = () => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialValues[fieldGroup.title] =
          fieldGroup.title === "Sub Service"
            ? [initialSubService]
            : fieldGroup.title === "Attachment"
              ? initialAttachment
              : fieldGroup.children.reduce(
                (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
                {}
              );
      }
    });

    return {
      ...initialValues,
    };
  };

  const initialErrors = () => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialErrorValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialErrorValues[fieldGroup.title] =
          fieldGroup.title === "Sub Service"
            ? [initialErrorSubService]
            : fieldGroup.title === "Attachment"
              ? initialErrorAttachment
              : fieldGroup.children.reduce(
                (acc, field) => ({ ...acc, [field.name]: "" }),
                {}
              );
      }
    });

    return initialErrorValues;
  };


  const [formValues, setFormValues] = useState(initialFormValues());
  const [errors, setErrors] = useState(initialErrors());


  useEffect(() => {}, [dispatch]);

  useEffect(() => {
    if (isActive === true) {
      navigate(-1);
    }
  }, [isActive]);


//   useEffect(() => {
//     const fetchOptions = async (apiEndpoint, name) => {
//       try {
//         const response = await axios.get(apiEndpoint, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//
//         // Check if the response data has the 'fullname' property
//         const modifiedData = response.data.data.map((item) => {
//           if (item.hasOwnProperty("fullname")) {
//             return {
//               ...item,
//               name: item.name,
//             };
//           } else {
//             return item;
//           }
//         });
//
//         // console.log("fetchOptions", name, modifiedData);
//         setOptions((prevOptions) => ({
//           ...prevOptions,
//           [name]: modifiedData,
//         }));
//       } catch (error) {
//         console.error(`Error fetching ${name} options:`, error);
//       }
//     };
//
//     fields.forEach((field) => {
//       // Check if the field has a children array
//       if (field.children) {
//         field.children.forEach((child) => {
//           const { name, apiEndpoint } = child;
//           if (apiEndpoint) {
//             if(!options.hasOwnProperty(name)) {
//               fetchOptions(apiEndpoint, name);
//             }
//           }
//         });
//       } else {
//         // If there's no children array, use the field directly
//         const { name, apiEndpoint } = field;
//         if (apiEndpoint) {
//           if(!options.hasOwnProperty(name)) {
//             fetchOptions(apiEndpoint, name);
//           }
//         }
//       }
//     });
//   }, [options]);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Check if the response data has the 'fullname' property
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.name,
            };
          } else {
            return item;
          }
        });

        // console.log("fetchOptions", name, modifiedData);
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: modifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      // Check if the field has a children array
      if (field.children) {
        field.children.forEach((child) => {
          const { name, apiEndpoint } = child;
          if (apiEndpoint) {
            if(!options.hasOwnProperty(name)) {
              fetchOptions(apiEndpoint, name);
            }
          }
        });
      } else {
        // If there's no children array, use the field directly
        const { name, apiEndpoint } = field;
        if (apiEndpoint) {
          if(!options.hasOwnProperty(name)) {
            fetchOptions(apiEndpoint, name);
          }
        }
      }
    });
  }, []);


  const handleRemoveFieldRow = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: prevValues[sectionTitle].filter(
        (row, index) => index !== rowIndex
      ),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: prevErrors[sectionTitle].filter(
        (error, index) => index !== rowIndex
      ),
    }));
  }


  const handleAddFieldRow = (sectionTitle) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: [
        ...prevValues[sectionTitle],
        sectionTitle === "Sub Service" && initialSubService,
      ], // Add an empty row
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: [
        ...prevErrors[sectionTitle],
        sectionTitle === "Sub Service"
        && initialErrorSubService
        ,
      ], // Add an empty error object
    }));
  };

  const handleAddFile = (sectionTitle, rowIndex, fieldName) => {
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!isNaN(rowIndex)) {
        updatedValues[sectionTitle][rowIndex][fieldName].push(""); // Add an empty certificate
      } else {
        updatedValues[sectionTitle][fieldName].push(""); // Add an empty certificate
      }

      return updatedValues;
    });
  };

  const handleRemoveFile = (sectionTitle, rowIndex, fileIndex, fieldName) => {
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][rowIndex][fieldName].splice(fileIndex, 1); // Remove the selected certificate

        return updatedValues;
      });
    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][fieldName].splice(fileIndex, 1); // Remove the selected certificate
        return updatedValues;
      });

    }
  };

  const handleFileSelect = (
    file,
    rowIndex,
    sectionTitle,
    fieldName,
    fileIndex
  ) => {
    debugger;
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][rowIndex][fieldName];
        debugger;
        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = '';
        }

        return updatedValues;
      });

    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][fieldName];
debugger;
        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = file;
        }

        return updatedValues;
      });

    }
  };

  const handleInputChange = async (
    event,
    index,
    sectionTitle,
    field,
    fileIndex
  ) => {
    const { name, value, type } = event.target;
    console.log(name, value, type, 'SOBARI')
    // debugger;

    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const fileError = validateFileInput(file, field);
        // debugger;
        // if (fileError) {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (!isNaN(index)) {
              updatedErrors[sectionTitle][index][field.name][fileIndex] = fileError;
            } else {
              updatedErrors[sectionTitle][field.name][fileIndex] = fileError;
            }

            return updatedErrors;
          });
          return;
        // }

        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          // debugger;
          if (!isNaN(index)) {
            updatedErrors[sectionTitle][index][field.name][fileIndex] = "";
          } else {
            updatedErrors[sectionTitle][field.name][fileIndex] = "";
          }
          return updatedErrors;
        });

        // Call the handleFileSelect function with the file if it's valid
        if (handleFileSelect) {
          handleFileSelect(file, index, sectionTitle, name);
        }
      } else {
        console.error("there is no file");
      }
    } else {
      // Handle regular input fields

      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
            (item, i) => (i === index ? { ...item, [name]: value } : item)
          );
        } else {
          updatedValues[sectionTitle] = {
            ...updatedValues[sectionTitle],
            [name]: value,
          }

        }
        return updatedValues;

      });

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        const fieldValue = value;
        const fieldErrors = validateFieldHelper(fieldValue, field);

        if (index !== undefined) {
          updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
            (item, i) =>
              i === index ? { ...item, [name]: fieldErrors } : { ...item }
          );
        } else {
          updatedErrors[sectionTitle] = {
            ...updatedErrors[sectionTitle],
            [name]: fieldErrors,
          };
        };
        return updatedErrors;
      });

    }
  }

const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    // console.log(name, checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    validateField(sectionTitle, fieldName, index, { name, value });
  }

  const validateFileInput = (
    fieldValue,
    field,
    sectionTitle,
    index,
    sectionErrors
  ) => {
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB

      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }

    }

    return "";
  };


  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    const fieldGroup = fields.find((group) => group.title === sectionTitle);

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find(
        (field) => field.name === fieldName
      );

      if (field) {
        const { name } = field;

        if (
          !isNaN(index) &&
          (sectionTitle === "Sub Service")
          // (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
        ) {
          const fieldValue = formValues[sectionTitle][index][name] || value;
          newErrors[sectionTitle][index][name] = validateFieldHelper(
            fieldValue,
            field
          );
        } else {
          const fieldValue = formValues[sectionTitle][name] || value;
          newErrors[sectionTitle][name] = validateFieldHelper(
            fieldValue,
            field
          );
        }
      }
    }
    setErrors(newErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" || fieldValue === undefined ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (
      fieldValue &&
      fieldValue.length &&
      validation &&
      !validation(fieldValue)
    ) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };


  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {



        const { title } = fieldGroup;
        const sectionErrors =
          fieldGroup.title === "Sub Service"
            ? []
            : {};


        fieldGroup.children.forEach((field) => {
          const { name, type } = field;
          if (
            fieldGroup.title === "Sub Service"
          ) {

            formValues[title].forEach((sectionValues, index) => {
              // Check if the field is of type "file" and an array
              if (
                type === INPUT_TYPE.FILE &&
                Array.isArray(sectionValues[name])
              ) {
                sectionValues[name].forEach((file, subIndex) => {
                  // Handle file input validation
                  const fileError = validateFileInput(file, field);

                  if (!sectionErrors[index]) {
                    sectionErrors[index] = {};
                  }

                  if (!sectionErrors[index][name]) {
                    sectionErrors[index][name] = [];
                  }

                  sectionErrors[index][name][subIndex] = fileError;

                  if (fileError) {
                    valid = false;
                    // debugger;
                  }
                });
              } else if (type !== "file") {
                const fieldValue =
                  sectionValues[name] !== undefined ? sectionValues[name] : "";
                const error = validateFieldHelper(fieldValue, field);

                if (!sectionErrors[index]) {
                  sectionErrors[index] = {};
                }

                sectionErrors[index][name] = error;

                if (error) {
                  valid = false;
                }

              }
            });
          } else {
            if (
              type === INPUT_TYPE.FILE &&
              Array.isArray(formValues[title][name])
            ) {

              formValues[title][name].forEach((file, subIndex) => {
                // Handle file input validation
                const fileError = validateFileInput(file, field);

                if (!sectionErrors[name]) {
                  sectionErrors[name] = [];
                }

                sectionErrors[name][subIndex] = fileError;

                if (fileError) {
                  valid = false;
                }
              });
            } else if (type !== "file") {
              // Handle regular input field validation
              const fieldValue =
                formValues[title] && formValues[title][name] !== undefined
                  ? formValues[title][name]
                  : "";
              const error = validateFieldHelper(fieldValue, field);

              sectionErrors[name] = error;

              if (error) {
                valid = false;
              }
            }
          }
        });

        newErrors[title] = sectionErrors;
      }
    });


    setErrors(newErrors);

    // debugger;
    return valid;
  };



  const resetFormValues = () => {
    setFormValues(
      Object.assign({}, initialFormValues(fields), actionType !== 'ADD_NEW_GROUP'
        ? {
          id: null,
          status: false,
        }
        : {})
    );
    initialErrors()
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(false);

    const isValid = validateForm();
    if (isValid) {
      if (actionType === ACTION_TYPE.EDIT) {
        setIsLoading(true);
        dispatch(editEmployeTransfer(token, formValues, id));
      } else {
        setIsLoading(true);
        dispatch(addEmployeTransfer(token, formValues));
      }
    }
  };


  const handleCancel = () => {
    setConfirmModal(true);
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    navigate(-1);
  };

  const renderFields = (section, index, field) => {
    if (isLoadedFormValues) {
      const currentFormValues =
        section.title === "Sub Service"
          ? formValues[section.title][index]
          // : section.title === "Attachment"
          //   ? formValues[section.title][index]
          : formValues[section.title];
      const currentErrors =
        section.title === "Sub Service"
          ? errors[section.title][index]
          // : section.title === "Attachment"
          //   ? errors[section.title][index]
          : errors[section.title];

      // debugger;
      // If section.title is undefined, use the general errors
      if (!section.title) {
        currentErrors = errors;
      }
      return (
        <>
          {section.children ?.map((field) => {
            // Check if children array exists
/*
          const checkSalesQuotation = formValues['Check Sales Quotation'];

          const [isClientIdExist, isClientIdANDPeriodeExist] = [
            !checkSalesQuotation ||
              checkSalesQuotation.client_id === undefined ||
              checkSalesQuotation.client_id === null ||
              checkSalesQuotation.client_id === '',
            !checkSalesQuotation ||
              checkSalesQuotation.client_id === undefined ||
              checkSalesQuotation.client_id === null ||
              checkSalesQuotation.client_id === '' ||
              checkSalesQuotation.periode === undefined ||
              checkSalesQuotation.periode === null ||
              checkSalesQuotation.periode === '',
          ];*/

/*
 if (field.title === "Sub Service" && field.children) {
        return {
          ...field,
          children: field.children.map((subField, index) => {
            if(subField.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE) {
              if (subField.name === "sub_service_id") {
                return {
                  ...subField,
                  additionalProps: {
                    ...subField.additionalProps,
                    disabled: (actionType !== ACTION_TYPE.EDITED  && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                     // old (actionType !== ACTION_TYPE.EDITED  && (formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)) || actionType === ACTION_TYPE.EDITED), // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                };
              } else {
              // return {
              //   ...subField,
              //   additionalProps: {
              //     ...subField.additionalProps,
              //     disabled:actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED
              //   },
              // };
            }
          } else {
            if (subField.name === "discount" || subField.name === "vat_percent"  || subField.name === "subtotal" || subField.name === "criteria_id") {
              // console.log(formValues['Sub Service'][index], 'SOBARII2',index)
              return {
                ...subField,
                additionalProps: {
                  ...subField.additionalProps,
                  disabled: true
                  // (formValues['Sub Service'][index]['sub_service_id'] === undefined ||
                  // formValues['Sub Service'][index]['sub_service_id'] === null)) , // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                },
              };
            }}
            return subField;
          }),
        };
      }
      */
            let optionsData;

            if (Array.isArray(options[field.name])) {
              const isRequired = field.required &&
                field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                  id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                  name: "Choose Option",
                };

              // if (isRequired) {
              //   optionsData = [
              //     // field.required &&
              //     //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
              //     //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
              //     //     name: "Choose Option",
              //     //   },
              //     ...options[field.name],
              //   ];
              // } else {
              //   optionsData = [...options[field.name]];
              // }

                if (field.name === "sub_service_id") {

                  field = {
                    ...field,
                    additionalProps: {
                      ...field.additionalProps,
                      disabled: (actionType !== ACTION_TYPE.EDITED  && formValues['Service'] && (formValues['Service']['service_id'] === undefined || formValues['Service']['service_id'] === null)), //|| actionType === ACTION_TYPE.EDITED, // akan terdisabled kalau actionTypenya EDITED atau selain actionType selain edit && serviceId == undefined || null
                    },
                  };

                  // Filter options based on sub_service_id values that are not used
                  const usedSubServiceIds = formValues['Sub Service']
                  .filter((_, i) => i !== index) // Exclude the sub_service_id at the current index
                  .map(subService => subService?.sub_service_id); // Use optional chaining to prevent errors if subService is undefined

                  // Filter options based on sub_service_id values that are not used
                  optionsData = options[field.name].filter(option => !usedSubServiceIds.includes(option.id));
                  console.warn('usedSubServiceIds', usedSubServiceIds);
                  console.warn('optionsData', optionsData);

                }  else if (field.name === "criteria_id") {

                    const usedSubServiceIds = formValues['Sub Service']
                    .filter((_, i) => i === index)
                    .map(subService => subService?.sub_service_id);
                    // console.log(usedSubServiceIds,'KKKK');

                    optionsData = options[field.name].filter(option => usedSubServiceIds.includes(option.service_id));

                } else {
                  optionsData = [...options[field.name]];

//                   if (field.name === "sales_quotation_id") {
//                     field = {...field, additionalProps: {
//                         ...field.additionalProps,
//                         disabled: isClientIdANDPeriodeExist ,
//                       },
//                       disabled: isClientIdANDPeriodeExist
//                     }
//                   }
                }

              } else {

                if(!currentFormValues[field.name]) {
                  currentFormValues[field.name] = field.defaultValue
                }

                if (field.name === "price" || field.name === "quantity" || field.name === "discount" || field.name === "vat_percent"  || field.name === "criteria_id") {
                  field = {...field, additionalProps: {
                    ...field.additionalProps,
                    disabled: formValues['Sub Service'][index] && (formValues['Sub Service'][index]['sub_service_id'] === undefined || formValues['Sub Service'][index]['sub_service_id'] === null || formValues['Sub Service'][index]['sub_service_id'] === '')
                  }}
                  console.log(field)
                }

            }


          const startIconMappings = {
              USD: { subtotal: 'USD ', price: 'USD ' },
              Rp: { subtotal: 'Rp ', price: 'Rp ' },
              EUR: { subtotal: 'EUR ', price: 'EUR ' },
              CNY: { subtotal: 'CNY ', price: 'CNY ' },
              SGD: { subtotal: 'SGD ', price: 'SGD ' },
              GBP: { subtotal: 'GBP ', price: 'GBP ' },
              AUD: { subtotal: 'AUD ', price: 'AUD ' },
            };


            const labelMappings = {
              USD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              Rp: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              EUR: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              CNY: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              SGD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              GBP: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
              AUD: ['price_services_info_to_rp', 'discount_info_to_rp', 'vat_info_to_rp', 'total_price_info_to_rp', 'total_to_rp', 'vat_to_rp', 'subtotal'],
            };


            if(formValues['Service'] && formValues['Service']['currency']) {
              const currency = formValues['Service']['currency'];

              if (currency && startIconMappings[currency] && startIconMappings[currency][field.name]) {
                field = { ...field, startIcon: startIconMappings[currency][field.name] };
              }

              if (currency && labelMappings[currency] && labelMappings[currency].includes(field.name)) {
                field = { ...field, label: `${currency} ` };
              }
            }



            /*
             * EFFECTIVE DATE
             *
             */
             if(formValues['is_effective_date_has_passed']) {

                /*const currentDate = new Date(); // Current date
                const effectiveDate = new Date(formValues['Employee Information']['effective_date']); // Effective date from the form values

                if (effectiveDate < currentDate) {
                */
                 field = {
                    ...field,
                    additionalProps: {
                      ...field.additionalProps,
                      disabled: field.type !== INPUT_TYPE.FILE ? true : false
                    },
                    disabled: field.type !== INPUT_TYPE.FILE ? true : false
                  };
                /*}*/

              }



            const fieldOptions = Array.isArray(options[field.name])
              ? optionsData
              : [];

            let fieldComponent = null;

            switch (field.type) {
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.NUMBER:
              case INPUT_TYPE.PHONE_NUMBER:
              case INPUT_TYPE.POINT:
              case INPUT_TYPE.CURRENCY:
              case INPUT_TYPE.DISCOUNT:
                fieldComponent = (
                  <TextInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              case INPUT_TYPE.TEXTAREA:
                fieldComponent = (
                  <TextArea
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              case INPUT_TYPE.FILE:
                console.log(currentFormValues[field.name], 'SOBARIIIIII', field.name, section.title, currentFormValues)
                fieldComponent = (
                  <div>
                    {currentFormValues[field.name].map(
                      (_, fileIndex) => (
                        <div key={fileIndex}>
                          <Grid container spacing={0.3}>
                            <Grid item xs={11.3}>
                              <FilePicker
                                indexFile={fileIndex}
                                field={field}
                                formValues={
                                  currentFormValues[field.name][fileIndex]
                                }
                                errors={currentErrors}
                                onFileSelect={(file) =>
                                  handleFileSelect(
                                    file,
                                    index,
                                    section.title,
                                    field.name,
                                    fileIndex
                                  )
                                }
                                handleInputChange={(e) =>
                                  handleInputChange(
                                    e,
                                    index,
                                    section.title,
                                    field,
                                    fileIndex
                                  )
                                }
                                tip={field ?.tip}
                                URIForViewDocument={currentFormValues[field.name][fileIndex]['url']}
                              />
                            </Grid>

                            <Grid item xs={0.5} sx={{ marginTop: "2.25rem" }}>
                              <Button
                                variant="contained"
                                disableElevation
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.primary.main,
                                  ...removeButton,
                                }}
                                onClick={() =>
                                  handleRemoveFile(
                                    section.title,
                                    index,
                                    fileIndex,
                                    field.name
                                  )
                                }
                                disabled={
                                  currentFormValues[field.name].length === 1
                                }
                              >
                                <>
                                  <img
                                    src={RemoveIcon}
                                    alt={`Remove ${section.title}`}
                                  />

                                </>
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}

                    <Box sx={{ ...justifyContentEnd }}>
                      <Button
                        onClick={() => handleAddFile(section.title, index, field.name)}
                      >
                        Add More
                      </Button>
                    </Box>
                  </div>
                );

                break;
              case INPUT_TYPE.SELECT_ONE:
                fieldComponent = (
                  <SelectOne
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : (field.options || [])
                    }
                  />
                );
                break;
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectOneAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) => {
                      handleInputBlur(field.name, section.title, index, e);
                    }}
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : (field.options || [])
                    }
                  />
                );
                break;
              case INPUT_TYPE.DIVIDER:
                fieldComponent = (
                  <Divider sx={field.style}/>
                );
                break;
              case INPUT_TYPE.TYPOGRAPHY:
                fieldComponent = (
                  <Typography variant={field.variant} sx={field.style}>{field.label}</Typography>
                );
                break;
//               OLD
//               case INPUT_TYPE.TYPOGRAPHY_DYNAMIC:
//                 fieldComponent = (
//                   <Typography key={field.name} variant={field.variant} sx={field.style}>{field.label} {field.name === 'price_services_info' ? currentFormValues[field.name] : formatCurrencyWithoutLable(currentFormValues[field.name])}</Typography>
//                 );
//                 break;
              case INPUT_TYPE.TYPOGRAPHY_DYNAMIC:
                if (field.name === 'price_services_info') {
                    fieldComponent = (
                        <Typography key={field.name} variant={field.variant} sx={field.style}>
                            {field.label} {currentFormValues[field.name]}
                        </Typography>
                    );
                } else if (field.name === 'discount_info_to_rp') {
                    fieldComponent = (
                        <Typography key={field.name} variant={field.variant} sx={field.style}>
                            ({field.label} {formatCurrencyWithoutLable(currentFormValues[field.name])})
                        </Typography>
                    );
                } else {
                    fieldComponent = (
                        <Typography key={field.name} variant={field.variant} sx={field.style}>
                            {field.label} {formatCurrencyWithoutLable(currentFormValues[field.name])}
                        </Typography>
                    );
                }
              break;
              case INPUT_TYPE.SELECT_MULTIPLE:
                fieldComponent = (
                  <SelectMultiple
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
                fieldComponent = (
                  <SelectMultipleAutocomplete
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={fieldOptions}
                  />
                );
                break;
              case INPUT_TYPE.CHECKBOX:
                fieldComponent = (
                  <CheckboxGroupInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : field.options
                    }
                  />
                );
                break;
              case INPUT_TYPE.RADIO:
                fieldComponent = (
                  <RadioGroupInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                    errors={currentErrors}
                    fieldOptions={
                      fieldOptions.length
                        ? fieldOptions.filter((option) => option !== false)
                        : field.options
                    }
                  />
                );
                break;
              case INPUT_TYPE.DATE:
                fieldComponent = (
                  <DatePickerInput
                    key={field.name}
                    field={field}
                    formValues={currentFormValues}
                    errors={currentErrors}
                    handleInputChange={(e) =>
                      handleInputChange(e, index, section.title, field)
                    }
                    handleInputBlur={(e) =>
                      handleInputBlur(field.name, section.title, index, e)
                    }
                  />
                );
                break;
              // Add cases for other field types
              default:
                fieldComponent = null;
            }

            return (
              <Grid
                item
                xs={field.gridWidth || 12}
                key={field.name}
                sx={{ paddingTop: "unset !important", ...field.styleForGrid }}
              >
                {fieldComponent}
              </Grid>
            );
          })}
        </>
      );
    }
  };

  const renderSectionFields = (section) => {
    if (isLoadedFormValues) {
      // if (section.title === "Sub Service" || section.title === "Attachment") {
      if (section.title === "Sub Service") {
        // Render Sub Service or Attachment section
        return (
          <Grid item xs={12} key={section.title}>
            {section.isDisplayTitle !== false && (
              <Box sx={{ marginBottom: 2 }}>
                {/* Title */}
                <Typography variant="h6" sx={{ fontWeight: 800 }}>
                  {section.title}
                </Typography>
              </Box>
            )}
            {formValues[section.title].map((rowValues, index) => (
              <div key={index}>
                <Grid container spacing={0.3}>
                  <Grid item xs={11.3}>
                    <Grid container spacing={1}>
                      {renderFields(section, index)}
                    </Grid>
                  </Grid>
                  <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        ...removeButton,
                      }}
                      onClick={() => handleRemoveFieldRow(section.title, index)}
                      // OLD disabled={(actionType === ACTION_TYPE.ADD && formValues[section.title].length === 1) || actionType === ACTION_TYPE.EDITED}>
                      disabled={formValues[section.title].length === 1}>
                      <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                    </Button>
                  </Grid>
                </Grid>
                {formValues[section.title].length > 1 && (
                  <Divider sx={{ mt: 2, mb: 2 }} />
                )}
              </div>
            ))}
            {
              /* ********************** OLD **********************
               actionType !== ACTION_TYPE.EDITED && (<Box sx={{ ...justifyContentEnd }}>
              <Button onClick={() => handleAddFieldRow(section.title)} disabled={
                //(actionType === ANOTHER_ACTION_TYPE.ADD_SALES_ORDER && formValues[section.title].length === 1) ||
                actionType === ACTION_TYPE.EDITED}>
                Add Another Sub {section.title === "Sub Service" ? " " : " "}
              </Button>
            </Box>)
              */
            (<Box sx={{ ...justifyContentEnd }}>
              <Button onClick={() => handleAddFieldRow(section.title)}>
                Add Another Sub {section.title === "Sub Service" ? " " : " "}
              </Button>
            </Box>)
            }
          </Grid>
        );
      } else {
        // Render other sections using renderFields function
        return (
          <Grid item xs={12} key={section.title}>
            {section.isDisplayTitle !== false && (
              <Box sx={{ marginBottom: 2 }}>
                {/* Title */}
                <Typography variant="h6" sx={{ fontWeight: 800 }}>
                  {section.title}
                </Typography>
                {/* Subtitle */}
                {section.subtitle && (
                  <Typography variant="subtitle2">{section.subtitle}</Typography>
                )}
              </Box>
            )
            }
            <Grid container spacing={1}>
              {renderFields(section)}
            </Grid>
          </Grid >
        );
      }
    }
  };

  const renderFormContent = () => {
    if(isLoadedFormValues) {
      if (
  //       (selectedData.length && actionType === ACTION_TYPE.EDITED)
//         (actionType === ACTION_TYPE.EDITED) ||
//         (!selectedData.length && actionType === ACTION_TYPE.ADD )
        true
      ) {
        return (
          <>
            <Grid
              container
              spacing={1.5}
              sx={{
                // overflowX: "auto",
                // height: "52vh",
                // paddingBottom: "2rem",
              }}
            // className="smooth-scroll"
            >
              {/* {JSON.stringify(isLoadedFormValues)} */}
              {/* <div class="test">
                {JSON.stringify(actionType)}
              {JSON.stringify(formValues)}
              </div>
              */}
              {/* <hr /> */}
              {/* {JSON.stringify(errors)} */}
              {isLoadedFormValues ?
                fields.map((section) => renderSectionFields(section)) : (<Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: '100%'
                }}><FacebookCircularProgress /></Box>)
              }
            </Grid>
          </>
        )
      } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
        return <ConfirmDelete userDeleted={userDeleted} />;
      }
    } else {
    return(<Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: '100%',
      height:'100%'
    }}><FacebookCircularProgress /></Box>)
  }

  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
          // onClick={handleSaveButtonClick}
        >
          Save
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "81vh",
          position: "relative",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ marginRight: ".5rem" }}
              >
                <img src={ArrowBackIcon} />
              </IconButton>
              {title}
            </div>
          }
        />
        <Divider />
        {isLoading ? (
          <FacebookCircularProgress />
        ) : hasCreatePermission ? (
          <form ref={formRef} onSubmit={handleSubmit}>
            <CardContent sx={{ padding: "1.7rem", maxHeight: "55vh", overflow:'auto' }}>
              {renderFormContent()}
            </CardContent>
            <CardActions
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "100%",
                justifyContent: "flex-end",
                background: "white",
                padding: "20px",
              }}
            >
              {renderActionButtons()}
            </CardActions>
          </form>
        ) : (
          <AccessRestrictedAlert />
        )}
      </Card>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default AddEmployeeTransfer;
