//@ts-check

/**
 * @typedef {object} optionDateType
 * @property {Intl.LocalesArgument} [location]
 * @property {Intl.DateTimeFormatOptions} [option]
 */

import { formatDate } from "../../../util/function";

export function formatValue(documetDetail) {
  const listDateFormat = ["Create At", "Due Date", "Pay At"];
  const listCurentFormat = ["total"];

  /**@param {string} value */
  const formatCurentcy = (value) => {
    let clearVal = value.includes(",") ? value.split(",").join("") : value;
    let styleCurrency = documetDetail.Currency;
    return new Intl.NumberFormat(styleCurrency === "Rp" ? "id-ID" : "en-EN", {
      style: "currency",
      currency: styleCurrency === "Rp" ? "IDR" : styleCurrency,
      minimumFractionDigits: 0,
    }).format(parseInt(clearVal));
  };

  /**
   *
   * @param {string} val
   * @param {string} key
   * @param {boolean} semicolon
   * @param {optionDateType} optionDate
   * @returns
   */
  function renderValue(
    val,
    key,
    semicolon = true,
    optionDate = { location: "", option: {} }
  ) {
    let result = "";
    if (
      listDateFormat.includes(key) &&
     ( val !== undefined &&
      val !== "" &&
      val !== "-" &&
      val !== null)
    ) {
      if (optionDate.location) {
        result = new Date(val).toLocaleDateString(
          optionDate.location,
          optionDate.option
        );
      } else {
        result = semicolon ? `: ${formatDate(val)}` : formatDate(val);
      }
    } else if (Array.isArray(val) && val.length > 0) {
      result = semicolon ? ": ".concat(val[0]) : val[0];
    } else if (
      val !== undefined &&
      listCurentFormat.includes(key.toLowerCase())
    ) {
      result = formatCurentcy(val);
    } else if (!val) {
      result = semicolon ? ": -" : "-";
    } else {
      result = semicolon ? ": ".concat(val) : val;
    }
    return result;
  }
  return { renderValue, formatCurentcy };
}
