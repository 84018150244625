import axios, { headersAPIToken, urlRequest } from "../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../util/global_state";
import {
  GET_SERVICE_SUCCESS,
  GET_SERVICE_FAILED,
  ADD_SERVICE_SUCCESS,
  ADD_SERVICE_FAILED,
  EDIT_SERVICE_SUCCESS,
  EDIT_SERVICE_FAILED,
  DELETE_SERVICE_FAILED,
  DELETE_SERVICE_SUCCESS,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../util/function";
import { logout } from "../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getService = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "services",
          page,
          limit,
          search,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getServiceSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getServiceSuccess([], null));
        } else {
          dispatch(getServiceFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getServiceFailed(error.response.data.message));
          }
        } else {
          dispatch(getServiceFailed(error.message));
        }
      });
  };
};

const getServiceSuccess = (data, pagination) => {
  return {
    type: GET_SERVICE_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getServiceFailed = (errorMessage) => {
  return {
    type: GET_SERVICE_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addService = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        "services",
        Object.assign({}, formData, {
          status: formData.status ? "active" : "inactive",
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "service"));
          dispatch(addServiceSuccess(response.data.message));
          localStorage.setItem("system-configuration/services/formValues", JSON.stringify({basic_price:0, sell_price_rp:0,sell_price_dollar:0 }));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addServiceFailed(response.data.message));
        } else {
          dispatch(addServiceFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addServiceFailed(error.response.data.message));
          }
        } else {
          dispatch(addServiceFailed(error.message));
        }
      });
  };
};

const addServiceSuccess = (message) => {
  return {
    type: ADD_SERVICE_SUCCESS,
    payload: message,
  };
};

const addServiceFailed = (message) => {
  return {
    type: ADD_SERVICE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editService = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .put(
        `services/${id}`,
        Object.assign({}, formData, {
          status: formData.status ? "active" : "inactive",
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "service"));
          dispatch(editServiceSuccess(response.data.message));
          localStorage.setItem("system-configuration/services/formValues", JSON.stringify({basic_price:0, sell_price_rp:0,sell_price_dollar:0 }));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editServiceFailed(response.data.message));
        } else {
          dispatch(editServiceFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editServiceFailed(error.response.data.message));
          }
        } else {
          dispatch(editServiceFailed(error.message));
        }
      });
  };
};

const editServiceSuccess = (message) => {
  return {
    type: EDIT_SERVICE_SUCCESS,
    payload: message,
  };
};

const editServiceFailed = (message) => {
  return {
    type: EDIT_SERVICE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteService = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`services${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "service"));
          dispatch(deleteServiceSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteServiceFailed(response.data.message));
        } else {
          dispatch(deleteServiceFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteServiceFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteServiceFailed(error.message));
        }
      });
  };
};

const deleteServiceSuccess = (message) => {
  return {
    type: DELETE_SERVICE_SUCCESS,
    payload: message,
  };
};

const deleteServiceFailed = (message) => {
  return {
    type: DELETE_SERVICE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
