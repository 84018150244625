import { REQUEST_LOADING } from "../../../util/global_state";

import {
  ADD_BENEFIT_FAILED,
  ADD_BENEFIT_SUCCESS,
  DELETE_BENEFIT_FAILED,
  DELETE_BENEFIT_SUCCESS,
  EDIT_BENEFIT_FAILED,
  EDIT_BENEFIT_SUCCESS,
  GET_BENEFIT_FAILED,
  GET_BENEFIT_SUCCESS,
} from "./state";

const initialState = {
  data: [],
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
};

// Define a helper function for common state updates
const updateState = (state, payload, pagination, isLoading = false) => {
  return {
    ...state,
    data: payload,
    pagination,
    message: null,
    isLoading,
  };
};

const benefitsReducer = (
  state = initialState,
  { type, payload, pagination }
) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_BENEFIT_SUCCESS:
      return updateState(state, payload, pagination);
    case GET_BENEFIT_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case ADD_BENEFIT_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
        response_status: true,
      };
    case ADD_BENEFIT_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_BENEFIT_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case EDIT_BENEFIT_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_BENEFIT_SUCCESS:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    case DELETE_BENEFIT_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default benefitsReducer;
