import axios, { headersAPIToken, urlRequest } from "../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../util/global_state";
import {
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILED,
  ADD_EMPLOYEES_SUCCESS,
  ADD_EMPLOYEES_FAILED,
  EDIT_EMPLOYEES_SUCCESS,
  EDIT_EMPLOYEES_FAILED,
  DELETE_EMPLOYEES_FAILED,
  DELETE_EMPLOYEES_SUCCESS,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../util/function";
import dayjs from "dayjs";
import { logout } from "../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getEmployee = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(page, limit, search, status, sortDirection, sortBy);
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "employes",
          page,
          limit,
          search,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortBy,
          sortDirection,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getEmployeeSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getEmployeeSuccess([], null));
        } else {
          dispatch(getEmployeeFailed(response.data.message));
        }
      })
      .catch((error) => {
        debugger;
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getEmployeeFailed(error.response.data.message));
          }
        } else {
          dispatch(getEmployeeFailed(error.message));
        }
      });
  };
};

const getEmployeeSuccess = (data, pagination) => {
  return {
    type: GET_EMPLOYEES_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getEmployeeFailed = (errorMessage) => {
  return {
    type: GET_EMPLOYEES_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addEmployee = (token, formData) => {
  delete formData.id;
  return (dispatch) => {
    // // Create a FormData object
    // const formDataObject = new FormData();

    // // Function to recursively append data to the FormData object
    // const appendData = (prefix, data) => {
    //   for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //       const value = data[key];
    //       const newKey = `${prefix}.${key}`;

    //       if (value instanceof File) {
    //         // Handle File objects (actual files selected by the user)
    //         formDataObject.append(newKey, value);
    //       } else if (Array.isArray(value)) {
    //         // Handle arrays
    //         value.forEach((item, index) => {
    //           // Check if the item is an object or a primitive value
    //           if (
    //             typeof item === "object" &&
    //             !(item instanceof File) &&
    //             (newKey.endsWith("date") || newKey.endsWith("year"))
    //           ) {
    //             // Recursively handle nested objects in arrays
    //             formDataObject.append(`${newKey}[${index}]`, formatDate(value));
    //           } else if (
    //             typeof item === "object" &&
    //             !(item instanceof File) &&
    //             (!newKey.endsWith("date") || !newKey.endsWith("year"))
    //           ) {
    //             appendData(`${newKey}[${index}]`, item);
    //           } else {
    //             // Handle primitive values in arrays
    //             formDataObject.append(`${newKey}[${index}]`, item);
    //           }
    //         });
    //       } else if (
    //         typeof value === "object" &&
    //         !(value instanceof File) &&
    //         (newKey.endsWith("date") || newKey.endsWith("year"))
    //       ) {
    //         // Handle nested objects (excluding File objects)
    //         formDataObject.append(newKey, formatDate(value));
    //       } else if (
    //         typeof value === "object" &&
    //         !(value instanceof File) &&
    //         (!newKey.endsWith("date") || !newKey.endsWith("year"))
    //       ) {
    //         appendData(newKey, value);
    //       } else if (newKey.endsWith("date")) {
    //         // Format and append date fields
    //         formDataObject.append(newKey, formatDate(value));
    //       } else {
    //         // Handle other values
    //         formDataObject.append(newKey, value);
    //       }
    //     }
    //   }
    // };

    // // Function to format dates
    // const formatDate = (dateString) => {
    //   return dayjs(dateString).isValid()
    //     ? dayjs(dateString).format("DD-MM-YYYY ")
    //     : dayjs().format("DD-MM-YYYY ");
    // };

    // appendData("StepOne", formData.StepOne);
    // appendData("StepTwo", formData.StepTwo);
    // appendData("StepThree", formData.StepThree);
    // appendData("StepFour", formData.StepTwo);

    // axios
    //   .post("employes", formDataObject, {
    //     headers: {
    //       "Content-Type": "multipart/form-data", // Set the content type for file uploads
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })

    dispatch(RequestLoading());
    axios
      .post("employes", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "employee"));
          dispatch(addEmployeeSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addEmployeeFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(addEmployeeFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(addEmployeeFailed(error.response.data.message));
          }
        } else {
          dispatch(addEmployeeFailed(error.message));
        }
      });
  };
};

const addEmployeeSuccess = (message) => {
  return {
    type: ADD_EMPLOYEES_SUCCESS,
    payload: message,
  };
};

const addEmployeeFailed = (message) => {
  return {
    type: ADD_EMPLOYEES_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editEmployee = (token, formData, id) => {
  debugger;
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`employes-update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "employee"));
          dispatch(editEmployeeSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editEmployeeFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(editEmployeeFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(editEmployeeFailed(error.response.data.message));
          }
        } else {
          dispatch(editEmployeeFailed(error.message));
        }
      });
  };
};

const editEmployeeSuccess = (message) => {
  return {
    type: EDIT_EMPLOYEES_SUCCESS,
    payload: message,
  };
};

const editEmployeeFailed = (message) => {
  return {
    type: EDIT_EMPLOYEES_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteEmployee = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`employes${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "employee"));
          dispatch(deleteEmployeeSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteEmployeeFailed(response.data.message));
        } else {
          dispatch(deleteEmployeeFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteEmployeeFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteEmployeeFailed(error.message));
        }
      });
  };
};

const deleteEmployeeSuccess = (message) => {
  return {
    type: DELETE_EMPLOYEES_SUCCESS,
    payload: message,
  };
};

const deleteEmployeeFailed = (message) => {
  return {
    type: DELETE_EMPLOYEES_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
