import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { Grid, Typography, Box, Divider, Button } from "@mui/material";
import CalendarIcon from "./../../../../../assets/calendar.png";
import RemoveIcon from "./../../../../../assets/minus.svg";

import { removeButton, justifyContentEnd } from "../../../../../util/style";
import axios from "../../../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
} from "../../../../../components/Form";

import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../../../../util/function";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const Step2_BPOM_MD = ({
  formData,
  formAttempted,
  isNextTriggered,
  updateWizardData,
  setIsNextTriggered,
}) => {

  const { id } = useParams();
  const [isFormValid, setIsFormValid] = useState(false);

  const fields = [
    {
      title: "Business Description",
      children: [
        {
          // ### Old ###
          // name: "business_activity_id",
          // type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          // label: "Business Activities",
          // placeholder: "Select business activities",
          // // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          // apiEndpoint: "/master/business-activities",
          // required: true,
          // gridWidth: 6, // Half width
          // ### NEW ###
          name: "business_activity",
          type: INPUT_TYPE.TEXT,
          label: "Business Activities",
          placeholder:"Input Business Activities (Ops)",
          required: false,
          gridWidth: 6, // Half width
        },
        {
          // ### Old ###
          // name: "product_marketing_id",
          // type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          // label: "Product Marketing",
          // placeholder: "Select product marketing",
          // // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          // apiEndpoint: "/master/product-marketings",
          // required: true,
          // gridWidth: 6, // Half width
          // ### NEW ###
          name: "product_marketing",
          type: INPUT_TYPE.TEXT,
          label: "Product Marketing",
          placeholder:"Input Product Marketing (Ops)",
          required: false,
          gridWidth: 6, // Half width
        },
      ],
    },
  ];

  useEffect(() => {
    if (isNextTriggered.isRun === "YES") {
      validateForm();
    }
  }, [isNextTriggered, isFormValid]);

  const { token } = useSelector((state) => state.auth);
  const initialFamily = {
    name: "",
    relation_id: "",
    birthdate: "",
    gender: "",
    religion_id: "",
    marital_status_id: "",
    identity_number: "",
    job_id: "",
  };
  const initialEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const handleAddRow = (sectionTitle) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: [
        ...prevValues[sectionTitle],
        sectionTitle === "Family" ? initialFamily : initialEmergencyContact,
      ], // Add an empty row
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: [
        ...prevErrors[sectionTitle],
        sectionTitle === "Family" ? initialFamily : initialEmergencyContact,
      ], // Add an empty error object
    }));
  };

  const handleRemoveRow = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: prevValues[sectionTitle].filter(
        (row, index) => index !== rowIndex
      ),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: prevErrors[sectionTitle].filter(
        (error, index) => index !== rowIndex
      ),
    }));
  };

  const initialFormValues = () => {
    const initialValues = {};
    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (
          fieldGroup.title === "Family" ||
          fieldGroup.title === "Emergency Contact"
        ) {
          initialValues[fieldGroup.title] =
            fieldGroup.title === "Family"
              ? [initialFamily]
              : [initialEmergencyContact];
        } else {
          fieldGroup.children.forEach((field) => {
            if (!initialValues[fieldGroup.title]) {
              initialValues[fieldGroup.title] = {};
            }
            initialValues[fieldGroup.title][field.name] = field.defaultValue;
          });
        }
      }
    });

    return {
      ...initialValues,
    };
  };

  const initialErrors = () => {
    const initialErrorValues = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (
          fieldGroup.title === "Family" ||
          fieldGroup.title === "Emergency Contact"
        ) {
          initialErrorValues[fieldGroup.title] =
            fieldGroup.title === "Family"
              ? [initialFamily]
              : [initialEmergencyContact];
        } else {
          if (!initialErrorValues[fieldGroup.title]) {
            initialErrorValues[fieldGroup.title] = {};
          }
          fieldGroup.children.forEach((field) => {
            initialErrorValues[fieldGroup.title][field.name] = "";
          });
        }
      }
    });

    return initialErrorValues;
  };

  const [formValues, setFormValues] = useState(
    formData && Object.values(formData).length > 0
      ? formData
      : initialFormValues()
  );

  const [options, setOptions] = useState({});
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    const storedOptions = JSON.parse(localStorage.getItem("business_permit_options_step_two"));

    if (!storedOptions || Object.keys(storedOptions || {}).length === 0 || id) {
      const fetchData = async () => {
        try {
          const fetchedOptions = {};

          for (const section of fields) {
            if (section.children) {
              for (const field of section.children) {
                const { name, apiEndpoint, params } = field;

                //NEW
                const requestOptions = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };

                if(params) {
                  requestOptions.params = { ...params };
                }

                if (apiEndpoint) {
                  const response = await axios.get(apiEndpoint, requestOptions);
                  fetchedOptions[name] = response.data.data;
                }
              }
            }
          }

          // Store fetched options in localStorage
          localStorage.setItem(
            "business_permit_options_step_two",
            JSON.stringify(fetchedOptions)
          );

          // Set options in state
          setOptions(fetchedOptions);
        } catch (error) {
          console.error("Error fetching options:", error);
        }
      };

      fetchData();

    } else {
      setOptions(storedOptions);
    }
  }, [token]);

//   useEffect(() => {
//     if (options) {
//       if (!options["approval_id"]) {
//         axios
//           .get(`/master/approval`, {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           })
//           .then((response) => {
//             setOptions((prevOptions) => ({
//               ...prevOptions,
//               approval_id: response.data.data,
//             }));
//           })
//           .catch((error) => {
//             console.error(
//               "Error fetching /master/approval?except_user_id:",
//               error
//             );
//           });
//       }
//     }
//   }, [options]);

  const handleInputChange = (event, index, sectionTitle, field) => {
    const { name, value } = event.target;
    const updatedErrors = { ...errors };

    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (
        index !== undefined &&
        (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
      ) {
        updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
          (item, i) => (i === index ? { ...item, [name]: value } : item)
        );
      } else {
        updatedValues[sectionTitle] = {
          ...updatedValues[sectionTitle],
          [name]: value,
        };
      }

      return updatedValues;
    });

    setErrors((prevErrors) => {
      const fieldValue = value;
      const fieldErrors = validateFieldHelper(fieldValue, field);

      if (
        index !== undefined &&
        (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
      ) {
        updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
          (item, i) =>
            i === index ? { ...item, [name]: fieldErrors } : { ...item }
        );
      } else {
        updatedErrors[sectionTitle] = {
          ...updatedErrors[sectionTitle],
          [name]: fieldErrors,
        };
      }
      return updatedErrors;
    });

    updateWizardData("StepTwo", formValues);
  };

  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    validateField(sectionTitle, fieldName, index, { name, value });
    // debugger;
  };

  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    const fieldGroup = fields.find((group) => group.title === sectionTitle);

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find(
        (field) => field.name === fieldName
      );

      if (field) {
        const { name } = field;

        if (
          !isNaN(index) &&
          (sectionTitle === "Family" || sectionTitle === "Emergency Contact")
        ) {
          const fieldValue = formValues[sectionTitle][index][name] || value;
          newErrors[sectionTitle][index][name] = validateFieldHelper(
            fieldValue,
            field
          );
        } else {
          const fieldValue = formValues[sectionTitle][name] || value;
          newErrors[sectionTitle][name] = validateFieldHelper(
            fieldValue,
            field
          );
        }
      }
    }
    setErrors(newErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (
      fieldValue &&
      fieldValue.length &&
      validation &&
      !validation(fieldValue)
    ) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    console.log(
      "------------------------------FORM VALUE----------------------"
    );
    console.log(formValues);
    console.log("------------------------------ERRRORS----------------------");
    console.log(errors);
  }, [formValues, errors]);

   useEffect(() => {
      updateWizardData("StepTwo", formValues);
    }, [formValues]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        const { title } = fieldGroup;

        const sectionErrors =
          fieldGroup.title === "Family" ||
          fieldGroup.title === "Emergency Contact"
            ? []
            : {};

        fieldGroup.children.forEach((field) => {
          const { name } = field;

          if (
            fieldGroup.title === "Family" ||
            fieldGroup.title === "Emergency Contact"
          ) {
            formValues[title].forEach((sectionValues, index) => {
              const fieldValue =
                sectionValues[name] !== undefined ? sectionValues[name] : "";
              const error = validateFieldHelper(fieldValue, field);

              if (!sectionErrors[index]) {
                sectionErrors[index] = {};
              }

              sectionErrors[index][name] = error;

              if (error) {
                valid = false;
              }
            });
          } else {
            const fieldValue =
              formValues[title] && formValues[title][name] !== undefined
                ? formValues[title][name]
                : "";
            const error = validateFieldHelper(fieldValue, field);

            sectionErrors[name] = error;

            if (error) {
              valid = false;
            }
          }
        });

        newErrors[title] = sectionErrors;
      }
    });

    setErrors(newErrors);
    // setIsFormValid(valid);
    formAttempted(valid);
    // setIsNextTriggered(false);
    updateWizardData("StepTwo", formValues);
    return valid;
  };

  const renderFields = (section, index, field) => {
    const currentFormValues =
      section.title === "Family"
        ? formValues[section.title][index]
        : section.title === "Emergency Contact"
        ? formValues[section.title][index]
        : formValues[section.title];

    const currentErrors =
      section.title === "Family"
        ? errors[section.title][index]
        : section.title === "Emergency Contact"
        ? errors[section.title][index]
        : errors[section.title];

    return (
      <>
        {section.children?.map((field) => {
          // Check if children array exists
          let optionsData;
          if (Array.isArray(options[field.name])) {
            const isRequired = field.required &&
              field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

            if (isRequired) {
              optionsData = [
                // field.required &&
                //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                //     name: "Choose Option",
                //   },
                ...options[field.name],
              ];
            } else {
              optionsData = [...options[field.name]];
            }
          }

          const fieldOptions = Array.isArray(options[field.name])
            ? optionsData
            : [];

          let fieldComponent = null;

          switch (field.type) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.NUMBER:
            case INPUT_TYPE.PHONE_NUMBER:
            case INPUT_TYPE.POINT:
            case INPUT_TYPE.CURRENCY:
              fieldComponent = (
                <TextInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.TEXTAREA:
              fieldComponent = (
                <TextArea
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE:
              fieldComponent = (
                <SelectOne
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectOneAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE:
              fieldComponent = (
                <SelectMultiple
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectMultipleAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.CHECKBOX:
              fieldComponent = (
                <CheckboxGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.RADIO:
              fieldComponent = (
                <RadioGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  errors={currentErrors}
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.DATE:
              fieldComponent = (
                <DatePickerInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            // Add cases for other field types
            default:
              fieldComponent = null;
          }

          return (
            <Grid
              item
              xs={field.gridWidth || 12}
              key={field.name}
              sx={{ paddingTop: "unset !important" }}
            >
              {fieldComponent}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderSectionFields = (section) => {
    if (section.title === "Family" || section.title === "Emergency Contact") {
      // Render Family or Emergency Contact section
      return (
        <Grid item xs={12} key={section.title}>
          <Box sx={{ marginBottom: 2 }}>
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {section.title}
            </Typography>
          </Box>

          {formValues[section.title].map((rowValues, index) => (
            <div key={index}>
              <Grid container spacing={0.3}>
                <Grid item xs={11.3}>
                  <Grid container spacing={1}>
                    {renderFields(section, index)} {/* Pass rowIndex */}
                  </Grid>
                </Grid>
                <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                      ...removeButton,
                    }}
                    onClick={() => handleRemoveRow(section.title, index)}
                    disabled={formValues[section.title].length === 1}
                  >
                    <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                  </Button>
                </Grid>
              </Grid>
              {formValues[section.title].length > 1 && (
                <Divider sx={{ mt: 2, mb: 2 }} />
              )}
            </div>
          ))}
          <Box sx={{ ...justifyContentEnd }}>
            <Button onClick={() => handleAddRow(section.title)}>
              Add Another {section.title === "Family" ? "Member" : "Contact"}
            </Button>
          </Box>
        </Grid>
      );
    } else {
      // Render other sections using renderFields function
      return (
        <Grid item xs={12} key={section.title}>
          <Box sx={{ marginBottom: 2 }}>
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {section.title}
            </Typography>
            {/* Subtitle */}
            {section.subtitle && (
              <Typography variant="subtitle2">{section.subtitle}</Typography>
            )}
          </Box>
          <Grid container spacing={1}>
            {renderFields(section)}
          </Grid>
        </Grid>
      );
    }
  };

  const renderFormContent = () => {
    return (
      <Grid
        container
        spacing={1.5}
        sx={{
          overflowX: "auto",
          height: "52vh",
          // paddingBottom: "2rem",
        }}
        className="smooth-scroll"
      >
        {fields.map((section) => renderSectionFields(section))}
      </Grid>
    );
  };

  return <div>{renderFormContent()}</div>;
};

export default Step2_BPOM_MD;
