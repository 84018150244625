import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_REQUEST_SUCCESS,
  GET_REQUEST_FAILED,
  CHANGE_REQUEST_SUCCESS,
  CHANGE_REQUEST_FAILED,
} from "./state";
import { logout } from "../../../auth/clients/login/actions";

import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../util/function";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getRequest = ({
  token,
  page = 1,
  limit = 10,
  searchValue = null,
  searchBy = null,
  dateFilter = null,
  statusFilter = null,
  categoryFilter = null,
  sortDirection = null,
  sortBy = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "requests_recived",
          page,
          limit,
          searchValue,
          searchBy,
          dateFilter:
            dateFilter == ACTION_TYPE.DEFAULT_DATE ? null : dateFilter,
          categoryFilter:
            categoryFilter == ACTION_TYPE.ALL_CATEGORY ? null : categoryFilter,
          status: statusFilter == ACTION_TYPE.ALL_STATUS ? null : statusFilter,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getRequestSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getRequestSuccess([], null));
        } else {
          dispatch(getRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(getRequestFailed(error.message));
        }
      });
  };
};

const getRequestSuccess = (data, pagination) => {
  return {
    type: GET_REQUEST_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getRequestFailed = (errorMessage) => {
  return {
    type: GET_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const changeRequest = (token, formData, id, type) => {
  return async (dispatch) => {
    console.log(formData);
    dispatch(RequestLoading());
    axios
      .post(
        `requests_recived/change_status/${id}?type=${type}`,
        Object.assign({}, formData),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal(type === "Reject" ? "REJECTED" : "APPROVED", "request"));
          dispatch(changeRequestSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(RunModal("FAILED", `${response.data.message}`));
          dispatch(changeRequestFailed(response.data.message));
        } else {
          dispatch(RunModal("FAILED", `${response.data.message}`));
          dispatch(changeRequestFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(RunModal("FAILED", `${error.response.data.message}`));
            dispatch(changeRequestFailed(error.response.data.message));
          }
        } else {
          dispatch(RunModal("FAILED", `${error.message}`));
          dispatch(changeRequestFailed(error.message));
        }
      });
  };
};

const changeRequestSuccess = (message) => {
  return {
    type: CHANGE_REQUEST_SUCCESS,
    payload: message,
  };
};

const changeRequestFailed = (message) => {
  return {
    type: CHANGE_REQUEST_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
