import React, { useState, useEffect, isValidElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import "dayjs/locale/en";
import CalendarIcon from "./../../../assets/calendar.png";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import RemoveIcon from "../../../assets/minus.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
} from "../../../util/style";
import axios, { headersAPIToken } from "../../../core/axios_config";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
  SelectOneAutocompleteSearch,
} from "../../../components/Form";
import {
  addPerformaInvoice,
  deletePerformaInvoice,
  editPerformaInvoice,
  getDetailForEditProformaInvoice,
  getPerformaInvoice,
  setEditId,
} from "./clients/action";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  createEmptyErrors,
  localToUTC,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import LeftDrawer from "../../../components/LeftDrawer";
import {
  LOCAL_ACTION_TYPE,
  MODAL_STYLE,
  fieldDrawerForEach,
  field_drawer,
  field_modal,
  columns,
} from "./parts/imutable_state";
import { logout } from "../../auth/clients/login/actions";
import RenderFormContent from "./components/RenderFormContent";
// import FormModalClientComponent from "./components/form-client/ModalClient";
import ModalClient from "./components/form-client/ModalClient";
import { CLEAR_OPTION_KEY, SET_OPTION_KEY } from "./clients/state";
// import { fields, columns, ANOTHER_INPUT_TYPE } from "./parts/imutable_state";
// import FormContent from "./componens/FormContent";

//* Main function
function PerformaInvoice() {
  /* ============================================================================ */
  /*                                    TypeDef */
  /* ============================================================================ */

  /**@typedef {"CHOOSE STATUS"|"CHOOSE POSITION"|"CHOOSE TYPE STAGE"|"CHOOSE CATGEORY TIME OFF"|"CHOOSE ACTION"|"CHOOSE DATE"|"CHOOSE TYPE"|"ALL STATUS"|"ALL TYPE_STAGE"|"ALL_CATEGORY"|"ALL CATEGORY TIME OFF"|"ALL POSITION"|"ALL ACTION"|"ALL DATE"| "ALL TYPE"|"DELETED"|"DETAIL"|"EDITED"|"ADD"} actionTypes */

  /**
   * @typedef {import("react").BaseSyntheticEvent} EventType
   */

  /** @typedef {import("./clients/classType").FieldType} FieldType */
  /**@typedef {import("./clients/classType").ParentFieldType} ParentFieldType */

  /** import("./clients/classType").FieldType */

  const OPTION_MODE = {
    MODAL: "MODAL",
    DRAWER: "DRAWER",
  };

  const theme = useTheme();

  const dispatch = useDispatch();
  const useStyles = defaultStylePage;

  // const fetchOption = useFetchOption();
  const [searchParams, setSearchParam] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("code");
  const [type, setType] = useState(ACTION_TYPE.DEFAULT_TYPE);
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [title, setTitle] = useState("Performa Invoice");
  const [stage, setStage] = useState(ACTION_TYPE.DEFAULT_TYPE_STAGE);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(1);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [selectedData, setSelectedData] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [choosedStatus, setChoosedStatus] = useState(
    ACTION_TYPE.DEFAULT_STATUS || undefined
  );
  const [sortModel, setSortModal] = useState([]);
  const [actionType, setActionType] = useState(ACTION_TYPE.DEFAULT_ACTION);
  const [formModal, setFormModal] = useState(false);
  const [drawerModal, setDrawerModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [doneFetchingDataForm, setDoneFetchingDataForm] = useState(true);
  const [fetchingSubSevice, setFetchingSubService] = useState(false);
  const [isSelectCurrency, setIsSelectCurrency] = useState(false);
  const [convertPayment, setConvertPayment] = useState(false);
  //* Flaging for action edit
  const [isEdit, setIsEdit] = useState(false);

  const [modalType, setModalType] = useState(MODAL_STYLE.MODAL);
  const [options, setOptions] = useState({});
  const [choosedClient, setChoosedClient] = useState(0);
  // const [choosedCategory, setChoosedCategory] = useState(0);
  const [optionMode, setOptionMode] = useState("");
  const [selectedId, setSelectedId] = useState(0);

  const [formValues, setFormValues] = useState({});
  const [priceInformation, setPriceInformation] = useState({});
  // const [modalFormValue, setModalFormValue] = useState({});
  const [errors, setErrors] = useState({});

  //@ts-ignore
  const { token, user } = useSelector((state) => state.auth);

  //@ts-ignore
  const { modal: isActive } = useSelector((state) => state.global);
  const {
    data: rows,
    message,
    pagination,
    isLoading,
    edited_id,
    optionsKey,
    //@ts-ignore
  } = useSelector((state) => state.financePerformaInvoice);

  //* for edit
  const { data: editedData } = useSelector(
    //@ts-ignore
    (state) => state.financeProformaInvoiceDetail
  );

  const setOptionKey = (/**@type {string} */ payload) => {
    return {
      type: SET_OPTION_KEY,
      payload: payload,
    };
  };

  const clearOptionKey = () => {
    return {
      type: CLEAR_OPTION_KEY,
    };
  };

  useEffect(() => {
    if (formModal === false) {
      dispatch(clearOptionKey());
    }
  }, [formModal]);

  /**@param {'drawer'|'modal'} type */
  const initialFormValues = (type) => {
    let newValue = {};
    if (type === "modal") {
      //* Clear modal form Value
      field_modal.forEach((field) => {
        newValue[field.name] = field.defaultValue;
      });
    } else if (type === "drawer") {
      //* clear drawer form value
      fieldDrawerForEach(
        (
          /** @type {FieldType} */ field,
          index,
          array,
          /**@type {ParentFieldType} */ parentField
        ) => {
          console.log("This is is ", field);
          if (!field.multiple && !field.hidden) {
            newValue[field.name] = field.defaultValue;
          } else if (field.multiple && parentField.title === "Service") {
            newValue[parentField.multipleValueName] =
              initialSubServiceField("Form");
          }
        }
      );
      newValue[field_drawer.attachment.field.name] = initialAttachmentValue;
    }
    //* Set the value to formValues;
    // console.log(newValue, type, "this is initial value");
    return newValue;
    // setFormValues(newValue);
  };

  /**@param {'drawer'|'modal'} type */
  const intialErrorValues = (type) => {
    const newError = {};
    if (type === "modal") {
      field_modal.forEach((field) => {
        newError[field.name] = "";
      });
    } else {
      fieldDrawerForEach(
        (
          /**@type {FieldType} */ field,
          /**@type {number} */ index,
          /**@type {Array<any>} */ arr,
          /**@type {ParentFieldType} */ parentField
        ) => {
          if (!field.multiple && !field.hidden) {
            newError[field.name] = "";
          } else if (field.multiple && parentField.title === "Service") {
            newError[parentField.multipleValueName] =
              initialSubServiceField("Error");
          }
        }
      );
      newError[field_drawer.attachment.field.name] = initialAttachmentError;
    }
    setErrors(newError);
  };

  useEffect(() => {
    //* Initial mount set formValues to type modal
    resetFormValues("modal");
    const idSearchEdit = searchParams.get("edit");
    if (idSearchEdit) {
      const parsedId = parseInt(idSearchEdit);
      setSelectedId(parsedId);
    }
  }, []);

  /**@param {'Form'|'Error'} type */
  const initialSubServiceField = (type) => {
    let localForm = {};
    fieldDrawerForEach((/** @type {FieldType} */ field) => {
      if (field.multiple) {
        if (type === "Form") {
          localForm[field.name] = field.defaultValue;
        } else if (type === "Error") {
          localForm[field.name] = "";
        }
      }
    });
    return [localForm];
  };

  const initialAttachmentValue = [""];
  const initialAttachmentError = [""];

  /**@param {'drawer'|'modal'} type */
  const resetFormValues = (type) => {
    // initialFormValues(type);
    setFormValues(initialFormValues(type));
  };

  /**@param {'drawer'|'modal'} [type] */
  const resetErrorValues = (type = "modal") => {
    intialErrorValues(type);
  };

  const intialAttachment = () => {
    setFormValues((prev) => ({
      ...prev,
      [field_drawer.attachment.field.name]: initialAttachmentValue,
    }));
    setErrors((prev) => ({
      ...prev,
      [field_drawer.attachment.field.name]: initialAttachmentError,
    }));
  };

  useEffect(() => {
    /**
     *
     * @param {string} apiEndpoint - endpoint resource
     * @param {string} name - name of field
     */
    const fetchOption = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, headersAPIToken(token));
        if (response.status === 200) {
          let responseData = response.data.data;
          if (name === "sales_order_id") {
            responseData = responseData.map((res) => {
              res.name = res.code;
              return res;
            });
          }

          if (name === "bank_address_id" && responseData.length < 1) {
            responseData = [
              {
                id: 1,
                name: "[dummy]-123456789",
              },
              {
                id: 2,
                name: "[dummy]-987654321",
              },
            ];
          }
          setOptions((prev) => ({ ...prev, [name]: responseData }));
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        console.log(`Error while get option for ${name} :`, error);
        //!Development purpose
        setOptions((prev) => ({ ...prev, [name]: [] }));
      }
    };

    const ignoreList = ["sales_order_id"];
    if (optionMode === OPTION_MODE.DRAWER) {
      fieldDrawerForEach((/**@type {FieldType} */ field) => {
        const { apiEndpoint, name, options: localOption } = field;
        if (apiEndpoint && !ignoreList.includes(name)) {
          if (optionsKey === null || !optionsKey?.includes(name)) {
            dispatch(setOptionKey(name));
            fetchOption(apiEndpoint, name);
          }
          if (!options.hasOwnProperty(name)) {
            // console.log("pake property", name); Slow
          }
        } else if (localOption) {
          if (!options.hasOwnProperty(name)) {
            setOptions((prev) => ({ ...prev, [name]: localOption }));
          }
        }
      });
    }
  }, [options, optionMode]);

  useEffect(() => {
    const getSubserviceOption = async (
      /**@type {string} */ url,
      /**@type {string} */ fieldName
    ) => {
      try {
        const { data } = await axios.get(url, headersAPIToken(token));
        let payloadResponse = data.data;
        if (fieldName === "bank_address_id") {
          if (Array.isArray(payloadResponse) && payloadResponse.length > 0) {
            payloadResponse = payloadResponse.map((res) => {
              res.name = res["no_account"];
              return res;
            });
          } else {
            //! Development purpose for dummy
            const dummyBankAddressId = [
              {
                id: 1,
                name: "[dummy]-123456789",
              },
              {
                id: 2,
                name: "[dummy]-987654321",
              },
            ];
            payloadResponse = dummyBankAddressId;
          }
        }

        setOptions((prev) => ({ ...prev, [fieldName]: payloadResponse }));
        setFetchingSubService(false);
      } catch (error) {
        console.error(`Failed get sub service option : ${error}`);
      }
    };

    //* Watch for sub_service;
    if (!options.hasOwnProperty("service_id")) {
      const serviceId = formValues["service_id"];
      if (serviceId && !fetchingSubSevice) {
        getSubserviceOption(
          `/master/sub-services?main_service_id=${serviceId}`,
          "sub_service_id"
        );
        setFetchingSubService(true);
      } else {
        setFetchingSubService(false);
      }
    }

    const bankId = formValues["bank_id"];
    if (
      modalType === MODAL_STYLE.DRAWER &&
      bankId != 0 &&
      bankId !== "" &&
      bankId !== undefined
    ) {
      if (
        options.hasOwnProperty("bank_address_id") &&
        (!formValues["bank_address_id"] ||
          formValues["bank_address_id"] ===
            SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE)
      ) {
        getSubserviceOption(
          "master/bank-address?bank_id=".concat(bankId),
          "bank_address_id"
        );
      }
    }
  }, [formValues]);

  useEffect(() => {
    if (
      modalType === MODAL_STYLE.DRAWER &&
      actionType === LOCAL_ACTION_TYPE.ADD_STEP_TWO
    ) {
      setDoneFetchingDataForm(false);
      handleGetDetailForAdd();
    }
    console.log({ modalType, actionType });
  }, [modalType, actionType]);

  /**
   *
   * @param {actionTypes} typeAction
   */
  const handleFormModal = (typeAction) => {
    if (selectedData.length > 0 && typeAction === ACTION_TYPE.DELETED) {
      //* Delete action
      setTitle("Delete");
      setActionType(ACTION_TYPE.DELETED);
      setFormModal(true);
    } else if (
      selectedData.length > 0 &&
      selectedData.length < 2 &&
      typeAction === ACTION_TYPE.EDITED
    ) {
      //*Edit action
      handleEdit();
      resetErrorValues("drawer");
    } else {
      //* add action
      resetFormValues("modal");
      resetErrorValues("modal");
      setActionType(LOCAL_ACTION_TYPE.ADD_STEP_ONE);
      setTitle("Add Proforma Invoice");
      setFormModal(true);
    }
  };

  const formatStringToCurrency = (value) => {
    // Remove leading zeros, but allow a single "0"
    value = String(value);
    value = value.replace(/^0+(?=\d{0,})/, "0");

    // Use a regular expression to allow only digits
    value = value.replace(/\D/g, "");

    // Format the input value as a currency by adding commas every three digits
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  /**
   * @param {EventType} event
   */
  const handleInputChange = (event) => {
    //@ts-ignore
    let { name, value, checked } = event.target;
    if (name === "client_id") {
      setChoosedClient(value);
    }

    if (name === "payment_received" && formValues["payment_currency"]) {
      const currency = formValues["payment_currency"];
      value = currency.concat(". ", formatStringToCurrency(value));
    } else if (name === "payment_received" && !formValues["payment_currency"]) {
      value = formatStringToCurrency(value);
    }

    if (name === "payment_received_convert") {
      value = "Rp. ".concat(formatStringToCurrency(value));
    }

    if (name === "payment_currency") {
      if (value !== "" && value !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE) {
        setIsSelectCurrency(true);
        if (value) {
          setFormValues((prev) => {
            const newPayload = { ...prev };
            newPayload["payment_received"] = value.concat(". ");
            return newPayload;
          });
        }
      } else {
        setIsSelectCurrency(false);
      }
    }

    if (name === "convert_payment" && checked !== undefined) {
      setConvertPayment(checked);
      setFormValues((prev) => ({
        ...prev,
        convert: checked,
        payment_received_convert: checked ? "Rp. " : "",
      }));
      return;
    }

    if (name === "bank_id") {
      setFormValues((prev) => {
        const copy = { ...prev };
        //* Reset value of bang address id when bank_id is change
        if (copy["bank_address_id"]) {
          copy["bank_address_id"] = -1;
        }
        return copy;
      });
      setCredentialBank({ name: "", address: "", bank_address: "" });
    }

    if (name === "bank_address_id") {
      const pattern = /\b(?:dummy)\b/gi;
      const valOnOpt = options["bank_address_id"].find(
        (opt) => (opt.id = value)
      );
      if (valOnOpt) {
        if (pattern.test(valOnOpt.name)) {
          // console.log("this is dummy");
          setCredentialBank();
        } else {
          // console.log("real data");
          findBankCredential();
          console.log("masuk sini terus");
        }
      }
    }
    //* Validate
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const findBankCredential = () => {
    const bankId = formValues["bank_id"];
    axios
      .get(
        "/master/bank-address?bank_id=".concat(bankId),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status == 200 && response.data.status === true) {
          setCredentialBank(response.data.data[0]);
        }
      })
      .catch((err) => {
        console.error("Failed get bank credential", err);
      });
  };

  const setCredentialBank = (payload) => {
    const copyFormValues = {};
    if (payload) {
      //* real data
      copyFormValues["account_name"] = payload["name"] || "";
      copyFormValues["account_address"] = payload["address"] || "";
      copyFormValues["bank_address"] = payload["bank_address"] || "";
      console.log("masuk sini");
    } else {
      //* dummy data
      copyFormValues["account_name"] = "Tony Jhonson";
      copyFormValues["account_address"] = "Oklahoma No. 124";
      copyFormValues["bank_address"] = "Jakarta Pusat Jl. Mawar Baru";
    }
    setFormValues((prev) => ({ ...prev, ...copyFormValues }));
  };

  useEffect(() => {
    const fetchNoSalesOrder = async (clientId) => {
      setOptions((prev) => ({
        ...prev,
        no_sales_order: [],
      }));
      try {
        const response = await axios.get(
          //! Change API Endpoint
          "/master/sales-orders?client_id=".concat(clientId),
          headersAPIToken(token)
        );
        if (response.status === 200 && response.data.status === true) {
          const formatedPayload = response?.data?.data.map((so) => {
            so.name = so.code;
            return so;
          });
          setOptions((prev) => ({
            ...prev,
            sales_order_id: formatedPayload,
          }));
        }
      } catch (error) {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (error.response.status === 401) {
            dispatch(logout());
          } else {
            console.error(error);
          }
        } else {
          console.error(error);
        }
      }
    };

    if (choosedClient != 0) {
      fetchNoSalesOrder(choosedClient);
      console.log("Get sales orders");
    }
  }, [choosedClient]);

  //* =================================================================== *//
  //*                                 EDIT                                *//
  //* =================================================================== *//

  // const getDetailForEdit = () => {
  //   try {
  //     console.log(selectedId, edited_id, "id detail");
  //     if (selectedId == edited_id) {
  //       //@ts-ignore
  //       dispatch(getDetailForEditProformaInvoice(token, edited_id));
  //     } else {
  //       throw new Error("Invalid ID");
  //     }
  //   } catch (error) {
  //     console.error(error, "Failed to get detail for edit");
  //   }
  // };

  //* Get data to populate form
  useEffect(() => {
    if (
      (selectedId !== null || selectedId !== undefined || selectedId !== 0) &&
      edited_id !== null
    ) {
      handleEdit(edited_id);
    } else if (edited_id === null && selectedId !== 0) {
      // setSelectedId(0);
      searchParams.delete("edit", String(selectedId));
    }
  }, [selectedId, edited_id]);

  // useEffect(() => {
  //   if (for_edit === true && editedData) {
  //     //TODO: set value to formValues
  //   }
  // }, [editedData]);

  //* =================================================================== *//
  //*                              VALIDATE                               *//
  //* =================================================================== *//

  /**
   *
   * @param {string} name
   * @param {string} [type]
   */
  const validateField = (name, type = "") => {
    let valid = true;
    const newError = { ...errors };
    /**@type {object} */
    let parentFields;
    /**@type {FieldType | null} */
    let field = null;
    if (type) {
      parentFields = field_drawer[type].field;
      field = parentFields.find(
        (/** @type {FieldType} */ F) => F.name === name
      );
    } else {
      fieldDrawerForEach((/** @type {FieldType} */ fieldDrawer) => {
        if (fieldDrawer.name === name) {
          field = fieldDrawer;
        }
      });
    }

    /** ---------------------------------------------------- */
    const valueInForm = formValues[name];
    // console.log(valueInForm);
    /** @type {string} */
    let messageError = "";
    if (field) {
      let { defaultValue, validation, errorMessage, required, name } = field;
      const listExclude = [
        "payment_currency",
        "payment_received",
        "payment_received_convert",
      ];
      if (listExclude.includes(name)) {
        required = false;
      }
      if (
        required &&
        (valueInForm === "" ||
          valueInForm === undefined ||
          valueInForm === defaultValue ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL)
      ) {
        messageError =
          errorMessage || `Please enter ${field.label.toLowerCase()}`;
        newError[field.name] = messageError;
        valid = false;
        // console.log("masuk siniiiiiiiiiiiiiiiiiiiiiiiiiiii");
      }
      if (
        valueInForm &&
        valueInForm.length &&
        validation &&
        !validation(valueInForm)
      ) {
        // console.log("masuk validate");
        messageError = errorMessage || `${field.name.toLowerCase()} is invalid`;
        newError[field.name] = messageError;
        valid = false;
      } else {
        // console.log("gk ada yanf masuk");
        newError[field.name] = "";
      }
      setErrors(newError);
      // console.log(newError);
      // console.log(field);
      return [valid, messageError];
    }
  };

  //* =================================================================== *//
  //*                              HANDLER                                *//
  //* =================================================================== *//

  /**
   *
   * @param {EventType} event
   */
  const onFileSelectHandle = (event) => {};
  const handleSelectionChange = (selection) => {
    setSelectedData(selection);
  };

  const handleDoubleClickRow = (e) => {
    const rowId = e?.id;
    const currentPath = location.pathname;
    navigate(currentPath.concat("/detail/", rowId));
  };

  /**
   *
   * @param {EventType} event
   * @param {object} parentField
   */
  const handleInputBlur = (event, parentField) => {
    const { name } = event.target;
    const { type } = parentField;
    validateField(name, type);
  };
  const handleCloseFormModal = () => {
    //* set modal cofirm true;
    setConfirmModal(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      setFormModal(false);
    } else {
      if (validateForm()) {
        if (actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
          dispatch(setEditId(null));
          setSearchParam({});
          resetFormValues("modal");
          dispatch(clearOptionKey());
        } else if (actionType === LOCAL_ACTION_TYPE.ADD_STEP_ONE) {
          switchToDrawerForm();
          resetErrorValues("drawer");
          resetFormValues("drawer");
        } else if (actionType === LOCAL_ACTION_TYPE.ADD_STEP_TWO) {
          handleAddRow();
        }
      } else {
        console.log("form invalid");
        console.log({ errors, formValues, actionType }, "ini errors");
      }
    }
  };

  const handleConfirmModalCancel = () => {
    setConfirmModal(false);
  };

  const handleConfirmModalYes = () => {
    setConfirmModal(false);
    if (modalType === MODAL_STYLE.MODAL) {
      setFormModal(false);
    } else {
      setDrawerModal(false);
      setModalType(MODAL_STYLE.MODAL);
      resetFormValues("modal");
      resetErrorValues("modal");
      dispatch(setEditId(null));
      searchParams.delete("edit");
    }
    setActionType(ACTION_TYPE.DEFAULT_ACTION);
    setChoosedClient(0);
    setSearchParam({});
    setSelectedData([]);
  };

  const switchToDrawerForm = () => {
    handleGetDetailForAdd();
    setActionType(LOCAL_ACTION_TYPE.ADD_STEP_TWO);
    setOptionMode(OPTION_MODE.DRAWER);
    setModalType(MODAL_STYLE.DRAWER);
    setFormModal(false);
    setDrawerModal(true);
  };

  const handleAddFile = (fieldName) => {};
  const handleRemoveFile = (fieldName, index) => {};
  const handleAddSubservice = () => {};
  const handleRemoveSubservice = () => {};

  const validateForm = () => {
    let newError = { ...errors };
    let valid = true;
    if (actionType === LOCAL_ACTION_TYPE.ADD_STEP_ONE) {
      field_modal.forEach((field) => {
        const { name, required, errorMessage, label, defaultValue } = field;
        let valueInForm = formValues[name];
        if (
          required &&
          (valueInForm === "" ||
            valueInForm === undefined ||
            valueInForm === defaultValue)
        ) {
          let msgError =
            errorMessage || `Please enter ${String(label).toLowerCase()}`;
          newError[name] = msgError;
          valid = false;
        }
      });
    }
    if (actionType === LOCAL_ACTION_TYPE.ADD_STEP_TWO) {
      const listIgnoreField = [
        "payment_currency",
        "payment_received",
        "payment_received_convert",
      ];
      fieldDrawerForEach((/** @type {FieldType} */ fieldDrawer) => {
        let { name, required, defaultValue, label } = fieldDrawer;
        if (listIgnoreField.includes(name)) {
          required = false;
        }
        let valueInForm = formValues[name];
        if (required) {
          //@ts-ignore
          const [returnValid, returnErrorsMsg] = validateField(name);
          valid = returnValid;
          newError[name] = returnErrorsMsg;
        }
        if (Array.isArray(valueInForm)) {
          // console.log(name, "array value");
        } else {
          // console.log(name, "normal value");
        }
      });
    }
    setErrors(newError);
    return valid;
  };

  const handleEdit = async (proformaId) => {
    let data;
    if (proformaId) {
      data = rows.find((row) => row?.id == proformaId);
    } else {
      data = rows.find((row) => row?.id === selectedData[0]);
    }
    if (data) {
      setActionType(ACTION_TYPE.EDITED);
      setIsEdit(true);
      dispatch(clearOptionKey());
      setModalType(MODAL_STYLE.DRAWER);
      setOptionMode(OPTION_MODE.DRAWER);
      setTitle("Edit Proforma Invoice");
      setDrawerModal(true);
      const { id } = data;
      setSelectedId(id);
      getPayloadEdit(data);
    }
  };

  const setAddPayloadToFormValues = (responseApi) => {
    const listFormInfotmation = [
      "discount_info_to_rp",
      "price_services_info",
      "price_services_info_to_rp",
      "total_price_info_to_rp",
      "vat_info_to_rp",
    ];
    //* Set Price information

    setPriceInformation((prev) => {
      let newVal = { ...prev };
      listFormInfotmation.forEach((el) => {
        const formatedValue = String(responseApi["Service"]["currency"]).concat(
          ". ",
          formatStringToCurrency(String(responseApi["Price Information"][el]))
        );
        newVal[el] =
          el === "price_services_info"
            ? responseApi["Price Information"][el]
            : formatedValue;
      });
      return newVal;
    });
    let newFormValues = { ...formValues };

    // console.log({ SO: responseApi });
    newFormValues["client_id"] = responseApi["Client"]["client_id"];
    // newFormValues["sub_services"] = responseApi["Sub Service"].map(());
    newFormValues["service_id"] = responseApi["Service"]["service_id"];
    newFormValues["service_currency"] = responseApi["Service"]["currency"];
    newFormValues["sales_order_id"] = responseApi["id"];
    newFormValues["attachment"] = responseApi["Attachment"]["attachment"];
    // newFormValues["note"] = responseApi["Note Section"]["note"];
    newFormValues["remark"] = responseApi["Note Section"]["remarks"];

    if (responseApi["Sub Service"].length > 0) {
      newFormValues["sub_services"] = responseApi["Sub Service"].map((item) =>
        manipulatedSubService(item, responseApi["Service"]["currency"])
      );
    }
    getSpecificOpt(
      "master/sub-services?main_service_id=".concat(
        newFormValues["service_id"]
      ),
      "sub_service_id",
      "code"
    )
      .then(() => {
        console.log("Done get sub service options");
      })
      .catch((error) => {
        // console.log(`Failed to get options`);
      });
    return newFormValues;
  };

  const handleGetDetailForAdd = async (SOId) => {
    setDoneFetchingDataForm(false);
    try {
      let salesOrderId;
      if (SOId) {
        salesOrderId = SOId;
      } else {
        salesOrderId = formValues["sales_order_id"]?.split("/")[0];
      }
      if (!salesOrderId) {
        throw new Error("sales order id is reqeuire");
      }

      const response = await axios.get(
        `sales-orders/${salesOrderId}/detail-for-edit`,
        headersAPIToken(token)
      );
      const { data } = response?.data;
      const newFormValues = setAddPayloadToFormValues(data);
      setFormValues(newFormValues);
      setDoneFetchingDataForm(true);
    } catch (error) {
      console.error(`Failed get data : ${error}`);
    }
  };

  const getPayloadEdit = async (detailData, detailId) => {
    try {
      setDoneFetchingDataForm(false);
      let idDocument = 0;
      if (detailData) {
        const { id } = detailData;
        idDocument = id;
      } else if (detailId) {
        idDocument = detailId;
      }

      let localUri = "proforma-invoices/".concat(String(idDocument), "/edit");
      const { data: payloadForEdit } = await axios.get(
        localUri,
        headersAPIToken(token)
      );
      const salesOrderId = payloadForEdit?.data["sales_order_id"];

      const payloadSalesOrder = await axios.get(
        `sales-orders/${salesOrderId}/detail-for-edit`,
        headersAPIToken(token)
      );

      const newFormValues = setPayload(
        payloadSalesOrder?.data?.data,
        payloadForEdit.data
      );
      await getSpecificOpt(
        "/master/bank-address?bank_id=".concat(newFormValues["bank_id"]),
        "bank_address_id",
        "no_account"
      );
      setFormValues(newFormValues);
      setDoneFetchingDataForm(true);
    } catch (error) {
      console.error("Failed get paylaod for edit: ", error);
    }
  };

  const getSpecificOpt = async (apiEndopint, name, aliasName) => {
    try {
      const response = await axios.get(apiEndopint, headersAPIToken(token));
      if (response.status == 200 && response.data.status == true) {
        let payload = response.data.data;
        if (aliasName) {
          payload = payload.map((val) => {
            val["name"] = val[aliasName];
            return val;
          });
        }
        setOptions((prev) => ({ ...prev, [name]: payload }));
      }
    } catch (error) {
      console.error(`Failed get option of ${name}: ${error.message}`);
      throw error;
    }
  };

  const setPayload = (payloadSO, payloadEdit) => {
    const newFormValues = { ...formValues };
    Object.keys(newFormValues).forEach((key) => {
      newFormValues[key] = payloadEdit[key];
    });
    newFormValues["account_address"] = payloadEdit["bank"]["account_address"];
    newFormValues["account_name"] = payloadEdit["bank"]["account_address"];
    newFormValues["bank_address"] = payloadEdit["bank"]["bank_address"];
    newFormValues["bank_address_id"] = payloadEdit["bank"]["bank_address_id"];
    newFormValues["bank_id"] = payloadEdit["bank"]["bank_id"];
    newFormValues["payment_currency"] = payloadEdit["bank"]["currency"];
    newFormValues["no_reference"] = payloadEdit["no_reference"];
    newFormValues["status"] = payloadEdit["status"];
    newFormValues["created_at"] = payloadEdit["created_at"];
    newFormValues["note"] = payloadEdit["note"];
    newFormValues["sales_order_id"] = payloadEdit["sales_order_id"];
    newFormValues["bill_to"] = payloadEdit["bill_to"];
    newFormValues["attend"] = payloadEdit["attend"];
    newFormValues["bill_address"] = payloadEdit["bill_address"];
    newFormValues["attachment"] = [];
    setIsSelectCurrency(true);

    const stringPaymentReceive = formatStringToCurrency(
        String(payloadEdit["bank"]["payment_received"])
      ),
      stringPaymentConvert = formatStringToCurrency(
        String(payloadEdit["bank"]["payment_received_convert"])
      );

    newFormValues["payment_received"] = String(
      payloadEdit["bank"]["currency"]
    ).concat(". ", stringPaymentReceive);

    if (payloadEdit["bank"]["payment_received_convert"]) {
      newFormValues["payment_received_convert"] = "Rp. ".concat(
        stringPaymentConvert
      );
      newFormValues["convert"] = true;
      setConvertPayment(true);
    } else {
      setConvertPayment(false);
      newFormValues["convert"] = false;
    }

    const addFormValue = setAddPayloadToFormValues(payloadSO);
    setChoosedClient(addFormValue["client_id"]);
    return Object.assign({}, addFormValue, newFormValues);
  };

  const manipulatedSubService = (subService, currency) => {
    const newPayload = {
      sub_service_id: subService["sub_service_id"],
      criteria_id: subService["criteria_id"],
      // criteria_id: 12,
      // subtotal: subService["subtotal"],
      discount: subService["discount"],
      vat_to_rp: subService["vat_to_rp"],
      description: subService["desc"],
      vat_percent: subService["vat_percent"],
    };

    if (Array.isArray(newPayload["vat_percent"])) {
      newPayload["vat_percent"] = subService["vat_percent"][0];
    }

    newPayload["discount"] = String(subService["discount"])
      .padEnd(8, " ")
      .concat("%");

    ["subtotal", "total_to_rp", "vat_to_rp"].forEach((key) => {
      newPayload[key] = String(currency).concat(
        ". ",
        String(formatStringToCurrency(subService[key]))
      );
    });

    // console.log("this is payload subserive", newPayload);
    return newPayload;
  };
  //* =================================================================== *//
  //*                          ACTION HANDLER                             *//
  //* =================================================================== *//

  const handleAddRow = () => {
    const maniputaltedPayload = manipulatePayload();
    // console.log(maniputaltedPayload, "payload to send");
    setDoneFetchingDataForm(false);
    //@ts-ignore
    dispatch(addPerformaInvoice(token, maniputaltedPayload)).then(() => {
      setDrawerModal(false);
      resetFormValues("modal");
      dispatch(clearOptionKey());
      resetErrorValues("modal");
      setDoneFetchingDataForm(true);
      setChoosedClient(0);
    });
  };

  const stringCurrencyToNumber = (value, currency) => {
    //* Substring for remove currency in the begining
    if (currency) {
      value = value.substring(currency.length + 2);
    } else {
      value = value.substring(4);
    }

    //* Remove comas for parse int;
    if (value.includes(",")) {
      value = value.replace(/,/g, "");
    }

    return parseInt(value);
  };

  const manipulatePayload = () => {
    // const formatedPayemntReceive = stringCurrencyToNumber(
    //   formValues["payment_received"],
    //   formValues["payment_currency"]
    // );
    // let fortmatedConvertPayment = null;
    // if (formValues["payment_received_convert"]) {
    //   fortmatedConvertPayment = stringCurrencyToNumber(
    //     formValues["payment_received_convert"],
    //     null
    //   );
    // }

    // const convertCurrency = "Rp";
    const newPayload = {
      no_reference: formValues["no_reference"] || null,
      client_id: formValues["client_id"],
      sales_order_id: formValues["sales_order_id"],
      bill_to: formValues["bill_to"],
      attend: formValues["attend"],
      bill_address: formValues["bill_address"],
      note: formValues["note"],
      banks: {
        account_name: formValues["account_name"],
        account_address: formValues["account_address"],
        bank_address: formValues["bank_address"],
        currency: formValues["payment_currency"],
        bank_id: formValues["bank_id"],
        bank_address_id: formValues["bank_address_id"],
        // payment_received: formatedPayemntReceive,
        //* empty string if add method
        // currency_convert: convertCurrency,
        // payment_received_convert: fortmatedConvertPayment,
      },
    };
    if (formValues["status"]) {
      newPayload["status"] = formValues["status"];
    }
    return newPayload;
  };

  const handleEditRow = () => {
    const manipulated = manipulatePayload();
    // console.log(selectedId);
    //@ts-ignore
    setDoneFetchingDataForm(false);
    dispatch(editPerformaInvoice(token, manipulated, selectedId)).then(() => {
      setDrawerModal(false);
      resetErrorValues("modal");
      resetFormValues("modal");
      setDoneFetchingDataForm(true);
      setChoosedClient(0);
      setSelectedId(0);
    });
  };
  const handleDeleteRow = () => {
    let ids = rows
      //@ts-ignore
      .filter((val) => selectedData.includes(val?.id))
      ?.map((val) => val.id);
    //@ts-ignore
    dispatch(deletePerformaInvoice(token, ids));
    setSelectedData([]);
  };

  const handleSortModalChange = (newSortModel) => {
    setSortModal(newSortModel);
  };

  //* =================================================================== *//
  //*                              COMPONENT                              *//
  //* =================================================================== *//

  const modalFormComponent = () => {
    if (actionType === ACTION_TYPE.DELETED && selectedData.length > 0) {
      return <ConfirmDelete userDeleted={"proforma invoice"} />;
    }
    return (
      <ModalClient
        choosedClient={choosedClient}
        errors={errors}
        handleChange={handleInputChange}
      />
    );
  };

  const renderForm = () => (
    <RenderFormContent
      actionType={actionType}
      errors={errors}
      priceInformation={priceInformation}
      options={options}
      formValues={formValues}
      onFileSelect={onFileSelectHandle}
      handleInputBlur={handleInputBlur}
      handleInputChange={handleInputChange}
      handleAddFile={handleAddFile}
      handleRemoveFile={handleRemoveFile}
      handleAddSubservice={handleAddSubservice}
      handleRemoveSubservice={handleRemoveSubservice}
      showPaymanetReceive={isSelectCurrency}
      canConvert={convertPayment}
    />
  );

  const renderActionButtons = () => {
    // console.log(actionType, "action type nya");
    return (
      <Box>
        {actionType !== ACTION_TYPE.ADD ? (
          <>
            <Button
              variant="outlined"
              sx={{ ...btnWhite }}
              onClick={handleCloseFormModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              type="submit"
            >
              {actionType === ACTION_TYPE.DELETED
                ? "Yes"
                : actionType === LOCAL_ACTION_TYPE.ADD_STEP_ONE
                ? "Get It"
                : "Save"}
            </Button>
          </>
        ) : (
          <>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              sx={{ ...btnWhite }}
              onClick={handleCloseFormModal}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              disableElevation
              type="submit"
              //   onClick={() => handleFormModal(ACTION_TYPE.EDITED, detailRow)}
            >
              Edit
            </Button>
          </>
        )}
      </Box>
    );
  };

  /** ============================================================= */
  /**                            PAGING                             */
  /** ============================================================= */
  const handlePageChange = (paramPage) => {
    setPage(paramPage + 1);
    setPageDB(paramPage);
    _getByPage(paramPage + 1);
  };
  const handleLimitChange = (paramLimit) => {
    setPage(1);
    setPageDB(1);
    setLimit(paramLimit);
    _getByLimit(paramLimit);
  };
  function _search() {
    //TODO: Get by search value;
    if (search !== "") {
      setChoosedStatus(ACTION_TYPE.ALL_STATUS);
      setStage("SEARCH");
    } else {
      setStage(ACTION_TYPE.DEFAULT_TYPE_STAGE);
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
    }
    dispatch(
      //@ts-ignore
      getPerformaInvoice({
        token,
        search,
        category: type,
        limit,
        page,
        sortBy,
        sortDirection,
        status,
        searchBy,
      })
    );
  }

  function _status(paramStatus) {
    setStatus(paramStatus);
    if (paramStatus === ACTION_TYPE.DEFAULT_STATUS) {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
      return;
    }
    setChoosedStatus(paramStatus);
    dispatch(
      getPerformaInvoice({
        token,
        page,
        limit,
        search,
        searchBy,
        sortBy,
        sortDirection,
        category: type,
        status: paramStatus,
      })
    );
  }

  function _type(paramType) {
    setType(paramType);
    if (paramType === ACTION_TYPE.DEFAULT_TYPE) {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
      return;
    }
    setChoosedStatus(ACTION_TYPE.ALL_STATUS);
    dispatch(
      getPerformaInvoice({
        token,
        page,
        limit,
        search,
        searchBy,
        sortBy,
        sortDirection,
        category: paramType,
        status,
      })
    );
  }

  function _getByPage(newPage) {
    //TODO: get by page;
    dispatch(
      //@ts-ignore
      getPerformaInvoice({
        token,
        search,
        category: type,
        limit,
        page: newPage,
        sortBy,
        sortDirection,
        status,
        searchBy,
      })
    );
  }

  function _getByLimit(newLimit) {
    //TODO: get by limit;
    dispatch(
      //@ts-ignore
      getPerformaInvoice({
        token,
        search,
        category: type,
        limit: newLimit,
        page,
        sortBy,
        sortDirection,
        status,
        searchBy,
      })
    );
  }
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };

  useEffect(() => {
    //TODO: dispatch
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        //@ts-ignore
        getPerformaInvoice({
          token,
          search,
          category: type,
          limit,
          page,
          sortBy,
          sortDirection,
          status,
          searchBy,
        })
      );
    }
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (!search) {
      setStage("SEARCH");
      setChoosedStatus(ACTION_TYPE.ALL_STATUS);
      _search();
    } else {
      setStage(ACTION_TYPE.DEFAULT_TYPE_STAGE);
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
    }
  }, [search]);

  useEffect(() => {
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive && status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        //@ts-ignore
        getPerformaInvoice({
          token,
          search,
          category: type,
          limit,
          page,
          sortBy,
          sortDirection,
          status,
          searchBy,
        })
      );
    }
  }, [isActive]);

  // /**
  //  *
  //  * @param {string | null} type
  //  * @param {string | null} status
  //  */
  // const fetchForStatusAndType = (type, status) => {
  //   setChoosedStatus(ACTION_TYPE.ALL_STATUS);
  //   dispatch(
  //     //@ts-ignore
  //     getPerformaInvoice({
  //       token,
  //       search,
  //       category: type,
  //       limit,
  //       page,
  //       sortBy,
  //       sortDirection,
  //       status,
  //       searchBy,
  //     })
  //   );
  // };

  // useEffect(() => {
  //   let currentStatus = ACTION_TYPE.DEFAULT_STATUS;
  //   let currentCat = null;
  //   if (type !== ACTION_TYPE.DEFAULT_TYPE) {
  //     currentCat = type;
  //   }
  //   if (status !== ACTION_TYPE.DEFAULT_STATUS) {
  //     currentStatus = status;
  //     setChoosedStatus(status);
  //   } else {
  //     setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
  //   }
  //   // console.log(currentStatus, currentCat);
  //   fetchForStatusAndType(currentCat, currentStatus);
  // }, [type, status]);

  useEffect(() => {
    const getCagetory = async () => {
      try {
        const response = await axios.get(
          "/master/services",
          headersAPIToken(token)
        );
        if (response.status === 200 && response.data.status === true) {
          let payload = [];
          if (Array.isArray(response.data.data)) {
            payload = response.data.data;
          }
          setListCategories(payload);
        }
      } catch (error) {
        console.error(`Failed get category : ${error}`);
      }
    };

    if (listCategories.length < 1) {
      getCagetory();
    }
  }, [listCategories]);

  /** ============================================================= */
  /**                             DEBUGGER                          */
  /** ============================================================= */

  // useEffect(() => {
  //   console.log(actionType, "action type");
  // }, [actionType]);

  useEffect(() => {
    console.log("this is form values", formValues);
  }, [formValues]);

  useEffect(() => {
    console.log("this is errors", errors);
  }, [errors]);

  useEffect(() => {
    console.log("this is options", options);
  }, [options]);

  // useEffect(() => {
  //   console.log(formModal, actionType, ";siiiidasdasd");
  // }, [formModal, actionType]);

  // useEffect(() => {
  //   console.log({ choosedStatus, rows, stage });
  // }, [choosedStatus, rows, stage]);

  useEffect(() => {
    setSelectedData([]);
  }, []);

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder={`Search by ${
                  searchBy === "code" ? "no proforma invoice" : "client name"
                }`}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={SearchIconBlack}
                        style={{ width: "20px", margin: " 0 8px 0 0" }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      SelectProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      sx={{
                        borderRadius,
                      }}
                      value={searchBy}
                      sx={{
                        "& fieldset": {
                          border: "unset",
                        },
                        borderLeft: `2px solid #ddd`,
                        width: "14rem",
                      }}
                      onChange={(e) => setSearchBy(e.target.value)}
                    >
                      <MenuItem value="code">No. Proforma Invoice</MenuItem>
                      <MenuItem value="client_name">Client Name</MenuItem>
                    </TextField>
                  ),
                  // onKeyDown: handleKeyPress,
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={type}
                onChange={(e) => _type(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_TYPE}>
                  Select category
                </MenuItem>
                {/* TODO: category dari server */}
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Category</MenuItem>
                {listCategories.map((item) => (
                  <MenuItem value={item?.id}>{item?.name}</MenuItem>
                ))}
                {/* <MenuItem value="2">Copyright</MenuItem>
                <MenuItem value="3">Legal Service</MenuItem>
                <MenuItem value="4">Industrial Design</MenuItem>
                <MenuItem value="5">Patent</MenuItem>
                <MenuItem value="6">Trademark</MenuItem> */}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                {/* <MenuItem value="Rejected">Partial</MenuItem> */}
                {/* <MenuItem value="Received">Paid</MenuItem> */}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Performa Invoice"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            //@ts-ignore
            search={search}
            statusChoosed={choosedStatus}
            // typeStageChoosed={stage}
            sortModel={sortModel}
            onSortModelChange={handleSortModalChange}
            // userAccess={userAccess}
            handleRowDoubleClick={handleDoubleClickRow}
          />
        </CardContent>
      </Card>

      {actionType !== ACTION_TYPE.DETAIL && (
        <>
          <LeftDrawer open={drawerModal}>
            {doneFetchingDataForm ? (
              <Box>
                <Form
                  title={title}
                  onCloseModal={handleCloseFormModal}
                  elementForm={renderForm}
                  elementActionButton={renderActionButtons}
                  onSubmit={handleSubmit}
                  workingWithDrawer={true}
                  elementOtherActionButton={null}
                />
              </Box>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <FacebookCircularProgress />
              </div>
            )}
          </LeftDrawer>
          <Modal open={formModal} onClose={() => handleCloseFormModal()}>
            <Box>
              <Form
                title={title}
                onCloseModal={handleCloseFormModal}
                elementForm={modalFormComponent}
                elementActionButton={renderActionButtons}
                onSubmit={handleSubmit}
                elementOtherActionButton={null}
                workingWithDrawer={false}
              />
            </Box>
          </Modal>
        </>
      )}
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleConfirmModalCancel}
        onCloseModal={handleConfirmModalYes}
        children={null}
        message={""}
      ></ModalConfirmCancel>
    </>
  );
}

export default PerformaInvoice;
