import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "../../../core/axios_config";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "./../../../assets/back.svg";
import DocumentActive from "./../../../assets/document_active.svg";
import DocumentNonActive from "./../../../assets/document_nonactive.svg";
import InfoIcon from "./../../../assets/info-circle.svg";
import {
  FacebookCircularProgress,
  StatusBadge,
  borderRadius,
  mainColor,
  warningSurfaceColor,
  warningMainColor,
  successSurfaceColor,
  successMainColor,
  dangerSurfaceColor,
  dangerMainColor,
  btnWhite,
} from "../../../util/style";
import Form, { TextArea } from "../../../components/Form";
import { useDispatch } from "react-redux";
import { INPUT_TYPE } from "../../../util/function";
import { changeRequest } from "./clients/actions";
import { useSelector } from "react-redux";

const DetailRequestRecipient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [title, setTitle] = useState("Detail Request");
  const [modalTitle, setModalTitle] = useState("");
  const [status, setStatus] = useState("");
  const [noteReject, setNoteReject] = useState(null);
  const [requestInfo, setRequestInfo] = useState([]);
  const [serviceInfo, setServiceInfo] = useState([]);
  const [attachInfo, setAttachInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formModal, setFormModal] = useState(false);
  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);
  const [actionType, setActionType] = useState("");
  const formRef = useRef(null);
  const [formValues, setFormValues] = useState(
    Object.assign({}, { note_reject: null })
  );
  const [errors, setErrors] = useState({
    note_reject: "",
  });

  useEffect(() => {
    let name = "";
    if (state && state.type) {
      name = `${state.type}`
    }
    setTitle(`Detail Request ${name}`)
    if (id) {
      axios
        .get(`requests_recived/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data.data;
          const requestNumber = { request_number: data.request_code.replace("-", "") };
          const requestDate = { request_date: formatDate(data.request_date) };
          const returnDate = { return_date: formatDate(data.return_date) };
          const customerName = { customer_name: data.customer_name };
          const note = { note: data.note ?? "-" };
          // const approvalLine = { approval_line: data.approval_line.fullname };
          const updateRequestInfo = [
            ...requestInfo,
            requestNumber,
            requestDate,
            returnDate,
            customerName,
            note,
            // approvalLine,
          ];
          const services = [];
          data.get_archive_service.map((e) => {
            services.push({
              service_category_name: e.get_service_category.name,
              sub_service_category_name: e.get_sub_service_category.name,
              reference_number: e.reference_number,
              registration_number: e.registration_number,
              applicant_name: e.applicant.name,
              brand_name: e.brand.name,
              cupboard_name: e.get_archive_detail.get_cupboard.cupboard_name,
              rack_number: e.get_archive_detail.get_rack.rack_number,
            });
          });
          // const attachs = [];
          // data.get_attachment.map((e) => {
          //   attachs.push({
          //     is_checked: e.is_checked,
          //     desc: e.desc,
          //   });
          // });
          const attachs = transformAttachFile(data.get_attachment);
          attachs.reverse()
          const newFormValues = {
            ...formValues,
            ["archive_id"]: data.archive_id,
            ["customer_id"]: data.customer_id,
            ["customer_name"]: data.customer_name,
          };
          setFormValues(newFormValues);
          setRequestInfo(updateRequestInfo);
          setStatus(data.status);
          setNoteReject(data.note_reject);
          setServiceInfo(services);
          setAttachInfo(attachs);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching sub-service categories:", error);
        });
    }
  }, []);

  function transformAttachFile(attachment_list) {
    const transformedList = {};
    attachment_list.forEach((attachment) => {
      const {
        service_category_name,
        sub_service_category_name,
        id,
        is_checked,
        desc,
        created_at,
        updated_at,
        status_loan,
      } = attachment;
      const key = `${service_category_name}_${sub_service_category_name}`;

      if (!transformedList[key]) {
        transformedList[key] = {
          service_category_name,
          sub_service_category_name,
          data: [],
        };
      }

      transformedList[key].data.push({
        id,
        is_checked,
        desc,
        created_at,
        updated_at,
        status_loan,
      });
    });
    const result = Object.values(transformedList);
    return result;
  }

  function keyObj(fieldName) {
    return fieldName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = date.toLocaleDateString("id-ID", options);

    return formattedDate;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleInputBlur = (e) => {
    const { name } = e.target;
    validateField(name);
  };

  const validateField = (fieldName, serviceIndex) => {
    const newErrors = { ...errors };
    if (fieldName === "typw") {
      newErrors.date = !formValues.date
        ? "Reason for Reject is required"
        : null;
    }
    setErrors(newErrors);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };
    if (!formValues.note_reject) {
      newErrors.note_reject = "Reason for Reject is required";
      valid = false;
    } else {
      newErrors.note_reject = null;
    }
    setErrors(newErrors);
    return valid;
  };

  const renderFormContent = () => {
    if (actionType === "reject") {
      return (
        <Box>
          <TextArea
            key={"note_reject"}
            field={{
              name: "note_reject",
              type: INPUT_TYPE.TEXTAREA,
              placeholder: "Input reason for your project",
              label: "Reason for Reject",
              defaultValue: "",
              required: true,
            }}
            formValues={formValues}
            errors={errors}
            handleInputChange={handleInputChange}
            handleInputBlur={handleInputBlur}
          />
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#757575", fontWeight: "700" }}>
            Confirm your approval
          </Typography>
          <Typography sx={{ color: "#757575", fontStyle: "italic" }}>
            By clicking the approve button you approve the request
          </Typography>
        </Box>
      );
    }
  };

  const renderActionModalButtons = () => {
    return (
      <Box>
        <Button
          variant="outline"
          sx={{ ...btnWhite }}
          onClick={handleCloseFormModal}
        >
          Cancel
        </Button>
        <Button color="info" variant="contained" onClick={handleSubmit}>
          {actionType === "reject" ? "Save" : actionType}
        </Button>
      </Box>
    );
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          color="error"
          variant="contained"
          sx={{ marginRight: "16px" }}
          onClick={() => {
            setModalTitle("Confirm Reject");
            setActionType("reject");
            setFormModal(true);
          }}
        >
          Reject
        </Button>
        <Button
          color="info"
          variant="contained"
          onClick={() => {
            setModalTitle("Confirm Approve");
            setActionType("Approve");
            setFormModal(true);
          }}
        >
          Approve
        </Button>
      </Box>
    );
  };

  const handleCloseFormModal = () => {
    setActionType("");
    setFormModal(false);
    setFormValues(Object.assign({}, { note_reject: "" }));
    setErrors({ note_reject: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(id);
    if (actionType === "reject") {
      const isValid = validateForm();
      if (isValid) {
        handleCloseFormModal();
        navigate(-1);
        dispatch(changeRequest(token, formValues, id, "Reject"));
      }
    } else {
      handleCloseFormModal();
      navigate(-1);
      dispatch(changeRequest(token, null, id, "Approve"));
    }
  };
  return (
    <>
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "81vh",
          position: "relative",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ marginRight: ".5rem" }}
              >
                <img src={ArrowBackIcon} />
              </IconButton>
              {title}
            </div>
          }
        />
        <Divider />
        {isLoading ? (
          <FacebookCircularProgress />
        ) : (
          <>
            <CardContent sx={{ padding: "1.7rem" }}>
              <Box display={"flex"} sx={{ gap: "1.5rem" }}>
                <Box flex={1} sx={{ overflow: "auto", maxHeight: "480px" }}>
                  <Box display={"flex"} sx={{ marginY: "16px" }}>
                    <Box flex={1}>
                      <Typography variant="h6">Request Information</Typography>
                    </Box>
                    <Box>
                      <StatusBadge
                        key={0}
                        status={status.name}
                        bgColor={status.bg_code}
                        colors={status.color_code} >
                        {status.name}
                      </StatusBadge>
                    </Box>
                  </Box>
                  {requestInfo.map((request) => (
                    <Box display={"flex"} sx={{ marginY: "16px" }}>
                      <Box flex={1}>
                        <Typography>
                          {keyObj(`${Object.keys(request)}`)}
                        </Typography>
                      </Box>
                      <Box flex={1}>
                        <Typography>{Object.values(request)}</Typography>
                      </Box>
                    </Box>
                  ))}
                  <Typography variant="h6" sx={{ marginY: "24px" }}>
                    Service Information
                  </Typography>
                  {serviceInfo.map((service, serviceIndex) => (
                    <>
                      <Box sx={{ display: "flex" }}>
                        <Avatar
                          sx={{
                            bgcolor: mainColor,
                            width: "24px",
                            height: "24px",
                            marginRight: "8px",
                          }}
                        >
                          <Typography>{`${serviceIndex + 1}`}</Typography>
                        </Avatar>
                        <Typography>
                          {" "}
                          {` ${service.service_category_name} - ${service.sub_service_category_name}`}
                        </Typography>
                      </Box>
                      <Box display={"flex"} sx={{ marginY: "16px" }}>
                        <Box flex={1}>
                          <Typography>Reference Number</Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography>{service.reference_number}</Typography>
                        </Box>
                      </Box>
                      <Box display={"flex"} sx={{ marginY: "16px" }}>
                        <Box flex={1}>
                          <Typography>Registration Number</Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography>{service.registration_number}</Typography>
                        </Box>
                      </Box>
                      <Box display={"flex"} sx={{ marginY: "16px" }}>
                        <Box flex={1}>
                          <Typography>Brand</Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography>{service.brand_name}</Typography>
                        </Box>
                      </Box>
                      <Box display={"flex"} sx={{ marginY: "16px" }}>
                        <Box flex={1}>
                          <Typography>Applicant</Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography>{service.applicant_name}</Typography>
                        </Box>
                      </Box>
                      <Box display={"flex"} sx={{ marginY: "16px" }}>
                        <Box flex={1}>
                          <Typography>Cupboard Name</Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography>{service.cupboard_name}</Typography>
                        </Box>
                      </Box>
                      <Box display={"flex"} sx={{ marginY: "16px" }}>
                        <Box flex={1}>
                          <Typography>Rack Number</Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography>{service.rack_number}</Typography>
                        </Box>
                      </Box>
                    </>
                  ))}
                  {
                    <>
                      {noteReject && (
                        <>
                          <Typography variant="h6" sx={{ marginY: "24px" }}>
                            Reason for Reject
                          </Typography>
                          <Box display={"flex"} sx={{ marginY: "16px" }}>
                            <Box flex={1}>
                              <Typography>Reason</Typography>
                            </Box>
                            <Box flex={1}>
                              <Typography>{noteReject}</Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                    </>
                  }
                  <Box sx={{ height: "300px" }} />
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box flex={1} sx={{ overflow: "auto", maxHeight: "480px" }}>
                  <Typography variant="h6">Attachment File List</Typography>
                  <Typography sx={{ marginBottom: "16px" }}>
                    List of attached files used for the selected service
                  </Typography>
                  {
                    attachInfo.map((attach, attachIndex) => (
                      <>
                        <Box>
                          <Box sx={{ display: "flex", marginBottom: "16px" }}>
                            <Avatar sx={{ bgcolor: mainColor, width: "24px", height: "24px", marginRight: "8px" }} ><Typography>{`${attachIndex + 1}`}</Typography></Avatar>
                            <Typography> {` ${attach.service_category_name} - ${attach.sub_service_category_name}`}</Typography>
                          </Box>
                          {
                            attach.data.map((v) => (
                              <Box sx={{ display: "flex", marginBottom: "16px" }}>
                                <Box sx={{ marginRight: "8px" }}>
                                  <CardMedia
                                    image={v.is_checked ? DocumentActive : DocumentNonActive}
                                    component="img"
                                    alt="Sample Image" />
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                  <Typography sx={{ color: v.is_checked ? "#0A0A0A" : "#9E9E9E" }}>
                                    {v.desc}
                                  </Typography>
                                </Box>
                                {
                                  v.status_loan && (
                                    <Box >
                                      <Tooltip placement="top" title={
                                        <Typography sx={{ fontSize: 14 }}>This attached file is being borrowed</Typography>
                                      } sx={{ margin: 0, padding: 0 }}>
                                        <IconButton aria-label="Image">
                                          <img src={InfoIcon} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  )
                                }
                              </Box>
                            ))
                          }
                        </Box>
                      </>

                    ))
                  }
                  {/* {attachInfo.map((attach) => (
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ marginRight: "8px" }}>
                        <CardMedia
                          image={
                            attach.is_checked
                              ? DocumentActive
                              : DocumentNonActive
                          }
                          component="img"
                          alt="Sample Image"
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color: attach.is_checked ? "#0A0A0A" : "#9E9E9E",
                          }}
                        >
                          {attach.desc}
                        </Typography>
                      </Box>
                    </Box>
                  ))} */}
                </Box>
              </Box>
            </CardContent>
            <CardActions
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "100%",
                justifyContent: "flex-end",
                background: "white",
                padding: "20px",
              }}
            >
              {status.name === "Pending" ? renderActionButtons() : ""}
            </CardActions>
          </>
        )}
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={modalTitle}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionModalButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
    </>
  );
};
export default DetailRequestRecipient;
