import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_REGISTRATION_SUCCESS,
  GET_REGISTRATION_FAILED,
  ADD_REGISTRATION_SUCCESS,
  ADD_REGISTRATION_FAILED,
  EDIT_REGISTRATION_SUCCESS,
  EDIT_REGISTRATION_FAILED,
  DELETE_REGISTRATION_FAILED,
  DELETE_REGISTRATION_SUCCESS,
  GET_REGISTRATION_DETAILS_FAILED,
  GET_REGISTRATION_DETAILS_SUCCESS
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import dayjs from "dayjs";
import { logout } from "../../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getRegistration = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  searchBy = null,
  status = null,
  sortDirection = null,
  sortBy = null,
  period = null,
  category = null,
}) => {
  console.log(page, limit, search, status, sortDirection, sortBy);
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "registrations",
          page,
          limit,
          searchValue: search,
          searchBy: search  ? searchBy : null,
          period,
          categoryId: category == ACTION_TYPE.ALL_CATEGORY ? null : category,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortBy,
          sortDirection,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getRegistrationSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getRegistrationSuccess([], null));
        } else {
          dispatch(getRegistrationFailed(response.data.message));
        }
      })
      .catch((error) => {
//         debugger;
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getRegistrationFailed(error.response.data.message));
          }
        } else {
          dispatch(getRegistrationFailed(error.message));
        }
      });
  };
};

const getRegistrationSuccess = (data, pagination) => {
  return {
    type: GET_REGISTRATION_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getRegistrationFailed = (errorMessage) => {
  return {
    type: GET_REGISTRATION_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};



/* -------------------------------------------------------------------------- */
/*                                 GET DETAIL                                 */
/* -------------------------------------------------------------------------- */

export const getRegistrationDetails = ({
  token,
  id = null,
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: `registrations/${id}`,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          debugger;
          dispatch(getRegistrationDetailsSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getRegistrationDetailsSuccess(null));
        } else {
          dispatch(getRegistrationDetailsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getRegistrationDetailsFailed(error.response.data.message));
          }
        } else {
          dispatch(getRegistrationDetailsFailed(error.message));
        }
      });
  };
};


const getRegistrationDetailsFailed = (errorMessage) => {
  return {
    type: GET_REGISTRATION_DETAILS_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

const getRegistrationDetailsSuccess = (data, pagination) => {
  return {
    type: GET_REGISTRATION_DETAILS_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addRegistration = (token, formData) => {
  delete formData.id;
  return (dispatch) => {
    // // Create a FormData object
    // const formDataObject = new FormData();

    // // Function to recursively append data to the FormData object
    // const appendData = (prefix, data) => {
    //   for (const key in data) {
    //     if (data.hasOwnProperty(key)) {
    //       const value = data[key];
    //       const newKey = `${prefix}.${key}`;

    //       if (value instanceof File) {
    //         // Handle File objects (actual files selected by the user)
    //         formDataObject.append(newKey, value);
    //       } else if (Array.isArray(value)) {
    //         // Handle arrays
    //         value.forEach((item, index) => {
    //           // Check if the item is an object or a primitive value
    //           if (
    //             typeof item === "object" &&
    //             !(item instanceof File) &&
    //             (newKey.endsWith("date") || newKey.endsWith("year"))
    //           ) {
    //             // Recursively handle nested objects in arrays
    //             formDataObject.append(`${newKey}[${index}]`, formatDate(value));
    //           } else if (
    //             typeof item === "object" &&
    //             !(item instanceof File) &&
    //             (!newKey.endsWith("date") || !newKey.endsWith("year"))
    //           ) {
    //             appendData(`${newKey}[${index}]`, item);
    //           } else {
    //             // Handle primitive values in arrays
    //             formDataObject.append(`${newKey}[${index}]`, item);
    //           }
    //         });
    //       } else if (
    //         typeof value === "object" &&
    //         !(value instanceof File) &&
    //         (newKey.endsWith("date") || newKey.endsWith("year"))
    //       ) {
    //         // Handle nested objects (excluding File objects)
    //         formDataObject.append(newKey, formatDate(value));
    //       } else if (
    //         typeof value === "object" &&
    //         !(value instanceof File) &&
    //         (!newKey.endsWith("date") || !newKey.endsWith("year"))
    //       ) {
    //         appendData(newKey, value);
    //       } else if (newKey.endsWith("date")) {
    //         // Format and append date fields
    //         formDataObject.append(newKey, formatDate(value));
    //       } else {
    //         // Handle other values
    //         formDataObject.append(newKey, value);
    //       }
    //     }
    //   }
    // };

    // // Function to format dates
    // const formatDate = (dateString) => {
    //   return dayjs(dateString).isValid()
    //     ? dayjs(dateString).format("DD-MM-YYYY ")
    //     : dayjs().format("DD-MM-YYYY ");
    // };

    // appendData("StepOne", formData.StepOne);
    // appendData("StepTwo", formData.StepTwo);
    // appendData("StepThree", formData.StepThree);
    // appendData("StepFour", formData.StepTwo);

    // axios
    //   .post("registrations", formDataObject, {
    //     headers: {
    //       "Content-Type": "multipart/form-data", // Set the content type for file uploads
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })

    dispatch(RequestLoading());
    axios
      .post("registrations", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "registration"));
          dispatch(addRegistrationSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addRegistrationFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(addRegistrationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(addRegistrationFailed(error.response.data.message));
          }
        } else {
          dispatch(addRegistrationFailed(error.message));
        }
      });
  };
};

const addRegistrationSuccess = (message) => {
  return {
    type: ADD_REGISTRATION_SUCCESS,
    payload: message,
  };
};

const addRegistrationFailed = (message) => {
  return {
    type: ADD_REGISTRATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
const transformToJson = (inputJson) => {
  let transformedJson = {}; // Declare transformedJson outside the if blocks

  if (inputJson.Status) {
    transformedJson = {
      status: inputJson.Status.status,
      attachments: inputJson.Attachment.attachments,
      certificate_number: inputJson.Certificate.certificate_number,
      expired_date: inputJson['Expired Date'].expired_date,
      // note: inputJson["Note Section"].note,
    };
  } else if (inputJson.Registration) {
    transformedJson = {
      no_registration: inputJson.Registration.no_registration,
      attachments: inputJson.Attachment.attachments,
      // note: inputJson["Note Section"].note,
    }
  }
  /*
   * OLD
   * else if (inputJson.Certificate) {
    transformedJson = {
      certificate_number: inputJson.Certificate.certificate_number,
    };
  }
  */

  debugger;

  return transformedJson;
};


export const editRegistration = (token, formData, id) => {
  debugger;
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`registrations/${id}/update`, transformToJson(formData), {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "registration"));
          dispatch(editRegistrationSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editRegistrationFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(editRegistrationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(editRegistrationFailed(error.response.data.message));
          }
        } else {
          dispatch(editRegistrationFailed(error.message));
        }
      });
  };
};

const editRegistrationSuccess = (message) => {
  return {
    type: EDIT_REGISTRATION_SUCCESS,
    payload: message,
  };
};

const editRegistrationFailed = (message) => {
  return {
    type: EDIT_REGISTRATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteRegistration = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`registrations${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "registration"));
          dispatch(deleteRegistrationSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteRegistrationFailed(response.data.message));
        } else {
          dispatch(deleteRegistrationFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteRegistrationFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteRegistrationFailed(error.message));
        }
      });
  };
};

const deleteRegistrationSuccess = (message) => {
  return {
    type: DELETE_REGISTRATION_SUCCESS,
    payload: message,
  };
};

const deleteRegistrationFailed = (message) => {
  return {
    type: DELETE_REGISTRATION_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
