import React, { useState, useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
  AttachmentItem
} from "../../../util/style";
import exportIcon from "./../../../assets/export.png";
import axios, { urlRequest } from "../../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  TimePickerInput,
  DatePickerInput,
} from "../../../components/Form";
import {
  // getAttendances,
  // addAttendances,
  // editAttendances,
  // deleteAttendances,
  // getAttendanceDetails,
  getSalesQuotationDetails
} from "./clients/actions";
import {
  ACTION_TYPE,
  SALES_ORDER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  saveResponseToFile,
  pxToMm,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import { RequestLoading } from "../../../util/global_state";
import ArrowBackIcon from "./../../../assets/back.svg";
import ZoomInIcon from "./../../../assets/zoom-in.png";
import successIMG from "./../../../assets/success.png";
import PrinterIcon from "./../../../assets/printer.png";
import PrinterGrayIcon from "./../../../assets/printer-gray.png";
import DownloadGrayIcon from "./../../../assets/download-gray.png";
import ZoomOutIcon from "./../../../assets/zoom-out.png";
import logo from "./../../../logo.png";
import boderImg from "./../../../assets/border.png";

const DetailSalesQuotation = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  // const useStyles = defaultStylePage;
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#e1e1e1',
      overflow: 'hidden',
      position:'relative !important',
    },
    logo:{
      position: "absolute",
      width: "100%",
    },
    header: {
      padding: '20px',
      backgroundColor: '#ffffff',
      borderRadius: '10px 10px 0 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    content: {
      padding: '20px',
      backgroundColor: '#ffffff',
    },
    footer: {
      padding: '20px',
      backgroundColor: '#09345C',
      borderRadius: '0 0 10px 10px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }));




  const [title, setTitle] = useState("Detail Sales Quotation ");
  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */


  const { token } = useSelector((state) => state.auth);
  const params = useParams();
  const [id, setId] = useState(parseInt(params.id));


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const documentPreviewForPrintRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [isDoneFetchHeightDocumentPreview, setIsDoneFetchHeightDocumentPreview] = useState(false);

  const [selectedIdSalesQuotation, setSelectedIdSalesQuotation] = useState(null);
  const [requestTo, setRequestTo] = React.useState('');
  const [detailRowV2, setDetailRowV2] = useState(
  {
    can_download_pdf: false,
    "Sales Quotation Information": {
        "No. Sales Quotation": "",
        "Client Name": "",
        "Date Created": "",
        "Created By": "",
        "Approval Line": {
          "name": "",
          "position": ""
        },
        "Date Approved": "",
        "Comment": "-",
        'Status': ''
    },
    "Requested Service": [
    {
      "title": "",
      "subtitle": "",
      "children": {
        "Particular": "",
        "Subtotal": "",
        "Discount (50%)": "",
        "VAT (11%)": "",
        "Total": ""
      }
    }
  ],
  "Attachments": [{
    filename: "-",
    size: "-",
    url: "https://download_certificate1.pdf",
  }],
  "Price Information": {
    "Price (0 service)": "",
    "Discount": "",
    "VAT": ""
  },
  "Total Price": "",
  "Note": ""
}
  );
    const {
    data,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.salesquotationsdetail);

  const handleChangeRequestTo = (event) => {
    setRequestTo(event.target.value);
  };

  useEffect(() => {
    setSelectedIdSalesQuotation(parseInt(id));
  }, [id]);


  useEffect(() => {
    if(data) {
      setDetailRowV2(data);
    }
  }, [data]);

    useEffect(() => {
    if (selectedIdSalesQuotation) {
      dispatch(
        getSalesQuotationDetails({
          token,
          id: selectedIdSalesQuotation
        })
      );
    }
  }, [selectedIdSalesQuotation, token]);

  // OLD Format
  const detailRow = {
    "id": 1,
    "code": "293/PPM/SQ/XII/2022",
    "client_name": "PT.Kompas Tbk",
    "service_categories": [
      {
        "service": {
          "id": 1,
          "name": "Main Service 1",
          "status": "active"
        },
        "sub_services": [
          {
            "sub_service_id": 3,
            "name": "Sub Service 2",
            "status": "active"
          },
          {
            "sub_service_id": 2,
            "name": "Sub Service 1",
            "status": "active"
          }
        ],
      },
    ],
    "total_price": "Rp. 29.000.000",
    "date_created": "24/02/2023",
    "created_by": "Howard Esther",
    "status": "Rejected",
    "approval_line": "Duke Silver",
    "note": 'img elements must have an alt prop, either with meaningful text, or an empty string for decorative images...',
    "reason_reject": ''
  };

  // NEW Format
//   const detailRowV2 = {
//     "Sales Quotation Information": {
//         "No. Sales Quotation": "293/PPM/SP/XII/2022",
//         "Client Name": "PT. Kompas Tbk",
//         "Date Created": "24 September 2023",
//         "Created By": "Howard Esther",
//         "Approval Line": {
//             "name": "Dianne Russel",
//             "position": "Sales Manager"
//         },
//         "Date Approved": "24/09/2023, 0910",
//         "Comment": "-",
//         'Status': 'Approved'
//     },
//     "Requested Service": [
//         {
//             "title": "Izin Usaha",
//             "subtitle": "Penamaan Bisnis",
//             "children": {
//                 "Criteria": "Criteria 1",
//                 "Subtotal": "Rp. 5000,000",
//                 "Discount (50%)": "Rp. 6000,000",
//                 "VAT (11%)": "Rp. 7000,000",
//                 "Total": "Rp. 8000,000"
//             }
//         },
//         {
//           "title": "Izin Usaha",
//           "subtitle": "Bangunan Bisnis",
//           "children": {
//               "Criteria": "Criteria 11",
//               "Subtotal": "Rp. 1000,000",
//               "Discount (50%)": "Rp. 2000,000",
//               "VAT (11%)": "Rp. 73000,000",
//               "Total": "Rp. 123000,000"
//           }
//       }
//     ],
//     "Price Information": {
//         "Price (2 service)": "Rp.3241",
//         "Discount": "Rp. 6000000",
//         "VAT": "Rp.4534534566"
//     },
//     "Total Price": "Rp.123123123123123",
//     "Note": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor  ut labore et dolore magna aliqua.Ut enim ad minim veniam "
// }


  // const ZoomableDocument = () => {

  //   return (
  //     <div>
  //       {/* JSX representation of your component */}
  //       <button onClick={handleZoomIn}>Zoom In</button>
  //       <button onClick={handleZoomOut}>Zoom Out</button>
  //       <p>Zoom: {zoom}</p>
  //       <p>Device Px Per CSS Px: {devicePxPerCssPx}</p>
  //       <button onClick={printContent}>Print Content</button>
  //     </div>
  //   );
  // };

  // NEW
  const DetailV2 = () => {
    const statusColors = {
      waiting: { bgColor: warningSurfaceColor, colors: warningMainColor },
      approved: { bgColor: successSurfaceColor, colors: successMainColor },
      rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
      // Add more statuses as needed
    };

    const statusInfo = statusColors[detailRowV2["Sales Quotation Information"]["Status"]] || {
      bgColor: infoSurfaceColor,
      colors: infoMainColor,
    };

      const excludedProperties = ["Comment", "Status"];

      return (
        <>
          {/* -------------------------------- Sales Quotation Information ------------------------------- */}
{/* Sales Quotation Information */}
  <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, p: 3 }}>
    <Grid item xs={12}>
       <Box sx={{ marginBottom: 2, ...justifyContentBetween, ...alignItemsCenter }}>
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          Sales Quotation Information
        </Typography>
        <StatusBadge bgColor={statusInfo.bgColor} colors={statusInfo.colors}>
          {detailRowV2["Sales Quotation Information"]["Status"]}
        </StatusBadge>
      </Box>
    </Grid>

    {Object.entries(detailRowV2["Sales Quotation Information"]).map(([key, value]) => (
      <React.Fragment key={key}>
        {!excludedProperties.includes(key) && (
          <React.Fragment key={key}>
            {key === "Approval Line" && typeof value === "object" ? (
              <>
                <Grid item xs={4}>
                  <Typography variant="body1">{key}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="subtitle1">
                    {value.name} <span style={{ color: '#666' }}>as {value.position}</span>
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={4}>
                  <Typography variant="body1">{key}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="subtitle1">{value}</Typography>
                </Grid>
              </>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    ))}
  </Grid>


        {/* -------------------------------- Requested Service ------------------------------- */}
  <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0, p: 3 }}>
    <Grid item xs={12}><Typography variant="h6" sx={{ fontWeight: 800 }}>Requested Service</Typography></Grid>

    {detailRowV2["Requested Service"].map((service, serviceIndex) => (
      <React.Fragment key={serviceIndex}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <span
              style={{
                marginRight: '5px',
                backgroundColor: mainColor,
                color: '#fff',
                width: '20px',
                height: '20px',
                borderRadius: '100%',
                fontSize: '13px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {serviceIndex + 1}
            </span>
            <Typography variant="subtitle1">
              {service.title} - <b> {service.subtitle}</b>
            </Typography>
          </Box>
        </Grid>

        {Object.entries(service.children).map(([key, value]) => (
          <React.Fragment key={key}>
            <Grid item xs={4}>
              <Typography variant="body1">{key}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1">{value}</Typography>
            </Grid>
          </React.Fragment>
        ))}
      </React.Fragment>
    ))}
  </Grid>

  <div style={{ height: '10px', backgroundColor: thirdColor, width: '100%' }}></div>
    {/* -------------------------------- Registration Attachment ------------------------------- */}

  <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0, p: 3 }}>
    <Grid item xs={12}><Typography variant="h6" sx={{ fontWeight: 800 }}>Attachment</Typography></Grid>

    <Grid item xs={12}>
    {detailRowV2['Attachments'].map((attachment, attachmentIndex) => (
     <AttachmentItem item={attachment} title="Attachment"/>
    ))}
    </Grid>
  </Grid>

  <div style={{ height: '10px', backgroundColor: thirdColor, width: '100%' }}></div>

  {/* -------------------------------- Price Information ------------------------------- */}
  <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0, p: 3 }}>
    <Grid item xs={12}><Typography variant="h6" sx={{ fontWeight: 800 }}>Price Information</Typography></Grid>

    {Object.entries(detailRowV2["Price Information"]).map(([key, value]) => (
      <React.Fragment key={key}>
        <Grid item xs={4}>
          <Typography variant="body1">{key}</Typography>
        </Grid>
        <Grid item xs={8} sx={{ textAlign: 'right' }}>
          <Typography variant="subtitle1">{value}</Typography>
        </Grid>
      </React.Fragment>
    ))}

    <Grid item xs={12}>
      <Divider />
    </Grid>

    <Grid item xs={4}>
      <Typography variant="h6" sx={{ fontWeight: 800 }}>Total Price</Typography>
    </Grid>
    <Grid item xs={8} sx={{ textAlign: 'right' }} >
      <Typography variant="h6" sx={{ fontWeight: 800 }}>{detailRowV2["Total Price"]}</Typography>
    </Grid>
  </Grid>

  <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0, p: 3 }}>
    <Grid item xs={4}>
      <Typography variant="body1">Note</Typography>
    </Grid>
    <Grid item xs={8} >
      <Typography variant="subtitle1">{detailRowV2['Sales Quotation Information']["Note"]}</Typography>
    </Grid>
  </Grid>
  </>
  );
};


 const handleBeforePrint = () => {
    document.title = `Sales Quotation : ${detailRowV2["Sales Quotation Information"]['No. Sales Quotation']}`;
  };

  const handleAfterPrint = () => {
    document.title = 'PPM Website'; // Replace with your original title
  };

  useEffect(() => {
    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [detailRowV2]);



  const Signature = ({ companyName, isMarked, signatureText }) => (
    <Box sx={{ textAlign: 'center', width: '100%', borderRadius: '8px', p: 1 }}>
      <Typography variant="subtitle1">{companyName}</Typography>
      <Box sx={{ height: '60px' }}>
        {isMarked && (<img src={successIMG} width="55px" />)}
      </Box>
      <Typography sx={{ fontWeight: '500' }}>{signatureText ? signatureText : '(................................................)'}</Typography>
    </Box>
  );

  const PreviewPDFV2 = () => {
    const [fontSize, setFontSize] = useState(12); // Initial font size
    const documentPreviewRef = useRef(null);
    const [percentage, setPercentage] = useState(100); // Initial percentage text

    useEffect(() => {
      // Apply the zoom effect to specific elements when fontSize changes
      if (documentPreviewRef.current) {
        const applyZoomToChildren = (element) => {
          if (element.childNodes.length > 0) {
            for (const childNode of element.childNodes) {
              applyZoomToChildren(childNode);
            }
          }
          if (element.style) {
            const tagName = element.tagName.toLowerCase();
            const className = element.className.toLowerCase();
            // if (tagName === 'h1' || tagName === 'h2' || tagName === 'h3' || tagName === 'h4' || tagName === 'h5' || tagName === 'h6') {
            //   // Apply +1px zoom to h1, h2, h3, h4, h5, h6
            //   element.style.fontSize = `${fontSize + 1}px`;
            // } else {
            //   element.style.fontSize = `${fontSize}px`;
            // }

            const hasNotEffectClass = className && className.includes('not-effect');

            if (!hasNotEffectClass) {
              // Apply +1px zoom to h1, h2, h3, h4, h5, h6 if it doesn't have the 'not-effect' class
              element.style.fontSize = `${fontSize + 1}px`;
            } else {
              // Apply normal zoom if it has the 'not-effect' class
              element.style.fontSize = `${element.style.fontSize}px`;
            }
          }
        };
        applyZoomToChildren(documentPreviewRef.current);
      }
    }, [fontSize]);

    const handleZoomIn = () => {
      setFontSize((prevSize) => prevSize + 1);
      setPercentage((prevPercentage) => prevPercentage + 10);
    };

    const handleZoomOut = () => {
      setFontSize((prevSize) => Math.max(prevSize - 1, 1));
      setPercentage((prevPercentage) => Math.max(prevPercentage - 10, 10));
    };

    const printContent = () => {
      // Implement the print logic
      //setHeight(document.querySelector('#kakak').clientHeight)//documentPreviewForPrintRef.current);
//       setHeight(documentPreviewForPrintRef?.current?.clientHeight);
      window.print();
    };


    const renderGridItemsFromObject = (object) => {
      const excludedProperties = ["Approval Line", "Created By", "Date Approved", "Comment", "Status", "Employee Referral Code"];
      const rightAlignedKeys = ["Price", "Quantity", "Subtotal", "Discount", "VAT (11%)", "Total"];

      const isRightAlignedKey = (key) => {
        return rightAlignedKeys.includes(key) || key.toLowerCase().includes('discount');
      };

      return Object.entries(object).map(([key, value]) => (
        // Skip rendering "Approval Line"
        excludedProperties.includes(key) ? null : (
          <React.Fragment key={key}>
            <Grid item xs={4}>
              <Typography variant="body1">{key}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1" sx={{ textAlign: isRightAlignedKey(key) ? 'right' : 'left' }}>{value}</Typography>
            </Grid>
          </React.Fragment>
        )
      ));
    };

    const renderRequestedServices = (services) => {
      return services.map((service, serviceIndex) => (
        <React.Fragment key={serviceIndex}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {service.title} - {service.subtitle}
              </Typography>
            </Box>
          </Grid>
          {renderGridItemsFromObject(service.children)}
        </React.Fragment>
      ));
    };

    const renderPriceInformation = (priceInfo) => {
      return Object.entries(priceInfo).map(([key, value]) => (
        <React.Fragment key={key}>
          <Grid item xs={8}>
            <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
              {key}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 800, textAlign: 'right' }}>
              {value}
            </Typography>
          </Grid>
        </React.Fragment>
      ));
    };

    const classes = useStyles();

    return (
      <Box style={{ height: '100%', width: '100%', backgroundColor: thirdColor, position: 'relative', p: 3, overflow:'auto' }}  ref={documentPreviewRef}>
        <Grid item xs={12} sx={{ position: 'absolute',width: '100%', p:2  }}>
          <Box sx={{ marginBottom: 2, ...justifyContentBetween, ...alignItemsCenter,
    alignItems: 'baseline' }}>
            <Typography variant="h6" sx={{ fontWeight: 800, color:neutral70}} className="not-effect">Document Preview</Typography>
            <Box sx={{ display: 'flex', flexDirection:'column', gap:'1.5rem' }}>
            <Box sx={{ ...justifyContentEnd, gap:'.5rem' }}>
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() =>handleZoomIn()}
              >
                <img src={ZoomInIcon} />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() =>handleZoomOut()}
              >
                <img src={ZoomOutIcon} />
              </IconButton>
              <Button sx={{
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'darkgray', // Change the color when hovered if desired
                },
                borderRadius
              }} className="not-effect">
                {percentage}%
    </Button>
            </Box>
            <Box sx={{ display: 'flex', gap:'.5rem' }}>
            <Button
  variant="outlined"
//   OLD
  sx={{
    ...(detailRowV2["can_download_pdf"] === true
      ? btnLightBlue
      : btnGray),
    borderRadius,
  }}
//     sx={{
//        btnLightBlue,
//     borderRadius,
//   }}
  disableElevation
//   OLD disabled={detailRowV2["Sales Quotation Information"]?.Status !== "approved" || isDoneFetchHeightDocumentPreview === false}
disabled={detailRowV2["can_download_pdf"] === false || isDoneFetchHeightDocumentPreview === false}
  className="not-effect"
  onClick={() => printContent()}
>
  <img src={PrinterGrayIcon} style={{ marginRight: '5px' }} alt="Printer Icon" />
  Print
</Button>
<Button
  variant="outlined"
//   OLD
  sx={{
    ...(detailRowV2["can_download_pdf"] === true
      ? btnLightBlue
      : btnGray),
    borderRadius,
  }}
//   sx={{
//        btnLightBlue,
//     borderRadius,
//   }}
  disableElevation
  //OLD disabled={detailRowV2["Sales Quotation Information"]?.Status !== "approved" || isDoneFetchHeightDocumentPreview === false}
  disabled={detailRowV2["can_download_pdf"] === false}
  className="not-effect"
  onClick={() => {
                    window.open(
                        process.env.REACT_APP_API_URL +
                        `/api/sales-quotations/${id}/download-pdf`,
                        "_blank"
                    );
                }}
>
  <img src={DownloadGrayIcon} style={{ marginRight: '5px' }} alt="Download Icon" />
  Download
</Button>
            </Box>
            </Box>
          </Box>
          <Paper className={`${classes.root}`}  ref={documentPreviewForPrintRef}>
          <img src={boderImg} className={classes.logo}/>


      {/* Header */}
      <div className={classes.header}>
        <Typography variant="h4" sx={{fontWeight:'800'}} className="not-effect">SALES QUOTATION</Typography>
        <img src={logo} alt="logo"  />
      </div>


         <Grid container display="flex" sx={{...justifyContentBetween,padding: '20px',}}>
         <Grid item xs="6" container>
         {renderGridItemsFromObject(detailRowV2["Sales Quotation Information"])}
        </Grid>

      <Box sx={{
    width: '50%'}}>
      {/* <Grid item xs="5" container> */}
          {/* <Grid item xs={12}> */}
            <Typography variant="body1" sx={{textAlign:"right"}}>PT Pacific Patent Multiglobal </Typography>
          {/* </Grid> */}
          {/* <Grid item xs={12}> */}
            <Typography variant="subtitle1" sx={{textAlign:"right", color:neutral70}}>DIPO Business Center 11th Floor Jl. Jenderal Gatot Subroto kav 51-52<br/> Jakarta Pusat 10260</Typography>
          {/* </Grid> */}
        {/* </Grid>  */}
        </Box>
        </Grid>

      {/* Content */}
      <div className={classes.content}>
      <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0}}>
          <Grid item xs={12}><Typography variant="subtitle1" sx={{ color:neutral70}}>Work Scope</Typography></Grid>
          {renderRequestedServices(detailRowV2["Requested Service"])}
        </Grid>

      </div>
      <Divider/>


{/* Price information and total */}
<Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0, padding: '20px' }}>
  {renderPriceInformation(detailRowV2['Price Information'])}
  <Grid item xs={8}>
    <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
      Total Price
    </Typography>
  </Grid>
  <Grid item xs={4}>
    <Typography variant="subtitle1" sx={{ fontWeight: 800, textAlign: 'right' }}>
      {detailRowV2['Total Price']}
    </Typography>
  </Grid>
</Grid>



  <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0, p: 3 }}>
    <Grid item xs={12}>
      <Typography variant="body1">Note</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="subtitle1">{detailRowV2['Sales Quotation Information']["Note"]}</Typography>
    </Grid>
  </Grid>


        <Grid container spacing={3} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt:1, mb: 2.5 }}>
          <Grid item xs={6} sx={{ ...justifyContentCenter }}>
          {/*<Signature companyName="Sales Manager"  signatureText={detailRowV2["Sales Quotation Information"]['Approval Line']['name']} />*/}
          </Grid>
          <Grid item xs={6} sx={{ ...justifyContentCenter }}>
            <Signature companyName="Client Name" signatureText={detailRowV2["Sales Quotation Information"]['Client Name']} />
          </Grid>
        </Grid>


      {/* Footer */}
      <div className={classes.footer}>
      <Box sx={{ display: 'flex', gap:'.5rem' }}>
      <Typography variant="subtitle2" sx={{color:'white'}} >
      +6221 2986 5888
        </Typography>
        <Typography variant="subtitle2" sx={{color:'white'}} >
        pacific@pacificpatent.com
        </Typography>
            </Box>
        <Typography variant="subtitle2" sx={{color:'white'}} >
        www.pacificpatent.com
        </Typography>
      </div>
    </Paper>
    </Grid>
    </Box>
    )
}

  // Function to set height if the ref is initialized
const setHeightIfRefExists = () => {
  if (documentPreviewForPrintRef?.current) {
    setHeight(documentPreviewForPrintRef.current.clientHeight < 1122.5 ? 1122.5 : documentPreviewForPrintRef.current.clientHeight);
  }
};

// Check if the ref exists and then set the height
useEffect(() => {
  if(!isLoading && data !== null) {
  setTimeout(() => { console.log('timeout'); setHeightIfRefExists();setIsDoneFetchHeightDocumentPreview(true)}, 2000);
  }
}, [dispatch,isLoading, data, documentPreviewForPrintRef]); // Dependency array to watch for changes in the ref


  const ForPrintingV3 = () => {
    const classes = useStyles();


    const renderGridItemsFromObject = (object) => {
      const excludedProperties = ["Approval Line", "Created By", "Date Approved", "Comment", "Status", "Employee Referral Code"];
      const rightAlignedKeys = ["Price", "Quantity", "Subtotal", "Discount", "VAT (11%)", "Total"];

      const isRightAlignedKey = (key) => {
        return rightAlignedKeys.includes(key) || key.toLowerCase().includes('discount');
      };

      return Object.entries(object).map(([key, value]) => (
        // Skip rendering "Approval Line"
        excludedProperties.includes(key) ? null : (
          <React.Fragment key={key}>
            <Grid item xs={4}>
              <Typography variant="body1">{key}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle1" sx={{ textAlign: isRightAlignedKey(key) ? 'right' : 'left' }}>{value}</Typography>
            </Grid>
          </React.Fragment>
        )
      ));
    };

    const renderRequestedServices = (services) => {
      return services.map((service, serviceIndex) => (
        <React.Fragment key={serviceIndex}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {service.title} - {service.subtitle}
              </Typography>
            </Box>
          </Grid>
          {renderGridItemsFromObject(service.children)}
        </React.Fragment>
      ));
    };

    const renderPriceInformation = (priceInfo) => {
      return Object.entries(priceInfo).map(([key, value]) => (
        <React.Fragment key={key}>
          <Grid item xs={8}>
            <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
              {key}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" sx={{ fontWeight: 800, textAlign: 'right' }}>
              {value}
            </Typography>
          </Grid>
        </React.Fragment>
      ));
    };



    return (
    <Paper className={`${classes.root} DocumentPreview`}  sx={{boxShadow: "unset"}}>
    <img src={boderImg} className={classes.logo}/>


{/* Header */}
<div className={classes.header}>
  <Typography variant="h4" sx={{fontWeight:'800'}} className="not-effect">SALES QUOTATION</Typography>
  <img src={logo} alt="logo"  />
</div>


   {/* OLD <Grid container display="flex" sx={{...justifyContentBetween,padding: '20px',}}> */}
   <Grid container display="flex" sx={{...justifyContentBetween,paddingRight: '20px',paddingLeft:'20px',}}>
    <Grid item xs="6" container>
      {renderGridItemsFromObject(detailRowV2["Sales Quotation Information"])}
    </Grid>

<Box sx={{
width: '50%'}}>
{/* <Grid item xs="5" container> */}
    {/* <Grid item xs={12}> */}
      <Typography variant="body1" sx={{textAlign:"right"}}>PT Pacific Patent Multiglobal </Typography>
    {/* </Grid> */}
    {/* <Grid item xs={12}> */}
      <Typography variant="subtitle1" sx={{textAlign:"right", color:neutral70}}>DIPO Business Center 11th Floor Jl. Jenderal Gatot Subroto kav 51-52 Jakarta Pusat 10260</Typography>
    {/* </Grid> */}
  {/* </Grid>  */}
  </Box>
  </Grid>

{/* Content */}
<div className={classes.content}>
    <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '5px' }, mt: 0}}>
        <Grid item xs={12}><Typography variant="subtitle1" sx={{ color:neutral70}}>Work Scope</Typography></Grid>
        {renderRequestedServices(detailRowV2["Requested Service"])}
      </Grid>

</div>
<Divider/>

{/* Price information and total */}
<Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0, padding: '20px' }}>
  {renderPriceInformation(detailRowV2['Price Information'])}
  <Grid item xs={8}>
    <Typography variant="body2" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
      Total Price
    </Typography>
  </Grid>
  <Grid item xs={4}>
    <Typography variant="subtitle1" sx={{ fontWeight: 800, textAlign: 'right' }}>
      {detailRowV2['Total Price']}
    </Typography>
  </Grid>
</Grid>

{/* Note Section */}
  <Grid container spacing={2} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt: 0, p: 3 }}>
    <Grid item xs={12}>
      <Typography variant="body1">Note</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="subtitle1">{detailRowV2['Sales Quotation Information']["Note"]}</Typography>
    </Grid>
  </Grid>



  {/* Signature Section*/}
    <Grid container spacing={3} sx={{ "& .MuiGrid-item": { paddingTop: '7px' }, mt:1, mb: 2.5 }}>
      <Grid item xs={6} sx={{ ...justifyContentCenter }}>
      {/*<Signature companyName="Sales Manager"  signatureText={detailRowV2["Sales Quotation Information"]['Approval Line']['name']} />*/}
      </Grid>
      <Grid item xs={6} sx={{ ...justifyContentCenter }}>
        <Signature companyName="Client Name" signatureText={detailRowV2["Sales Quotation Information"]['Client Name']} />
      </Grid>
    </Grid>

{/* Footer */}
<div className={`${classes.footer} DocumentPreviewFooter`}>

<Box sx={{ display: 'flex', gap:'.5rem' }}>
<Typography variant="subtitle2" sx={{color:'white'}} >
+6221 2986 5888
  </Typography>
  <Typography variant="subtitle2" sx={{color:'white'}} >
  pacific@pacificpatent.com
  </Typography>
      </Box>
  <Typography variant="subtitle2" sx={{color:'white', marginRigth:'20px'}} >
  www.pacificpatent.com
  </Typography>
</div>
</Paper>)
  }

  return (
    <>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "81vh",
          position: "relative",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:'space-between',
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <Box>
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ marginRight: ".5rem" }}
              >
                <img src={ArrowBackIcon} />
              </IconButton>
              {title}
              </Box>
              <Box sx={{display:'flex', alignItems:'center'}}>
              <div>
      {/*<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-simple-select-autowidth-label">Approval Line</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={requestTo}
          onChange={handleChangeRequestTo}
          autoWidth
          label="Approval Line"
        >
          <MenuItem value={''}>Select Approval Line</MenuItem>
          <MenuItem value={"Proforma Invoice"}>Proforma Invoice</MenuItem>
          <MenuItem value={"Invoice"}>Invoice</MenuItem>
        </Select>
      </FormControl>
      */}
    </div>
    {detailRowV2 && detailRowV2["Sales Quotation Information"]["Status"] == 'Rejected' && (
              <Button
              color="primary"
              variant="outlined"
              disableElevation
              type="submit">
                Resumbit
              </Button>
    )
          }
          </Box>
</div>
          }
        />
        <Divider />
        <Box
          flex="1"
          sx={{
            overflow: "auto",
            maxHeight: "70vh",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "45vh",
                display: "flex",
                alignItems: " center",
                justifyContent: "center",
              }}
            >
              <FacebookCircularProgress />
            </div>
          ) : (
          <Grid container>
            <Grid item xs={12}>
            <ForPrintingV3/>
            </Grid>
            <Grid item xs={5.2}>
              <DetailV2 />
              {/* <Detail/> */}
            </Grid>
            <Grid item xs={6.8}>
              {/* <img src="https://s3.bukalapak.com/img/3942411727/w-1000/lacto.png"></img> */}
              {/* <PreviewPDF /> */}
              <PreviewPDFV2 />
            </Grid>
          </Grid>
          )}
        </Box>
        <style>
        {`
        .page-break {
          page-break-before: always;
        }
        .DocumentPreview{
          display:none;
        }
        .DocumentPreviewFooter{
          padding: 20px;
          background-color: #09345C;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        @media print {
//           @page {
//               /* size: A4; */
//               /* margin: 200mm; */
//               /* Adjust margins as needed */
//           }

          @page {
              // size: A4;
//               size: 210mm 280mm;
            size: 210mm ${pxToMm(height)}mm; /* A4 size */
              margin: 0;
              /* Remove all margins */
          }

          body {
              margin: 0;
          }

          body *{
            visibility:hidden;
          }
.DocumentPreviewFooter{
   position:fixed;
  bottom:0;
  left:0;
  width:100%;
  border-radius:unset;
}
          .DocumentPreviewFooter, .DocumentPreviewFooter *{
              visibility: visible;
 padding: 10px 20px;

          display: flex;
          justify-content: space-between;
          align-items: center;
          print-color-adjust: exact;
          webkit-print-color-adjust: exact; /* For WebKit-based browsers */
        background-color:  #09345C !important; /* Override background color */
        }

          .DocumentPreview{
            display:block;
            position: fixed !important;
            top: 0;

            left: 0;
            width: 100%;
            z-index: 11999;
            visibility: visible;
            page-break-before: always;
          }

          .DocumentPreview,.DocumentPreview *{
              visibility: visible;
          }
        }

        `}
      </style>
      </Card>
    </>
  );
}

export default DetailSalesQuotation;
