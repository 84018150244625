import { useEffect } from "react"; // Import useEffect
import { useDispatch } from "react-redux";
import { getMenuAccessed } from "../auth/clients/login/actions";
import { logout } from "../auth/clients/login/actions";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response1 = await dispatch(getMenuAccessed(token));
  //       console.log("response1111");
  //       console.log(response1.status); // Mengambil status code dari respons
  //     } catch (error) {
  //       if (error.response.status === 401) {
  //         navigate("/login");
  //         dispatch(logout());
  //         localStorage.removeItem("t");
  //       }
  //       console.error("Error:", error.response.status);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return <h1>Dashboard</h1>;
};

export default Dashboard;
