export const GET_SALES_QUOTATION_SUCCESS = "GET_SALES_QUOTATION_SUCCESS";
export const GET_SALES_QUOTATION_FAILED = "GET_SALES_QUOTATION_FAILED";
export const ADD_SALES_QUOTATION_SUCCESS = "ADD_SALES_QUOTATION_SUCCESS";
export const ADD_SALES_QUOTATION_FAILED = "ADD_SALES_QUOTATION_FAILED";
export const EDIT_SALES_QUOTATION_SUCCESS = "EDIT_SALES_QUOTATION_SUCCESS";
export const EDIT_SALES_QUOTATION_FAILED = "EDIT_SALES_QUOTATION_FAILED";
export const DELETE_SALES_QUOTATION_SUCCESS = "DELETE_SALES_QUOTATION_SUCCESS";
export const DELETE_SALES_QUOTATION_FAILED = "DELETE_SALES_QUOTATION_FAILED";
export const GET_SALES_QUOTATION_DETAILS_SUCCESS = "GET_SALES_QUOTATION_DETAILS_SUCCESS";
export const GET_SALES_QUOTATION_DETAILS_FAILED = "GET_SALES_QUOTATION_DETAILS_FAILED";
export const REQUEST_LOADING_QUOTATION = "REQUEST_LOADING_QUOTATION";
