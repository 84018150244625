export const GET_TIME_OFF_REQUEST_SUCCESS = "GET_TIME_OFF_REQUEST_SUCCESS";
export const GET_TIME_OFF_REQUEST_FAILED = "GET_TIME_OFF_REQUEST_FAILED";
export const ADD_TIME_OFF_REQUEST_SUCCESS = "ADD_TIME_OFF_REQUEST_SUCCESS";
export const ADD_TIME_OFF_REQUEST_FAILED = "ADD_TIME_OFF_REQUEST_FAILED";
export const EDIT_TIME_OFF_REQUEST_SUCCESS = "EDIT_TIME_OFF_REQUEST_SUCCESS";
export const EDIT_TIME_OFF_REQUEST_FAILED = "EDIT_TIME_OFF_REQUEST_FAILED";
export const DELETE_TIME_OFF_REQUEST_SUCCESS = "DELETE_TIME_OFF_REQUEST_SUCCESS";
export const DELETE_TIME_OFF_REQUEST_FAILED = "DELETE_TIME_OFF_REQUEST_FAILED";
export const GET_TIME_OFF_REQUEST_DETAILS_SUCCESS = "GET_TIME_OFF_REQUEST_DETAILS_SUCCESS";
export const GET_TIME_OFF_REQUEST_DETAILS_FAILED = "GET_TIME_OFF_REQUEST_DETAILS_FAILED";
