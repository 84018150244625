"use strict";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import "dayjs/locale/en";
import ExportIcon from "./../../../assets/export.png";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import RemoveIcon from "../../../assets/minus.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  // StatusBadge,
  defaultStylePage,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  removeButton,
  justifyContentEnd,
  FacebookCircularProgress,
  btnTableToolbar,
  imgBtnToolbar,
} from "../../../util/style";
import axios, { headersAPIToken } from "./../../../core/axios_config";
import OriginAxios from "axios";
import Form, {
  TimePickerInput,
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  PasswordInputWithValidation,
  FilePicker,
  SelectOneCountryAutocomplete,
  SelectOneAutocompleteSearch,
  SelectOneAutocompleteSearchManageble,
} from "./../../../components/Form";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  createEmptyErrors,
  localToUTC,
} from "./../../../util/function";
import DataTable from "./../../../components/Table";
import ConfirmDelete from "./../../../components/ConfirmDelete";
import ModalConfirmCancel from "./../../../components/ConfirmCancel";
import LeftDrawer from "./../../../components/LeftDrawer";
import { TransitionAlerts } from "../../../components/Alert";
import { columns, fields } from "./part/imutable_state";
import {
  addAsset,
  deleteAsset,
  updateAsset,
  detailAsset,
  getAsset,
} from "./client/action";

/**
 * @typedef {import("react").SyntheticEvent} EventType
 *
 */

export default function Asset() {
  const theme = useTheme();
  const useStyles = defaultStylePage;

  const searchByKey = Object.freeze({
    "No References": "code",
    "Stock Name": "name",
    "Brand Name": "brand",
  });

  const GLOBAL_NAME = Object.freeze({
    apiEndpoint: "/assets",
  });

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  // const [userDeleted, setClientDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(ACTION_TYPE.DEFAULT_CATEGORY);
  const [sortModel, setSortModel] = useState([]);

  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add Asset");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [choosedStatus, setChoosedStatus] = useState(
    ACTION_TYPE.DEFAULT_STATUS
  );
  const [selectedCategoryId, setSelectedCategotyId] = useState(0);
  const [searchBy, setSearchBy] = useState(searchByKey["No References"]);
  // const [totalPurchase, setTotalPurchase] = useState("");
  // const [detailDoc, setDetailDoc] = useState(null);

  //* Type as Category
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(true);
  const [selectedId, setSelectedId] = useState();
  const dispatch = useDispatch();

  const {
    isLoading,
    data: rows,
    pagination,
    message,
    detail,
  } = useSelector((state) => state.asset);

  //* =================================================================================*//
  //*                                    INITIAL FORM                                  *//
  //* =================================================================================*//

  const initialFormValues = () => {
    const newFormValues = {};
    fields.forEach((field) => {
      newFormValues[field.name] = field.defaultValue;
    });
    return newFormValues;
  };

  const initialErrors = () => {
    const newErrors = {};
    fields.forEach((field) => {
      newErrors[field.name] = "";
    });
    return newErrors;
  };

  const [formValues, setFormValues] = useState(initialFormValues());
  const [errors, setErrors] = useState(initialErrors());

  const resetForm = () => {
    setFormValues(initialFormValues());
    setErrors(initialErrors());
  };

  //* =================================================================================*//
  //*                                      HANDLER                                     *//
  //* =================================================================================*//

  const validateField = (fieldName) => {
    const copyError = { ...errors };
    const field = fields.find((field) => field.name === fieldName);
    if (field) {
      let messageError = "";
      const { name, defaultValue, errorMessage, validation, required, label } =
        field;
      const valueInForm = formValues[name];
      if (
        required &&
        (valueInForm === "" ||
          valueInForm === 0 ||
          valueInForm === undefined ||
          valueInForm === defaultValue ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE)
      ) {
        messageError = `Please enter ${String(label).toLowerCase()}`;
      }

      if (
        (valueInForm || valueInForm.length) &&
        validation &&
        !validation(valueInForm)
      ) {
        messageError = errorMessage || String(label).concat(" is invalid");
      }

      copyError[name] = messageError;
    }
    setErrors(copyError);
  };

  const validateForm = () => {
    let valid = true;
    const copyError = { ...errors };
    fields.forEach((field) => {
      const {
        name,
        defaultValue,
        label,
        required,
        type,
        validation,
        errorMessage,
      } = field;
      const valueInForm = formValues[name];
      let messageError = "";
      if (
        required &&
        (valueInForm === "" ||
          valueInForm === undefined ||
          valueInForm === defaultValue ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE)
      ) {
        messageError = "Please enter ".concat(String(label).toLowerCase());
        valid = false;
      }

      if (
        (valueInForm || valueInForm.length) &&
        validation &&
        !validation(valueInForm)
      ) {
        messageError = errorMessage || String(label).concat(" is invalid");
        valid = false;
      }
      copyError[name] = messageError;
    });
    setErrors(copyError);
    return valid;
  };

  const handleFormModal = (actionParam = ACTION_TYPE.ADD) => {
    setActionType(actionParam);
    switch (actionParam) {
      case ACTION_TYPE.ADD:
        setTitle("Add Asset");
        setFormModal(true);
        break;
      case ACTION_TYPE.EDITED:
        if (selectedData.length > 0 && selectedData.length < 2) {
          setTitle("Edit Asset");
          setFormModal(true);
          const id = selectedData[0];
          handleEditAction(id);
        }
        break;
      case ACTION_TYPE.DELETED:
        setTitle("Delete");
        setFormModal(true);
        break;
    }
  };

  const handleSubmit = (/** @type {EventType} */ event) => {
    event.preventDefault();
    if (actionType === ACTION_TYPE.DELETED) {
      setFormModal(false);
      handleDeleteRow();
    } else {
      if (validateForm()) {
        if (actionType === ACTION_TYPE.EDITED) {
          handleEditRow();
        } else if (actionType === ACTION_TYPE.ADD) {
          handleAddRow();
        }
      }
    }
  };

  const handleInputChange = (/**@type {EventType} */ event) => {
    const { name, value } = event.target;

    if (name === "category_id") {
      setSelectedCategotyId(value);
      setFormValues((prev) => ({
        ...prev,
        brand_id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
      }));
    }

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputBlur = (/** @type {EventType} */ event) => {
    const { name } = event.target;
    validateField(name);
  };

  const handleDetailAsset = ({ id }) => {
    // setTitle("Detail Asset")
    // setFormModal(true);
    // setSelectedId(id);
    // dispatch(detailAsset(token, id));
    //* No detail action
  };

  const formatStringToNumber = (/**@type {string} */ value, sparator = ",") => {
    value = String(value);

    value = value.replace(new RegExp(sparator, "g"), "");

    return parseInt(value);
  };

  //* ------------------------------- [ EDIT SECTION ] --------------------------------*/

  // useEffect(() => {
  //   if (detail !== null) {
  //     setDetailDoc(detail);
  //     setPayload(detail);
  //   }
  // }, [detail]);

  const handleEditAction = async (detailId) => {
    try {
      setIsLoadedFormValues(false);
      const response = await axios.get(
        GLOBAL_NAME.apiEndpoint.concat("/", detailId, "/edit"),
        headersAPIToken(token)
      );
      if (response.status === 200 && response.data.status === true) {
        setPayload(response.data.data);
        setSelectedId(detailId);
      }
    } catch (error) {
      console.error(`Error while get detail : ${error.message}`);
    }
  };

  const setPayload = (detailPayload) => {
    const newPayload = {
      id: detailPayload.id,
    };
    fields.forEach((field) => {
      newPayload[field.name] = detailPayload[field.name];
    });
    setSelectedCategotyId(newPayload["category_id"]);
    setFormValues(newPayload);
    setIsLoadedFormValues(true);
  };

  //* =================================================================================*//
  //*                                  HANDLER REDUCER                                 *//
  //* =================================================================================*//

  const handleAddRow = () => {
    const manipulated = manipulatePayload();
    // console.log(manipulated);
    // alert("done");
    dispatch(addAsset(token, manipulated)).then(() => {
      // code when process done
      setFormModal(false);
      resetForm();
      setSelectedData([]);
      dispatch(
        getAsset(token, {
          category,
          search,
          searchBy,
          sortBy,
          sortDirection,
          limit,
          page,
        })
      );
    });
  };
  const handleEditRow = () => {
    const manipulated = manipulatePayload();
    dispatch(updateAsset(token, manipulated, selectedId)).then(() => {
      setFormModal(false);
      resetForm();
      setSelectedData([]);
      setSelectedId(0);
      dispatch(
        getAsset(token, {
          category,
          search,
          searchBy,
          sortBy,
          sortDirection,
          limit,
          page,
        })
      );
    });
  };
  const handleDeleteRow = () => {
    const ids = selectedData.join(",");
    dispatch(deleteAsset(token, ids)).then(() => {
      setSelectedData([]);
      dispatch(
        getAsset(token, {
          category,
          search,
          searchBy,
          sortBy,
          sortDirection,
          limit,
          page,
        })
      );
    });
  };

  const manipulatePayload = () => {
    const copyForm = { ...formValues };

    //? Can be improve to ignore some field
    const numberField = fields
      .filter((field) => field.type === INPUT_TYPE.NUMBER)
      .map((field) => field.name);

    numberField.forEach((fieldName) => {
      copyForm[fieldName] = formatStringToNumber(copyForm[fieldName]);
    });

    return copyForm;
  };

  //* =================================================================================*//
  //*                                     COMPONENTS                                   *//
  //* =================================================================================*//

  const ModifiedLeftToolbar = () => {
    let exportUrl = process.env.REACT_APP_API_URL.concat("/api/assets/export");
    if (search) {
      exportUrl = exportUrl.concat(
        "?search_by=",
        searchBy,
        "&search_value=",
        search
      );
    }
    if (
      category !== ACTION_TYPE.DEFAULT_CATEGORY &&
      category !== ACTION_TYPE.ALL_CATEGORY
    ) {
      const alredyHasQuery = exportUrl.includes("?");
      if (alredyHasQuery) {
        exportUrl += "&category_id=".concat(category);
      } else {
        exportUrl += "?category_id=".concat(category);
      }
    }
    return (
      <span>
        <Button
          size="medium"
          color="primary"
          disabled={
            (search === "" || rows?.length < 1) &&
            category === ACTION_TYPE.DEFAULT_CATEGORY
          }
          sx={{
            ...btnTableToolbar,
            borderRadius: "5px !important",
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
          onClick={() => {
            window.open(exportUrl, "_blank");
          }}
        >
          <img
            src={ExportIcon}
            style={{
              ...imgBtnToolbar,
              marginRight: 5,
            }}
          />
          Export
        </Button>
      </span>
    );
  };
  const LoadingComponent = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <FacebookCircularProgress />
    </Box>
  );
  //* =================================================================================*//
  //*                                         MODAL                                    *//
  //* =================================================================================*//

  const renderFormContent = () => {
    if (!isLoadedFormValues || isLoading) {
      return <LoadingComponent />;
    }
    return (
      <React.Fragment>
        {actionType === ACTION_TYPE.DELETED ? (
          <ConfirmDelete userDeleted={"Assets"} />
        ) : (
          fields.map((field) => {
            switch (field.type) {
              case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
                let endpoint = field.apiEndpoint;
                const additionalProps = {};
                if (field.name === "brand_id") {
                  //* Disabled when category not selected
                  if (selectedCategoryId == 0) {
                    additionalProps.disabled = true;
                  } else {
                    endpoint = endpoint.concat(
                      "?category_id=",
                      selectedCategoryId
                    );
                    additionalProps["optionPayload"] = {
                      category_id: selectedCategoryId,
                    };
                    additionalProps["disabled"] = false;
                  }
                }
                return (
                  <>
                    <SelectOneAutocompleteSearchManageble
                      errors={errors}
                      handleInputChange={handleInputChange}
                      name={field.name}
                      title={field.label}
                      url={endpoint}
                      field={field}
                      formValues={formValues}
                      additionalProps={additionalProps}
                    />
                  </>
                );
              case INPUT_TYPE.TEXT:
              case INPUT_TYPE.NUMBER:
                return (
                  <Box width={field.name === "qty" ? "5rem" : "inherit"}>
                    <TextInput
                      errors={errors}
                      field={field}
                      formValues={formValues}
                      handleInputBlur={handleInputBlur}
                      handleInputChange={handleInputChange}
                    />
                  </Box>
                );
              default:
                return null;
            }
          })
        )}
      </React.Fragment>
    );
  };
  const renderActionButtons = () => {
    if (isLoadedFormValues && isLoading === false) {
      return (
        <Box>
          <Button
            variant="outlined"
            sx={{ ...btnWhite }}
            onClick={() => handleCloseFormModal()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disableElevation
            type="submit"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      );
    } else return null;
  };
  const handleCloseFormModal = (important = false) => {
    // console.log({ important });
    if (important) {
      setFormModal(false);
      resetForm();
      setSelectedData([]);
    } else {
      setConfirmModal(true);
    }
  };

  const onCloseConfirmModal = () => {
    // alert("Cancel");
    setConfirmModal(false);
  };
  const onConfirmModal = () => {
    // alert("Close");
    setConfirmModal(false);
    handleCloseFormModal(true);
  };
  //* =================================================================================*//
  //*                                   HANDLER PAGING                                 *//
  //* =================================================================================*//

  useEffect(() => {
    if (!search && search !== "") {
      console.log(search);
      _search();
      setChoosedStatus("SEARCH STATUS");
    } else {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
    }
  }, [search]);

  useEffect(() => {
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (category !== ACTION_TYPE.DEFAULT_CATEGORY) {
      dispatch(
        getAsset(token, {
          search,
          searchBy,
          sortBy,
          sortDirection,
          category,
          limit,
          page,
        })
      );
    }
  }, [sortBy, sortDirection]);

  useEffect(() => {
    if (category !== ACTION_TYPE.DEFAULT_CATEGORY) {
      dispatch(
        getAsset(token, {
          search,
          searchBy,
          sortBy,
          sortDirection,
          category,
          limit,
          page,
        })
      );
      setChoosedStatus("SHOW LIST");
    } else {
      setChoosedStatus(ACTION_TYPE.DEFAULT_STATUS);
    }
  }, [category]);

  //   function _status(newStatus) {}
  function _category(newCategory) {
    setCategory(newCategory);
  }
  function handleKeyPress(/**@type {KeyboardEvent} */ event) {
    console.log(event.key);
    if (event.key === "Enter") {
      _search();
    }
  }
  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };
  const handleLimitChange = (newLimit) => {
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };
  const handleSelectionChange = (selection) => {
    setSelectedData(selection || []);
  };
  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const _getByPage = (newPage) => {
    dispatch(
      getAsset(token, {
        search,
        searchBy,
        sortBy,
        sortDirection,
        category,
        limit,
        page: newPage,
      })
    );
  };
  const _getByLimit = (newLimit) => {
    dispatch(
      getAsset(token, {
        search,
        searchBy,
        sortBy,
        sortDirection,
        category,
        limit: newLimit,
        page,
      })
    );
  };
  function _search() {
    dispatch(
      getAsset(token, {
        search,
        searchBy,
        sortBy,
        sortDirection,
        category,
        limit,
        page,
      })
    );
  }

  const [menuCategory, setMenuCategory] = useState([]);

  useEffect(() => {
    const fetchCategoryMenu = () => {
      axios
        .get("/master/asset-categories", headersAPIToken(token))
        .then((response) => {
          if (response.status == 200 && response.data.status === true) {
            setMenuCategory(response.data.data);
          }
        })
        .catch((error) => {
          console.error(`Failed get menu category : ${error.message}`);
        });
    };

    fetchCategoryMenu();
  }, []);

  const getNameForPlaceholder = () => {
    let result = "";
    Object.entries(searchByKey).forEach(([key, val]) => {
      if (searchBy === val) {
        result = String(key).toLowerCase();
      }
    });
    return result;
  };

  //* =================================================================================*//
  //*                                      WATCHER                                     *//
  //* =================================================================================*//

  useEffect(() => {
    console.log("this is form Values", formValues);
  }, [formValues]);

  useEffect(() => {
    console.log("This is errors", errors);
  }, [errors]);

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder={`Search by ${getNameForPlaceholder()}`}
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                  endAdornment: (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      SelectProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      sx={{
                        borderRadius,
                      }}
                      value={searchBy}
                      sx={{
                        "& fieldset": {
                          border: "unset",
                        },
                        borderLeft: `2px solid #ddd`,
                        width: "14rem",
                      }}
                      onChange={(e) => setSearchBy(e.target.value)}
                    >
                      {/* Search By */}
                      {Object.entries(searchByKey).map(([key, val]) => (
                        <MenuItem value={val}>{key}</MenuItem>
                      ))}
                    </TextField>
                  ),
                  onKeyDown: handleKeyPress,
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={category}
                onChange={(e) => _category(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_CATEGORY}>
                  Select Category
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_CATEGORY}>
                  All Category
                </MenuItem>
                {/* <MenuItem value="Pending">Dynamic value</MenuItem> */}
                {menuCategory.length &&
                  menuCategory.map((cat) => {
                    return <MenuItem value={cat.id}>{cat.name}</MenuItem>;
                  })}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* ------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Asset"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={choosedStatus}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            componentModifyToolbarLeft={ModifiedLeftToolbar}
            // userAccess={userAccess}
            handleRowDoubleClick={handleDetailAsset}
          />
        </CardContent>
      </Card>

      <Modal open={formModal} onClose={() => {}}>
        <Form
          title={title}
          onCloseModal={() => handleCloseFormModal()}
          elementForm={renderFormContent}
          elementActionButton={renderActionButtons}
          onSubmit={handleSubmit}
        />
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={onCloseConfirmModal}
        onCloseModal={onConfirmModal}
      ></ModalConfirmCancel>
    </>
  );
}
