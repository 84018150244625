import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Checkbox,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import SearchIconBlack from "./../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
} from "../../util/style";

import Form, {
  PasswordInputWithValidation,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
} from "../../components/Form";
import {
  addAttachment,
  deleteAttachment,
  editAttachment,
  getAttachment,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  INPUT_TYPE,
  SELECT_OPTION,
  MODE_CRUD_WITH,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";

const Attachment = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */
  const columns = [
    {
      field: "code",
      headerName: "Attachment Code",
      width: 180,
      disableColumnMenu: true,
    },
    {
      field: "attachment_name",
      headerName: "Attachment Name",
      width: 160,
      disableColumnMenu: true,
    },
    {
      field: "formats",
      headerName: "Format",
      // description: "This column has a value getter and is not sortable.",
      // sortable: false,
      width: 165,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => {
        // 'params.value' will be the 'statuses' array
        return (
          <Box
            sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
          >
            {params.value.map((val, key) => (
              <StatusBadge
                key={key}
                status={val.name}
                isinfo={true}
                style={{ margin: ".1rem .25rem" }}
              >
                {val.name}
              </StatusBadge>
            ))}
          </Box>
        );
      },
    },
    {
      field: "sizes",
      headerName: "Maximum Size",
      width: 120,
      disableColumnMenu: true,
      valueGetter: (params) => `${params.value} MB`,
    },
    {
      field: "service_categories",
      headerName: "Service Category",
      width: 280,
      disableColumnMenu: true,
      // sortable: false,
      valueGetter: (params) => {
        // Assuming 'params.row.service_categories' is an array of objects
        return params.row.service_categories
          .map((item) => item.service.name)
          .join(", ");
      },
      renderCell: (params) => {
        return (
          <Box
            sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
          >
            {params.value.split(", ").map((name, key) => (
              <StatusBadge
                key={key}
                status={name}
                isinfo={true}
                style={{ margin: ".1rem .25rem" }}
              >
                {name}
              </StatusBadge>
            ))}
          </Box>
        );
      },
    },

    {
      field: "sub_service_categories",
      headerName: "Sub Service Category",
      width: 280,
      disableColumnMenu: true,
      // sortable: false,
      valueGetter: (params) => {
        // Assuming 'params.row.service_categories' is an array of objects
        const subServices = params.row.service_categories.flatMap((item) =>
          item.sub_services.map((subService) => subService.name)
        );
        return subServices.join(", ");
      },
      renderCell: (params) => {
        return (
          <Box
            sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
          >
            {params.value.split(", ").map((name, key) => (
              <StatusBadge
                key={key}
                status={name}
                isinfo={true}
                style={{ margin: ".1rem .25rem" }}
              >
                {name}
              </StatusBadge>
            ))}
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      // description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 120,
      disableColumnMenu: true,
      valueGetter: (params) => params.value, // Return the status value as a string
      renderCell: (params) => (
        <StatusBadge status={params.value}> {params.value} </StatusBadge>
      ),
    },
  ];

  const { token } = useSelector((state) => state.auth);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add New Attachment");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [options, setOptions] = useState({});
  const dispatch = useDispatch();

  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.attachments);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search])
const handleKeyPress = (e) => {
  if (e.key === "Enter" && search) {
    _search();
  }
};
;

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getAttachment({ token, limit, search, status, sortDirection, sortBy })
      );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [status]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getAttachment({ token, limit, search, status, sortDirection, sortBy })
      );
    }
  }, [isActive]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    }
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle("Delete Attachment");
      const data = rows.filter((row) => selectedData.includes(row.id));
      setUserDeleted(
        `the attached file '${data
          .map((row) => row.attachment_name)
          .join(", ")}'`
      );
    }
  };

  const handleCloseFormModal = (important = false) => {
    setFormModal(false);
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    setSelectedData([]); //buat disabled button toolbar
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteAttachment(token, { data: { ids } }, true));
  };

  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

  const renderFormContent = () => {
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={userDeleted} />;
    }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        >
          {actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"}
        </Button>
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getAttachment({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
        })
      );
    }
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getAttachment({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getAttachment({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
      })
    );
  }
  function _search() {
    if(status !== ACTION_TYPE.DEFAULT_STATUS) {
      dispatch(
        getAttachment({ token, limit, search, status, sortDirection, sortBy })
      );
    }
  }
  function _status(status) {
    setStatus(status);
    // dispatch(
    //   getAttachment({ token, limit, search, status, sortDirection, sortBy })
    // );
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    // console.log(selection);
    setSelectedData(selection || []);
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                placeholder="Search attachment file name"
                size="small"
                InputProps={{
                  startAdornment: (
                    <img
                      src={SearchIconBlack}
                      style={{ width: "20px", margin: " 0 8px 0 0" }}
                    />
                  ),
                }}
                sx={{
                  borderRadius,
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inactive">Inactive</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Attachment"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            statusChoosed={status}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            modeCRUD={MODE_CRUD_WITH.PAGING}
            search={search}
            linkCRUD={{ create: "add", update: `edit/${selectedData}` }}
          />
        </CardContent>
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Attachment;
