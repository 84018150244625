import axios, { headersAPIToken, urlRequest } from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  GET_SALES_ORDER_SUCCESS,
  GET_SALES_ORDER_FAILED,
  ADD_SALES_ORDER_SUCCESS,
  ADD_SALES_ORDER_FAILED,
  EDIT_SALES_ORDER_SUCCESS,
  EDIT_SALES_ORDER_FAILED,
  DELETE_SALES_ORDER_FAILED,
  DELETE_SALES_ORDER_SUCCESS,GET_SALES_ORDER_DETAILS_FAILED,GET_SALES_ORDER_DETAILS_SUCCESS
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getSalesOrder = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  sortDirection = null,
  sortBy = null,
  dateFilter = null,
  startDate = dateFilter !== "custom_date" ? null : startDate,
  endDate = dateFilter !== "custom_date" ? null : endDate,
  category = null,
}) => {
  console.log(sortDirection, sortBy, "sortDirection, sortBy,");
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "sales-orders",
          page,
          limit,
          search,
          dateFilter: dateFilter == ACTION_TYPE.ALL_DATE ? null : dateFilter,
          startDate: dateFilter !== "custom_date" ? null : startDate,
          endDate: dateFilter !== "custom_date" ? null : endDate,
          category: category == ACTION_TYPE.ALL_CATEGORY ? null : category,
          status: status == ACTION_TYPE.ALL_STATUS ? null : status,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getSalesOrderSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getSalesOrderSuccess([], null));
        } else {
          dispatch(getSalesOrderFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getSalesOrderFailed(error.response.data.message));
          }
        } else {
          dispatch(getSalesOrderFailed(error.message));
        }
      });
  };
};

const getSalesOrderSuccess = (data, pagination) => {
  return {
    type: GET_SALES_ORDER_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getSalesOrderFailed = (errorMessage) => {
  return {
    type: GET_SALES_ORDER_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */
const transformToJson = (inputJson) => {
  const transformedJson = {
    client_id: inputJson.Client.client_id,
    service_id: inputJson.Service.service_id,
    currency: inputJson.Service.currency,
    date: inputJson.Client.date,
    referral: inputJson.Client.referral,
    price:inputJson["Price Information"].price_services_info_to_rp,
    total_price:inputJson["Price Information"].total_price_info_to_rp,
    total_vat:inputJson["Price Information"].vat_info_to_rp,
    total_discount:inputJson["Price Information"].discount_info_to_rp,
    sub_services: inputJson["Sub Service"].map(subService => ({
      sub_service_id: subService.sub_service_id,
      criteria_id: subService.criteria_id,
      price: subService.price,
      quantity: subService.quantity,
      subtotal: subService.subtotal,
      total: subService.total_to_rp,  
      discount: subService.discount,
      vat_percent: Array.isArray(subService.vat_percent) && subService.vat_percent.length === 1 ? parseInt(subService.vat_percent[0], 10) : 0,
      desc: subService.desc,
    })),
    attachments: inputJson.Attachment.attachment,
    remarks: inputJson["Note Section"].remarks,
    note: inputJson["Note Section"].note,
  };
  debugger;

  return transformedJson;
};
export const addSalesOrder = (token, formData) => {
  // debugger;
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        "sales-orders",
        transformToJson(formData),
        {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      }
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "sales order"));
          dispatch(addSalesOrderSuccess(response.data.message));
          localStorage.setItem("sales-management/sales-order/formValues", JSON.stringify({}));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          alert(response.data.message);
          dispatch(addSalesOrderFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(addSalesOrderFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            alert(error.response.data.message);
            dispatch(addSalesOrderFailed(error.response.data.message));
          }
        } else {
          dispatch(addSalesOrderFailed(error.message));
        }
      });
  };
};

const addSalesOrderSuccess = (message) => {
  return {
    type: ADD_SALES_ORDER_SUCCESS,
    payload: message,
  };
};

const addSalesOrderFailed = (message) => {
  return {
    type: ADD_SALES_ORDER_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editSalesOrder = (token, formData, id) => {
  // debugger;
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        `sales-orders/${id}/update`,
        transformToJson(formData),
        {
        headers: {
          "Content-Type": "multipart/form-data", // Set the content type for file uploads
          Authorization: `Bearer ${token}`,
        },
      }
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "sales order"));
          dispatch(editSalesOrderSuccess(response.data.message));
          localStorage.setItem("sales-management/sales-order/formValues", JSON.stringify({}));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          alert(response.data.message);
          dispatch(editSalesOrderFailed(response.data.message));
        } else {
          alert(response.data.message);
          dispatch(editSalesOrderFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editSalesOrderFailed(error.response.data.message));
          }
        } else {
          dispatch(editSalesOrderFailed(error.message));
        }
      });
  };
};

const editSalesOrderSuccess = (message) => {
  return {
    type: EDIT_SALES_ORDER_SUCCESS,
    payload: message,
  };
};

const editSalesOrderFailed = (message) => {
  return {
    type: EDIT_SALES_ORDER_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteSalesOrder = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`sales-orders${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "sales order"));
          dispatch(deleteSalesOrderSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteSalesOrderFailed(response.data.message));
        } else {
          dispatch(deleteSalesOrderFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteSalesOrderFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteSalesOrderFailed(error.message));
        }
      });
  };
};

const deleteSalesOrderSuccess = (message) => {
  return {
    type: DELETE_SALES_ORDER_SUCCESS,
    payload: message,
  };
};

const deleteSalesOrderFailed = (message) => {
  return {
    type: DELETE_SALES_ORDER_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};


/* -------------------------------------------------------------------------- */
/*                                 GET DETAIL                                 */
/* -------------------------------------------------------------------------- */

export const getSalesOrderDetails = ({
  token,
  id = null,
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: `sales-orders/${id}/detail`,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getSalesOrderDetailsSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getSalesOrderDetailsSuccess(null));
        } else {
          dispatch(getSalesOrderDetailsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getSalesOrderDetailsFailed(error.response.data.message));
          }
        } else {
          dispatch(getSalesOrderDetailsFailed(error.message));
        }
      });
  };
};

const getSalesOrderDetailsFailed = (errorMessage) => {
  return {
    type: GET_SALES_ORDER_DETAILS_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

const getSalesOrderDetailsSuccess = (data, pagination) => {
  return {
    type: GET_SALES_ORDER_DETAILS_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};
