import React, { useEffect, useState } from "react";
import {
  createTheme,
  Toolbar,
  IconButton,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  MenuItem,
  ThemeProvider,
  Chip,
  Box,
  Modal,
} from "@mui/material";
import addIcon from "./../../../assets/add.png";
import editIcon from "./../../../assets/edit.png";
import deleteIcon from "./../../../assets/delete.png";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StatusBadge,
  borderRadius,
  btnWhite,
  defaultStylePage,
  mainColor,
  surfaceColor,
  neutral10,
  neutral40,
  neutral100,
} from "../../../util/style";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { useTheme } from "@mui/styles";
import DataTable from "../../../components/Table";
import { useDispatch, useSelector } from "react-redux";
import { deleteReturn, getReturn } from "./clients/actions";
import {
  ACTION_TYPE,
  INPUT_TYPE,
  MODE_CRUD_WITH,
  formatDate,
} from "../../../util/function";
import Form, { SelectOneAutocomplete, SelectOneAutocompleteSearch } from "../../../components/Form";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import ConfirmDelete from "../../../components/ConfirmDelete";
import axios from "../../../core/axios_config";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    field: "return_code",
    headerName: "Return Number",
    width: 135,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <div><span>{params.value.replace("-", "")}</span></div>
      );
    }
  },
  {
    field: "return_date",
    headerName: "Return Date",
    width: 201,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.get_request.return_date;
      return sc;
    },
    renderCell: (params) => {
      return (
        <div><span>{formatDate(params.value)}</span></div>
      );
    }
  },
  {
    field: "actual_return_date",
    headerName: "Actual Return Date",
    width: 201,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <div><span>{formatDate(params.value)}</span></div>
      );
    }
  },
  {
    field: "received_by",
    headerName: "Received By",
    width: 201,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.get_recived_by.fullname;
      return sc;
    },
  },

  {
    field: "request_number",
    headerName: "Request Number",
    width: 201,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.get_request.request_code;
      return sc;
    },
    renderCell: (params) => {
      return (
        <div><span>{params.value.replace("-", "")}</span></div>
      );
    }
  },
  {
    field: "request_date",
    headerName: "Request Date",
    width: 201,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.get_request.request_date;
      return sc;
    },
    renderCell: (params) => {
      return (
        <div><span>{formatDate(params.value)}</span></div>
      );
    }
  },
  {
    field: "request_by",
    headerName: "Request By",
    width: 201,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.get_request.get_user.fullname;
      return sc;
    },
  },
  {
    field: "customer_name",
    headerName: "Customer Name",
    width: 291,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.get_request.customer_name;
      return sc;
    },
  },
  {
    field: "service_category",
    headerName: "Service Category",
    width: 301,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.get_return;
      return sc;
    },
    renderCell: (params) => {
      return (
        <Box
          sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
        >
          {params.value.map((v, key) => (
            <StatusBadge
              key={key}
              status={v.get_service_category ? v.get_service_category.name : ""}
              isinfo={true}
              style={{ margin: ".1rem .25rem" }}
            >
              {v.get_service_category ? v.get_service_category.name : ""}
            </StatusBadge>
          ))}
        </Box>
      );
    },
  },
  {
    field: "sub_service_category",
    headerName: "Sub Service Category",
    width: 301,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.get_return;
      let ssc = sc.flatMap((v) => v.get_sub_service_category);
      return ssc;
    },
    renderCell: (params) => {
      return (
        <Box
          sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
        >
          {params.value.map((v, key) => (
            <StatusBadge
              key={key}
              status={v ? v.name : ""}
              isinfo={true}
              style={{ margin: ".1rem .25rem" }}
            >
              {v ? v.name : ""}
            </StatusBadge>
          ))}
        </Box>
      );
    },
  },
  {
    field: "registration_number",
    headerName: "Registration Number",
    width: 291,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <div></div>
      );
    }
  },
  {
    field: "reference_number",
    headerName: "Reference Number",
    width: 291,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <div></div>
      );
    }
  },
  {
    field: "brand",
    headerName: "Brand",
    width: 291,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <div></div>
      );
    }
  },
  {
    field: "applicant",
    headerName: "Applicant",
    width: 291,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <div></div>
      );
    }
  },
  {
    field: "cupboard_name",
    headerName: "Cupboard Name",
    width: 301,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.get_request.get_archive_service;
      return sc;
    },
    renderCell: (params) => {
      return (
        <Box
          sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
        >
          {params.value.map((v, key) => (
            <StatusBadge
              key={key}
              status={v.get_archive_detail.get_cupboard.cupboard_name}
              isinfo={true}
              style={{ margin: ".1rem .25rem" }}
            >
              {v.get_archive_detail.get_cupboard.cupboard_name}
            </StatusBadge>
          ))}
        </Box>
      );
    },
  },
  {
    field: "rack_number",
    headerName: "Rack Number",
    width: 301,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.get_request.get_archive_service;
      return sc;
    },
    renderCell: (params) => {
      return (
        <Box
          sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}
        >
          {params.value.map((v, key) => (
            <StatusBadge
              key={key}
              status={v.get_archive_detail.get_rack.rack_number}
              isinfo={true}
              style={{ margin: ".1rem .25rem" }}
            >
              Rack {v.get_archive_detail.get_rack.rack_number}
            </StatusBadge>
          ))}
        </Box>
      );
    },
  },
  {
    field: "note",
    headerName: "Note",
    width: 240,
    disableColumnMenu: true,
  },
];

const fields = [
  {
    name: "request_by",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    label: "Request By",
    defaultValue: "",
    apiEndpoint: "/return/get_request",
    required: true,
  },
  {
    name: "request_number",
    type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
    label: "Request Number",
    defaultValue: "",
    required: true,
  },
];

const Return = () => {
  const theme = useTheme();
  const useStyles = defaultStylePage;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [selectedData, setSelectedData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("customer_name");
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [returnDeleted, setReturnDeleted] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [confirmModal, setConfirmModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [title, setTitle] = useState("Add New Return");
  const [typeData, setTypeData] = useState("Hardcopy");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [dateFilter, setDateFilter] = useState(ACTION_TYPE.DEFAULT_DATE);
  const [categoryFilter, setCategoryFilter] = useState(
    ACTION_TYPE.ALL_CATEGORY
  );
  const placeholder = {
    "customer_name": "Customer Name",
    "Brand": "Brand",
    "Applicant": "Applicant",
    "reference_number": "No. Reference",
    "registration_number": "No. Registration",
  }
  const [listCategory, setlistCategory] = useState([]);
  const [selectedReqById, setSelectedReqById] = useState("");
  const initialErrors = {};
  const [errors, setErrors] = useState(initialErrors);
  const initialFormValues = (type = "fields") => {
    return fields.reduce(
      (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
      {}
    );
  };
  const [formValues, setFormValues] = useState(
    Object.assign({}, initialFormValues("fields"))
  );
  const [options, setOptions] = useState({
    request_number: [],
  });

  // useSelector
  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.return);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  // validate
  const validateField = (fieldName, serviceIndex) => {
    const newErrors = { ...errors };

    if (fieldName === "request_by") {
      newErrors.customer_id = !formValues.request_by
        ? "Request By is required"
        : null;
    }
    if (fieldName === "request_number") {
      newErrors.customer_id = !formValues.request_number
        ? "Request Number is required"
        : null;
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (!formValues.request_by) {
      newErrors.request_by = "Request By is required";
      valid = false;
    } else {
      newErrors.request_by = null;
    }
    if (!formValues.request_number) {
      newErrors.request_number = "Request Number is required";
      valid = false;
    } else {
      newErrors.request_number = null;
    }

    setErrors(newErrors);
    return valid;
  };

  // useEffect
  useEffect(() => {
    dispatch(
      getReturn({
        token,
        page,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    );
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getReturn({
          token,
          limit,
          searchValue,
          searchBy,
          sortDirection,
          sortBy,
        })
      );
    }
  }, [isActive]);

  useEffect(() => {
    axios
      .get("/master/services", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setlistCategory(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching service categories:", error);
      });
  }, []);

  useEffect(() => {
    const fetchOptions = async (apiEndpoint, name) => {
      try {
        const response = await axios.get(apiEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const modifiedData = response.data.data.map((item) => {
          if (item.hasOwnProperty("fullname")) {
            return {
              ...item,
              name: item.fullname,
            };
          } else {
            return item;
          }
        });
        const newmodifiedData = modifiedData.map((item) => {
          if (item.hasOwnProperty("created_by")) {
            return {
              ...item,
              id: item.created_by,
            };
          } else {
            return item;
          }
        });
        setOptions((prevOptions) => ({
          ...prevOptions,
          [name]: newmodifiedData,
        }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    };

    fields.forEach((field) => {
      const { name, apiEndpoint } = field;
      if (apiEndpoint) {
        fetchOptions(apiEndpoint, name);
      }
    });
  }, []);

  // useEffect(() => {
  //   if (selectedReqById) {
  //     axios
  //       .get(`return/get_request?req_code=${selectedReqById}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((response) => {
  //         const modifiedData = response.data.data.map((item) => {
  //           if (item.hasOwnProperty("request_number")) {
  //             return {
  //               ...item,
  //               name: item.request_number,
  //               id: item.request_number,
  //             };
  //           } else {
  //             return item;
  //           }
  //         });
  //         setOptions((prevOptions) => ({
  //           ...prevOptions,
  //           request_number: modifiedData,
  //         }));
  //         // console.log(options);
  //         // debugger;
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching sub-service categories:", error);
  //       });
  //   }
  // });

  // handle

  const handlePageChange = (newPage) => {
    setPage(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(0);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  useEffect(() => {
    const filteredRows = rows.filter((row) => selectedData.includes(row.id));
    if (filteredRows.length === 1) {
      const newFormValues = {
        ...formValues,
        ["request_by"]: filteredRows[0].recived_by,
        ["request_number"]: filteredRows[0].get_request.request_code,
      };
      setFormValues(newFormValues);
      console.log(filteredRows, "filteredRows");
    }
  }, [selectedData]);

  useEffect(() => {
    if (!searchValue) { _search() }
  }, [searchValue])
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchValue) {
      _search();
    }
  };


  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  const handleRowDoubleClick = (selection) => {
    navigate(`detail/${selection.id}`, { state: { type: typeData } });
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    console.log("actionType==>" + ACTION_TYPE);
    if (actionType === ACTION_TYPE.ADD) {
      setTitle("Check Request Hardcopy");
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle(`Delete Return ${typeData}`);
      const data = rows.filter((row) => selectedData.includes(row.id));
      setReturnDeleted(data.map((row) => row.return_code).join(", "));
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        console.log("b", important);
        if (true) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log("handleInputChange", name, value);
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if (name === "request_by") {
      console.log("request_by");
      setSelectedReqById(value);
    }
  };

  const handleInputBlur = (e) => {
    const { name } = e.target;
    validateField(name);
  };

  const handleOnConfirmCancelModal = () => {
    console.log("handleOnConfirmCancelModal");
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    console.log("handleOnCloseConfirmCancelModal");
    resetFormValues();
    setSelectedData([]); //buat disabled button toolbar
  };
  const resetFormValues = () => {
    setFormValues(Object.assign({}, {
      request_by: "",
      request_number: "",
    }))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      const isValid = validateForm();
      if (isValid) {
        navigate("add", { state: { data: formValues, type: typeData } });
      }
    }
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteReturn(token, { data: { ids } }, true));
  };

  // render
  const renderFormContent = () => {
    if (actionType === ACTION_TYPE.ADD) {
      return (
        <div>
          <SelectOneAutocompleteSearch
            title="Request By"
            url="/return/get_request"
            handleInputChange={handleInputChange}
            name="request_by"
            errors={errors}
          />
          <Box sx={{ marginBottom: '16px' }} />
          <SelectOneAutocompleteSearch
            title="Request Number"
            url={`return/get_request?req_code=${selectedReqById}`}
            handleInputChange={handleInputChange}
            name="request_number"
            disabled={!formValues.request_by}
            errors={errors}
          />
        </div>
      )

      // return fields.map((field) => {
      //   const fieldOptions = Array.isArray(options[field.name])
      //     ? [...options[field.name]]
      //     : [];
      //   switch (field.type) {
      //     case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
      //       return (
      //         <SelectOneAutocomplete
      //           key={field.name}
      //           field={field}
      //           formValues={formValues}
      //           errors={errors}
      //           showError={false}
      //           placeholder="Search Cutomer Name"
      //           handleInputChange={handleInputChange}
      //           handleInputBlur={handleInputBlur}
      //           fieldOptions={fieldOptions}
      //         />
      //       );
      //     default:
      //       return null;
      //   }
      // });
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={`return ${typeData.toLowerCase()}`} />;
    }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        >
          {actionType === ACTION_TYPE.DELETED ? 'Yes' : 'Get It'}
        </Button>
      </Box>
    );
  };

  // function
  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getReturn({
        token,
        page,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getReturn({
        token,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    );
  }

  function _dateFilter(date) {
    setDateFilter(date);
    dispatch(
      getReturn({
        categoryFilter,
        dateFilter: date,
        token,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    );
  }

  function _categoryFilter(category) {
    setCategoryFilter(category);
    dispatch(
      getReturn({
        categoryFilter: category,
        dateFilter,
        token,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    );
  }

  const handleChangeType = (v) => {
    setTypeData(v);
  }

  const handleToolbarLeft = () => {
    return (
      <Box>
        <StatusBadge
          key={"Hardcopy"}
          status={"Hardcopy"}
          isinfo={false}
          colors={typeData === "Hardcopy" ? mainColor : neutral100}
          bgColor={typeData === "Hardcopy" ? surfaceColor : neutral10}
          onClick={() => handleChangeType("Hardcopy")}
          style={{
            marginRight: "16px",
            borderRadius: "48px",
            padding: "6px 16px 6px 16px",
            border: typeData !== "Hardcopy" ? `1px solid ${neutral40}` : 'none'
          }} >
          Hardcopy
        </StatusBadge>
        <StatusBadge
          key={"Softcopy"}
          status={"Softcopy"}
          isinfo={false}
          colors={typeData === "Softcopy" ? mainColor : neutral100}
          bgColor={typeData === "Softcopy" ? surfaceColor : neutral10}
          onClick={() => handleChangeType("Softcopy")}
          style={{
            marginRight: "16px",
            borderRadius: "48px",
            padding: "6px 16px 6px 16px",
            border: typeData !== "Softcopy" ? `1px solid ${neutral40}` : 'none'
          }} >
          Softcopy
        </StatusBadge >
      </Box >
    )
  }

  function _search() {
    dispatch(
      getReturn({
        categoryFilter,
        dateFilter,
        token,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    );
  }

  return (
    <>
      <Card style={{ boxShadow: "unset" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder={`Search ${placeholder[searchBy]}`}
                size="small" // Set the size to "small"
                sx={{
                  borderRadius, // Apply the desired border radius value
                }}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                  startAdornment: <SearchIcon />,
                  endAdornment: (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      SelectProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      value={searchBy}
                      sx={{
                        width: 250,
                        '& fieldset': {
                          border: 'unset',
                        }, borderLeft: `2px solid #ddd`
                      }}
                      onChange={(e) => setSearchBy(e.target.value)}
                    >
                      <MenuItem value="customer_name">Customer Name</MenuItem>
                      <MenuItem value="Brand">Brand</MenuItem>
                      <MenuItem value="Applicant">Applicant</MenuItem>
                      <MenuItem value="reference_number">No. Reference</MenuItem>
                      <MenuItem value="registration_number">No. Registration</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}>
                <SearchIcon />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                value={categoryFilter}
                size="small"
                disabled={dateFilter === ACTION_TYPE.DEFAULT_DATE}
                SelectProps={{
                  IconComponent: ExpandMoreIcon, // Use ExpandMoreIcon for the open state
                  // Comment out the line above and use the line below to use ExpandLessIcon for the open state
                  // IconComponent: ExpandLessIcon,
                }}
                onChange={(e) => _categoryFilter(e.target.value)}
                sx={{
                  borderRadius, // Apply the desired border radius value
                }}
              >
                <MenuItem value={ACTION_TYPE.ALL_CATEGORY}>
                  All service category
                </MenuItem>
                {listCategory.map((category) => (
                  <MenuItem value={category.id}>{category.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                value={dateFilter}
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon, // Use ExpandMoreIcon for the open state
                  // Comment out the line above and use the line below to use ExpandLessIcon for the open state
                  // IconComponent: ExpandLessIcon,
                }}
                onChange={(e) => _dateFilter(e.target.value)}
                style={{
                  borderRadius, // Apply the desired border radius value
                }}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_DATE}>
                  Select Date
                </MenuItem>
                <MenuItem value="all_date">All date</MenuItem>
                <MenuItem value="this_week">This week</MenuItem>
                <MenuItem value="this_month">This month</MenuItem>
                <MenuItem value="last_6_months">Last 6 months</MenuItem>
                <MenuItem value="this_year">This year</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Return"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={page}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            handleRowDoubleClick={handleRowDoubleClick}
            theme={theme}
            dateChoosed={dateFilter}
            search={searchValue}
            sortModel={sortModel}
            toolBarLeft={handleToolbarLeft}
            onSortModelChange={handleSortModelChange}
            modeCRUD={MODE_CRUD_WITH.PAGING}
            stateUpdate={{ data: formValues, type: typeData }}
            linkCRUD={{
              create: "add",
              update: `edit/${selectedData}`,
              createOption: "hybrid",
            }}
          />
        </CardContent>
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Return;
