import { REQUEST_LOADING } from "../../../util/global_state";

import { GET_USER_LOGS_FAILED, GET_USER_LOGS_SUCCESS } from "./state";

const initState = {
  data: [],
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
};

const userlogsReducer = (state = initState, { type, payload, pagination }) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_USER_LOGS_SUCCESS:
      return {
        data: payload,
        pagination: pagination,
        message: null,
        isLoading: false,
      };
    case GET_USER_LOGS_FAILED:
      return {
        ...state,
        message: payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default userlogsReducer;
