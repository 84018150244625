import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  Select,
  Checkbox,
  Divider,
  InputAdornment,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import RemoveIcon from "../../../assets/minus.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  removeButton,
  FacebookCircularProgress,
} from "../../../util/style";

import Form, {
  PasswordInputWithValidation,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  CheckboxGroupInput,
  RadioGroupInput,
  DatePickerInput,
  SelectOneAutocompleteSearch,
  FilePicker,
} from "../../../components/Form";
import {
  addInvoice,
  deleteInvoice,
  editInvoice,
  getDataFormAdd,
  getInvoice,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  INPUT_TYPE,
  SELECT_OPTION,
  formatCurrencyWithoutLable,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import LeftDrawer from "../../../components/LeftDrawer";
import {
  columns,
  extraFields,
  INVOICE_TYPE,
  referal_field,
} from "./parts/imutable_state";
import RenderFormContent from "../proforma-invoice/components/RenderFormContent";
import {
  LOCAL_ACTION_TYPE,
  fieldDrawerForEach,
  field_drawer,
} from "../proforma-invoice/parts/imutable_state";
import axios, { headersAPIToken } from "../../../core/axios_config";
import { SET_EDIT_ID } from "./clients/state";

const Invoice = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  /** @typedef {import('../proforma-invoice/clients/classType').FieldType} FieldType */
  /** @typedef {import('../proforma-invoice/clients/classType').ParentFieldType} ParentFieldType */

  const GLOBAL_NAME = Object.freeze({
    IGNORE_FIELD: [
      "payment_received",
      "currency_convert",
      "payment_received_convert",
      "payment_currency",
    ],
  });
  /* -------------------------------------------------------------------------- */
  /*                                    STATE                                   */
  /* -------------------------------------------------------------------------- */

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formModal, setFormModal] = useState(false);
  const [leftDrawer, setLeftDrawer] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [formStep, setFormStep] = useState(LOCAL_ACTION_TYPE.ADD_STEP_ONE);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(ACTION_TYPE.DEFAULT_STATUS);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [serviceCategory, setServiceCategory] = useState(
    ACTION_TYPE.DEFAULT_STATUS
  );
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [page, setPage] = useState(1);
  const [pageDB, setPageDB] = useState(0);
  const [limit, setLimit] = useState(10);
  const [title, setTitle] = useState("Add new Invoice");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [typeInvoice, setTypeInvoice] = useState(INVOICE_TYPE.GET_DATA_INVOICE);
  const [options, setOptions] = useState({});
  const [isLoadedFormValues, setIsLoadedFormValues] = useState(false);
  const [searchBy, setSearchBy] = useState("code");
  const [selectedClientId, setSelectedClientId] = useState(0);
  const [urlSourceInvoice, setUrlSourceInvoice] = useState("");
  const [priceInformation, setPriceInvormation] = useState({});
  const [optionKey, setOptionKey] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(0);
  const [canGetBankAddre, setCanGetBankAddre] = useState(true);
  const [canGetOptions, setGetOptions] = useState(false);
  const [listCategory, setListCategory] = useState([]);

  //* ================================================================== *//
  //*                             LOCAL REDUCER                          *//
  //* ================================================================== *//

  const STATE = {
    UPDATE_SHOW_PAYMENT_RECEIVE: "UPDATE_SHOW_PAYMENT_RECEIVE",
    UPDATE_SHOW_CONVERSION: "UPDATE SHOW CONVERSION",
    UPDATE_CAN_CONVERT: "UPDATE CAN CONVERT",
    UPDATE_DISABLED: "Update Disabled",
    DISABLED_ALL: "Disabled all state",
  };

  const formReducer = (state, { type, payload }) => {
    switch (type) {
      case STATE.UPDATE_CAN_CONVERT:
        return {
          ...state,
          canConvert: payload,
        };
      case STATE.UPDATE_SHOW_CONVERSION:
        return {
          ...state,
          showConversionReceive: payload,
        };
      case STATE.UPDATE_SHOW_PAYMENT_RECEIVE:
        return {
          ...state,
          showPaymentReceive: payload,
        };
      case STATE.UPDATE_DISABLED:
        return {
          ...state,
          canConvert: payload,
          showConversionReceive: payload,
        };
      case STATE.DISABLED_ALL:
        return {
          ...state,
          showPaymentReceive: false,
          canConvert: false,
          showConversionReceive: false,
        };
      default:
        return state;
    }
  };

  const initialState = {
    showPaymentReceive: false,
    canConvert: false,
    showConversionReceive: false,
  };

  const [formState, formDispatch] = useReducer(formReducer, initialState);

  useEffect(() => {
    if (selectedClientId !== 0) {
      if (typeInvoice === INVOICE_TYPE.PERFORMA_INVOICE) {
        setUrlSourceInvoice(
          "master/proforma-invoices?client_id=".concat(
            selectedClientId,
            "&status=Approved"
          )
        );
      } else if (typeInvoice === INVOICE_TYPE.SALES_ORDER) {
        setUrlSourceInvoice(
          "/master/sales-orders?client_id=".concat(selectedClientId)
        );
      }
    }
  }, [typeInvoice, selectedClientId]);

  const {
    data: rows,
    message,
    isLoading,
    pagination,
    payload_add_form,
  } = useSelector((state) => state.financeInvoice);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  const [searchParams, setSearchParams] = useSearchParams();
  const { edit_id } = useSelector((state) => state.financeInvoiceDetail);

  useEffect(() => {
    const paramId = searchParams.get("edit");
    if (paramId && edit_id) {
      let parsedEdit = parseInt(paramId);
      let parseEditId = parseInt(edit_id);
      if (
        !isNaN(parseEditId) &&
        !isNaN(parsedEdit) &&
        parsedEdit === parseEditId
      ) {
        // handleEdit(parseEditId);
        setIsLoadedFormValues(false);
        setGetOptions(true);
        setLeftDrawer(true);
        setActionType(ACTION_TYPE.EDITED);
        fetchEditData(parseEditId);
      }
    }
  }, [searchParams, edit_id]);

  useEffect(() => {
    setSelectedData([]); //buat disabled button toolbar
  }, [dispatch]);

  useEffect(() => {
    if (status !== ACTION_TYPE.DEFAULT_STATUS) {
      // dispatch(
      //   getDirector({ token, limit, search, status, sortDirection, sortBy })
      // );
    } else {
      setSelectedData([]); //buat disabled button toolbar
    }
  }, [status]);

  const resetFormValues = () => {
    setFormValues({});
    setErrors({});
    setTypeInvoice(INVOICE_TYPE.GET_DATA_INVOICE);
  };

  const fieldSalesOrder = [
    {
      name: "client_id",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      label: "Client Name",
      placeholder: "Select client name",
      defaultValue: 0,
      required: true,
      url: "/master/clients",
    },
    {
      name: "sales_order_id",
      type: INPUT_TYPE,
      label: "No. Sales Order",
      placeholder: "Select no. sales order",
      defaultValue: 0,
      required: true,
      url: "/master/sales-orders",
    },
  ];

  const fieldProformaInvoice = [
    {
      name: "client_id",
      type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
      label: "Client Name",
      placeholder: "Select client name",
      defaultValue: 0,
      required: true,
      url: "/master/clients",
    },
    {
      name: "proforma_invoice_id",
      type: INPUT_TYPE,
      label: "No. Proforma Invoice",
      placeholder: "Select no. proforma invoice",
      defaultValue: 0,
      required: true,
      url: "/master/clients",
    },
  ];

  /** Initial values */
  const intialAttachment = [""];
  /* ------------------------- PAKE COLUMN / FIELDS ? ------------------------- */

  /**
   * return value of initial formValues
   * @param {'modal'|'drawer'} type
   */
  const initialFormValues = (type, modelInvoice) => {
    const newFormValues = {};
    if (type === "drawer") {
      fieldDrawerForEach(
        (
          /**@type {FieldType} */ field,
          index,
          arr,
          /**@type {ParentFieldType} */ parentField
        ) => {
          if (!GLOBAL_NAME.IGNORE_FIELD.includes(field.name)) {
            if (!field.multiple && !field.hidden) {
              newFormValues[field.name] = field.defaultValue;
            } else if (field.multiple && parentField.title === "Service") {
              newFormValues[parentField.multipleValueName] =
                initialSubserviceValue("form");
            }
          }
        }
      );

      // If invoice type === Proforma, add extra field as proforma_invoices_id
      if (typeInvoice === INVOICE_TYPE.PERFORMA_INVOICE) {
        newFormValues[extraFields.name] = extraFields.defaultValue;
      }

      // Attachment field;
      newFormValues[field_drawer.attachment.field.name] = intialAttachment;
    } else {
      if (modelInvoice === INVOICE_TYPE.PERFORMA_INVOICE) {
        fieldProformaInvoice.forEach((field) => {
          newFormValues[field.name] = field.defaultValue;
        });
      } else if (modelInvoice === INVOICE_TYPE.SALES_ORDER) {
        fieldSalesOrder.forEach((field) => {
          newFormValues[field.name] = field.defaultValue;
        });
      } else {
        // console.log(typeInvoice);
      }
    }
    return newFormValues;
  };

  /**
   * return value of initial errors
   * @param {'modal'|'drawer'} type
   */
  const initialErrors = (type, modelInvoice) => {
    const newErrors = {};
    if (type === "drawer") {
      //* Drawer form
      fieldDrawerForEach(
        (
          /**@type {FieldType} */ field,
          index,
          arr,
          /**@type {ParentFieldType} */ parentField
        ) => {
          if (!GLOBAL_NAME.IGNORE_FIELD.includes(field.name)) {
            if (field.multiple && parentField.title === "Subservice") {
              newErrors[field.name] = initialSubserviceValue("error");
            } else {
              newErrors[field.name] = "";
            }
          }
        }
      );
      if (typeInvoice === INVOICE_TYPE.PERFORMA_INVOICE) {
        newErrors[extraFields.name] = "";
      }
      newErrors[field_drawer.attachment.field.name] = intialAttachment;
    } else {
      // modal form
      if (modelInvoice === INVOICE_TYPE.PERFORMA_INVOICE) {
        fieldProformaInvoice.forEach((field) => {
          newErrors[field.name] = "";
        });
      } else if (modelInvoice === INVOICE_TYPE.SALES_ORDER) {
        fieldSalesOrder.forEach((field) => {
          newErrors[field.name] = "";
        });
      }
    }
    return newErrors;
  };

  /**
   * return initial subservice value in formValues
   * @param {'form' | 'error'} type
   */
  const initialSubserviceValue = (type) => {
    const localForm = {};
    fieldDrawerForEach((/**@type {FieldType} */ field) => {
      if (field.multiple) {
        if (type === "form") {
          localForm[field.name] = field.defaultValue;
        } else {
          localForm[field.name] = "";
        }
      }
    });
    // console.log("this is local form", localForm);
    return [localForm];
  };

  //* Form Values
  const [formValues, setFormValues] = useState({});

  const [errors, setErrors] = useState({});

  // useEffect(() => {
  //   const filteredRows = rows.filter((row) => selectedData.includes(row.id));
  //   // console.log(filteredRows, "filteredRows");
  // }, [selectedData]);

  useEffect(() => {
    if (!search) {
      _search();
    }
  }, [search]);
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search) {
      _search();
    }
  };

  useEffect(() => {
    // console.log(sortModel, "newSortModel");
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getInvoice({
          token,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          searchBy,
        })
      );
    }
  }, [isActive]);

  //* Watch perubahan form actionType
  useEffect(() => {
    setIsLoadedFormValues(false);
    if (
      actionType === ACTION_TYPE.ADD &&
      typeInvoice !== INVOICE_TYPE.GET_DATA_INVOICE
    ) {
      setIsLoadedFormValues(true);
    } else {
    }
  }, [actionType, typeInvoice]);

  useEffect(() => {
    if (payload_add_form) {
      setFormValueFromPayload();
    } else {
      // console.info("payload_add masih null");
    }
  }, [payload_add_form]);

  // useEffect(() => {
  //   const getOptions = async (endpoin, name, aliasColumn) => {
  //     try {
  //       const response = await axios.get(endpoin, headersAPIToken(token));
  //       if (response.status === 200 && response.data.status === true) {
  //         let payloadResponse = response.data.data;
  //         if (Array.isArray(payloadResponse) && aliasColumn) {
  //           payloadResponse = payloadResponse.map((opt) => {
  //             opt["name"] = opt[aliasColumn];
  //             return opt;
  //           });
  //         }
  //         if (name === "bank_address_id" && payloadResponse.length < 1) {
  //           payloadResponse = [
  //             {
  //               id: 1,
  //               name: "[dummy]-123456789",
  //             },
  //             {
  //               id: 2,
  //               name: "[dummy]-987654321",
  //             },
  //           ];
  //         }
  //         setOptions((prev) => ({ ...prev, [name]: payloadResponse }));
  //       } else {
  //         throw new Error("Failed get option : ".concat(response.data.message));
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }

  //     //* -------------------------------------------------------------- *//
  //     // const serviceId = formValues["service_id"];
  //     // if (
  //     //   formValues.hasOwnProperty("service_id") &&
  //     //   serviceId !== ACTION_TYPE.DEFAULT_VALUE_SELECT_ONE
  //     // ) {
  //     //   getOptions(
  //     //     `/master/sub-services?main_service_id=${serviceId}`,
  //     //     "sub_service_id"
  //     //   );
  //     //   // setIsLoadedFormValues(false);
  //     // }
  //   };
  //   // const bankId = formValues["bank_id"];
  //   // console.log({ bankId, address: formValues["bank_address_id"] });
  //   // if (
  //   //   bankId &&
  //   //   bankId != 0 &&
  //   //   formValues["bank_address_id"] !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE
  //   // ) {
  //   //   getOptions(
  //   //     "/master/bank-address?bank_id=".concat(bankId),
  //   //     "bank_address_id",
  //   //     "no_account"
  //   //   );
  //   // }
  // }, [formValues]);

  const setFormValuesFromSelectedData = () => {
    const data = rows.filter((row) => selectedData.includes(row.id))[0];
    for (const key in data) {
      // console.log(key, "key");
      // Check if the property exists in formValues before updating
      if (formValues.hasOwnProperty(key)) {
        // Special handling for the 'status' property
        const value = key === "status" ? data[key] === "active" : data[key];
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [key]: typeof value === "object" ? value?.id : value,
        }));
      }
    }
  };

  const isFormFilled = () => {
    return Object.values(formValues).some((value) => {
      let typeData = Object.prototype.toString.call(value);
      // console.log(value, typeData, "=====================================");
      switch (typeData) {
        case "[object Number]":
          return value !== 0;
        case "[object String]":
          return value.trim() !== "";
        case "[object Array]":
          return value.length >= 1;
        default:
          return;
      }
    });
  };

  const formatStringToCurrency = (value) => {
    // Remove leading zeros, but allow a single "0"
    value = value.replace(/^0+(?=\d{0,})/, "0");

    // Use a regular expression to allow only digits
    value = value.replace(/\D/g, "");

    // Format the input value as a currency by adding commas every three digits
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };
  /* -------------------------------------------------------------------------- */
  /*                                   ACTION                                   */
  /* -------------------------------------------------------------------------- */

  const handleInputChange = (event) => {
    const { name, checked } = event.target;
    let { value } = event.target;

    if (formModal && name === "client_id") {
      setSelectedClientId(value);
    }

    if (name === "bank_id") {
      // setSelectedBankId(value);

      if (value) {
        setFormValues((prev) => {
          const copy = { ...prev };
          if (copy["bank_address_id"]) {
            copy["bank_address_id"] = SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE;
          }
          return copy;
        });
        getSpecificOption(
          "/master/bank-address?bank_id=".concat(value),
          "bank_address_id",
          "no_account"
        );
      }
      setCredentialBank({ name: "", address: "", bank_address: "" });
    }

    if (name === "bank_address_id") {
      const pattern = /\b(?:dummy)\b/gi;
      const valOnOpt = options["bank_address_id"].find(
        (opt) => (opt.id = value)
      );
      if (valOnOpt) {
        if (pattern.test(valOnOpt.name)) {
          // console.log("this is dummy");
          setCredentialBank();
        } else {
          // console.log("real data");
          findBankCredential();
          // console.log("masuk sini terus");
        }
      }
    }

    if (name === "payment_received") {
      const currency = formValues["payment_currency"];
      if (currency) {
        value = currency.concat(". ", formatStringToCurrency(value));
      } else {
        value = formatStringToCurrency(value);
      }
    }

    if (name === "payment_received_convert") {
      value = "Rp. ".concat(formatStringToCurrency(value));
    }

    if (name === "payment_currency") {
      if (value !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE) {
        formDispatch({
          type: STATE.UPDATE_SHOW_PAYMENT_RECEIVE,
          payload: true,
        });

        if (value) {
          setFormValues((prev) => {
            prev["payment_received"] = value.concat(
              ". ",
              numberToCurrency(String(prev["payment_received"]))
            );
            if (value === "Rp") {
              prev["payment_received_convert"] = null;
              formDispatch({
                type: STATE.UPDATE_DISABLED,
                payload: false,
              });
              prev["convert_payment"] = false;
            }
            return prev;
          });
        } else {
          formDispatch({ type: STATE.DISABLED_ALL });
        }
      } else {
        formDispatch({
          type: STATE.UPDATE_SHOW_PAYMENT_RECEIVE,
          payload: false,
        });
      }
    }

    if (name === "convert_payment") {
      formDispatch({ type: STATE.UPDATE_CAN_CONVERT, payload: checked });
      value = checked;
      setFormValues((prev) => ({
        ...prev,
        payment_received_convert: checked ? "Rp. " : "",
      }));
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const findBankCredential = () => {
    const bankId = formValues["bank_id"];
    axios
      .get(
        "/master/bank-address?bank_id=".concat(bankId),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status == 200 && response.data.status === true) {
          setCredentialBank(response.data.data[0]);
        }
      })
      .catch((err) => {
        console.error("Failed get bank credential", err);
      });
  };

  const setCredentialBank = (payload) => {
    const copyFormValues = {};
    if (payload) {
      //* real data
      copyFormValues["account_name"] = payload["name"] || "";
      copyFormValues["account_address"] = payload["address"] || "";
      copyFormValues["bank_address"] = payload["bank_address"] || "";
      // console.log("masuk sini");
    } else {
      //* dummy data
      copyFormValues["account_name"] = "Tony Jhonson";
      copyFormValues["account_address"] = "Oklahoma No. 124";
      copyFormValues["bank_address"] = "Jakarta Pusat Jl. Mawar Baru";
    }
    setFormValues((prev) => ({ ...prev, ...copyFormValues }));
  };

  const setFormValueFromPayload = async (detailData) => {
    if (payload_add_form) {
      setFormStep(LOCAL_ACTION_TYPE.ADD_STEP_TWO);
      if (typeInvoice === INVOICE_TYPE.PERFORMA_INVOICE) {
        const newFormValues = {
          ...formValues,
          client_id: payload_add_form["client_id"],
          sales_order_id: payload_add_form["sales_order_id"],
          bill_to: payload_add_form["bill_to"],
          no_reference: payload_add_form["no_reference"],
          attend: payload_add_form["attend"],
          bill_address: payload_add_form["bill_address"],
          invoice_proforma_id: payload_add_form["bank"]["invoice_proforma_id"],
          account_name: payload_add_form["bank"]["account_name"],
          account_address: payload_add_form["bank"]["account_address"],
          bank_id: payload_add_form["bank"]["bank_id"],
          bank_address_id: payload_add_form["bank"]["bank_address_id"],
          bank_address: payload_add_form["bank"]["bank_address"],
          // payment_currency: payload_add_form["bank"]["currency"],
          // payment_received: String(payload_add_form["bank"]["currency"]).concat(
          //   ". ",
          //   formatStringToCurrency(
          //     String(payload_add_form["bank"]["payment_received"])
          //   )
          // ),
        };
        // formDispatch({
        //   type: STATE.UPDATE_SHOW_PAYMENT_RECEIVE,
        //   payload: true,
        // });
        // if (payload_add_form["bank"]["payment_received_convert"]) {
        //   const formatedVal = formatStringToCurrency(
        //     String(payload_add_form["bank"]["payment_received_convert"])
        //   );
        //   newFormValues["payment_received_convert"] = "Rp. ".concat(
        //     formatedVal
        //   );
        //   formDispatch({ type: STATE.UPDATE_CAN_CONVERT, payload: true });
        // }

        getSalesOrdersDetail(newFormValues);
        // setFormValues(newFormValues);
      } else if (typeInvoice === INVOICE_TYPE.SALES_ORDER) {
        const pricess = {};
        const newFormValues = {
          client_id: payload_add_form["Client"]["client_id"],
          sales_order_id: payload_add_form["id"],
          remark: payload_add_form["Note Section"]["remarks"],
          [referal_field.name]: payload_add_form["Client"]["referral"] ?? "",
          service_id: payload_add_form["Service"]["service_id"],
          service_currency: payload_add_form["Service"]["currency"],
          // sub_services: payload_add_form["Sub Service"],
          attachment: payload_add_form["Attachment"]["attachment"],
        };

        if (
          Array.isArray(payload_add_form["Sub Service"]) &&
          payload_add_form["Sub Service"].length > 0
        ) {
          newFormValues["sub_services"] = payload_add_form["Sub Service"].map(
            (item) =>
              manipulateSubServicePayload(
                item,
                String(payload_add_form["Service"]["currency"])
              )
          );
        } else {
          newFormValues["sub_services"] = [];
        }

        const pricesKeyList = [
          "total_price_info_to_rp",
          "discount_info_to_rp",
          "vat_info_to_rp",
          "price_services_info_to_rp",
          "price_services_info",
        ];
        pricesKeyList.forEach((key) => {
          let formated = String(payload_add_form["Service"]["currency"]).concat(
            ". ",
            formatStringToCurrency(
              String(payload_add_form["Price Information"][key])
            )
          );
          pricess[key] =
            key !== "price_services_info"
              ? formated
              : payload_add_form["Price Information"][key];
        });
        getSpecificOption(
          "/master/sales-orders?client_id=".concat(newFormValues["client_id"]),
          "sales_order_id",
          "code"
        );

        setPriceInvormation(pricess);
        setFormValues(newFormValues);
        setIsLoadedFormValues(true);
      }
    } else if (detailData) {
      const newFormValues = {
        ...initialFormValues("drawer"),
        client_id: detailData["client_id"],
        sales_order_id: detailData["sales_order_id"],
        bill_to: detailData["bill_to"],
        attend: detailData["attend"],
        note: detailData["note"],
        status: detailData["status"],
        id: detailData["id"],
        bill_address: detailData["bill_address"],
        invoice_proforma_id: detailData["bank"]["invoice_id"],
        no_reference: detailData["no_reference"],
        account_name: detailData["bank"]["account_name"],
        account_address: detailData["bank"]["account_address"],
        bank_id: detailData["bank"]["bank_id"],
        bank_address: detailData["bank"]["bank_address"],
        bank_address_id: detailData["bank"]["bank_address_id"],
      };

      setSelectedBankId(detailData["bank"]["bank_id"]);
      // setSelectedClientId(detailData["client_id"]);
      getSpecificOption(
        "/master/sales-orders?client_id=".concat(newFormValues["client_id"]),
        "sales_order_id",
        "code"
      );
      // console.log("Thius tuis", { newFormValues });
      getSalesOrdersDetail(newFormValues);
    }
  };

  const getSalesOrdersDetail = async (curentFormValues) => {
    try {
      let salesOrderId = curentFormValues["sales_order_id"];
      const priceses = {};
      const resposenSalesOrder = await axios.get(
        "/sales-orders/".concat(salesOrderId, "/detail-for-edit"),
        headersAPIToken(token)
      );
      const { data } = resposenSalesOrder.data;
      curentFormValues[referal_field.name] = data["Client"]["referral"] ?? " ";

      curentFormValues["service_id"] = data["Service"]["service_id"];
      if (
        Array.isArray(data["Sub Service"]) &&
        data["Sub Service"].length > 0
      ) {
        curentFormValues["sub_services"] = data["Sub Service"].map((item) =>
          manipulateSubServicePayload(item, String(data["Service"]["currency"]))
        );
      } else {
        curentFormValues["sub_services"] = [];
      }

      curentFormValues[referal_field.name] = data["Client"]["referral"] ?? " ";
      curentFormValues["service_currency"] = data["Service"]["currency"];
      curentFormValues["remark"] = data["Note Section"]["remarks"];
      curentFormValues["attachment"] = data["Attachment"]["attachment"];

      const pricesKeyList = [
        "total_price_info_to_rp",
        "discount_info_to_rp",
        "vat_info_to_rp",
        "price_services_info_to_rp",
      ];
      pricesKeyList.forEach((key) => {
        priceses[key] = String(data["Service"]["currency"]).concat(
          ". ",
          formatStringToCurrency(String(data["Price Information"][key]))
        );
      });

      priceses["price_services_info"] =
        data["Price Information"]["price_services_info"];

      setPriceInvormation(priceses);
      setFormValues(curentFormValues);
      // debugger;
      setIsLoadedFormValues(true);
    } catch (error) {
      console.error(`Failed get sales order detail: ${error}`);
    }
  };

  const manipulateSubServicePayload = (subServices, serviceCurrency) => {
    if (Array.isArray(subServices)) return subServices;
    const newPayload = {
      sub_service_id: subServices["sub_service_id"],
      criteria_id: subServices["criteria_id"],
      discount: subServices["discount"],
      vat_to_rp: subServices["vat_to_rp"],
      vat_percent: subServices["vat_to_rp"],
      description: subServices["desc"],
    };

    if (Array.isArray(subServices["vat_percent"])) {
      newPayload["vat_percent"] = subServices["vat_percent"][0];
    } else {
      newPayload["vat_percent"] = subServices["vat_percent"];
    }

    newPayload["discount"] = String(subServices["discount"])
      .padEnd(8, " ")
      .concat("%");

    ["subtotal", "total_to_rp", "vat_to_rp"].forEach((key) => {
      newPayload[key] = String(serviceCurrency).concat(
        ". ",
        formatStringToCurrency(String(subServices[key]))
      );
    });
    // console.log("debug", { subServices, newPayload });
    // console.log("Termanipulate", { newPayload });
    return newPayload;
  };

  //* Watch option
  useEffect(() => {
    let bankAddrKey = "bank_address_id";
    const getOptions = async (endpoint, name) => {
      try {
        const response = await axios.get(endpoint, headersAPIToken(token));
        if (response.status === 200 && response.data.status == true) {
          let manipulateData = response?.data?.data;

          const dummyBankAddressId = [
            {
              id: 1,
              name: "[dummy]-123456789",
            },
            {
              id: 2,
              name: "[dummy]-987654321",
            },
          ];

          setOptions((prev) => {
            let mutable = manipulateData;

            if (name === bankAddrKey) {
              if (mutable.length > 0) {
                mutable = mutable.map((item) => {
                  item["name"] = item.no_account;
                  return item;
                });
              } else {
                mutable = dummyBankAddressId;
              }
            }
            if (name === "invoice_proforma_id" || name === "sales_order_id") {
              mutable = mutable.map((item) => {
                item["name"] = item.code;
                return item;
              });
            }

            return { ...prev, [name]: mutable };
          });
        }
      } catch (error) {
        console.error(`Failed get options : ${error}`);
        //! Development only
        setOptions((prev) => ({ ...prev, [name]: [] }));
      }
    };

    // Get option for extra field;
    if (!options.hasOwnProperty(extraFields.name)) {
      getOptions(extraFields.apiEndpoint, extraFields.name);
    }

    //! Get Option when bank id is selected
    // if (selectedBankId !== 0 && canGetBankAddre) {
    //   getOptions(
    //     "master/bank-address?bank_id=".concat(selectedBankId),
    //     bankAddrKey
    //   );
    //   setCanGetBankAddre(false);
    // }

    // if (
    //   selectedClientId != 0 &&
    //   // leftDrawer &&
    //   !options.hasOwnProperty("sales_order_id")
    // ) {
    //   console.log("aaaaaa sales order get");
    //   if (typeInvoice === INVOICE_TYPE.SALES_ORDER) {
    //     let endpointSO = "/master/sales-orders?client_id=".concat(
    //       selectedClientId
    //     );
    //     getOptions(endpointSO, "sales_order_id");
    //   }
    //   //  else if (typeInvoice === INVOICE_TYPE.PERFORMA_INVOICE) {
    //   //   let proformaEndpoint = extraFields.apiEndpoint.concat(
    //   //     "?client_id=",
    //   //     selectedClientId
    //   //   );
    //   //   getOptions(proformaEndpoint, extraFields.name);
    //   // }
    // }

    fieldDrawerForEach((/**@type {FieldType} */ field) => {
      const { name, apiEndpoint, options: localOptions } = field;
      if (apiEndpoint && canGetOptions) {
        if (!optionKey.includes(name) && !options.hasOwnProperty(name)) {
          getOptions(apiEndpoint, name);

          const newOptionKey = [...optionKey];
          newOptionKey.push(name);
          setOptionKey(newOptionKey);
        } else {
          // console.log("this is options", options);
        }
      } else if (localOptions) {
        if (!optionKey.includes(name) && !options.hasOwnProperty(name)) {
          setOptions((prev) => ({ ...prev, [name]: localOptions }));

          const newOptionKey = [...optionKey];
          newOptionKey.push(name);
          setOptionKey(newOptionKey);
        } else {
          // console.log({ name, localOptions });
        }
      }
    });

    if (!options.hasOwnProperty("sub_service_id")) {
      getOptions("/master/sub-services", "sub_service_id");
    }
  }, [options, selectedBankId, canGetOptions, selectedClientId]);

  const getSpecificOption = (endpoint, name, aliasName) => {
    setIsLoadedFormValues(false);
    axios
      .get(endpoint, headersAPIToken(token))
      .then((response) => {
        if (response.status == 200 && response.data.status === true) {
          let payloadData = response.data.data;
          if (!Array.isArray(payloadData)) return;
          if (aliasName) {
            payloadData = payloadData.map((item) => {
              item["name"] = item[aliasName];
              return item;
            });
          }
          if (name === "bank_address_id" && payloadData.length < 1) {
            payloadData = [
              {
                id: 1,
                name: "[dummy]-123456789",
              },
              {
                id: 2,
                name: "[dummy]-987654321",
              },
            ];
          }
          setOptions((prev) => ({ ...prev, [name]: payloadData }));
        } else {
          setOptions((prev) => ({ ...prev, [name]: [] }));
        }
      })
      .catch((error) => {
        console.error(`Failed get option for ${name}: ${error.message}`);
      })
      .finally(() => {
        //Set something
        setIsLoadedFormValues(true);
      });
  };

  const handleInputBlur = (event) => {
    const { name } = event.target;
    validateField(name);
  };

  const validateField = (fieldName) => {
    let field = null;
    fieldDrawerForEach((Fie) => {
      if (Fie.name === fieldName) {
        field = Fie;
      }
    });

    if (
      !field &&
      typeInvoice === INVOICE_TYPE.PERFORMA_INVOICE &&
      fieldName === extraFields.name
    ) {
      field = extraFields;
    }

    const newErrors = { ...errors };

    if (field) {
      const { name, required, validation, errorMessage, label } = field;
      if (
        required &&
        (String(formValues[name]).trim() === "" ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          formValues[name] === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = `Please enter ${String(field.label).toLowerCase()}`;
      } else if (
        formValues[name] &&
        formValues[name].length &&
        validation &&
        !validation(formValues[name])
      ) {
        newErrors[name] = errorMessage || `${label} is invalid`;
      } else {
        newErrors[name] = "";
      }

      setErrors(newErrors);
      // console.log(newErrors, "newErrors");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };
    const excludeField = GLOBAL_NAME.IGNORE_FIELD;

    const fieldCheck = (field) => {
      const { name, defaultValue, validation, errorMessage } = field;
      let { required } = field;
      const valueInForm = formValues[name];
      // console.log({ name, required, isInclude: excludeField.includes(name) });
      if (excludeField.includes(name)) {
        required = false;
      }
      if (
        required &&
        (String(valueInForm).trim() === "" ||
          valueInForm === undefined ||
          valueInForm === defaultValue ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
      ) {
        newErrors[name] = `Please enter ${String(field.label).toLowerCase()}`;
        valid = false;
      }

      if (
        valueInForm &&
        valueInForm.length &&
        validation &&
        !validation(valueInForm)
      ) {
        newErrors[name] = errorMessage || `${field.label} is invalid`;
        valid = false;
      }
    };

    if (leftDrawer) {
      fieldDrawerForEach(fieldCheck);
      fieldCheck(extraFields);
    } else if (formModal) {
      if (typeInvoice === INVOICE_TYPE.PERFORMA_INVOICE) {
        fieldProformaInvoice.forEach(fieldCheck);
      } else if (typeInvoice === INVOICE_TYPE.SALES_ORDER) {
        fieldSalesOrder.forEach(fieldCheck);
      }
    }
    // console.log("this is new eror", newErrors);
    setErrors(newErrors);
    // debugger;?
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    } else {
      if (validateForm()) {
        if (
          actionType === ACTION_TYPE.EDITED &&
          (selectedData.length || edit_id !== null)
        ) {
          handleEditRow();
        } else if (actionType === ACTION_TYPE.ADD) {
          if (typeInvoice !== INVOICE_TYPE.GET_DATA_INVOICE && !leftDrawer) {
            // console.log("masuk get paylaod");
            hendleGetDataForm();
            setGetOptions(true);
            setFormModal(false);
          } else {
            // console.log("masuk add form");
            handleAddRow();
            handleCloseFormModal(true);
          }
        }
        setSelectedData([]);
      } else {
        // console.log("Tidak valid");
      }
    }
  };

  const fetchEditData = async (id) => {
    try {
      setTitle("Edit Invoice");
      setTypeInvoice(INVOICE_TYPE.SALES_ORDER);
      setIsLoadedFormValues(false);
      let url = `/invoices/${id}/edit`;
      const response = await axios.get(url, headersAPIToken(token));
      // console.log({ response });
      setFormValueFromPayload(response.data.data);
    } catch (error) {
      console.error("Failed get data for edit", error);
    }
  };

  const handleEdit = (detailId) => {
    let data;
    if (detailId) {
      data = rows.find((doc) => doc.id === detailId);
    } else if (selectedData.length >= 1 && selectedData.length < 2) {
      data = rows.filter((doc) => selectedData.includes(doc.id))[0];
    }
    if (data) {
      let { id } = data;
      fetchEditData(id);
    } else {
      // alert("document not found");
    }
  };

  //* Old handle
  /**
   *
   * @param {string} actionType
   * @param {string} invoiceType
   */
  const handleFormModal = (actionType = ACTION_TYPE.ADD, invoiceType = "") => {
    setActionType(actionType);
    setErrors(initialErrors);
    if (selectedData.length && actionType === ACTION_TYPE.EDITED) {
      setGetOptions(true);
      setLeftDrawer(true);
      setActionType(ACTION_TYPE.EDITED);
      handleEdit();
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setFormModal(true);
      setTypeInvoice("DELETE INVOICE");
      setTitle("Delete Invoice");
    } else {
      setFormModal(true);
      if (
        invoiceType === INVOICE_TYPE.PERFORMA_INVOICE ||
        invoiceType === INVOICE_TYPE.SALES_ORDER
      ) {
        // getOptions(invoiceType);
        setTypeInvoice(invoiceType);
        // setActionType(actionType);
        setTitle("Add Invoice");
        setErrors(initialErrors("modal", invoiceType));
        setFormValues(initialFormValues("modal", invoiceType));
      } else {
        setTitle("Source Invoice");
      }
    }
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      if (
        (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) &&
        (typeInvoice === INVOICE_TYPE.PERFORMA_INVOICE ||
          typeInvoice === INVOICE_TYPE.SALES_ORDER)
      ) {
        if (isFormFilled()) {
          setConfirmModal(true);
          // console.log("aaaa");
          return;
        } else {
          // console.log("bb");
          setFormModal(false);
        }
      } else {
        // console.log("ccc");
        setFormModal(false);
      }
    } else {
      // console.log("ddd");
      setFormModal(false);
      setSelectedClientId(0);
    }
    // console.log("Akhir");
    setLeftDrawer(false);
    setTypeInvoice(INVOICE_TYPE.GET_DATA_INVOICE);
    dispatch({ type: SET_EDIT_ID, payload: null });
  };

  const numberToCurrency = (value) => {
    // Remove leading zeros, but allow a single "0"
    value = value.replace(/^0+(?=\d{0,})/, "0");

    // Use a regular expression to allow only digits
    value = value.replace(/\D/g, "");

    // Format the input value as a currency by adding commas every three digits
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
  };

  const stringCurrencyToNumber = (value) => {
    // Otherway
    // value = value.substring(currency ? (currency.length + 2) : 4);
    value = String(value);

    value = value.replace(/\D/g, "");

    return parseInt(value);
  };

  const manipulatePayload = () => {
    // key = formValues, value = key payload;
    const keyValuesForm = {
      no_reference: "no_reference",
      client_id: "client_id",
      sales_order_id: "sales_order_id",
      bill_to: "bill_to",
      attend: "attend",
      bill_address: "bill_address",
      invoice_proforma_id: "invoice_proforma_id",
      note: "note",
      banks: {
        account_name: "account_name",
        account_address: "account_address",
        bank_address: "bank_address",
        payment_currency: "currency",
        bank_id: "bank_id",
        bank_address_id: "bank_address_id",
        // payment_received: "payment_received",
        // currency_convert: "currency_convert",
        // payment_received_convert: "payment_received_convert",
      },
    };

    const specialField = ["payment_received", "payment_received_convert"];

    const fillValue = (curentForm, field) => {
      let resultPayload = {};
      Object.entries(field).forEach(([key, value]) => {
        if (typeof value === "object") {
          let banks = fillValue(curentForm, value);
          // if (formState.canConvert) {
          //   banks = { ...banks, currency_convert: "Rp" };
          // } else {
          //   banks = {
          //     ...banks,
          //     currency_convert: "Rp",
          //     payment_received_convert: null,
          //   };
          // }
          resultPayload = Object.assign(resultPayload, { [key]: banks });
        } else if (curentForm[key]) {
          let valuePayload = curentForm[key];

          // if (specialField.includes(key)) {
          //   valuePayload = stringCurrencyToNumber(valuePayload) || null;
          // }
          resultPayload[value] = valuePayload;
        }
      });
      return resultPayload;
    };

    const copyForm = { ...formValues };

    const result = fillValue(copyForm, keyValuesForm);
    return result;
  };

  const handleOnConfirmCancelModal = () => {
    //* cancel modal confirm
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    ///* Yes modal confirm
    setConfirmModal(false);
    setLeftDrawer(false);
    // setFormModal(false);
    resetFormValues();
    setTypeInvoice(INVOICE_TYPE.GET_DATA_INVOICE);
    setSelectedClientId(0);
    setSelectedData([]); //buat disabled button toolbar
  };

  const hendleGetDataForm = () => {
    //* Get data from server
    setIsLoadedFormValues(false);
    dispatch(getDataFormAdd(token, formValues)).then(() => {
      setErrors(initialErrors("drawer"));
      //* Set Drawer on
      setLeftDrawer(true);
    });
    // setFormValues(initialFormValues("drawer"));
    // setFormValueFromPayload();
  };

  const handleAddRow = () => {
    const manipulated = manipulatePayload();
    // debugger;
    setIsLoadedFormValues(false);
    dispatch(addInvoice(token, manipulated)).then(() => {
      setLeftDrawer(false);
      setIsLoadedFormValues(true);
      dispatch(
        getInvoice({
          token,
          searchBy,
          limit,
          page,
          search,
          sortBy,
          sortDirection,
          status,
          category: serviceCategory,
        })
      );
    });
    setGetOptions(false);
  };

  const handleEditRow = () => {
    setGetOptions(false);
    let manipulated = manipulatePayload();
    if (manipulated.hasOwnProperty("invoice_proforma_id")) {
      delete manipulated["invoice_proforma_id"];
    }
    setIsLoadedFormValues(false);
    dispatch(
      editInvoice(
        token,
        Object.assign({ status: formValues["status"] }, manipulated),
        formValues["id"]
      )
    ).then(() => {
      setActionType(ACTION_TYPE.DEFAULT_ACTION);
      dispatch({ type: SET_EDIT_ID, payload: null });
      setIsLoadedFormValues(true);
      handleCloseFormModal(true);
      setSearchParams({});
      dispatch(
        getInvoice({
          token,
          page,
          limit,
          search,
          status,
          sortDirection,
          sortBy,
          searchBy,
        })
      );
    });
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteInvoice(token, ids)).then(() => {
      setSelectedData([]);
    });
  };

  const handleSortModelChange = (newSortModel) => {
    // if (newSortModel && newSortModel.length) {
    //   const { field, sort } = newSortModel[0];
    setSortModel(newSortModel);
    // setSortBy(field);
    // setSortDirection(sort);
    // }
  };
  /* -------------------------------------------------------------------------- */
  /*                                    FORM                                    */
  /* -------------------------------------------------------------------------- */

  const handleRemoveFieldRow = (sectionTitle, rowIndex) => {};
  const handleAddFieldRow = (sectionTitle) => {};
  const handleAddFile = (sectionTitle, rowIndex, fieldName) => {};
  const onFileSelect = () => {};
  /**
   *
   * @param {any} data
   * @param {import("react").BaseSyntheticEvent} event
   */

  const handleDetailPage = (data, event) => {
    navigate("/finance/invoice/detail/" + data?.id);
  };
  const handleRemoveFile = (
    sectionTitle,
    rowIndex,
    fieldIndex,
    fieldName
  ) => {};

  const handleFileSelect = (
    file,
    rowIndex,
    sectionTitle,
    fieldName,
    fileIndex
  ) => {
    // debugger;
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][rowIndex][fieldName];

        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = "";
        }

        return updatedValues;
      });
    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][fieldName];

        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = file;
        }

        return updatedValues;
      });
    }
  };

  const renderSectionFields = () => {
    let iterationField = [];
    if (typeInvoice === INVOICE_TYPE.SALES_ORDER) {
      iterationField = fieldSalesOrder;
    } else if (typeInvoice === INVOICE_TYPE.PERFORMA_INVOICE) {
      iterationField = fieldProformaInvoice;
    }
    return (
      <>
        <div>
          {iterationField.map((field, index) => (
            <React.Fragment key={field.name}>
              <SelectOneAutocompleteSearch
                title={field.label}
                url={index == 0 ? field.url : urlSourceInvoice}
                handleInputChange={handleInputChange}
                name={field.name}
                disabled={index === 1 && selectedClientId === 0}
                errors={errors}
              />
              {index % 2 === 0 && <Box sx={{ marginBottom: ".6rem" }} />}
            </React.Fragment>
          ))}
        </div>
      </>
    );
  };

  const renderFormDrawer = () => {
    return (
      <>
        {isLoadedFormValues ? (
          <RenderFormContent
            actionType={actionType}
            errors={errors}
            formValues={formValues}
            options={options}
            handleAddFile={handleAddFile}
            // handleAddSubservice={}
            handleInputBlur={handleInputBlur}
            handleInputChange={handleInputChange}
            handleRemoveFile={handleRemoveFile}
            // handleRemoveSubservice={}
            onFileSelect={onFileSelect}
            priceInformation={priceInformation}
            invoiceType={typeInvoice}
            canConvert={formState.canConvert}
            showPaymanetReceive={formState.showPaymentReceive}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <FacebookCircularProgress />
          </Box>
        )}
      </>
    );
  };

  const renderFormContent = () => {
    // console.log(actionType, typeInvoice);
    if (
      actionType === ACTION_TYPE.ADD &&
      typeInvoice === INVOICE_TYPE.GET_DATA_INVOICE
    ) {
      // console.log("satu");
      return (
        <Grid container spacing="5">
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              disableElevation
              onClick={() =>
                handleFormModal(ACTION_TYPE.ADD, INVOICE_TYPE.PERFORMA_INVOICE)
              }
              id="ADD_INVOICE_FROM_PERFORMA"
            >
              Get From Performa Invoice
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              fullWidth
              disableElevation
              onClick={() =>
                handleFormModal(ACTION_TYPE.ADD, INVOICE_TYPE.SALES_ORDER)
              }
              id="ADD_INVOICE_FROM_SALES_ORDER"
            >
              {" "}
              Get From Sales Order
            </Button>
          </Grid>
        </Grid>
      );
    } else if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={true} />;
    } else {
      // console.log("tiga");
      return renderSectionFields();
    }
  };

  const renderActionButtons = () => {
    let textShowing = "";
    if (actionType === ACTION_TYPE.DELETED) {
      textShowing = "Yes";
    } else if (
      formStep === LOCAL_ACTION_TYPE.ADD_STEP_TWO ||
      actionType === ACTION_TYPE.EDITED
    ) {
      textShowing = "Save";
    } else if (
      actionType === ACTION_TYPE.ADD &&
      (typeInvoice === INVOICE_TYPE.PERFORMA_INVOICE ||
        typeInvoice === INVOICE_TYPE.SALES_ORDER)
    ) {
      textShowing = "Get It";
    }
    // console.log({ actionType, formStep, typeInvoice });

    if (typeInvoice === INVOICE_TYPE.GET_DATA_INVOICE) {
      return null;
    }
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
        >
          {textShowing}
        </Button>
      </Box>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                  DATATABLE                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    // console.log(search, status, isLoading, "query");
  }, [search, status, isLoading]);

  useEffect(() => {
    // if (status !== ACTION_TYPE.DEFAULT_STATUS) {
    // }
    dispatch(
      getInvoice({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        searchBy,
      })
    );
  }, [sortDirection, sortBy]);

  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getInvoice({
        token,
        page,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        searchBy,
      })
    );
  }

  useEffect(() => {
    setSelectedStatus(status);
  }, [status]);

  function _getByLimit(limit) {
    dispatch(
      getInvoice({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        searchBy,
      })
    );
  }

  function _search() {
    if (status === ACTION_TYPE.DEFAULT_STATUS) {
      setSelectedStatus(ACTION_TYPE.ALL_STATUS);
    }
    dispatch(
      getInvoice({
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        searchBy,
      })
    );
  }
  function _status(status) {
    setStatus(status);
    dispatch(
      getInvoice({
        page,
        token,
        limit,
        search,
        status,
        sortDirection,
        sortBy,
        category: serviceCategory,
        searchBy,
      })
    );
  }

  function _serviceCategory(service) {
    setServiceCategory(service);
    // if (status === ACTION_TYPE.DEFAULT_STATUS) {
    //   setStatus(ACTION_TYPE.ALL_STATUS);
    // }
    setSelectedStatus(ACTION_TYPE.ALL_STATUS);
    dispatch(
      getInvoice({
        token,
        page,
        limit,
        search,
        sortBy,
        sortDirection,
        category:
          serviceCategory === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
          serviceCategory === ACTION_TYPE.ALL_STATUS
            ? null
            : service,
        status,
        searchBy,
      })
    );
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
    setPageDB(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    setPage(1);
    setPageDB(1);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    setSelectedData(selection || []);
  };

  useEffect(() => {
    const getCagetory = async () => {
      try {
        const response = await axios.get(
          "/master/services",
          headersAPIToken(token)
        );
        if (response.status === 200 && response.data.status === true) {
          let payload = [];
          if (Array.isArray(response.data.data)) {
            payload = response.data.data;
          }
          setListCategory(payload);
        }
      } catch (error) {
        console.error(`Failed get category : ${error}`);
      }
    };

    if (listCategory.length < 1) {
      getCagetory();
    }
  }, [listCategory]);
  //* ================================================================================= *//
  //*                                        WATCHER                                    *//
  //* ================================================================================= *//

  // useEffect(() => {
  //   console.log(errors, "errors");
  // }, [errors]);

  // useEffect(() => {
  //   console.log(formValues, "form values");
  // }, [formValues]);

  // useEffect(() => {
  //   console.log(options, "this is options");
  // }, [options]);

  // useEffect(() => {
  //   console.log({ actionType });
  // }, [actionType]);

  // useEffect(() => {
  //   console.log(typeInvoice, "type invoice");
  // }, [typeInvoice]);

  // useEffect(() => {
  //   console.log(formState, "this is form state");
  // }, [formState]);

  // useEffect(() => {
  //   console.log({ formModal, leftDrawer });
  // }, [formModal, leftDrawer]);

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   SEARCH                                   */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{ boxShadow: "unset", borderRadius, border: "1px solid #ddd" }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder={`Search by ${
                  searchBy === "code" ? "no invoice" : "client name"
                }`}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={SearchIconBlack}
                        style={{ width: "20px", margin: " 0 8px 0 0" }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      SelectProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      sx={{
                        borderRadius,
                      }}
                      value={searchBy}
                      sx={{
                        "& fieldset": {
                          border: "unset",
                        },
                        borderLeft: `2px solid #ddd`,
                        width: "14rem",
                      }}
                      onChange={(e) => setSearchBy(e.target.value)}
                    >
                      <MenuItem value="code">No. Invoice</MenuItem>
                      <MenuItem value="client_name">Client Name</MenuItem>
                    </TextField>
                  ),
                }}
                sx={{
                  borderRadius: 4, // Adjust the borderRadius as needed
                }}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
              >
                <img src={SearchIcon} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={serviceCategory}
                onChange={(e) => _serviceCategory(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Category
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Category</MenuItem>
                {listCategory.map((cate) => (
                  <MenuItem value={cate.id}>{cate.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon,
                }}
                sx={{
                  borderRadius,
                }}
                value={status}
                onChange={(e) => _status(e.target.value)}
              >
                <MenuItem value={ACTION_TYPE.DEFAULT_STATUS}>
                  Select Status
                </MenuItem>
                <MenuItem value={ACTION_TYPE.ALL_STATUS}>All Status</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Partial">Partial</MenuItem>
                <MenuItem value="Paid">Paid</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
        }}
      >
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Invoice"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={pageDB}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={search}
            statusChoosed={selectedStatus}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            handleRowDoubleClick={handleDetailPage}
            // userAccess={[_.CREATE, _.UPDATE, _.DELETE]}
          />
        </CardContent>
      </Card>

      {/* -------------------------------------------------------------------------- */
      /*                             LeftDrawer && Modal                             */
      /* -------------------------------------------------------------------------- */}

      <LeftDrawer open={leftDrawer}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormDrawer}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
            workingWithDrawer={true}
          />
        </Box>
      </LeftDrawer>
      <Modal open={formModal}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  );
};

export default Invoice;
