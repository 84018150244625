"use strict";
import { INPUT_TYPE, SELECT_OPTION } from "../../../../util/function";
//@ts-check
import { PaymentStatusBadge } from "../../payment-request/parts/CusomStyle";

export const columns = [
  {
    field: "code",
    headerName: "No. Invoice",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "client_name",
    headerName: "Client Name",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "service_name",
    headerName: "Service Category",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "sub_services",
    headerName: "Sub Category Service",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
    renderCell: ({ value, row }) => {
      if (Array.isArray(value) && value.length > 0) {
        return value.map((val) =>
          val ? (
            <PaymentStatusBadge status="Paid">{val}</PaymentStatusBadge>
          ) : (
            "-"
          )
        );
      }
    },
  },
  {
    field: "service_total_price",
    headerName: "Total Price",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "referral",
    headerName: "Employee referral code",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
    renderCell: ({ value }) => {
      return value ? value : "-";
    },
  },
  {
    field: "payment_received_formatted",
    headerName: "Payment Receive",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "payment_conversion_received_formatted",
    headerName: "Payment Conversion Receive",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "created_at_formatted",
    headerName: "Create At",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: "status",
    headerName: "Total Price",
    width: 160,
    disableColumnMenu: true,
    sortable: true,
    valueGetter: (params) => params.value, // Return the status value as a string
    renderCell: (params) => (
      <PaymentStatusBadge status={params.value}>
        {" "}
        {params.value}{" "}
      </PaymentStatusBadge>
    ),
  },
];

export const INVOICE_TYPE = {
  SALES_ORDER: "Add invoice from sales order",
  PERFORMA_INVOICE: "Add invoice from performa invoice",
  GET_DATA_INVOICE: "GET INVOICE DATA",
};

export const extraFields = {
  name: "invoice_proforma_id",
  type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
  apiEndpoint: "/master/proforma-invoices",
  label: "No. Proforma Invoice",
  placeholder: "",
  defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
  required: false,
  canChange: true,
  hidden: false,
};
export const referal_field = {
  name: "code_referal",
  type: INPUT_TYPE.NUMBER,
  // apiEndpoint: "/master/proforma-invoices",
  label: "Employee referral code",
  placeholder: " ",
  defaultValue: "0",
  required: false,
  canChange: true,
  hidden: false,
  additionalProps: {
    disabled: true,
  },
};

export const manipulatePaylaodDetail = (payloadRespon) => {
  const newPayload = { product: {}, bank: {}, client: {}, others: {} };
  newPayload["product"] = payloadRespon["Product"];
  newPayload["bank"] = payloadRespon["Bank Transfer"];
  newPayload["client"] = payloadRespon["Client"];
  newPayload["others"] = payloadRespon["Others"];
  newPayload["others"]["canDownload"] = payloadRespon["canDownload"];
  newPayload["others"]["canAction"] = payloadRespon["canAction"];
  return newPayload;
};
