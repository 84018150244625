export const GET_BENEFIT_SUCCESS = "GET_BENEFIT_SUCCESS";
export const GET_BENEFIT_FAILED = "GET_BENEFIT_FAILED";
export const ADD_BENEFIT_SUCCESS = "ADD_BENEFIT_SUCCESS";
export const ADD_BENEFIT_FAILED = "ADD_BENEFIT_FAILED";
export const EDIT_BENEFIT_SUCCESS = "EDIT_BENEFIT_SUCCESS";
export const EDIT_BENEFIT_FAILED = "EDIT_BENEFIT_FAILED";
export const DELETE_BENEFIT_SUCCESS = "DELETE_BENEFIT_SUCCESS";
export const DELETE_BENEFIT_FAILED = "DELETE_BENEFIT_FAILED";
export const GET_BENEFIT_DETAILS_SUCCESS = "GET_BENEFIT_DETAILS_SUCCESS";
export const GET_BENEFIT_DETAILS_FAILED = "GET_BENEFIT_DETAILS_FAILED";
export const ADD_PAY_BENEFIT_FAILED = "ADD_PAY_BENEFIT_FAILED";
export const ADD_PAY_BENEFIT_SUCCESS = "ADD_PAY_BENEFIT_FAILED";
export const EDIT_PAY_BENEFIT_FAILED = "EDIT_PAY_BENEFIT_FAILED";
export const EDIT_PAY_BENEFIT_SUCCESS = "EDIT_PAY_BENEFIT_FAILED";
