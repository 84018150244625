import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Divider } from "@mui/material";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../../../util/style";
import { PaymentStatusBadge } from "./../../../payment-request/parts/CusomStyle";

import { formatDate, formatSize } from "../../../../../util/function";
import { ValidateValueRender } from "../../validation/validateValue";

const DocumentDetail = ({ detailDocument }) => {
  //   const { renderValue } = formatValue(detailDocument);

  const statusColors = {
    pending: { bgColor: warningSurfaceColor, colors: warningMainColor },
    approved: { bgColor: successSurfaceColor, colors: successMainColor },
    rejected: { bgColor: dangerSurfaceColor, colors: dangerMainColor },
    paid: { bgColor: "#B0CDE7", colors: "#1268B8" },
    // Add more statuses as needed
  };

  /**
   *
   * @param {'product'|'payment'} type
   * @returns
   */
  const renderPropertyFromObject = (type = "") => {
    /**
     * key === key in document detail
     * value === show in UI
     */
    const listRenderProduct = {
      one: {
        no_proforma: "No. Proforma Invoice",
        create_at: "Create At",
        no_reference: "No. Reference",
        service_category: "Service Category",
        sub_service: "Sub Category Service",
        price: "Price",
        discount: "Discount",
      },
      two: {
        vat: "VAT",
        total: "Total",
        description: "Description",
      },
    };
    const listRenderBank = {
      acount_name: "Account Name",
      acount_address: "Account address",
      bank_name: "Name of Bank",
      acount_no: "Account No.",
      bank_address: "Bank Address",
      // payment_receive: "Payment Received",
      // payment_conversion_receive: "Payment Conversion Received",
    };

    const RenderElement = ({ detail, objEntries }) =>
      Object.entries(objEntries).map(([key, titleShow]) => (
        <React.Fragment key={key}>
          <Grid item xs={6}>
            <Typography variant="body1">{titleShow}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              {key === "sub_service"
                ? RenderSubServices(detail[key])
                : detail[key]}
            </Typography>
          </Grid>
        </React.Fragment>
      ));

    if (type === "product") {
      return (
        <>
          <RenderElement
            detail={detailDocument?.product}
            objEntries={listRenderProduct?.one}
          />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <RenderElement
            detail={detailDocument?.product}
            objEntries={listRenderProduct?.two}
          />
        </>
      );
    } else if (type === "payment") {
      return (
        <>
          <RenderElement
            detail={detailDocument?.bank}
            objEntries={listRenderBank}
          />
        </>
      );
    } else return null;
  };

  const RenderSubServices = (value) => {
    if (Array.isArray(value) && value.length > 0) {
      // console.log("debug", { value });
      return value.map((sub) => {
        if (sub === null) return;
        return <span>{sub.name}</span>;
      });
    }
  };
  /**
   *
   * @param {{ size: string|undefined, color:string|undefined }} props
   * @returns
   */
  const DotCircleGreen = (props) => (
    <span
      style={{
        display: "inline-block",
        width: props?.size || ".8rem",
        height: props?.size || ".8rem",
        borderRadius: "100%",
        background: props?.color || "#751314",
      }}
    />
  );

  const documentDetails = ["product", "payment"];
  return (
    <>
      {/* -------------------------------- Detail ------------------------------- */}
      {documentDetails.map((detail) => (
        <Grid
          container
          spacing={2}
          sx={{ "& .MuiGrid-item": { paddingTop: "7px" }, p: 3 }}
        >
          <Grid item xs={6}>
            <Typography variant="h5" fontWeight={"800"}>
              {detail === documentDetails[0] ? "Avon Product" : "Bank Transfer"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {detail === documentDetails[0] && (
              <Box
                sx={{
                  marginBottom: 2,
                  ...justifyContentEnd,
                  ...alignItemsCenter,
                }}
              >
                {detailDocument.product.hasOwnProperty("status") && (
                  <PaymentStatusBadge
                    status={
                      detailDocument?.product["status"] === "Received"
                        ? "Paid"
                        : detailDocument?.product["status"]
                    }
                  >
                    {detailDocument?.product["status"] === "Received"
                      ? "Paid"
                      : detailDocument?.product["status"]}
                  </PaymentStatusBadge>
                )}
              </Box>
            )}
          </Grid>
          {renderPropertyFromObject(detail)}
        </Grid>
      ))}
    </>
  );
};

export default DocumentDetail;
