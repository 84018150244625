import { makeStyles } from "@mui/styles";

// const useStyle = () => {
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e1e1e1",
    overflow: "hidden",
    position: "relative !important",
  },
  logo: {
    position: "absolute",
    width: "100%",
  },
  header: {
    padding: "20px",
    backgroundColor: "#ffffff",
    borderRadius: "10px 10px 0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    padding: "20px",
    backgroundColor: "#ffffff",
  },
  footer: {
    padding: "20px",
    backgroundColor: "#09345C",
    borderRadius: "0 0 10px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

// export default useStyle;
