"use strict";
//@ts-check

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../../../util/style";

import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
  ButtonGroup,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import PrinterGrayIcon from "../../../../../assets/printer-gray.png";
import DownloadGrayIcon from "../../../../../assets/download-gray.png";
import { useState } from "react";

import ArrowDown from "../../../../../assets/arrow-down.svg";
const RenderBottomAction = ({
  handlePrintBtn,
  handleDownload,
  handleEdit,
  handleAction,
  status,
  canDownload,
  canAction,
  documentId,
}) => {
  const [expandAction, setExpandAction] = useState(false);
  const downloadURL = process.env.REACT_APP_API_URL.concat(
    "/api/proforma-invoices/",
    documentId,
    "/download-pdf"
  );
  return (
    <Box
      sx={{
        display: "flex",
        marginInline: "1rem",
        marginBlock: ".5rem",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: ".5rem", marginInline: "1rem" }}>
        <Button
          variant="outlined"
          sx={{
            ...btnLightBlue,
          }}
          style={{ borderRadius: "7px" }}
          disableElevation
          className="not-effect"
          onClick={handlePrintBtn}
          disabled={!canDownload}
        >
          <img
            src={PrinterGrayIcon}
            style={{ marginRight: "5px", margin: "4px" }}
            alt="Printer Icon"
          />
          Print
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...btnLightBlue,
          }}
          disableElevation
          className="not-effect"
          disabled={!canDownload}
          // onClick={() => {
          //   window.open(downloadURL, "_blank");
          // }}
          onClick={handleDownload}
        >
          <img
            src={DownloadGrayIcon}
            style={{ marginRight: "5px", margin: "4px" }}
            alt="Download Icon"
          />
          Download
        </Button>
      </Box>
      <Box
        style={{ display: "flex", gap: ".4rem", fledDirection: "row" }}
        position="relative"
      >
        {/* 
          - TODO: Edit button only show when status === Pending 
          - TODO: Action button only show when status === Approve | Pending | Partial
          - See notes.todo for more information
          */}
        {status === "Pending" && (
          <Button variant="outlined" onClick={handleEdit}>
            Edit
          </Button>
        )}
        {canAction && (
          <Button
            variant="contained"
            onClick={() => setExpandAction((prev) => !prev)}
          >
            Action
            <img
              src={ArrowDown}
              alt="arrow"
              style={{ rotate: expandAction ? "180deg" : "0deg" }}
            />
          </Button>
        )}
        {expandAction && (
          <Box
            position="absolute"
            border="1px solid #3498db"
            bottom="105%"
            width={"100%"}
            paddingY={".2rem"}
            borderRadius={"5px"}
            sx={{
              background: "#fff",
              ".MuiTypography-body2:hover": {
                background: "#1268B8",
                color: "#fff",
              },
            }}
          >
            <Typography
              variant="body2"
              padding={".5rem"}
              textAlign={"center"}
              // bgcolor={"#1268B8"}
              marginY={".2rem"}
              // color={"#fff"}
              style={{ cursor: "pointer" }}
              onClick={handleAction}
            >
              {status === "Pending" ? "Approved" : "Receive Payment"}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RenderBottomAction;
