import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Modal,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  borderRadius,
  StatusBadge,
  defaultStylePage,
  btnWhite,
  mainColor,
  surfaceColor,
  neutral10,
  neutral100,
  neutral40,
} from "../../../util/style";
import { useDispatch, useSelector } from "react-redux";
import {
  ACTION_TYPE,
  MODE_CRUD_WITH,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import { deleteArchive, getArchive } from "./clients/actions";
import { useTheme } from "@mui/styles";
import Form from "../../../components/Form";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import axios from "../../../core/axios_config";
import { useNavigate } from "react-router-dom";

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
  return formattedDate;
}

const columns = [
  {
    field: "archive_code",
    headerName: "Archive Number",
    width: 135,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.row.archive_code.replace("-", "")
    },
  },
  {
    field: "date",
    headerName: "Date Created",
    width: 201,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <div>Created: <span><b>{formatDate(params.value)}</b></span></div>
      );
    }
  },
  {
    field: "customer_name",
    headerName: "Client Name",
    width: 291,
    disableColumnMenu: true,
  },
  {
    field: "service_category",
    headerName: "Service Category",
    width: 161,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
          {params.value.map((v, key) => (
            // <Chip label={v} sx={{ color: mainColor, backgroundColor: surfaceColor, m: "2px 2px" }} />
            <StatusBadge
              key={key}
              status={v.service_category_name}
              isinfo={true}
              style={{ margin: ".1rem .25rem" }}
            >
              {v.service_category_name}
            </StatusBadge>
          ))}
        </Box>
      );
    }
  },
  {
    field: "sub_service_category",
    headerName: "Sub Service Category",
    width: 301,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.service_category;
      let ssc = sc.reduce((r, i) => [...r, ...i.get_sub_category], [])
      return ssc;
    },
    renderCell: (params) => {
      return (
        <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
          {
            params.value.map((v, key) => (
              <StatusBadge
                key={key}
                status={v.name}
                isinfo={true}
                style={{ margin: ".1rem .25rem" }}
              >
                {v.name}
              </StatusBadge>
            ))
          }

        </Box>
      );
    }
  },
  {
    field: "registration_number",
    headerName: "Registration Number",
    width: 291,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.service_category;
      return sc;
    },
    renderCell: (params) => {
      return (
        <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
          {params.value.map((v, key) => (
            // <Chip label={v} sx={{ color: mainColor, backgroundColor: surfaceColor, m: "2px 2px" }} />
            <StatusBadge
              key={key}
              status={v.registration_number}
              isinfo={true}
              style={{ margin: ".1rem .25rem" }}
            >
              {v.registration_number}
            </StatusBadge>
          ))}
        </Box>
      );
    }
  },
  {
    field: "reference_number",
    headerName: "Reference Number",
    width: 291,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.service_category;
      return sc;
    },
    renderCell: (params) => {
      return (
        <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
          {params.value.map((v, key) => (
            // <Chip label={v} sx={{ color: mainColor, backgroundColor: surfaceColor, m: "2px 2px" }} />
            <StatusBadge
              key={key}
              status={v.reference_number}
              isinfo={true}
              style={{ margin: ".1rem .25rem" }}
            >
              {v.reference_number}
            </StatusBadge>
          ))}
        </Box>
      );
    }
  },
  {
    field: "brand",
    headerName: "Brand/Patent (Design)",
    width: 291,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.service_category;
      let ssc = sc.reduce((r, i) => [...r, ...[i.brand]], [])
      return ssc;
    },
    renderCell: (params) => {
      return (
        <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
          {
            params.value.map((v, key) => (
              <StatusBadge
                key={key}
                status={v ? v.name : ""}
                isinfo={true}
                style={{ margin: ".1rem .25rem" }}
              >
                {v ? v.name : ""}
              </StatusBadge>
            ))
          }

        </Box>
      );
    }
  },
  {
    field: "applicant",
    headerName: "Applicant",
    width: 291,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.service_category;
      let ssc = sc.reduce((r, i) => [...r, ...[i.applicant]], [])
      return ssc;
    },
    renderCell: (params) => {
      return (
        <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
          {
            params.value.map((v, key) => (
              <StatusBadge
                key={key}
                status={v ? v.name : ""}
                isinfo={true}
                style={{ margin: ".1rem .25rem" }}
              >
                {v ? v.name : ""}
              </StatusBadge>
            ))
          }

        </Box>
      );
    }
  },
  {
    field: "cupboard_name",
    headerName: "Cupboard Name",
    width: 301,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.service_category;
      let cupboard = sc.reduce((r, i) => [...r, ...[i.get_cupboard]], [])
      return cupboard;
    },
    renderCell: (params) => {
      return (
        <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
          {
            params.value.map((v, key) => (
              <StatusBadge
                key={key}
                status={v.cupboard_name}
                isinfo={true}
                style={{ margin: ".1rem .25rem" }}
              >
                {v.cupboard_name}
              </StatusBadge>
            ))
          }

        </Box>
      );
    }
  },
  {
    field: "rack_number",
    headerName: "Rack Number",
    width: 301,
    disableColumnMenu: true,
    valueGetter: (params) => {
      let sc = params.row.service_category;
      let rack_number = sc.reduce((r, i) => [...r, ...[i.get_rack]], [])
      return rack_number;
    },
    renderCell: (params) => {
      return (
        <Box sx={{ display: "inline-block", gap: "0.4rem", padding: ".5rem 0" }}>
          {
            params.value.map((v, key) => (
              <StatusBadge
                key={key}
                status={v.rack_number}
                isinfo={true}
                style={{ margin: ".1rem .25rem" }}
              >
                Rack {v.rack_number}
              </StatusBadge>
            ))
          }

        </Box>
      );
    }
  },
  {
    field: "note",
    headerName: "Note",
    width: 240,
    disableColumnMenu: true,
  },
  {
    field: "created_by",
    headerName: "Created By",
    width: 250,
    valueGetter: (params) => {
      return params.row.get_user.fullname;
    },
    disableColumnMenu: true,
  },
];

const Archive = () => {
  /* -------------------------------------------------------------------------- */
  /*                                   STYLES                                   */
  /* -------------------------------------------------------------------------- */

  const theme = useTheme();
  const useStyles = defaultStylePage;

  const { token } = useSelector(state => state.auth)
  const [selectedData, setSelectedData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState("customer_name");
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortBy, setSortBy] = useState("id");
  const [sortModel, setSortModel] = useState([]);
  const [archiveDeleted, setArchiveDeleted] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [confirmModal, setConfirmModal] = useState(false);
  const [formModal, setFormModal] = useState(false);
  const [title, setTitle] = useState("Add New Archive");
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD);
  const [dateFilter, setDateFilter] = useState(ACTION_TYPE.DEFAULT_DATE);
  const [categoryFilter, setCategoryFilter] = useState(ACTION_TYPE.ALL_CATEGORY);
  const [listCategory, setlistCategory] = useState([]);
  const [typeData, setTypeData] = useState("Hardcopy");
  const placeholder = {
    "customer_name": "Customer Name",
    "Brand": "Brand",
    "Applicant": "Applicant",
    "reference_number": "No. Reference",
    "registration_number": "No. Registration",
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // console.log(token);
  // useSelector
  const {
    data: rows,
    message,
    isLoading,
    pagination,
  } = useSelector((state) => state.archive);

  const {
    modal: { isActive },
  } = useSelector((state) => state.global);

  // useEffect
  useEffect(() => {
    dispatch(
      getArchive({
        categoryFilter,
        dateFilter,
        token,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    );
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (isActive === true) {
      dispatch(
        getArchive({
          categoryFilter,
          dateFilter,
          token,
          limit,
          searchValue,
          searchBy,
          sortDirection,
          sortBy,
        })
      );
    }
  }, [isActive]);

  useEffect(() => {
    axios
      .get("/master/services", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setlistCategory(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching service categories:", error);
      });
  }, [])

  useEffect(() => {
    if (sortModel && sortModel.length) {
      const { field, sort } = sortModel[0];
      setSortBy(field);
      setSortDirection(sort);
    }
  }, [sortModel]);

  useEffect(() => {
    if (!searchValue) { _search() }
  }, [searchValue])
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchValue) {
      _search();
    }
  };


  // handle
  const handlePageChange = (newPage) => {
    setPage(newPage);
    _getByPage(newPage + 1);
  };

  const handleLimitChange = (newLimit) => {
    // console.log(newLimit);
    setPage(0);
    setLimit(newLimit);
    _getByLimit(newLimit);
  };

  const handleSelectionChange = (selection) => {
    console.log(selection, "selection");
    setSelectedData(selection || []);
  };

  const handleFormModal = (actionType = ACTION_TYPE.ADD) => {
    setActionType(actionType);
    setFormModal(true);
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      setTitle(`Delete Archive ${typeData}`);
      const data = rows.filter((row) => selectedData.includes(row.id));
      setArchiveDeleted(data.map((row) => row.customer_name).join(", "));
    }
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    setFormModal(false);
    // resetFormValues();
    setSelectedData([]); //buat disabled button toolbar
  };

  const handleCloseFormModal = (important = false) => {
    if (!important) {
      console.log("a", important);
      if (actionType === ACTION_TYPE.EDITED || actionType === ACTION_TYPE.ADD) {
        console.log("b", important);
        if (true) setConfirmModal(true);
      } else {
        setFormModal(false);
        console.log("c", important);
      }
    } else {
      console.log("d", important);
      setFormModal(false);
    }
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      handleDeleteRow();
      handleCloseFormModal(true);
      setSelectedData([]);
    }
  };

  const handleDeleteRow = () => {
    const data = rows.filter((row) => selectedData.includes(row.id));
    const ids = data.map((row) => row.id);
    dispatch(deleteArchive(token, { data: { ids } }, true));
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
  };

  const handleRowDoubleClick = (selection) => {
    console.log(selection, "selection");
    navigate(`detail/${selection.id}`, { state: { type: typeData } });
  }

  const handleChangeType = (v) => {
    setTypeData(v);
  }

  const handleToolbarLeft = () => {
    return (
      <Box>
        <StatusBadge
          key={"Hardcopy"}
          status={"Hardcopy"}
          isinfo={false}
          colors={typeData === "Hardcopy" ? mainColor : neutral100}
          bgColor={typeData === "Hardcopy" ? surfaceColor : neutral10}
          onClick={() => handleChangeType("Hardcopy")}
          style={{
            marginRight: "16px",
            borderRadius: "48px",
            padding: "6px 16px 6px 16px",
            border: typeData !== "Hardcopy" ? `1px solid ${neutral40}` : 'none'
          }} >
          Hardcopy
        </StatusBadge>
        <StatusBadge
          key={"Softcopy"}
          status={"Softcopy"}
          isinfo={false}
          colors={typeData === "Softcopy" ? mainColor : neutral100}
          bgColor={typeData === "Softcopy" ? surfaceColor : neutral10}
          onClick={() => handleChangeType("Softcopy")}
          style={{
            marginRight: "16px",
            borderRadius: "48px",
            padding: "6px 16px 6px 16px",
            border: typeData !== "Softcopy" ? `1px solid ${neutral40}` : 'none'
          }} >
          Softcopy
        </StatusBadge >
      </Box >
    )
  }

  // render
  const renderFormContent = () => {
    if (selectedData.length && actionType === ACTION_TYPE.DELETED) {
      return <ConfirmDelete userDeleted={`the archive ${typeData}`} />;
    }
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCloseFormModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit">
          {
            actionType === ACTION_TYPE.DELETED ? "Yes" : "Save"
          }
        </Button>
      </Box>
    );
  };

  // function
  function _getByPage(page) {
    if (pagination === null || pagination === undefined) {
      return;
    }
    dispatch(
      getArchive({
        categoryFilter,
        dateFilter,
        token,
        page,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    );
  }

  function _getByLimit(limit) {
    dispatch(
      getArchive({
        categoryFilter,
        dateFilter,
        token,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    );
  }

  function _dateFilter(date) {
    setDateFilter(date);
    dispatch(
      getArchive({
        categoryFilter,
        dateFilter: date,
        token,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    )
  }

  function _categoryFilter(category) {
    setCategoryFilter(category);
    dispatch(
      getArchive({
        categoryFilter: category,
        dateFilter,
        token,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    )
  }

  function _search() {
    dispatch(
      getArchive({
        categoryFilter,
        dateFilter,
        token,
        limit,
        searchValue,
        searchBy,
        sortDirection,
        sortBy,
      })
    )
  }

  return (
    <>
      <Card style={{ boxShadow: "unset" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                placeholder={`Search ${placeholder[searchBy]}`}
                size="small" // Set the size to "small"
                sx={{
                  borderRadius, // Apply the desired border radius value
                }}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  onKeyDown: handleKeyPress,
                  startAdornment: <SearchIcon />,
                  endAdornment: (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      SelectProps={{
                        IconComponent: ExpandMoreIcon,
                      }}
                      value={searchBy}
                      sx={{
                        width: 250,
                        '& fieldset': {
                          border: 'unset',
                        }, borderLeft: `2px solid #ddd`
                      }}
                      onChange={(e) => setSearchBy(e.target.value)}
                    >
                      <MenuItem value="customer_name">Customer Name</MenuItem>
                      <MenuItem value="Brand">Brand</MenuItem>
                      <MenuItem value="Applicant">Applicant</MenuItem>
                      <MenuItem value="reference_number">No. Reference</MenuItem>
                      <MenuItem value="registration_number">No. Registration</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="contained"
                disableElevation
                sx={{ height: "100%" }}
                onClick={_search}
                onKeyPress={handleKeyPress}
              >
                <SearchIcon />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                value={categoryFilter}
                size="small"
                disabled={dateFilter === ACTION_TYPE.DEFAULT_DATE}
                SelectProps={{
                  IconComponent: ExpandMoreIcon, // Use ExpandMoreIcon for the open state
                  // Comment out the line above and use the line below to use ExpandLessIcon for the open state
                  // IconComponent: ExpandLessIcon,
                }}
                onChange={(e) => _categoryFilter(e.target.value)}
                sx={{
                  borderRadius, // Apply the desired border radius value
                }}
              >
                <MenuItem value={ACTION_TYPE.ALL_CATEGORY}>All service category</MenuItem>
                {listCategory.map((category) => (
                  <MenuItem value={category.id}>{category.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                select
                fullWidth
                size="small"
                SelectProps={{
                  IconComponent: ExpandMoreIcon, // Use ExpandMoreIcon for the open state
                  // Comment out the line above and use the line below to use ExpandLessIcon for the open state
                  // IconComponent: ExpandLessIcon,
                }}
                value={dateFilter}
                onChange={(e) => _dateFilter(e.target.value)}
                sx={{
                  borderRadius, // Apply the desired border radius value
                }} >
                <MenuItem value={ACTION_TYPE.DEFAULT_DATE}>Select Date</MenuItem>
                <MenuItem value="all_date">All date</MenuItem>
                <MenuItem value="this_week">This week</MenuItem>
                <MenuItem value="this_month">This month</MenuItem>
                <MenuItem value="last_6_months">Last 6 months</MenuItem>
                <MenuItem value="this_year">This year</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: "1rem", boxShadow: "unset" }}>
        <CardContent sx={{ paddingTop: "unset" }}>
          <DataTable
            title={"Archive"}
            useStyles={useStyles}
            rows={rows}
            columns={columns}
            isLoading={isLoading}
            pagination={pagination}
            limit={limit}
            page={page}
            handlePageChange={handlePageChange}
            handleLimitChange={handleLimitChange}
            handleFormModal={handleFormModal}
            handleRowDoubleClick={handleRowDoubleClick}
            selectedData={selectedData}
            handleSelectionChange={handleSelectionChange}
            theme={theme}
            search={searchValue}
            dateChoosed={dateFilter}
            sortModel={sortModel}
            stateCreate={{ type: typeData }}
            stateUpdate={{ type: typeData }}
            toolBarLeft={handleToolbarLeft}
            onSortModelChange={handleSortModelChange}
            modeCRUD={MODE_CRUD_WITH.PAGING}
            linkCRUD={{ create: "add", update: `edit/${selectedData}` }}
          />
        </CardContent>
      </Card>
      <Modal open={formModal} onClose={() => handleCloseFormModal()}>
        <Box>
          <Form
            title={title}
            onCloseModal={() => handleCloseFormModal()}
            elementForm={renderFormContent}
            elementActionButton={renderActionButtons}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onConfirmModal={handleOnConfirmCancelModal}
        onCloseModal={handleOnCloseConfirmCancelModal}
      ></ModalConfirmCancel>
    </>
  )
}

export default Archive;
