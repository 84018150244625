import { REQUEST_LOADING } from "../../../../util/global_state";
import {
  ADD_STOCK_OUT_FAILED,
  ADD_STOCK_OUT_SUCCESS,
  DELETE_STOCK_OUT_FAILED,
  DELETE_STOCK_OUT_SUCCESS,
  GET_STOCK_OUT_FAILED,
  GET_STOCK_OUT_SUCCESS,
  UPDATE_STOCK_OUT_FAILED,
  UPDATE_STOCK_OUT_SUCCESS,
} from "./state";

const initialState = {
  data: null,
  isLoading: false,
  pagination: null,
  message: null,
};

const stockOutReducer = (
  state = initialState,
  { type, payload, pagination }
) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_STOCK_OUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload,
        pagination: pagination,
      };
    case GET_STOCK_OUT_FAILED:
    case ADD_STOCK_OUT_FAILED:
    case ADD_STOCK_OUT_SUCCESS:
    case UPDATE_STOCK_OUT_FAILED:
    case UPDATE_STOCK_OUT_SUCCESS:
    case DELETE_STOCK_OUT_FAILED:
    case DELETE_STOCK_OUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: payload,
      };
    default:
      return state;
  }
};

export default stockOutReducer;
