import React, { useState, useRef, useEffect } from "react";
import axios, { headersAPIToken } from "../../../../core/axios_config";
import { useSelector } from "react-redux";

/**
 *  @typedef {object} OptionData;
 * @property {string} endpoint
 * @property {string} name
 */

/**
 *
 * @param {boolean} toggleGet
 * @param {OptionData | OptionData[]} data
 * @return {[object[], boolean, any]} | [options, doneFetching, error] |
 */
export default function useOptions(toggleGet, data) {
  const { token } = useSelector((state) => state.auth);
  const [doneFetching, setDoneFetching] = useState(false);
  const [canGet, setCanGet] = useState(toggleGet);
  const [options, setOptions] = useState({});
  const responseError = useRef(null);
  const count = useRef(0);

  const getOptions = async (endpoint, name) => {
    axios
      .get(endpoint, headersAPIToken(token))
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          let payloadOpt = response.data.data;
          if (name === "bank_address_id" && payloadOpt.length < 1) {
            payloadOpt = [
              {
                id: 1,
                name: "[dummy]-123456789",
              },
              {
                id: 2,
                name: "[dummy]-987654321",
              },
            ];
          }
          setOptions((prev) => ({ ...prev, [name]: payloadOpt }));
        } else {
          setOptions((prev) => ({ ...prev, [name]: [] }));
        }
      })
      .catch((error) => {
        responseError.current = error;
      })
      .finally(() => {
        // setCount((prev) => prev++);
        count.current = count.current + 1;
      });
  };

  if (!Array.isArray(data) && data.hasOwnProperty("endpoint") && canGet) {
    getOptions(data.endpoint, data.name);
  } else if (Array.isArray(data) && data.length > 0 && canGet) {
    let dataLength = data.length;
    console.log({ length: dataLength });
    // setDoneFetching(false);
    for (let i = 0; i < dataLength; i++) {
      getOptions(data[i].endpoint, data[i].name);

      if (i === dataLength - 1) {
        // setDoneFetching(true);
        setCanGet(false);
      }
    }
  }

  useEffect(() => {
    if (Array.isArray(data) && count.current === data.length - 1) {
      setDoneFetching(true);
    } else if (count.current === 1) {
      setDoneFetching(true);
    }
    console.log(count.current);
  }, [count.current, data]);

  return [options, doneFetching, responseError];
}
