import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
  ButtonGroup,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../util/style";
import exportIcon from "./../../../assets/export.png";
import axios, { urlRequest } from "../../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  TimePickerInput,
  DatePickerInput,
} from "../../../components/Form";
import {
  clearDetailPaymentRequest,
  getDetailPaymentRequest,
  markDetailForEdit,
  sendApproval,
} from "./clients/actions";
import {
  ACTION_TYPE,
  SALES_ORDER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  saveResponseToFile,
  pxToMm,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import { RequestLoading } from "../../../util/global_state";
import ArrowBackIcon from "./../../../assets/back.svg";
import ZoomInIcon from "./../../../assets/zoom-in.png";
import successIMG from "./../../../assets/success.png";
import PrinterIcon from "./../../../assets/printer.png";
import PrinterGrayIcon from "./../../../assets/printer-gray.png";
import DownloadGrayIcon from "./../../../assets/download-gray.png";
import ZoomOutIcon from "./../../../assets/zoom-out.png";
import logo from "./../../../logo.png";
import boderImg from "./../../../assets/border.png";
import PreviewPDF from "./componens/PreviewPDF";
import DetailDocument from "./componens/DetailPapper";
import DocumentForPrint from "./componens/DocumentForPrint";
import { paymentStatusColor } from "./parts/CusomStyle";
import { ACTION_APPROVE } from "./parts/imutable_state";
import RenderBottomAction from "./componens/detail/BottomAction";

//* Main Function
function DetailPaymenRequest() {
  /**
   * @typedef {object} fieldForm
   * @property {String} name
   * @property {String} type
   * @property {String} label
   * @property {String} defaultValue
   * @property {String} placeholder
   * @property {Boolean} required
   * @property {string} errorMessage
   */

  //* State Reducer
  const { data, pagination, message, isLoading } = useSelector(
    (state) => state.financePaymentRequestDetail
  );

  const statusValidToPrintPDF = ["approved", "paid"];

  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#e1e1e1",
      overflow: "hidden",
      position: "relative !important",
    },
    logo: {
      position: "absolute",
      width: "100%",
    },
    header: {
      padding: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "10px 10px 0 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    content: {
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    footer: {
      padding: "20px",
      backgroundColor: "#09345C",
      borderRadius: "0 0 10px 10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));

  /**@type {[fieldForm, FunctionConstructor]} */
  const [commentField, setCommentField] = useState({
    name: "comment",
    type: INPUT_TYPE.TEXT,
    label: "Comment",
    defaultValue: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState(ACTION_APPROVE.DEFAULT);
  const [formValue, setFormValue] = useState({});
  const [errors, setErrors] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [modalConfirmMessage, setModalCofirmMessage] = useState(
    "You want to cancel the process approval payment request"
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("Detail Payment Request");
  const documentPreviewForPrintRef = useRef(null);
  const [
    isDoneFetchHeightDocumentPreview,
    setIsDoneFetchHeightDocumentPreview,
  ] = useState(true);

  const [height, setHeight] = useState(0);
  //* Detail Payment Request
  const [detailPR, setDetailPR] = useState({});
  const [attachmentDetail, setAttachmentDetail] = useState(null);

  const initializeFormValue = () => ({
    [commentField.name]: commentField.defaultValue,
  });

  const initializeError = () => ({
    [commentField.name]: "",
  });

  //* Initial Render;
  useEffect(() => {
    //* set Form value to default value of field
    setFormValue(initializeFormValue());
    setErrors(initializeError());
  }, []);

  const handleInputBlur = (event) => {
    // validate;
    const { name } = event.target;
    validateForm(name);
  };

  const handleInputChange = (e) => {
    // console.log(e);
    const { name, value } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: value }));
  };

  const [selectedIdPaymentRequest, setSelectedIdPaymentRequest] =
    useState(null);
  // const formRef = useRef();

  const { token, user } = useSelector((state) => state.auth);

  const { id: paymentReqId } = useParams();

  useEffect(() => {
    setSelectedIdPaymentRequest(paymentReqId);
  }, [paymentReqId]);

  useEffect(() => {
    if (selectedIdPaymentRequest) {
      dispatch(getDetailPaymentRequest(token, selectedIdPaymentRequest));
    }
  }, [selectedIdPaymentRequest]);

  useEffect(() => {
    // console.log(data);
    if (data !== null) {
      let PRDetail = data["Payment Request Information"];
      // console.log(data, "data when get");
      let attach = data.Attachment;
      attach = attach !== undefined ? attach.attachment : null;
      setAttachmentDetail(attach);
      setDetailPR(PRDetail);
      // console.log(PRDetail, "data detail");
    }
  }, [data]);

  //* Watch actionType
  useEffect(() => {
    if (action === ACTION_APPROVE.APPROVE) {
      setCommentField((prev) => ({
        ...prev,
        placeholder: "E.g.: I Approve the submission as of this date.",
        required: false,
      }));
    } else if (action === ACTION_APPROVE.REJECT) {
      setCommentField((prev) => ({
        ...prev,
        placeholder: "E.g.: the data is not complete.",
        required: true,
        errorMessage: "Fill in the comment",
      }));
    }
  }, [action]);

  const validateForm = () => {
    if (commentField.required) {
      if (
        formValue[commentField.name] === null ||
        formValue[commentField.name] === "" ||
        formValue[commentField.name] === undefined
      ) {
        setErrors((prev) => ({
          ...prev,
          [commentField.name]: commentField.errorMessage,
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, [commentField.name]: "" }));
        return true;
      }
    }
    return true;
  };

  const handleBackBtn = () => {
    dispatch(clearDetailPaymentRequest());
    navigate("/finance/payment-request");
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    // validasi
    if (validateForm()) {
      //TODO: send approve and show modal success
      let payloadAction = {
        action:
          action === ACTION_APPROVE.APPROVE
            ? "approve"
            : action === ACTION_APPROVE.REJECT
            ? "reject"
            : "",
        reason: formValue[commentField.name],
      };
      dispatch(sendApproval(token, payloadAction, paymentReqId, action));
      dispatch(getDetailPaymentRequest(token, selectedIdPaymentRequest));
      setShowModal(false);
      setFormValue(initializeFormValue());
    }
  };

  const handleCloseModal = () => {
    setConfirmModal(true);
  };
  const handleClickEdit = () => {
    dispatch(markDetailForEdit(paymentReqId));
    navigate("/finance/payment-request");
  };

  const handleActionDetail = (typeAction) => {
    setAction(typeAction);
    setShowModal(true);
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
    setShowModal(false);
    setAction(ACTION_APPROVE.DEFAULT);
    setFormValue(initializeFormValue());
    setErrors(initializeError());
  };

  const handleYesConfirmModal = () => {
    setConfirmModal(false);
  };
  //*  =============== Component section ==================

  const RenderActionButtonsApproval = () => {
    if (
      (detailPR.can_approve || user.email === "vforimmortaly@gmail.com") &&
      detailPR.Status !== "Paid" &&
      detailPR.Status !== "Approved"
    ) {
      return (
        <>
          <Box>
            <Button
              sx={{
                backgroundColor: paymentStatusColor.rejected.backgroundColor,
                color: paymentStatusColor.rejected.color,
                marginInline: ".5rem",
                border: "none",
              }}
              disableElevation
              type="button"
              variant="outlined"
              className="no-effect"
              onClick={() => handleActionDetail(ACTION_APPROVE.REJECT)}
            >
              Reject
            </Button>
            <Button
              sx={{
                backgroundColor: paymentStatusColor.approved.backgroundColor,
                color: paymentStatusColor.approved.color,
                border: "none",
              }}
              disableElevation
              type="button"
              variant="outlined"
              className="no-effect"
              onClick={() => handleActionDetail(ACTION_APPROVE.APPROVE)}
            >
              Approve
            </Button>
          </Box>
        </>
      );
    } else {
      return null;
    }
  };

  // Function to set height if the ref is initialized
  const setHeightIfRefExists = () => {
    if (documentPreviewForPrintRef?.current) {
      setHeight(documentPreviewForPrintRef.current.clientHeight);
    }
  };

  const printContent = () => {
    // Implement the print logic
    //setHeight(document.querySelector('#kakak').clientHeight)//documentPreviewForPrintRef.current);
    //       setHeight(documentPreviewForPrintRef?.current?.clientHeight);
    window.print();
  };

  const isDisableButtonAction = () =>
    !statusValidToPrintPDF.includes(detailPR.Status.toLowerCase());

  const renderBottomAction = () => {
    return (
      <Box
        sx={{
          display: "flex",
          marginInline: "1rem",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: ".5rem", marginInline: "1rem" }}>
          <Button
            variant="outlined"
            sx={{
              ...(detailPR["Status"] === "approved" ? btnLightBlue : btnGray),
            }}
            style={{ borderRadius: "7px" }}
            disableElevation
            className="not-effect"
            onClick={() => printContent()}
          >
            <img
              src={PrinterGrayIcon}
              style={{ marginRight: "5px", margin: "4px" }}
              alt="Printer Icon"
            />
            Print
          </Button>
          <Button
            variant="outlined"
            sx={{
              ...(detailPR.Status && detailPR.Status.toLowerCase() == "approved"
                ? btnLightBlue
                : btnGray),
            }}
            disableElevation
            className="not-effect"
            onClick={() => {
              window.open(
                process.env.REACT_APP_API_URL +
                  `/api/payment-requests/${selectedIdPaymentRequest}/download-pdf`,
                "_blank"
              );
            }}
          >
            <img
              src={DownloadGrayIcon}
              style={{ marginRight: "5px", margin: "4px" }}
              alt="Download Icon"
            />
            Download
          </Button>
        </Box>
        <Box>
          {detailPR.Status && detailPR.Status.toLowerCase() === "rejected" && (
            <Button variant="contained" onClick={handleClickEdit}>
              Edit
            </Button>
          )}
        </Box>
      </Box>
    );
  };

  const renderActionButtonModal = () => (
    <>
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          color={action === ACTION_APPROVE.APPROVE ? "primary" : "error"}
          variant="contained"
          disableElevation
          type="submit"
        >
          {action}
        </Button>
      </Box>
    </>
  );

  const renderElementFormModal = () => {
    return (
      <>
        <Box marginBottom={"1.5rem"}>
          <Typography variant="h6" fontWeight={700}>
            {action} Approval?
          </Typography>
          <Typography>
            Request will be{" "}
            {action === ACTION_APPROVE.APPROVE ? "approved" : "rejected"} by you
          </Typography>
        </Box>
        <TextInput
          field={commentField}
          formValues={formValue}
          errors={errors}
          handleInputBlur={handleInputBlur}
          handleInputChange={handleInputChange}
        />
      </>
    );
  };

  // Check if the ref exists and then set the height
  useEffect(() => {
    if (!isLoading && data !== null) {
      setTimeout(() => {
        console.log("timeout");
        setHeightIfRefExists();
        setIsDoneFetchHeightDocumentPreview(true);
      }, 2000);
    }
  }, [isLoading, data, documentPreviewForPrintRef]); // Dependency array to watch for changes in the ref

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "81vh",
          position: "relative",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <Box>
                <IconButton
                  edge="start"
                  color="inherit"
                  variant="outlined"
                  onClick={handleBackBtn}
                  sx={{ marginRight: ".5rem" }}
                >
                  <img src={ArrowBackIcon} />
                </IconButton>
                {title}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <RenderActionButtonsApproval />
              </Box>
            </div>
          }
        />
        <Divider />
        <Box
          flex="1"
          sx={{
            overflow: "auto",
            // marginBottom: "2.3rem",
            // maxHeight: "70vh",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "45vh",
                display: "flex",
                alignItems: " center",
                justifyContent: "center",
              }}
            >
              <FacebookCircularProgress />
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                {/* <ForPrintingV3 /> */}
                <DocumentForPrint
                  detailPR={detailPR}
                  useStyles={useStyles}
                  height={height}
                  mode="PAYMENT REQUEST"
                />
              </Grid>
              <Grid item xs={5.2} style={{ height: `calc(70dvh - 3rem)` }}>
                {/* <Detail/> */}
                <DetailDocument
                  detailDocument={detailPR}
                  attachmentAltenative={attachmentDetail}
                  mode="PAYMENT_REQUEST"
                />
              </Grid>
              <Grid item xs={6.8}>
                {/* <img src="https://s3.bukalapak.com/img/3942411727/w-1000/lacto.png"></img> */}
                <PreviewPDF
                  detailPR={detailPR}
                  isDoneFetchHeightDocumentPreview={
                    isDoneFetchHeightDocumentPreview
                  }
                  useStyles={useStyles}
                  mode={"PAYMENT REQUEST"}
                />
                {/* <PreviewPDFV2 /> */}
              </Grid>
            </Grid>
          )}
        </Box>
        <style>
          {`
      .page-break {
        page-break-before: always;
      }
      .DocumentPreview{
        display:none;
      }
      .DocumentPreviewFooter{
        padding: 20px;
        background-color: #09345C;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      @media print {
//           @page {
//               /* size: A4; */
//               /* margin: 200mm; */
//               /* Adjust margins as needed */
//           }

        @page {
            // size: A4;
//               size: 210mm 280mm;
          size: 210mm ${pxToMm(height)}mm; /* A4 size */
            margin: 0;
            /* Remove all margins */
        }

        body {
            margin: 0;
        }

        body *{
          visibility:hidden;
        }
.DocumentPreviewFooter{
 position:fixed;
bottom:0;
left:0;
width:100%;
border-radius:unset;
}
        .DocumentPreviewFooter, .DocumentPreviewFooter *{
            visibility: visible;
padding: 10px 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        print-color-adjust: exact;
        webkit-print-color-adjust: exact; /* For WebKit-based browsers */
      background-color:  #09345C !important; /* Override background color */
      }

        .DocumentPreview{
          display:block;
          position: fixed !important;
          top: 0;import { INPUT_TYPE } from './../../../util/function';


          left: 0;
          width: 100%;
          z-index: 11999;
          visibility: visible;
          page-break-before: always;
        }

        .DocumentPreview,.DocumentPreview *{
            visibility: visible;
        }
      }

      `}
        </style>
        <Box
          id="parent bawah"
          sx={{
            paddingBlock: ".6rem",
            position: "absolute",
            bottom: 0,
            height: "3rem",
            width: "100%",
            background: "#fff",
          }}
        >
          {!isLoading && (
            <RenderBottomAction
              handlePrintBtn={printContent}
              handleRightAction={handleClickEdit}
              id={selectedIdPaymentRequest}
              mode="PAYMENT_REQUEST"
              status={detailPR?.Status}
            />
          )}
        </Box>
      </Card>
      <Modal open={showModal}>
        <Box>
          <Form
            title={"Approval"}
            onCloseModal={handleCloseModal}
            elementForm={renderElementFormModal}
            elementActionButton={renderActionButtonModal}
            onSubmit={handleSubmitForm}
          ></Form>
        </Box>
      </Modal>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onCloseModal={handleCloseConfirmModal}
        onConfirmModal={handleYesConfirmModal}
        message={modalConfirmMessage}
      ></ModalConfirmCancel>
    </>
  );
}
export default DetailPaymenRequest;
