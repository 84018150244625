export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS";
export const GET_APPLICATION_FAILED = "GET_APPLICATION_FAILED";
export const ADD_APPLICATION_SUCCESS = "ADD_APPLICATION_SUCCESS";
export const ADD_APPLICATION_FAILED = "ADD_APPLICATION_FAILED";
export const EDIT_APPLICATION_SUCCESS = "EDIT_APPLICATION_SUCCESS";
export const EDIT_APPLICATION_FAILED = "EDIT_APPLICATION_FAILED";
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS";
export const DELETE_APPLICATION_FAILED = "DELETE_APPLICATION_FAILED";
export const GET_APPLICATION_DETAILS_SUCCESS = "GET_APPLICATION_DETAILS_SUCCESS";
export const GET_APPLICATION_DETAILS_FAILED = "GET_APPLICATION_DETAILS_FAILED";

export const APPLICATION_LOADING = "APPLICATION_LOADING";
export const APPLICATION_DETAILS_LOADING = "APPLICATION_DETAILS_LOADING";

