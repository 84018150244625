//@ts-check
import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../util/function";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import { logout } from "../../../auth/clients/login/actions";
import {
  ADD_GA_ASSET_FAILED,
  ADD_GA_ASSET_SUCCES,
  DELETE_GA_ASSET_FAILED,
  DELETE_GA_ASSET_SUCCESS,
  DETAIL_GA_ASSET_FAILED,
  DETAIL_GA_ASSET_SUCCESS,
  GET_GA_ASSET_FAILED,
  GET_GA_ASSET_SUCCESS,
  UPDATE_GA_ASSET_FAILED,
  UPDATE_GA_ASSET_SUCCESS,
} from "./state";

/**
 * @typedef {import("@reduxjs/toolkit").Dispatch} dispatch
 * @typedef {'GET'|'POST'|'EDIT'|'DELETE'|'DETAIL'} MethodType
 */

const assetBaseURl = "assets";

/** --------------------------------------------------------------------- */
/**                                 HELPER                                */
/** --------------------------------------------------------------------- */

/**
 *
 * @param {import("axios").AxiosResponse} response
 * @param {dispatch} dispatch
 * @param {Function} successHandler
 * @param {Function} errorHandler
 * @param {MethodType} type
 */
const responseHandler = (
  response,
  dispatch,
  successHandler,
  errorHandler,
  type
) => {
  if (
    typeof successHandler !== "function" ||
    typeof errorHandler !== "function"
  ) {
    throw new Error("Handler is not function");
  }
  // const listActionRunModal = ["POST", "EDIT", "DELETE"];
  if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === true
  ) {
    if (type === "GET") {
      dispatch(successHandler(response.data.data, response.data));
    } else if (type === "DETAIL") {
      dispatch(successHandler(response.data.data));
    } else {
      dispatch(
        RunModal(
          type === "POST" ? "SAVED" : type === "EDIT" ? "EDITED" : "DELETED",
          "assets"
        )
      );
      dispatch(successHandler(response.data.message));
    }
  } else if (
    (response.status === 200 || response.status === 201) &&
    response.data.status === false
  ) {
    if (type === "GET") {
      dispatch(successHandler([], null));
    } else {
      dispatch(errorHandler(response.data.message));
      dispatch(RunModal("FAILED", response.data.message));
    }
  } else {
    dispatch(errorHandler(response.data.message));
    dispatch(RunModal("FAILED", response.data.message));
  }
};

/**
 *
 * @param {import('axios').AxiosError} errorResposense
 * @param {import("@reduxjs/toolkit").Dispatch} dispatch
 * @param {Function} failHandler
 */
const errorHandler = (errorResposense, dispatch, failHandler) => {
  let messageError = "";
  if (
    errorResposense.response !== undefined &&
    errorResposense.response.data !== undefined
  ) {
    if (errorResposense.response.status === 401) {
      dispatch(logout());
      return;
    } else {
      dispatch(failHandler(errorResposense.response.data.message));
      messageError = errorResposense.response.data.message;
    }
  } else {
    messageError = formatNetworkErrorMessage(errorResposense.message);
    dispatch(failHandler(messageError));
  }

  dispatch(RunModal("FAILED", messageError));
};

/** --------------------------------------------------------------------- */
/**                                   GET                                 */
/** --------------------------------------------------------------------- */
export const getAsset = (
  token,
  { search, category, page = 1, limit = 10, sortDirection, sortBy, searchBy }
) => {
  return (/** @type {dispatch} */ dispatch) => {
    dispatch(RequestLoading());
    //@ts-ignore
    const craftedUrl = urlRequest({
      limit,
      page,
      searchValue: search,
      searchBy: search === "" ? null : searchBy,
      categoryId:
        category === ACTION_TYPE.DEFAULT_CATEGORY ||
        category === ACTION_TYPE.ALL_CATEGORY
          ? null
          : category,
      sortDirection,
      sortBy,
      url: assetBaseURl,
    });

    axios
      .get(craftedUrl, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          getAssetSuccess,
          getAssetFail,
          "GET"
        );
      })
      .catch((error) => {
        errorHandler(error, dispatch, getAssetFail);
      });
  };
};

function getAssetSuccess(data, pagination) {
  return {
    type: GET_GA_ASSET_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: parseInt(pagination.limit),
            totalData: pagination.total_data,
            totalPage: pagination.total_data,
          },
  };
}
/** @param {string} message */
function getAssetFail(message) {
  return {
    type: GET_GA_ASSET_FAILED,
    payload: message,
  };
}

/** --------------------------------------------------------------------- */
/**                                   ADD                                 */
/** --------------------------------------------------------------------- */

export const addAsset = (token, payload) => {
  return (/** @type {dispatch} */ dispatch) => {
    dispatch(RequestLoading());
    return axios
      .post(assetBaseURl, payload, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          addAssetSuccess,
          addAssetFail,
          "POST"
        );
      })
      .catch((error) => {
        errorHandler(error, dispatch, addAssetFail);
      });
  };
};
/** @param {string} message */
function addAssetSuccess(message) {
  return {
    type: ADD_GA_ASSET_SUCCES,
    payload: message,
  };
}
/** @param {string} message */
function addAssetFail(message) {
  return {
    type: ADD_GA_ASSET_FAILED,
    payload: message,
  };
}

/** --------------------------------------------------------------------- */
/**                                 UPDATE                                */
/** --------------------------------------------------------------------- */
export const updateAsset = (token, payload, id) => {
  return (/**@type {dispatch} */ dispacth) => {
    dispacth(RequestLoading());
    let localUrl = assetBaseURl.concat("/", id);
    return axios
      .put(localUrl, payload, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispacth,
          updateAssetSuccess,
          updateAssetFail,
          "EDIT"
        );
      })
      .catch((error) => {
        errorHandler(error, dispacth, updateAssetFail);
      });
  };
};

/** @param {string} message */
function updateAssetSuccess(message) {
  return {
    type: UPDATE_GA_ASSET_SUCCESS,
    payload: message,
  };
}

/** @param {string} message */
function updateAssetFail(message) {
  return {
    type: UPDATE_GA_ASSET_FAILED,
    payload: message,
  };
}
/** --------------------------------------------------------------------- */
/**                                 DELETE                                */
/** --------------------------------------------------------------------- */
export const deleteAsset = (token, ids) => {
  return (/**@type {dispatch} */ dispacth) => {
    dispacth(RequestLoading());
    const localUrl = assetBaseURl.concat("/", ids);
    return axios
      .delete(localUrl, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispacth,
          deleteAssetSucces,
          deleteAssetFail,
          "DELETE"
        );
      })
      .catch((error) => {
        errorHandler(error, dispacth, deleteAssetFail);
      });
  };
};
/** @param {string} message */
function deleteAssetSucces(message) {
  return {
    type: DELETE_GA_ASSET_SUCCESS,
    payload: message,
  };
}
/** @param {string} message */
function deleteAssetFail(message) {
  return {
    type: DELETE_GA_ASSET_FAILED,
    payload: message,
  };
}

/** --------------------------------------------------------------------- */
/**                                 DETAIL                                */
/** --------------------------------------------------------------------- */

export const detailAsset = (token, id) => {
  return (/**@type {dispatch} */ dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(assetBaseURl.concat("/", id), headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          detailAssetSuccess,
          detailAssettFail,
          "DETAIL"
        );
      })
      .catch((error) => {
        errorHandler(error, dispatch, detailAssettFail);
      });
  };
};

/** @param {object} data */
function detailAssetSuccess(data) {
  return {
    type: DETAIL_GA_ASSET_SUCCESS,
    payload: data,
  };
}
/** @param {string} message */
function detailAssettFail(message) {
  return {
    type: DETAIL_GA_ASSET_FAILED,
    payload: message,
  };
}
