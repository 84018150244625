import axios, { headersAPIToken, urlRequest } from "../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../util/global_state";
import {
  GET_ACTIVITY_DETAILS_SUCCESS,
  GET_ACTIVITY_DETAILS_FAILED,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAILED,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_FAILED,
  EDIT_ACTIVITY_SUCCESS,
  EDIT_ACTIVITY_FAILED,
  DELETE_ACTIVITY_FAILED,
  DELETE_ACTIVITY_SUCCESS,
} from "./state";
import { ACTION_TYPE, formatNetworkErrorMessage } from "../../../util/function";
import { logout } from "../../auth/clients/login/actions";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getActivities = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  sortDirection = null,
  sortBy = null,
  dateFilter = null,
  startDate = dateFilter !== "custom_date" ? null : startDate,
  endDate = dateFilter !== "custom_date" ? null : endDate,
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "activities",
          page,
          limit,
          search,
          dateFilter,
          fromDate: dateFilter !== "custom_date" ? null : startDate,
          toDate: dateFilter !== "custom_date" ? null : endDate,
          sortBy,
          sortDirection,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getActivitiesSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getActivitiesSuccess([], null));
        } else {
          dispatch(getActivitiesFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getActivitiesFailed(error.response.data.message));
          }
        } else {
          dispatch(getActivitiesFailed(error.message));
        }
      });
  };
};

const getActivitiesSuccess = (data, pagination) => {
  return {
    type: GET_ACTIVITY_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getActivitiesFailed = (errorMessage) => {
  return {
    type: GET_ACTIVITY_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                 GET DETAIL                                 */
/* -------------------------------------------------------------------------- */

export const getAttendanceDetails = ({
  token,
  page = 1,
  limit = 10,
  search = null,
  sortDirection = null,
  sortBy = null,
  dateFilter = null,
  userId = null,
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: `activities/details`,
          page,
          limit,
          search,
          dateFilter,
          sortBy,
          sortDirection,
          userId,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (response.status === 200 && response.data.status === true) {
          dispatch(getAttendanceDetailsSuccess(response.data.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getAttendanceDetailsSuccess(null));
        } else {
          dispatch(getAttendanceDetailsFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getAttendanceDetailsFailed(error.response.data.message));
          }
        } else {
          dispatch(getAttendanceDetailsFailed(error.message));
        }
      });
  };
};

const getAttendanceDetailsFailed = (errorMessage) => {
  return {
    type: GET_ACTIVITY_DETAILS_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

const getAttendanceDetailsSuccess = (data, pagination) => {
  return {
    type: GET_ACTIVITY_DETAILS_SUCCESS,
    payload: data,
    pagination:
      pagination === null || pagination === undefined
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addActivities = (token, formData) => {
  delete formData.id;
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(
        "activities",
        Object.assign({}, formData, {
          status: formData.status ? "active" : "inactive",
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "attendance"));
          dispatch(addActivitiesSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addActivitiesFailed(response.data.message));
        } else {
          dispatch(addActivitiesFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addActivitiesFailed(error.response.data.message));
          }
        } else {
          dispatch(addActivitiesFailed(error.message));
        }
      });
  };
};

const addActivitiesSuccess = (message) => {
  return {
    type: ADD_ACTIVITY_SUCCESS,
    payload: message,
  };
};

const addActivitiesFailed = (message) => {
  return {
    type: ADD_ACTIVITY_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editActivities = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .put(`activities/${id}`, formData, headersAPIToken(token))
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "attendance"));
          dispatch(editActivitiesSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editActivitiesFailed(response.data.message));
        } else {
          dispatch(editActivitiesFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editActivitiesFailed(error.response.data.message));
          }
        } else {
          dispatch(editActivitiesFailed(error.message));
        }
      });
  };
};

const editActivitiesSuccess = (message) => {
  return {
    type: EDIT_ACTIVITY_SUCCESS,
    payload: message,
  };
};

const editActivitiesFailed = (message) => {
  return {
    type: EDIT_ACTIVITY_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteActivities = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`activities${params}`, {
        ...formData,
        ...headersAPIToken(token),
      })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "attendance"));
          dispatch(deleteActivitiesSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteActivitiesFailed(response.data.message));
        } else {
          dispatch(deleteActivitiesFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteActivitiesFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteActivitiesFailed(error.message));
        }
      });
  };
};

const deleteActivitiesSuccess = (message) => {
  return {
    type: DELETE_ACTIVITY_SUCCESS,
    payload: message,
  };
};

const deleteActivitiesFailed = (message) => {
  return {
    type: DELETE_ACTIVITY_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
