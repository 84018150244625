import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { Grid, Typography, Box, Divider, Button } from "@mui/material";
import CalendarIcon from "./../../../assets/calendar.png";
import RemoveIcon from "./../../../assets/minus.svg";

import {
  removeButton,
  justifyContentEnd,
  justifyContentCenter,
} from "../../../util/style";
import axios from "../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  FilePicker,
} from "../../../components/Form";

import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  cloneObjectWithEmptyStrings,
} from "../../../util/function";

import { useSelector } from "react-redux";

const Step3 = ({
  formData,
  formAttempted,
  isNextTriggered,
  updateWizardData,
  setIsNextTriggered,
}) => {
  // debugger;
  const [isFormValid, setIsFormValid] = useState(false);
  const fields = [
    {
      title: "Education & Experience",
      subtitle:
        "You can skip this page, because the information is only as a complement",
    },
    {
      title: "Formal Education",
      subtitle: "This data is optional to fill in",
      children: [
        {
          name: "grade_id",
          type: INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE,
          label: "Grade",
          // defaultValue: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
          apiEndpoint: "/master/grades",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "institution_name",
          type: INPUT_TYPE.TEXT,
          label: "Institution Name",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "major",
          type: INPUT_TYPE.TEXT,
          label: "Majors",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "score",
          type: INPUT_TYPE.POINT,
          label: "Score",
          placeholder: "Score ( Ops )",
          defaultValue: "",
          required: false,
          gridWidth: 6, // Half width
        },
        {
          name: "start_year",
          type: INPUT_TYPE.DATE,
          label: "Start Year",
          endIcon: <img src={CalendarIcon} />,
          formatDate: "YYYY", // Custom date format
          // defaultValue: dayjs("01/01/2021"),
          additionalProps: {
            placeholder: "“year”",
            views: ["year"],
          },
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "end_year",
          type: INPUT_TYPE.DATE,
          label: "End Year",
          endIcon: <img src={CalendarIcon} />,
          formatDate: "YYYY", // Custom date format
          // defaultValue: dayjs("01/01/2021"),
          additionalProps: {
            placeholder: "“year”",
            views: ["year"],
          },
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "certificates",
          type: INPUT_TYPE.FILE,
          label: "Certificate",
          tip: "Max size: 2 MB. Supported file: PNG, JPEG, JPG, PDF, DOC, DOCX.",
          allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png"],
          maximumSizeInMB: 2, //MB
          gridWidth: 12, // Half width
        },
      ],
    },
    {
      title: "Informal Education",
      subtitle: "This data is optional to fill in",
      children: [
        {
          name: "name",
          type: INPUT_TYPE.TEXT,
          label: "Name",
          placeholder: "Input event name",
          defaultValue: "",
          required: true,
          gridWidth: 12, // Half width
        },
        {
          name: "organize_by",
          type: INPUT_TYPE.TEXT,
          label: "Organized By",
          placeholder: "Input organizer's name",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "fee",
          type: INPUT_TYPE.CURRENCY,
          label: "Fee",
          placeholder: "Input fee ( Ops )",
          defaultValue: "",
          required: false,
          gridWidth: 6, // Half width
        },
        {
          name: "start_date",
          type: INPUT_TYPE.DATE,
          label: "Start Date",
          endIcon: <img src={CalendarIcon} />,
          formatDate: "YYYY", // Custom date format
          // defaultValue: dayjs("01/01/2021"),
          additionalProps: {
            placeholder: "“year”",
            views: ["year"],
          },
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "end_date",
          type: INPUT_TYPE.DATE,
          label: "End Date",
          endIcon: <img src={CalendarIcon} />,
          formatDate: "YYYY", // Custom date format
          // defaultValue: dayjs("01/01/2021"),
          additionalProps: {
            placeholder: "“year”",
            views: ["year"],
          },
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "certificates",
          type: INPUT_TYPE.FILE,
          label: "Certificate",
          tip: "Max size: 2 MB. Supported file: PNG, JPEG, JPG, PDF, DOC, DOCX.",
          allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png"],
          maximumSizeInMB: 2,
          gridWidth: 12, // Half width
        },
      ],
    },
    {
      title: "Work Experience",
      subtitle: "This data is optional to fill in",
      children: [
        {
          name: "company_name",
          type: INPUT_TYPE.TEXT,
          label: "Company Name",
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "job_position",
          type: INPUT_TYPE.TEXT,
          label: "Job Position",
          required: true,
          defaultValue: "",
          gridWidth: 6, // Half width
        },
        {
          name: "start_date",
          type: INPUT_TYPE.DATE,
          label: "Start Date",
          endIcon: <img src={CalendarIcon} />,
          formatDate: "MMMM YYYY", // Custom date format
          // defaultValue: dayjs("01/01/2021"),
          additionalProps: {
            placeholder: "“month” & “year”",
            views: ["month", "year"],
          },
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
        {
          name: "end_date",
          type: INPUT_TYPE.DATE,
          label: "End Date",
          endIcon: <img src={CalendarIcon} />,
          formatDate: "MMMM YYYY", // Custom date format
          // defaultValue: dayjs("01/01/2021"),
          additionalProps: {
            placeholder: "“month” & “year”",
            views: ["month", "year"],
          },
          defaultValue: "",
          required: true,
          gridWidth: 6, // Half width
        },
      ],
    },
  ];

  useEffect(() => {
    if (isNextTriggered.isRun === "YES") {
      validateForm();
    }
  }, [isNextTriggered, isFormValid]);

  const { token } = useSelector((state) => state.auth);
  const initialFormalEducation = {
    grade_id: "",
    major: "",
    institution_name: "",
    score: "",
    start_year: "",
    end_year: "",
    certificates: [""],
  };
  const initialInFormalEducation = {
    name: "",
    organize_by: "",
    fee: "",
    start_date: "",
    end_date: "",
    certificates: [""],
  };
  const initialWorkExperience = {
    company_name: "",
    job_position: "",
    start_date: "",
    end_date: "",
  };

  const initialErrorFormalEducation = {
    grade_id: "",
    major: "",
    institution_name: "",
    score: "",
    start_year: "",
    end_year: "",
    certificates: [""],
  };
  const initialErrorInFormalEducation = {
    name: "",
    organize_by: "",
    fee: "",
    start_date: "",
    end_date: "",
    certificates: [""],
  };
  const initialErrorWorkExperience = {
    company_name: "",
    job_position: "",
    start_date: "",
    end_date: "",
  };

  const handleAddRow = (sectionTitle) => {
    let initData, initError;
    if (sectionTitle === "Formal Education") {
      initData = initialFormalEducation;
      initError = initialErrorFormalEducation;
    } else if (sectionTitle === "Informal Education") {
      initData = initialInFormalEducation;
      initError = initialErrorInFormalEducation;
    } else {
      initData = initialWorkExperience;
      initError = initialErrorWorkExperience;
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: [...prevValues[sectionTitle], initData], // Add an empty row
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: [...prevErrors[sectionTitle], initError], // Add an empty error object
    }));
  };

  const handleRemoveRow = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: prevValues[sectionTitle].filter(
        (row, index) => index !== rowIndex
      ),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: prevErrors[sectionTitle].filter(
        (error, index) => index !== rowIndex
      ),
    }));
  };

  const initialFormValues = () => {
    const initialValues = {};
    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (fieldGroup.title) {
          let initData;
          if (fieldGroup.title === "Formal Education") {
            initData = initialFormalEducation;
          } else if (fieldGroup.title === "Informal Education") {
            initData = initialInFormalEducation;
          } else {
            initData = initialWorkExperience;
          }
          // initialValues[fieldGroup.title] = [initData];
          initialValues[fieldGroup.title] = [];
        } else {
          console.error("initialFormValues");
        }
      }
    });

    return {
      ...initialValues,
    };
  };

  const initialErrors = () => {
    const initialErrorValues = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        if (fieldGroup.title) {
          let initError;
          if (fieldGroup.title === "Formal Education") {
            initError = initialErrorFormalEducation;
          } else if (fieldGroup.title === "Informal Education") {
            initError = initialErrorInFormalEducation;
          } else {
            initError = initialErrorWorkExperience;
          }

          // initialErrorValues[fieldGroup.title] = [initData];
          initialErrorValues[fieldGroup.title] = [];
        } else {
          console.error("initialErrors");
        }
      }
    });

    return initialErrorValues;
  };

  const [formValues, setFormValues] = useState(
    formData && Object.values(formData) ?.length > 0
      ? formData
      : initialFormValues()
  );

  const [options, setOptions] = useState({});
  const [errors, setErrors] = useState(
    formData && Object.values(formData) ?.length > 0
      ? cloneObjectWithEmptyStrings(formData)
      : initialErrors()
  );

  useEffect(() => {
    const storedOptions = JSON.parse(
      localStorage.getItem("options_step_three")
    );

    if (!storedOptions || Object.keys(storedOptions).length === 0) {
      // Options data not found in localStorage, fetch it
      const fetchData = async () => {
        try {
          const fetchedOptions = {};

          for (const section of fields) {
            if (section.children) {
              for (const field of section.children) {
                const { name, apiEndpoint } = field;

                if (apiEndpoint) {
                  const response = await axios.get(apiEndpoint, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });

                  fetchedOptions[name] = response.data.data;
                }
              }
            }
          }

          // Store fetched options in localStorage
          localStorage.setItem(
            "options_step_three",
            JSON.stringify(fetchedOptions)
          );

          // Set options in state
          setOptions(fetchedOptions);
        } catch (error) {
          console.error("Error fetching options:", error);
        }
      };

      fetchData();
    } else {
      // Options data found in localStorage, set it in state
      setOptions(storedOptions);
    }
  }, [token]);

  const handleInputChange = (
    event,
    index,
    sectionTitle,
    field,
    certificateIndex
  ) => {
    const { name, value, type } = event.target;

    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const fileError = validateFileInput(file, field);
        if (fileError) {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            // debugger;
            updatedErrors[sectionTitle][index][field.name][certificateIndex] =
              fileError;
            return updatedErrors;
          });
          return;
        }

        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          // debugger;
          updatedErrors[sectionTitle][index][field.name][certificateIndex] = "";
          return updatedErrors;
        });

        // Call the handleFileSelect function with the file if it's valid
        if (handleFileSelect) {
          handleFileSelect(file, index, sectionTitle, name);
        }
      } else {
        console.error("there is no file");
      }
    } else {
      // Handle regular input fields
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
            (item, i) => (i === index ? { ...item, [name]: value } : item)
          );
        } else {
          console.error("setFormValues error handleInputChange");
        }
        return updatedValues;
      });

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        const fieldValue = value;
        const fieldErrors = validateFieldHelper(fieldValue, field);

        if (index !== undefined) {
          updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
            (item, i) =>
              i === index ? { ...item, [name]: fieldErrors } : { ...item }
          );
        } else {
          console.error("setErrors error handleInputChange");
        }
        return updatedErrors;
      });
    }
  };

  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    validateField(sectionTitle, fieldName, index, { name, value });
    // debugger;
  };
  const validateFileInput = (
    fieldValue,
    field,
    sectionTitle,
    index,
    sectionErrors
  ) => {
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB

      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }
    }

    return "";
  };

  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    const fieldGroup = fields.find((group) => group.title === sectionTitle);

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find(
        (field) => field.name === fieldName
      );

      if (field) {
        const { name } = field;

        if (!isNaN(index)) {
          const fieldValue = formValues[sectionTitle][index][name] || value;
          newErrors[sectionTitle][index][name] = validateFieldHelper(
            fieldValue,
            field
          );
        } else {
          console.log("validateFIedl error");
        }
      }
    }
    setErrors(newErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("MMMM YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }
    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (
      fieldValue &&
      fieldValue.length &&
      validation &&
      !validation(fieldValue)
    ) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    console.log(
      "------------------------------FORM VALUE----------------------"
    );
    console.log(formValues);
    console.log("------------------------------ERRRORS----------------------");
    console.log(errors);
  }, [formValues, errors]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        const { title } = fieldGroup;
        const sectionErrors = [];

        fieldGroup.children.forEach((field) => {
          const { name, type } = field;

          formValues[title].forEach((sectionValues, index) => {
            // Check if the field is of type "file" and an array
            if (
              type === INPUT_TYPE.FILE &&
              Array.isArray(sectionValues[name])
            ) {
              sectionValues[name].forEach((file, subIndex) => {
                // Handle file input validation
                const fileError = validateFileInput(file, field);

                if (!sectionErrors[index]) {
                  sectionErrors[index] = {};
                }

                if (!sectionErrors[index][name]) {
                  sectionErrors[index][name] = [];
                }

                sectionErrors[index][name][subIndex] = fileError;

                if (fileError) {
                  valid = false;
                  // debugger;
                }
              });
            } else if (type !== "file") {
              // Handle regular input field validation
              const fieldValue =
                sectionValues[name] !== undefined ? sectionValues[name] : "";
              const error = validateFieldHelper(fieldValue, field);

              if (!sectionErrors[index]) {
                sectionErrors[index] = {};
              }

              sectionErrors[index][name] = error;

              if (error) {
                valid = false;
                // debugger;
              }
            }
          });
        });

        newErrors[title] = sectionErrors;
      }
    });

    setErrors(newErrors);
    // setIsFormValid(valid);
    formAttempted(valid);
    // setIsNextTriggered(false);
    updateWizardData("StepThree", formValues);
    return valid;
  };

  const handleAddCertificate = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      updatedValues[sectionTitle][rowIndex].certificates.push(""); // Add an empty certificate
      return updatedValues;
    });
  };

  const handleRemoveCertificate = (sectionTitle, rowIndex, certIndex) => {
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      updatedValues[sectionTitle][rowIndex].certificates.splice(certIndex, 1); // Remove the selected certificate
      return updatedValues;
    });
  };

  const handleFileSelect = (
    file,
    rowIndex,
    sectionTitle,
    fieldName,
    certIndex
  ) => {
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      const certificates = updatedValues[sectionTitle][rowIndex].certificates;

      // Check if the certificate at certIndex already exists
      if (certificates[certIndex] !== undefined) {
        // Update the existing certificate at certIndex
        certificates[certIndex] = file;
      }

      return updatedValues;
    });
  };

  const renderFields = (section, index, field) => {
    const currentFormValues = formValues[section.title][index];
    const currentErrors = errors[section.title][index];

    return (
      <>
        {section.children ?.map((field) => {
          // Check if children array exists
          let optionsData;
          if (Array.isArray(options[field.name])) {
            const isRequired = field.required &&
              field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

            if (isRequired) {
              optionsData = [
                // field.required &&
                //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                //     name: "Choose Option",
                //   },
                ...options[field.name],
              ];
            } else {
              optionsData = [...options[field.name]];
            }
          }

          const fieldOptions = Array.isArray(options[field.name])
            ? optionsData
            : [];

          let fieldComponent = null;

          switch (field.type) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.NUMBER:
            case INPUT_TYPE.PHONE_NUMBER:
            case INPUT_TYPE.POINT:
            case INPUT_TYPE.CURRENCY:
              fieldComponent = (
                <TextInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.TEXTAREA:
              fieldComponent = (
                <TextArea
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.FILE:
              fieldComponent = (
                <div>
                  {currentFormValues.certificates.map(
                    (certificate, certIndex) => (
                      <div key={certIndex}>
                        <Grid container spacing={0.3}>
                          <Grid item xs={11.3}>
                            <FilePicker
                              indexFile={certIndex}
                              field={field}
                              formValues={
                                currentFormValues["certificates"][certIndex]
                              }
                              errors={currentErrors}
                              onFileSelect={(file) =>
                                handleFileSelect(
                                  file,
                                  index,
                                  section.title,
                                  field.name,
                                  certIndex
                                )
                              }
                              handleInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  index,
                                  section.title,
                                  field,
                                  certIndex
                                )
                              }
                              tip={field ?.tip}
                            />
                          </Grid>

                          <Grid item xs={0.5} sx={{ marginTop: "2.25rem" }}>
                            <Button
                              variant="contained"
                              disableElevation
                              sx={{
                                backgroundColor: (theme) =>
                                  theme.palette.primary.main,
                                ...removeButton,
                                padding: "14px 24px",
                                marginTop: "-5px",
                              }}
                              onClick={() =>
                                handleRemoveCertificate(
                                  section.title,
                                  index,
                                  certIndex
                                )
                              }
                              disabled={
                                currentFormValues.certificates.length === 1
                              }
                            >
                              <>
                                <img
                                  src={RemoveIcon}
                                  alt={`Remove ${section.title}`}
                                />
                              </>
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  )}

                  <Box sx={{ ...justifyContentEnd }}>
                    <Button
                      onClick={() => handleAddCertificate(section.title, index)}
                    >
                      Add Certificate
                    </Button>
                  </Box>
                </div>
              );

              break;
            case INPUT_TYPE.SELECT_ONE:
              fieldComponent = (
                <SelectOne
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectOneAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE:
              fieldComponent = (
                <SelectMultiple
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectMultipleAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.CHECKBOX:
              fieldComponent = (
                <CheckboxGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.RADIO:
              fieldComponent = (
                <RadioGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  errors={currentErrors}
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.DATE:
              fieldComponent = (
                <DatePickerInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            // Add cases for other field types
            default:
              fieldComponent = null;
          }

          return (
            <Grid
              item
              xs={field.gridWidth || 12}
              key={field.name}
              sx={{ paddingTop: "unset !important" }}
            >
              {fieldComponent}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderSectionFields = (section) => {
    if (section.title) {
      // Render Family or Emergency Contact section
      return (
        <Grid item xs={12} key={section.title}>
          <Box sx={{ marginBottom: 2 }}>
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {section.title}
            </Typography>
            {/* Subtitle */}
            {section.subtitle && (
              <Typography variant="subtitle2">{section.subtitle}</Typography>
            )}
          </Box>

          {section.children &&
            formValues[section.title].map((rowValues, index) => (
              <div key={index}>
                <Grid container spacing={0.3}>
                  <Grid item xs={11.3}>
                    <Grid container spacing={1}>
                      {renderFields(section, index)} {/* Pass rowIndex */}
                    </Grid>
                  </Grid>
                  <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        ...removeButton,
                      }}
                      onClick={() => handleRemoveRow(section.title, index)}
                    >
                      <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                    </Button>
                  </Grid>
                </Grid>
                {formValues[section.title].length > 1 && (
                  <Divider sx={{ mt: 2, mb: 2 }} />
                )}
              </div>
            ))}
          {section.children && (
            <>
              {formValues[section.title].length > 0 ? (
                <Box sx={{ ...justifyContentEnd }}>
                  <Button onClick={() => handleAddRow(section.title)}>
                    Add Another {section.title}
                  </Button>
                </Box>
              ) : (
                  <Box sx={{ ...justifyContentCenter }}>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => handleAddRow(section.title)}
                    >
                      Add {section.title}
                    </Button>
                  </Box>
                )}
            </>
          )}
        </Grid>
      );
    } else {
      return null; // Remove or comment out code for other sections
    }
  };

  const renderFormContent = () => {
    return (
      <Grid
        container
        spacing={1.5}
        sx={{
          overflowX: "auto",
          height: "52vh",
        }}
        className="smooth-scroll"
      >
        {fields.map((section) => renderSectionFields(section))}
      </Grid>
    );
  };

  return <div>{renderFormContent()}</div>;
};

export default Step3;
