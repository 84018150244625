import React, { useEffect, useState } from "react";
import { Grid, Box, Button } from "@mui/material";
import {
  SelectOneAutocomplete,
  SelectOneAutocompleteSearch,
} from "../../../../../components/Form";
import {
  ACTION_TYPE,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../../../../util/function";
import ComponentRender from "./ComponentRender";
import { justifyContentEnd, removeButton } from "../../../../../util/style";
import RemoveIcon from "../../../../../assets/minus.svg";
import { FireHydrantAltRounded } from "@mui/icons-material";
import { validateDisabled } from "../../parts/imutable_state";

export default function ServiceFormComponent({
  field,
  parentField,
  actionType,
  errors,
  options,
  formValues,
  handleInputChange,
  handleInputBlur,
  handleAddSubService,
  handleRemoveSubService,
}) {
  const RenderInputTextDisabled = ({ field }) => {
    let valueToRender = formValues[field.name];
    if (options.hasOwnProperty(field.name)) {
      let selectedOption = options[field.name].find(
        (opt) => opt?.id == valueToRender
      );
      valueToRender =
        field.name === "sales_order_id"
          ? selectedOption?.code
          : selectedOption?.name;
    }
    return (
      <div
        style={{
          padding: 0,
          margin: 0,
          paddingRight: ".55rem",
          display: "flex",
          justifyContent: "end",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <p
          style={{
            paddingBlock: ".4rem",
            marginBlock: "0",
            fontWeight: "500",
          }}
        >
          {field.label}
        </p>

        <input
          type="text"
          disabled
          style={{ width: "100%", paddingBlock: ".6rem" }}
          // value={"valueToRender"}
          value={valueToRender}
        />
      </div>
    );
  };

  if (field.hidden) return null;
  const currencyField = parentField?.field.find(
    (fiel) => fiel.name === "service_currency"
  );
  let optionData = options[field.name] || [];
  let requireOption = null;
  if (field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && field.require) {
    requireOption = {
      id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
      name: "Choose Option",
    };
  }
  optionData = requireOption ? [...optionData, requireOption] : optionData;

  //* Disabled currency field
  currencyField["additionalProps"] = {
    disabled: true,
  };

  field["additionalProps"] = {
    disabled: true,
  };

  return (
    <React.Fragment key={field.name}>
      <Grid container>
        {field.parent ? (
          <>
            <Grid item xs={9.5} paddingRight={".8rem"}>
              <SelectOneAutocomplete
                key={"service category"}
                errors={errors}
                field={field}
                fieldOptions={optionData}
                formValues={formValues}
                handleInputBlur={(e) => handleInputBlur(e, parentField)}
                handleInputChange={handleInputChange}
                tip={field.tip}
              />
            </Grid>
            <Grid item xs={2.5}>
              <SelectOneAutocomplete
                key={"Curreny service".concat(formValues[field.name])}
                errors={errors}
                field={currencyField}
                fieldOptions={currencyField?.options}
                formValues={formValues}
                handleInputBlur={(e) => handleInputBlur(e, parentField)}
                handleInputChange={handleInputChange}
                tip={currencyField?.tip}
              />
            </Grid>
          </>
        ) : (
          !field.hidden && (
            <>
              {formValues[parentField.multipleValueName]?.map((_, index) => (
                <>
                  <Grid item xs={10.75}>
                    <ComponentRender
                      field={field}
                      options={options}
                      errors={errors}
                      formValues={
                        formValues[parentField.multipleValueName][index]
                      }
                      handleChange={handleInputChange}
                      handleBlur={(e) => handleInputBlur(e, parentField)}
                      disabled={validateDisabled(field.name, actionType)}
                      paymentReceive={false}
                      canConvert={false}
                    />
                  </Grid>
                  {field.name === "sub_service_id" && (
                    <Grid item xs={1.25} sx={{ marginTop: "1.8rem" }}>
                      <Button
                        variant="contained"
                        disableElevation
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                          ...removeButton,
                        }}
                        // onClick={() =>
                        //   handleRemoveSubService(field.name, index)
                        // }
                        disabled={true}
                      >
                        <img src={RemoveIcon} alt={`Remove ${field.name}`} />
                      </Button>
                    </Grid>
                  )}
                  {/* {field.name === "description" && false && (
                    <Grid xs={12}>
                      <Box sx={{ ...justifyContentEnd, display: "flex" }}>
                        <Button
                          onClick={() => handleAddSubService(field.name)}
                          disabled={true}
                        >
                          <span
                            style={{
                              fontSize: "1.5rem",
                              marginRight: ".6rem",
                              display: "inline-block",
                            }}
                          >
                            +
                          </span>
                          Add More
                        </Button>
                      </Box>
                    </Grid>
                  )} */}
                </>
              ))}
            </>
          )
        )}
      </Grid>
    </React.Fragment>
  );
}
