import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { Grid, Typography, Box, Divider, Button } from "@mui/material";
import CalendarIcon from "./../../../../../assets/calendar.png";
import RemoveIcon from "./../../../../../assets/minus.svg";

import { removeButton, justifyContentEnd } from "../../../../../util/style";
import axios from "../../../../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  FilePicker,
} from "../../../../../components/Form";

import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
} from "../../../../../util/function";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const Step5_BPOM_ML = ({
  formData,
  formAttempted,
  isNextTriggered,
  updateWizardData,
  setIsNextTriggered,
}) => {

    const titlesToKeep = [
      "KTP Pimpinan",
      "NPWP",
      "Nomor Induk Berusaha (NIB)",
      "Akte Perusahaan",
      "Contoh Label Kemasan",
      "Sertifikat Merek",
      "Pendaftaran Merek",
      "Layout Bangunan",
      "Surat Kerjasama Keagenan (LOA)",
      "Others"
  ];

  if(formData && Object.values(formData).length > 0) {
      for(const key in formData) {
          if(!titlesToKeep.includes(key)) {
              delete formData[key];
          }
      }
  }

  const { id, type } = useParams();

  const [isFormValid, setIsFormValid] = useState(false);

  const fields = [
  {
    title: "Attachments",
  },
//     {
//       title: "Attachment",
//       subtitle: 'You can upload the documents required for this proses ( Ops)',
//       isDisplayTitle: true,
//       children: [
//         {
//           name: "attachments",
//           type: INPUT_TYPE.FILE,
//           label: "Upload File",
//           tip: "Max size: 2 MB. Supported file: PNG, JPEG, JPG, PDF, DOC, DOCX.",
//           allowedExtensions: [".pdf", ".doc", ".docx", ".jpeg", ".jpg", ".png"],
//           maximumSizeInMB: 2, //MB
//           defaultValue: '',
//           // required: true,
//         },
//       ],
//     },

 {
    title: "KTP Pimpinan",
    isDisplayTitle: true,
    children: [
      {
        name: "attachments",
        type: INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 5 MB. Format file: JPG, PNG, or PDF.",
        allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 5, //MB
        defaultValue: '',
        disabled: true,
        // required: true,
      },
    ]
  },
  {
    title: "NPWP",
    isDisplayTitle: true,
    children: [
      {
        name: "attachments",
        type: INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 5 MB. Format file: JPG, PNG, or PDF.",
        allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 5, //MB
        defaultValue: '',
        disabled: true,
        // required: true,
      },
    ]
  },
  {
    title: "Nomor Induk Berusaha (NIB)",
    isDisplayTitle: true,
    children: [
      {
        name: "attachments",
        type: INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 5 MB. Format file: JPG, PNG, or PDF.",
        allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 5, //MB
        defaultValue: '',
        disabled: true,
        // required: true,
      },
    ]
  },
  {
    title: "Akte Perusahaan",
    isDisplayTitle: true,
    children: [
      {
        name: "attachments",
        type: INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 5 MB. Format file: JPG, PNG, or PDF.",
        allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 5, //MB
        defaultValue: '',
        disabled: true,
        // required: true,
      },
    ]
  },
  {
    title: "Contoh Label Kemasan",
    isDisplayTitle: true,
    children: [
      {
        name: "attachments",
        type: INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 5 MB. Format file: JPG, PNG, or PDF.",
        allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 5, //MB
        defaultValue: '',
        disabled: true,
        // required: true,
      },
    ]
  },
  {
    title: "Sertifikat Merek",
    isDisplayTitle: true,
    children: [
      {
        name: "attachments",
        type: INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 5 MB. Format file: JPG, PNG, or PDF.",
        allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 5, //MB
        defaultValue: '',
        disabled: true,
        // required: true,
      },
    ]
  },
  {
    title: "Pendaftaran Merek",
    isDisplayTitle: true,
    children: [
      {
        name: "attachments",
        type: INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 5 MB. Format file: JPG, PNG, or PDF.",
        allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 5, //MB
        defaultValue: '',
        disabled: true,
        // required: true,
      },
    ]
  },
  {
    title: "Layout Bangunan",
    isDisplayTitle: true,
    children: [
      {
        name: "attachments",
        type: INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 5 MB. Format file: JPG, PNG, or PDF.",
        allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 5, //MB
        defaultValue: '',
        disabled: true,
        // required: true,
      },
    ]
  },
  {
    title: "Surat Kerjasama Keagenan (LOA)",
    isDisplayTitle: true,
    children: [
      {
        name: "attachments",
        type: INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 5 MB. Format file: JPG, PNG, or PDF.",
        allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 5, //MB
        defaultValue: '',
        disabled: true,
        // required: true,
      },
    ]
  },
  {
    title: "Others",
    isDisplayTitle: true,
    children: [
      {
        name: "attachments",
        type: INPUT_TYPE.FILE,
        label: "Upload File",
        tip: "Max size: 5 MB. Format file: JPG, PNG, or PDF.",
        allowedExtensions: [".pdf", ".jpeg", ".jpg", ".png"],
        maximumSizeInMB: 5, //MB
        defaultValue: '',
        disabled: true,
        // required: true,
      },
    ]
  },
  ];

  useEffect(() => {
    if (isNextTriggered.isRun === "YES") {
      validateForm();
    }
  }, [isNextTriggered, isFormValid]);

  const { token } = useSelector((state) => state.auth);

  const initialSubService = {
    sub_service_id: "",
    subtotal: 0,
    discount: 0,
    vat_percent: '',
    vat_to_rp: 0,
    total_to_rp: 0,
    criteria_id: "",
    desc: "",
  };


  const initialAttachment = {
    attachments: [""],
  };

  const initialAttachmentKTPPimpinan = {
    attachments: [""],
  };

  const initialAttachmentNPWP = {
    attachments: [""],
  };

  const initialAttachmentNomorIndukBerusaha = {
    attachments: [""],
  };

  const initialAttachmentAktePerusahaan = {
    attachments: [""],
  };

  const initialAttachmentCatatanLabelKemasan = {
    attachments: [""],
  };

  const initialAttachmentSertifikatMerek = {
    attachments: [""],
  };

  const initialAttachmentPendaftaranMerek = {
    attachments: [""],
  };

  const initialAttachmentLayoutBangunan = {
    attachments: [""],
  };

  const initialAttachmentSuratKerjasamaKeagenaan = {
    attachments: [""],
  };

  const initialAttachmentOthers = {
    attachments: [""],
  };




  const initialErrorSubService = {
    sub_service_id: "",
    discount: "",
    vat_percent: "",
    criteria_id: "",
    desc: "",
  };

   const initialErrorAttachment = {
    attachments: [""],
  };


  const initialErrorAttachmentKTPPimpinan = {
    attachments: [""],
  };

  const initialErrorAttachmentNPWP = {
    attachments: [""],
  };


  const initialErrorAttachmentNomorIndukBerusaha = {
    attachments: [""],
  };

  const initialErrorAttachmentAktePerusahaan = {
    attachments: [""],
  };

  const initialErrorAttachmentCatatanLabelKemasan = {
    attachments: [""],
  };

  const initialErrorAttachmentSertifikatMerek = {
    attachments: [""],
  };

  const initialErrorAttachmentPendaftaranMerek = {
    attachments: [""],
  };

  const initialErrorAttachmentLayoutBangunan = {
    attachments: [""],
  };

  const initialErrorAttachmentSuratKerjasamaKeagenaan = {
    attachments: [""],
  };

  const initialErrorAttachmentOthers = {
    attachments: [""],
  };




  const initialFamily = {
    name: "",
    relation_id: "",
    birthdate: "",
    gender: "",
    religion_id: "",
    marital_status_id: "",
    identity_number: "",
    job_id: "",
  };
  const initialEmergencyContact = {
    name: "",
    relation_id: "",
    phone: "",
  };

  const handleAddRow = (sectionTitle) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: [
        ...prevValues[sectionTitle],
        sectionTitle === "Family" ? initialFamily : initialEmergencyContact,
      ], // Add an empty row
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: [
        ...prevErrors[sectionTitle],
        sectionTitle === "Family" ? initialFamily : initialEmergencyContact,
      ], // Add an empty error object
    }));
  };

  const handleRemoveRow = (sectionTitle, rowIndex) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [sectionTitle]: prevValues[sectionTitle].filter(
        (row, index) => index !== rowIndex
      ),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [sectionTitle]: prevErrors[sectionTitle].filter(
        (error, index) => index !== rowIndex
      ),
    }));
  };
const initialFormValues = () => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialValues[fieldGroup.title] =
          fieldGroup.title === "Sub Service"
            ? [initialSubService]
            : fieldGroup.title === "Attachment"
              ? initialAttachment
              : fieldGroup.title === "KTP Pimpinan"
                ? initialAttachmentKTPPimpinan
                : fieldGroup.title === "NPWP"
                  ? initialAttachmentNPWP
                  : fieldGroup.title === "Nomor Induk Berusaha (NIB)"
                    ? initialAttachmentNomorIndukBerusaha
                    : fieldGroup.title === "Akte Perusahaan"
                      ? initialAttachmentAktePerusahaan
                      : fieldGroup.title === "Contoh Label Kemasan"
                      ? initialAttachmentCatatanLabelKemasan
                      : fieldGroup.title === "Sertifikat Merek"
                        ? initialAttachmentSertifikatMerek
                        : fieldGroup.title === "Pendaftaran Merek"
                          ? initialAttachmentPendaftaranMerek
                          : fieldGroup.title === "Layout Bangunan"
                            ? initialAttachmentLayoutBangunan
                            : fieldGroup.title === "Surat Kerjasama Keagenan (LOA)"
                            ? initialAttachmentSuratKerjasamaKeagenaan
                            : fieldGroup.title === "Others"
                            ? initialAttachmentOthers
                            : fieldGroup.children.reduce(
                              (acc, field) => ({ ...acc, [field.name]: field.defaultValue }),
                              {}
                            );
      }
    });

    return {
      ...initialValues,
    };
  };

  const initialErrors = () => {
    const nonDividerAndTypographyFields = fields.filter(
      (field) => field.type !== INPUT_TYPE.DIVIDER && field.type !== INPUT_TYPE.TYPOGRAPHY
    );

    const initialErrorValues = {};

    nonDividerAndTypographyFields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        initialErrorValues[fieldGroup.title] =
          fieldGroup.title === "Sub Service"
            ? [initialErrorSubService]
            : fieldGroup.title === "Attachment"
            ? initialErrorAttachment
              : fieldGroup.title === "KTP Pimpinan"
                ? initialErrorAttachmentKTPPimpinan
                : fieldGroup.title === "NPWP"
                  ? initialErrorAttachmentNPWP
                  : fieldGroup.title === "Nomor Induk Berusaha (NIB)"
                    ? initialErrorAttachmentNomorIndukBerusaha
                    : fieldGroup.title === "Akte Perusahaan"
                      ? initialErrorAttachmentAktePerusahaan
                      : fieldGroup.title === "Contoh Label Kemasan"
                      ? initialErrorAttachmentCatatanLabelKemasan
                      : fieldGroup.title === "Sertifikat Merek"
                        ? initialErrorAttachmentSertifikatMerek
                        : fieldGroup.title === "Pendaftaran Merek"
                          ? initialErrorAttachmentPendaftaranMerek
                          : fieldGroup.title === "Layout Bangunan"
                            ? initialErrorAttachmentLayoutBangunan
                            :  fieldGroup.title === "Surat Kerjasama Keagenaan (LOA)"
                            ? initialErrorAttachmentSuratKerjasamaKeagenaan
                            :  fieldGroup.title === "Others"
                            ? initialErrorAttachmentOthers
                            : fieldGroup.children.reduce(
                              (acc, field) => ({ ...acc, [field.name]: "" }),
                              {}
                            );
      }
    });

    return initialErrorValues;
  };

  const [formValues, setFormValues] = useState(
    formData && Object.values(formData).length > 0
      ? formData
      : initialFormValues()
  );

  const [options, setOptions] = useState({});
  const [errors, setErrors] = useState(initialErrors);

  const handleAddFile = (sectionTitle, rowIndex, fieldName) => {
    setFormValues((prevValues) => {
      const updatedValues = { ...prevValues };
      if (!isNaN(rowIndex)) {
        updatedValues[sectionTitle][rowIndex][fieldName].push(""); // Add an empty certificate
      } else {
        updatedValues[sectionTitle][fieldName].push(""); // Add an empty certificate
      }

      return updatedValues;
    });
  };

  const handleRemoveFile = (sectionTitle, rowIndex, fileIndex, fieldName) => {
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][rowIndex][fieldName].splice(fileIndex, 1); // Remove the selected certificate

        return updatedValues;
      });
    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[sectionTitle][fieldName].splice(fileIndex, 1); // Remove the selected certificate
        return updatedValues;
      });

    }
  };

  const handleFileSelect = (
    file,
    rowIndex,
    sectionTitle,
    fieldName,
    fileIndex
  ) => {
    debugger;
    if (!isNaN(rowIndex)) {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][rowIndex][fieldName];
debugger;
        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = '';
        }

        return updatedValues;
      });
      updateWizardData("StepFive", formValues);

    } else {
      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const fileColumn = updatedValues[sectionTitle][fieldName];
debugger;
        // Check if the certificate at certIndex already exists
        if (fileColumn[fileIndex] !== undefined) {
          // Update the existing certificate at fileIndex
          fileColumn[fileIndex] = file;
        }

        return updatedValues;
      });
      updateWizardData("StepFive", formValues);
    }
  };


  const handleInputChange = (event, index, sectionTitle, field, fileIndex) => {
     const { name, value, type } = event.target;
    console.log(name, value, type, 'SOBARI')
    debugger;

    if (type === "file") {
      const file = event.target.files[0];
      if (file) {
        const fileError = validateFileInput(file, field);
        // debugger;
        // if (fileError) {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            if (!isNaN(index)) {
              updatedErrors[sectionTitle][index][field.name][fileIndex] = fileError;
            } else {
              updatedErrors[sectionTitle][field.name][fileIndex] = fileError;
            }

            return updatedErrors;
          });
          return;
        // }

        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          // debugger;
          if (!isNaN(index)) {
            updatedErrors[sectionTitle][index][field.name][fileIndex] = "";
          } else {
            updatedErrors[sectionTitle][field.name][fileIndex] = "";
          }
          return updatedErrors;
        });

        // Call the handleFileSelect function with the file if it's valid
        if (handleFileSelect) {
          handleFileSelect(file, index, sectionTitle, name);
        }
      } else {
        console.error("there is no file");
      }
    } else {
      // Handle regular input fields

      setFormValues((prevValues) => {
        const updatedValues = { ...prevValues };
        if (index !== undefined) {
          updatedValues[sectionTitle] = updatedValues[sectionTitle].map(
            (item, i) => (i === index ? { ...item, [name]: value } : item)
          );
        } else {
          updatedValues[sectionTitle] = {
            ...updatedValues[sectionTitle],
            [name]: value,
          }

        }
        return updatedValues;

      });

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        const fieldValue = value;
        const fieldErrors = validateFieldHelper(fieldValue, field);

        if (index !== undefined) {
          updatedErrors[sectionTitle] = updatedErrors[sectionTitle].map(
            (item, i) =>
              i === index ? { ...item, [name]: fieldErrors } : { ...item }
          );
        } else {
          updatedErrors[sectionTitle] = {
            ...updatedErrors[sectionTitle],
            [name]: fieldErrors,
          };
        };
        return updatedErrors;
      });

      updateWizardData("StepFive", formValues);

    }
  }


  const handleInputBlur = (fieldName, sectionTitle, index, event) => {
    const { name, value } = event.target;
    validateField(sectionTitle, fieldName, index, { name, value });
    // debugger;
  };


  const validateFileInput = (
    fieldValue,
    field,
    sectionTitle,
    index,
    sectionErrors
  ) => {
    if (fieldValue instanceof File) {
      const allowedExtensions = field.allowedExtensions || [
        ".pdf",
        ".doc",
        ".docx",
        ".jpeg",
        ".jpg",
        ".png",
      ];
      const size = field.maximumSizeInMB || 10;
      const maxSizeInBytes = size * 1024 * 1024; // MB

      const fileExtension = fieldValue.name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        return "Invalid file extension. Please select a file with a valid extension.";
      } else if (fieldValue.size > maxSizeInBytes) {
        return `File size exceeds the maximum allowed size of ${size} MB.`;
      }

    }

    return "";
  };


  const validateField = (sectionTitle, fieldName, index, { value }) => {
    const newErrors = { ...errors };
    const fieldGroup = fields.find((group) => group.title === sectionTitle);

    if (fieldGroup && fieldGroup.children) {
      const field = fieldGroup.children.find(
        (field) => field.name === fieldName
      );

      if (field) {
        const { name } = field;

        if (
          !isNaN(index) &&
          (sectionTitle === "Sub Service")
          // (sectionTitle === "Sub Service" || sectionTitle === "Attachment")
        ) {
          const fieldValue = formValues[sectionTitle][index][name] || value;
          newErrors[sectionTitle][index][name] = validateFieldHelper(
            fieldValue,
            field
          );
        } else {
          const fieldValue = formValues[sectionTitle][name] || value;
          newErrors[sectionTitle][name] = validateFieldHelper(
            fieldValue,
            field
          );
        }
      }
    }
    setErrors(newErrors);
  };

  const validateFieldHelper = (fieldValue, field) => {
    const { required, validation, errorMessage, label, type } = field;

    // Format the date fieldValue if the type is DATE
    if (type === INPUT_TYPE.DATE) {
      fieldValue = dayjs(fieldValue).isValid()
        ? dayjs(fieldValue).format("DD/MM/YYYY")
        : "";
      console.log(fieldValue, "fieldValue");
    }

    if (
      required &&
      (String(fieldValue).trim() === "" ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE_NULL ||
        fieldValue === SELECT_OPTION.DEFAULT_VALUE_SELECT_MULTIPLE)
    ) {
      return `${label} is required`;
    } else if (
      fieldValue &&
      fieldValue.length &&
      validation &&
      !validation(fieldValue)
    ) {
      return errorMessage || `${label} is invalid`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    console.log(
      "------------------------------FORM VALUE----------------------"
    );
    console.log(formValues);
    console.log("------------------------------ERRRORS----------------------");
    console.log(errors);
  }, [formValues, errors]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    fields.forEach((fieldGroup) => {
      if (fieldGroup.children) {
        const { title } = fieldGroup;

        const sectionErrors =
          fieldGroup.title === "Family" ||
          fieldGroup.title === "Emergency Contact"
            ? []
            : {};

        fieldGroup.children.forEach((field) => {
          const { name } = field;

          if (
            fieldGroup.title === "Family" ||
            fieldGroup.title === "Emergency Contact"
          ) {
            formValues[title].forEach((sectionValues, index) => {
              const fieldValue =
                sectionValues[name] !== undefined ? sectionValues[name] : "";
              const error = validateFieldHelper(fieldValue, field);

              if (!sectionErrors[index]) {
                sectionErrors[index] = {};
              }

              sectionErrors[index][name] = error;

              if (error) {
                valid = false;
              }
            });
          } else {
            const fieldValue =
              formValues[title] && formValues[title][name] !== undefined
                ? formValues[title][name]
                : "";
            const error = validateFieldHelper(fieldValue, field);

            sectionErrors[name] = error;

            if (error) {
              valid = false;
            }
          }
        });

        newErrors[title] = sectionErrors;
      }
    });

    setErrors(newErrors);
    // setIsFormValid(valid);
    formAttempted(valid);
    // setIsNextTriggered(false);
    updateWizardData("StepFive", formValues);
    return valid;
  };

  const renderFields = (section, index, field) => {
    const currentFormValues =
      section.title === "Family"
        ? formValues[section.title][index]
        : section.title === "Emergency Contact"
        ? formValues[section.title][index]
        : formValues[section.title];

    const currentErrors =
      section.title === "Family"
        ? errors[section.title][index]
        : section.title === "Emergency Contact"
        ? errors[section.title][index]
        : errors[section.title];

    return (
      <>
        {section.children?.map((field) => {
          // Check if children array exists
          let optionsData;
          if (Array.isArray(options[field.name])) {
            const isRequired = field.required &&
              field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                name: "Choose Option",
              };

            if (isRequired) {
              optionsData = [
                // field.required &&
                //   field.type === INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE && {
                //     id: SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE,
                //     name: "Choose Option",
                //   },
                ...options[field.name],
              ];
            } else {
              optionsData = [...options[field.name]];
            }
          }

          const fieldOptions = Array.isArray(options[field.name])
            ? optionsData
            : [];

          let fieldComponent = null;

          switch (field.type) {
            case INPUT_TYPE.TEXT:
            case INPUT_TYPE.NUMBER:
            case INPUT_TYPE.PHONE_NUMBER:
            case INPUT_TYPE.POINT:
            case INPUT_TYPE.CURRENCY:
              fieldComponent = (
                <TextInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            case INPUT_TYPE.TEXTAREA:
              fieldComponent = (
                <TextArea
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
              case INPUT_TYPE.FILE:
                console.log(currentFormValues[field.name], 'SOBARIIIIII', field.name, section.title)
                fieldComponent = (
                  <div>

                  {currentFormValues[field.name].map(
                      (_, fileIndex) => (
                        <div key={fileIndex}>
                          <Grid container spacing={0.3}>
                            <Grid item xs={11.3}>
                              <FilePicker
                                indexFile={fileIndex}
                                field={field}
                                formValues={
                                  currentFormValues[field.name][fileIndex]
                                }
                                errors={currentErrors}
                                onFileSelect={(file) =>
                                  handleFileSelect(
                                    file,
                                    index,
                                    section.title,
                                    field.name,
                                    fileIndex
                                  )
                                }
                                handleInputChange={(e) =>
                                  handleInputChange(
                                    e,
                                    index,
                                    section.title,
                                    field,
                                    fileIndex
                                  )
                                }
                                tip={field ?.tip}
                              />
                            </Grid>

                            <Grid item xs={0.5} sx={{ marginTop: "2.25rem" }}>
                              <Button
                                variant="contained"
                                disableElevation
                                sx={{
                                  backgroundColor: (theme) =>
                                    theme.palette.primary.main,
                                  ...removeButton,
                                }}
                                onClick={() =>
                                  handleRemoveFile(
                                    section.title,
                                    index,
                                    fileIndex,
                                    field.name
                                  )
                                }
                                // OLD : disabled={
                                //   currentFormValues[field.name].length === 1
                                // }
                                disabled={true}
                              >
                                <>
                                  <img
                                    src={RemoveIcon}
                                    alt={`Remove ${section.title}`}
                                  />

                                </>
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      )
                    )}


                    <Box sx={{ ...justifyContentEnd }}>
                      <Button
                        onClick={() => handleAddFile(section.title, index, field.name)}
                        disabled={true}
                      >
                        Add More
                      </Button>
                    </Box>
                  </div>
                );

                break;
            case INPUT_TYPE.SELECT_ONE:
              fieldComponent = (
                <SelectOne
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_ONE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectOneAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE:
              fieldComponent = (
                <SelectMultiple
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.SELECT_MULTIPLE_AUTOCOMPLETE:
              fieldComponent = (
                <SelectMultipleAutocomplete
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={fieldOptions}
                />
              );
              break;
            case INPUT_TYPE.CHECKBOX:
              fieldComponent = (
                <CheckboxGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.RADIO:
              fieldComponent = (
                <RadioGroupInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                  errors={currentErrors}
                  fieldOptions={
                    fieldOptions.length
                      ? fieldOptions.filter((option) => option !== false)
                      : field.options
                  }
                />
              );
              break;
            case INPUT_TYPE.DATE:
              fieldComponent = (
                <DatePickerInput
                  key={field.name}
                  field={field}
                  formValues={currentFormValues}
                  errors={currentErrors}
                  handleInputChange={(e) =>
                    handleInputChange(e, index, section.title, field)
                  }
                  handleInputBlur={(e) =>
                    handleInputBlur(field.name, section.title, index, e)
                  }
                />
              );
              break;
            // Add cases for other field types
            default:
              fieldComponent = null;
          }

          return (
            <Grid
              item
              xs={field.gridWidth || 12}
              key={field.name}
              sx={{ paddingTop: "unset !important" }}
            >
              {fieldComponent}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderSectionFields = (section) => {
    if (section.title === "Family" || section.title === "Emergency Contact") {
      // Render Family or Emergency Contact section
      return (
        <Grid item xs={12} key={section.title}>
          <Box sx={{ marginBottom: 2 }}>
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {section.title}
            </Typography>
          </Box>

          {formValues[section.title].map((rowValues, index) => (
            <div key={index}>
              <Grid container spacing={0.3}>
                <Grid item xs={11.3}>
                  <Grid container spacing={1}>
                    {renderFields(section, index)} {/* Pass rowIndex */}
                  </Grid>
                </Grid>
                <Grid item xs={0.5} sx={{ marginTop: "1.25rem" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.main,
                      ...removeButton,
                    }}
                    onClick={() => handleRemoveRow(section.title, index)}
                    // OLD : disabled={formValues[section.title].length === 1}
                    disabled={true}
                  >
                    <img src={RemoveIcon} alt={`Remove ${section.title}`} />
                  </Button>
                </Grid>
              </Grid>
              {formValues[section.title].length > 1 && (
                <Divider sx={{ mt: 2, mb: 2 }} />
              )}
            </div>
          ))}
          <Box sx={{ ...justifyContentEnd }}>
            <Button onClick={() => handleAddRow(section.title)}>
              Add Another {section.title === "Family" ? "Member" : "Contact"}
            </Button>
          </Box>
        </Grid>
      );
    } else {
      // Render other sections using renderFields function
      return (
        <Grid item xs={12} key={section.title}>
          <Box sx={{ marginBottom: 2 }}>
            {/* Title */}
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              {section.title}
            </Typography>
            {/* Subtitle */}
            {section.subtitle && (
              <Typography variant="subtitle2">{section.subtitle}</Typography>
            )}
          </Box>
          <Grid container spacing={1}>
            {renderFields(section)}
          </Grid>
        </Grid>
      );
    }
  };

  const renderFormContent = () => {
    return (
      <Grid
        container
        spacing={1.5}
        sx={{
          overflowX: "auto",
          height: "52vh",
          width:'50%'
          // paddingBottom: "2rem",
        }}
        className="smooth-scroll"
      >
        {fields.map((section) => renderSectionFields(section))}
      </Grid>
    );
  };

  return <div>{renderFormContent()}</div>;
};

export default Step5_BPOM_ML;
