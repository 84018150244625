import React, { useEffect, useState } from "react";
import { SelectOneAutocompleteSearch } from "../../../../../components/Form";
import { field_modal } from "../../parts/imutable_state";
import { ACTION_TYPE } from "../../../../../util/function";
import ConfirmDelete from "../../../../../components/ConfirmDelete";

export default function ModalClient({ choosedClient, errors, handleChange }) {
  const [salesOrderUri, setSalesOrderUri] = useState("");
  const [disabledFied, setDisabledField] = useState(true);

  useEffect(() => {
    if (choosedClient !== 0) {
      setDisabledField(false);
      setSalesOrderUri(
        "/master/sales-orders?client_id=".concat(String(choosedClient))
      );
    } else {
      setSalesOrderUri("");
      setDisabledField(true);
    }
    // console.log(choosedClient);
  }, [choosedClient]);

  return field_modal.map((field) => (
    <React.Fragment key={field.name}>
      <SelectOneAutocompleteSearch
        errors={errors}
        disabled={field.name === "sales_order_id" ? disabledFied : false}
        url={
          field.name === "sales_order_id" ? salesOrderUri : field.apiEndpoint
        }
        name={field.name}
        title={field.label}
        handleInputChange={handleChange}
      />
    </React.Fragment>
  ));
}
