import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import {
  Tooltip,
  Grid,
  Divider,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  IconButton,
  Popover,
  Paper,
  Autocomplete,
  ButtonGroup,
} from "@mui/material";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../../assets/search.svg";
import SearchIconBlack from "./../../../assets/search-black.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  borderRadius,
  thirdColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  justifyContentEnd,
  justifyContentBetween,
  warningSurfaceColor,
  successSurfaceColor,
  warningMainColor,
  successMainColor,
  dangerMainColor,
  dangerSurfaceColor,
  infoSurfaceColor,
  infoMainColor,
  btnGreen,
  btnGray,
  btnLightBlue,
  justifyContentCenter,
  mainColor,
  alignItemsCenter,
  inputDate,
  CustomOpenPickerButton,
  secondColor,
  neutral70,
  FacebookCircularProgress,
} from "../../../util/style";
import exportIcon from "./../../../assets/export.png";
import axios, { headersAPIToken, urlRequest } from "../../../core/axios_config";
import Form, {
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
  TimePickerInput,
  DatePickerInput,
} from "../../../components/Form";
import {
  detailPerformaInvoice,
  editPerformaInvoice,
  getDetailForEditProformaInvoice,
  setEditId,
} from "./clients/action";
import {
  ACTION_TYPE,
  SALES_ORDER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  saveResponseToFile,
  pxToMm,
} from "../../../util/function";
import DataTable from "../../../components/Table";
import ConfirmDelete from "../../../components/ConfirmDelete";
import ModalConfirmCancel from "../../../components/ConfirmCancel";
import { RequestLoading } from "../../../util/global_state";
import ArrowBackIcon from "./../../../assets/back.svg";
import ZoomInIcon from "./../../../assets/zoom-in.png";
import successIMG from "./../../../assets/success.png";
import PrinterIcon from "./../../../assets/printer.png";
import PrinterGrayIcon from "./../../../assets/printer-gray.png";
import DownloadGrayIcon from "./../../../assets/download-gray.png";
import ZoomOutIcon from "./../../../assets/zoom-out.png";
import logo from "./../../../logo.png";
import boderImg from "./../../../assets/border.png";
// import { paymentStatusColor } from "./parts/CusomStyle";
import RenderBottomAction from "./components/detail/BottomAction";
import DocumentDetail from "./components/detail/DocumentDetail";
import DocumentPreview from "./components/detail/DocumentPreview";
import DocumentForPrint from "./components/detail/DocumentForPrint";
import FormDetail from "./components/FormDetail";
import LeftDrawer from "../../../components/LeftDrawer";
import RenderFormContent from "./components/RenderFormContent";
import { fieldDrawerArray, fieldDrawerForEach } from "./parts/imutable_state";
import useLocalStorage from "./hooks/useLocalStorage";

//* Main Function
function DetailProformaInvoice() {
  //* State Reducer
  const {
    data: detailProforma,
    isLoading,
    for_edit,
    message,
  } = useSelector((state) => state.financeProformaInvoiceDetail);

  const statusValidToPrintPDF = ["approved"];

  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#e1e1e1",
      overflow: "hidden",
      position: "relative !important",
    },
    logo: {
      position: "absolute",
      width: "100%",
    },
    header: {
      padding: "20px",
      backgroundColor: "#ffffff",
      borderRadius: "10px 10px 0 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    content: {
      padding: "20px",
      backgroundColor: "#ffffff",
    },
    footer: {
      padding: "20px",
      backgroundColor: "#09345C",
      borderRadius: "0 0 10px 10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));

  const [showModal, setShowModal] = useState(false);

  const [searchParams, setUrlSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [title, setTitle] = useState("Detail Proforma Invoice");
  const documentPreviewForPrintRef = useRef(null);
  const [
    isDoneFetchHeightDocumentPreview,
    setIsDoneFetchHeightDocumentPreview,
  ] = useState(true);

  const [height, setHeight] = useState(0);
  //* Detail Document
  //! Dummy data
  const [detailDocument, setDetailDocument] = useState({
    product: {
      status: "Approved",
      no_proforma: "12782",
      create_at: "12/24/2023",
      no_reference: "123321",
      service_category: "Patent",
      created_by: "Json miranti",
      currency: "Rp",
      sub_service: [
        { name: "Patent Pecahan", id: 2, price: 2_000_000 },
        { name: "Patent Sederhana", id: 4, price: 4_000_000 },
      ],
      price: 12323121,
      discount: 2_000_000,
      vat: 50_000,
      total: 2536273,
      description:
        "Lorem ipsum dolor sit amet consectetur. Auctor dolor pellentesque viverra enim posuere elementum iaculis a",
      remark: "Microsoft Office",
    },
    bank: {
      acount_name: "eric",
      acount_address: "Jl. Gatot",
      acount_no: 1232321,
      bank_name: "Bank XYZ",
      bank_address: "Jalan Sudirman",
      payment_receive: 99999999,
      payment_conversion_receive: 990099,
    },
    client: {
      bill_to: "Andre",
      attn: "Avon product",
      bill_address: "Jl. Tomang No. 123 Jakarta 456",
    },
    Accounting: "Markus jhonson",
  });

  //*  ================================================================= *//
  //*                            FORM SECTION                            *//
  //*  ================================================================= *//

  const { id: detailId } = useParams();

  const [idDetail, setIdDetail] = useState(detailId);
  const [selectedId, setSelectedId] = useState(0);
  // const formRef = useRef();

  const { token, user } = useSelector((state) => state.auth);

  useEffect(() => {
    setSelectedId(idDetail);
  }, [idDetail]);

  useEffect(() => {
    if (selectedId && !isNaN(selectedId)) {
      dispatch(detailPerformaInvoice(token, idDetail));
    }
  }, [selectedId]);

  useEffect(() => {
    if (detailProforma !== null) {
      setDetailDocument(setPayload(detailProforma));
    }
    // console.log(detailProforma);
  }, [detailProforma]);

  const setPayload = (detail) => {
    const newPayload = {
      product: {},
      bank: {},
      client: {},
    };

    //* ======================= Product ========================
    newPayload["product"]["status"] = detail["Product"]["status"];
    newPayload["product"]["no_proforma"] =
      detail["Product"]["No. Proforma Invoice"];
    newPayload["product"]["create_at"] = detail["Product"]["Created At"];
    newPayload["product"]["no_reference"] = detail["Product"]["No Reference"];
    newPayload["product"]["service_category"] =
      detail["Product"]["Service Category"];
    newPayload["product"]["currency"] = detail["Product"]["status"];
    newPayload["product"]["sub_service"] =
      detail["Product"]["Sub Category Service"];
    newPayload["product"]["price"] = detail["Product"]["Price ( 0 service)"];
    newPayload["product"]["discount"] = detail["Product"]["Discount"];
    newPayload["product"]["vat"] = detail["Product"]["VAT"];
    newPayload["product"]["total"] = detail["Product"]["Total"];
    newPayload["product"]["description"] = detail["Product"]["Description"];
    newPayload["product"]["remark"] = detail["Client"]["Remark"];

    //* ======================== Bank =============================
    newPayload["bank"]["acount_name"] = detail["Bank Transfer"]["Account Name"];
    newPayload["bank"]["acount_address"] =
      detail["Bank Transfer"]["Account address"];
    newPayload["bank"]["acount_no"] = detail["Bank Transfer"]["Name of Bank"];
    newPayload["bank"]["bank_name"] = detail["Bank Transfer"]["Account No"];
    newPayload["bank"]["bank_address"] =
      detail["Bank Transfer"]["Bank address"];
    newPayload["bank"]["payment_receive"] =
      detail["Bank Transfer"]["Payment Received"];
    newPayload["bank"]["payment_conversion_receive"] =
      detail["Bank Transfer"]["Payment Conversion Received"];

    //* ======================== Client =============================
    newPayload["client"]["bill_to"] = detail["Client"]["Bill To"];
    newPayload["client"]["attn"] = detail["Client"]["Attn"];
    newPayload["client"]["bill_address"] = detail["Client"]["Bill Address"];

    //* ======================== Other =============================
    newPayload["Accounting"] = detail["Client"]["Accounting"];
    newPayload["approval"] = detail["Others"]["approval"];
    newPayload["note"] = detail["Others"]["notes"];
    newPayload["canDownload"] = detail["canDownload"];
    newPayload["canAction"] = detail["canAction"];

    //TODO: need key
    newPayload["product"]["price_key"] =
      detail["Product"]["Price ( 0 service)"];
    newPayload["product"]["created_by"] = detail["Product"]["not"];

    return newPayload;
  };

  //*  ================================================================= *//
  //*                            HANDLER SECTION                         *//
  //*  ================================================================= *//
  const handleBackBtn = () => {
    // dispatch(clearDetailPaymentRequest());
    navigate(-1);
  };

  const getSalesOrderOption = async (clientId) => {
    // sales_order_id
    try {
      const response = await axios.get(
        "/master/sales-orders?client_id=".concat(clientId),
        headersAPIToken(token)
      );
      if (response.status === 200 && response.data.status === 200) {
      }
    } catch (error) {
      console.error(`Failed get option for sales-order : ${error.message}`);
    }
  };

  const handleEditBtn = () => {
    //TODO: Need customize, set dynamic id;
    dispatch(setEditId(selectedId));
    navigate(`/finance/proforma-invoice?edit=${selectedId}`);
  };

  // const handleActionDetail = (typeAction) => {
  //   setAction(typeAction);
  //   setShowModal(true);
  // };

  //*  ================================================================= *//
  //*                            COMPONENT SECTION                       *//
  //*  ================================================================= *//

  const handleActionBtn = () => {
    // set action depend on status document;
    // getSalesOrderOption();
    setShowModal(true);
    dispatch(getDetailForEditProformaInvoice(token, selectedId));
  };

  // Function to set height if the ref is initialized
  const setHeightIfRefExists = () => {
    if (documentPreviewForPrintRef?.current) {
      setHeight(documentPreviewForPrintRef.current.clientHeight);
    }
  };

  const printContent = () => {
    // Implement the print logic
    window.print();
  };

  const handleDownloadPdf = (e) => {
    e.preventDefault();
    window.open(
      process.env.REACT_APP_API_URL.concat(
        "/api/proforma-invoices/",
        selectedId,
        "/download-pdf"
      ),
      "_blank"
    );
  };

  const renderActionButtonModal = () => (
    <>
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          color={"primary"}
          variant="contained"
          disableElevation
          type="submit"
        >
          Approve
        </Button>
      </Box>
    </>
  );

  // Check if the ref exists and then set the height
  useEffect(() => {
    if (!isLoading && detailProforma !== null) {
      setTimeout(() => {
        console.log("timeout");
        setHeightIfRefExists();
        setIsDoneFetchHeightDocumentPreview(true);
      }, 2000);
    }
  }, [isLoading, detailProforma, documentPreviewForPrintRef]); // Dependency array to watch for changes in the ref

  //*  ================================================================= *//
  //*                                   WATCHER                          *//
  //*  ================================================================= *//

  /** ===================================================[ APPROVAL ]==================================================== */
  const { detail_for_edit } = useSelector(
    (state) => state.financePerformaInvoice
  );

  const ACTION_APPROVE = Object.freeze({
    APPROVE: "Approve",
    RECEIVE: "Receive",
    DEFAULT: "Save",
  });

  const [formValues, setFormValues] = useState({});
  const [action, setAction] = useState("");
  const [errors, setErrors] = useState({});
  const [actionType, setActionType] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [canConvert, setCanConvert] = useState(false);
  const [paymentReceive, setPaymentReceive] = useState(false);
  const [options, setOptions] = useState({});
  const [priceInformation, setPriceInformation] = useState({});

  const [isLoadedForm, setIsLoadedForm] = useState(false);

  const [LS_VAL, setLS_VAL] = useLocalStorage("proforma-invoice");
  /** -------------------------------------- [ HANDLER ] --------------------------------------- */
  const handleInputChange = (
    /** @type {import("react").SyntheticEvent} */ e
  ) => {
    const { name, checked } = e.target;
    let { value } = e.target;

    /** -------------------------- [ Payment Receive] ----------------------- */
    if (name === "payment_received" && formValues["payment_currency"]) {
      const currency = formValues["payment_currency"];
      value = currency.concat(". ", formatToCurrency(value));
    } else if (name === "payment_received" && !formValues["payment_currency"]) {
      value = formatToCurrency(value);
    }

    /** --------------------- [ Payment Receive Convert ] ------------------- */
    if (name === "payment_received_convert") {
      value = "Rp. ".concat(formatToCurrency(value));
    }

    if (name === "payment_currency") {
      if (value !== "" && value !== SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE) {
        setPaymentReceive(true);
        const copyForm = { ...formValues };
        if (value) {
          copyForm["payment_received"] = value.concat(
            ". ",
            formatToCurrency(copyForm["payment_received"])
          );
          // setCanConvert(true);
          setPaymentReceive(true);
        } else {
          copyForm["payment_received"] = formatToCurrency(
            copyForm["payment_received"]
          );
          setCanConvert(false);
          setPaymentReceive(false);
        }
        setFormValues(copyForm);
      } else {
        setPaymentReceive(false);
      }
    }

    if (name === "convert_payment" && checked !== undefined) {
      setCanConvert(checked);
      let paymentConvertKey = "payment_received_convert";
      if (checked) {
        //* If convert checked and has value before. get value from local storage.
        if (
          LS_VAL.hasOwnProperty(paymentConvertKey) &&
          LS_VAL[paymentConvertKey] !== null
        ) {
          const valuePaymentConvert = "Rp. ".concat(
            formatToCurrency(LS_VAL[paymentConvertKey])
          );
          setFormValues((prev) => ({
            ...prev,
            [paymentConvertKey]: valuePaymentConvert,
          }));
          setLS_VAL((prev) => ({ ...prev, [paymentConvertKey]: null }));
        }
      } else {
        //* If convert no checked remove value from formValues and set to localStorage
        setLS_VAL((prev) => ({
          ...prev,
          [paymentConvertKey]: currencyToNum(formValues[paymentConvertKey]),
        }));
        setFormValues((prev) => ({ ...prev, [paymentConvertKey]: null }));
      }
    }

    setFormValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleInputBlur = (e) => {
    const { name } = e.target;
    let field = null;
    fieldDrawerForEach((fiel) => {
      if (fiel.name === name) {
        field = fiel;
      }
    });
    if (field) {
      const copyError = { ...errors };
      let { name, required, label, defaultValue } = field;
      const listExclude = [
        "payment_currency",
        "payment_received",
        "payment_received_convert",
      ];
      let valueInForm = formValues[name];
      if (listExclude.includes(name)) {
        required = false;
      }
      if (
        required &&
        (valueInForm === "" ||
          valueInForm === 0 ||
          valueInForm === undefined ||
          valueInForm === defaultValue ||
          valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE)
      ) {
        copyError[name] = "Please enter ".concat(String(label).toLowerCase());
      }
      setErrors(copyError);
    }
  };
  const handleFunction = (e) => {};

  const validateForm = () => {
    let valid = true;
    const copyError = { ...errors };
    const listField = [
      "account_name",
      "account_address",
      "bank_id",
      "bank_address_id",
      "bank_address",
      // "payment_currency",
      // "payment_received",
      ,
    ];
    listField.forEach((keys) => {
      let field = null;
      fieldDrawerForEach((fiel) => {
        if (fiel.name === keys) {
          field = fiel;
        }
      });
      if (field) {
        const { label, defaultValue } = field;
        let valueInForm = formValues[keys];
        const listExclude = [
          "payment_currency",
          "payment_received",
          "payment_received_convert",
        ];
        if (
          !listExclude.includes(keys) &&
          (valueInForm === "" ||
            valueInForm === 0 ||
            valueInForm === defaultValue ||
            valueInForm === SELECT_OPTION.DEFAULT_VALUE_SELECT_ONE ||
            valueInForm === undefined)
        ) {
          copyError[keys] = "Please enter ".concat(String(label).toLowerCase());
          valid = false;
        }
      }
    });
    setErrors(copyError);
    return valid;
  };

  const handleSubmit = (
    /** @type {import("react").SyntheticEvent} */ event
  ) => {
    event.preventDefault();
    if (validateForm()) {
      const manipulated = manipulatePayload();
      // console.log("Submit", { manipulated });
      setIsLoadedForm(false);
      dispatch(editPerformaInvoice(token, manipulated, idDetail)).then(() => {
        setShowModal(false);
        setIsLoadedForm(true);
        resetForm();
        setLS_VAL({});
      });
    }
  };
  const handleCloseModal = () => {
    setConfirmModal(true);
  };

  const resetForm = () => {
    setFormValues({});
    setErrors({});
  };

  const manipulatePayload = () => {
    const newPayload = {
      status: "Approved",
      no_reference: formValues["no_reference"],
      client_id: formValues["client_id"],
      sales_order_id: formValues["sales_order_id"],
      bill_to: formValues["bill_to"],
      attend: formValues["attend"],
      bill_address: formValues["bill_address"],
      banks: {
        account_name: formValues["account_name"],
        account_address: formValues["account_address"],
        bank_address: formValues["bank_address"],
        bank_address_id: formValues["bank_address_id"],
        bank_id: formValues["bank_id"],
        // currency: formValues["payment_currency"],
        // payment_received: formValues,
        // currency_convert: formValues,
        // payment_received_convert: formValues,
      },
    };

    // newPayload["banks"]["payment_received"] = currencyToNum(
    //   formValues["payment_received"]
    // );
    // if (currencyToNum(formValues["payment_received_convert"])) {
    //   newPayload["banks"]["payment_received_convert"] = currencyToNum(
    //     formValues["payment_received_convert"]
    //   );
    //   newPayload["banks"]["currency_convert"] = "Rp";
    // }
    return newPayload;
  };
  /** -------------------------------------- [ OPTIONS ] --------------------------------------- */

  useEffect(() => {
    const getOption = (endPoint, name, alias) => {
      return axios
        .get(endPoint, headersAPIToken(token))
        .then((response) => {
          if (response.status === 200 && response.data.status === true) {
            let payloadOpt = response.data.data;
            if (alias) {
              payloadOpt = payloadOpt.map((opt) => {
                opt["name"] = opt[alias];
                return opt;
              });
            }

            if (name === "bank_address_id" && payloadOpt.length < 1) {
              payloadOpt = [
                {
                  id: 1,
                  name: "[dummy]-123456789",
                },
                {
                  id: 2,
                  name: "[dummy]-987654321",
                },
              ];
            }
            setOptions((prev) => ({ ...prev, [name]: payloadOpt }));
          }
        })
        .catch((err) => {
          console.error(`Failed get option for ${name} : ${err}`);
        });
    };

    if (formValues["bank_id"]) {
      const uri = "master/bank-address?bank_id=".concat(formValues["bank_id"]);
      getOption(uri, "bank_address_id", "no_account").then(() => {
        console.log("Done get bank address id");
        // console.log(formValues);
      });
    }

    if (formValues["client_id"]) {
      const uri = "master/sales-orders?client_id=".concat(
        formValues["client_id"]
      );
      getOption(uri, "sales_order_id", "code");
    }

    fieldDrawerForEach((field) => {
      if (field.apiEndpoint) {
        const { apiEndpoint, name } = field;
        if (!options.hasOwnProperty(name)) {
          getOption(apiEndpoint, name, null);
        }
      }
    });
  }, [formValues]);

  const getSpecificOption = (endpoint, name, alias) => {
    return axios
      .get(endpoint, headersAPIToken(token))
      .then((response) => {
        if (response.status == 200 && response.data.status == true) {
          let payloadOpt = response.data.data;
          if (alias) {
            payloadOpt = payloadOpt.map((opt) => {
              opt["name"] = opt[alias];
              return opt;
            });
          }
          setOptions((prev) => ({ ...prev, [name]: payloadOpt }));
        }
      })
      .catch((err) => {
        console.error(`Failed get option for ${name} : ${err}`);
        throw err;
      });
  };

  /** -------------------------------------- [ SET VALUE ] --------------------------------------- */

  useEffect(() => {
    if (detail_for_edit !== null && detail_for_edit !== undefined) {
      setIsLoadedForm(false);
      setPayloadApproval(detail_for_edit);
    }
  }, [detail_for_edit]);

  const setPayloadApproval = async (payloadEdit) => {
    try {
      const salesOrder = await getDetailSalesOrder(payloadEdit?.sales_order_id);
      if (!salesOrder) {
        throw new Error("Failed get sales order");
      }
      const newFormValues = {};

      const salesOrderCurrency = String(salesOrder["Service"]["currency"]);
      //* Set price information
      const keysPriceInformation = [
        "discount_info_to_rp",
        "price_services_info",
        "price_services_info_to_rp",
        "total_price_info_to_rp",
        "vat_info_to_rp",
      ];
      setPriceInformation((prev) => {
        const copyVal = { ...prev };
        keysPriceInformation.forEach((key) => {
          const formatedValue = salesOrderCurrency.concat( ". ",
            formatToCurrency(salesOrder["Price Information"][key]) || "0"
          );
          copyVal[key] =
            key !== "price_services_info"
              ? formatedValue
              : salesOrder["Price Information"][key];
        });
        return copyVal;
      });
      //* price information

      const commonField = [
        "status",
        "no_reference",
        "client_id",
        "sales_order_id",
        "bill_to",
        "attend",
        "bill_address",
        "note",
        "id",
        [
          "account_name",
          "account_address",
          "bank_id",
          "bank_address_id",
          "bank_address",
        ],
      ];

      commonField.forEach((key) => {
        if (Array.isArray(key)) {
          key.forEach((k) => {
            newFormValues[k] = payloadEdit["bank"][k];
          });
        } else {
          newFormValues[key] = payloadEdit[key];
        }
      });

      /** ---------------- Other field ------------------ */
      newFormValues["remark"] = salesOrder["Note Section"]["remarks"];
      newFormValues["service_id"] = salesOrder["Service"]["service_id"];
      newFormValues["service_currency"] = salesOrder["Service"]["currency"];
      newFormValues["attachment"] = salesOrder["Attachment"]["attachment"];
      newFormValues["payment_currency"] = payloadEdit["bank"]["currency"];

      /** ---------------- Special field -----------------*/
      if (salesOrder["Sub Service"].length > 0) {
        newFormValues["sub_services"] = salesOrder["Sub Service"].map(
          (sub, index) =>
            manipulateSubService(sub, index, salesOrderCurrency)
        );
      } else {
        newFormValues["sub_services"] = [];
      }

      /**Payment receive */
      newFormValues["payment_received"] = String(
        newFormValues["payment_currency"]
      ).concat(". ", formatToCurrency(payloadEdit["bank"]["payment_received"]));
      setPaymentReceive(true);

      /** Payment convert */
      if (
        payloadEdit["bank"]["payment_received_convert"] !== null &&
        payloadEdit["bank"]["payment_received_convert"] != 0
      ) {
        newFormValues["payment_received_convert"] = String("Rp").concat(
          ". ",
          formatToCurrency(payloadEdit["bank"]["payment_received_convert"])
        );
        setCanConvert(true);
      } else {
        setCanConvert(false);
      }
      setAction(
        formValues["status"] === "Pending"
          ? ACTION_APPROVE.APPROVE
          : ACTION_APPROVE.RECEIVE
      );
      setFormValues(newFormValues);
    } catch (error) {
      console.error(`Failed set formValues : ${error}`);
    } finally {
      setIsLoadedForm(true);
    }
  };

  const getDetailSalesOrder = async (id) => {
    try {
      let result = null;
      const SO_URI = "sales-orders/".concat(id, "/detail-for-edit");
      const responsen = await axios.get(SO_URI, headersAPIToken(token));
      if (responsen.status === 200 && responsen.data.status === true) {
        result = responsen.data.data;
      }
      return result;
    } catch (error) {
      console.log(`Failed get sales order: ${error}`);
    }
  };

  const manipulateSubService = (subServices, index, currency) => {
    if (Array.isArray(subServices)) return subServices;
    const localCurrency = currency ?? "Rp";
    const newPayload = {
      sub_service_id: subServices["sub_service_id"],
      criteria_id: subServices["criteria_id"],
      discount: subServices["discount"],
      vat_to_rp: subServices["vat_to_rp"],
      vat_percent: subServices["vat_to_rp"],
      description: subServices["desc"],
    };

    if (Array.isArray(subServices["vat_percent"])) {
      newPayload["vat_percent"] = subServices["vat_percent"][0];
    } else {
      newPayload["vat_percent"] = subServices["vat_percent"];
    }

    newPayload["discount"] = String(subServices["discount"])
      .padEnd(8, " ")
      .concat("%");

    ["subtotal", "total_to_rp", "vat_to_rp"].forEach((key) => {
      newPayload[key] = String(localCurrency).concat(
        ". ",
        String(subServices[key])
      );
    });
    return newPayload;
  };

  /** -------------------------------------- [ HELPER ] --------------------------------------- */

  const formatToCurrency = (value) => {
    value = String(value);
    // Remove leading zeros, but allow a single "0"
    value = value.replace(/^0+(?=\d{0,})/, "0");

    // Use a regular expression to allow only digits
    value = value.replace(/\D/g, "");

    // Format the input value as a currency by adding commas every three digits
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // console.log("in formater after", value);
    return value;
  };

  const currencyToNum = (value) => {
    value = String(value);
    value = value.replace(/\D/g, "");

    value = value.replace("/,/g", "");

    return +value;
  };

  /** -------------------------------------- [ MODAL ] --------------------------------------- */
  const handleCloseConfirmModal = () => {
    setConfirmModal(false);
    setShowModal(false);
    resetForm();
    setAction(ACTION_APPROVE.DEFAULT);
  };
  const handleYesConfirmModal = () => {
    setConfirmModal(false);
  };
  /** -------------------------------------- [ COMPONENT ] --------------------------------------- */

  const loadingComponent = () => (
    <Box
      style={{
        height: "45vh",
        display: "flex",
        alignItems: " center",
        justifyContent: "center",
      }}
    >
      <FacebookCircularProgress />
    </Box>
  );

  const renderFormElement = () => {
    if (isLoadedForm === false) {
      return loadingComponent();
    }
    return (
      <RenderFormContent
        actionType={ACTION_APPROVE.APPROVE}
        canConvert={canConvert}
        errors={errors}
        formValues={formValues}
        showPaymanetReceive={paymentReceive}
        handleAddFile={handleFunction}
        handleAddSubservice={handleFunction}
        handleInputBlur={handleInputBlur}
        handleInputChange={handleInputChange}
        handleRemoveFile={handleFunction}
        handleRemoveSubservice={handleFunction}
        onFileSelect={handleFunction}
        invoiceType={null}
        options={options}
        priceInformation={priceInformation}
      />
    );
  };

  // useEffect(() => {
  //   console.log("this is islaoding get detail", detailDocument?.canAction);
  // }, [detailDocument]);

  useEffect(() => {
    console.log("This is formValues", formValues);
  }, [formValues]);

  useEffect(() => {
    console.log("Price", priceInformation);
  }, [priceInformation]);

  return (
    <>
      {/* /* -------------------------------------------------------------------------- */
      /*                                  DATATABLE                                 */
      /* -------------------------------------------------------------------------- */}
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "81vh",
          position: "relative",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <Box sx={{ fontSize: "1.9rem" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  variant="outlined"
                  onClick={handleBackBtn}
                  sx={{ marginRight: ".5rem" }}
                >
                  <img src={ArrowBackIcon} />
                </IconButton>
                {title}
              </Box>
            </div>
          }
        />
        <Divider />
        <Box
          flex="1"
          sx={{
            overflow: "auto",
            maxHeight: "62vh",
          }}
        >
          {isLoading ? (
            <div
              style={{
                height: "45vh",
                display: "flex",
                alignItems: " center",
                justifyContent: "center",
              }}
            >
              <FacebookCircularProgress />
            </div>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                {/* ForPrinting */}
                <DocumentForPrint
                  detailDocument={detailDocument}
                  useStyles={useStyles}
                  height={height}
                />
              </Grid>
              <Grid item xs={5.2} style={{ height: "67vh" }}>
                {/* <Detail/> */}
                <DocumentDetail detailDocument={detailDocument} />
              </Grid>
              <Grid item xs={6.8}>
                {/* PDF Preview*/}
                <DocumentPreview
                  detailDocument={detailDocument}
                  isDoneFetchHeightDocumentPreview={
                    isDoneFetchHeightDocumentPreview
                  }
                  useStyles={useStyles}
                  documentPreviewForPrintRef={documentPreviewForPrintRef}
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <style>
          {`
      .page-break {
        page-break-before: always;
      }
      .DocumentPreview{
        display:none;
      }
      .DocumentPreviewFooter{
        padding: 20px;
        background-color: #09345C;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      @media print {
//           @page {
//               /* size: A4; */
//               /* margin: 200mm; */
//               /* Adjust margins as needed */
//           }

        @page {
            // size: A4;
//               size: 210mm 280mm;
          size: 210mm ${pxToMm(height)}mm; /* A4 size */
            margin: 0;
            /* Remove all margins */
        }

        body {
            margin: 0;
        }

        body *{
          visibility:hidden;
        }
.DocumentPreviewFooter{
 position:fixed;
bottom:0;
left:0;
width:100%;
border-radius:unset;
}
        .DocumentPreviewFooter, .DocumentPreviewFooter *{
            visibility: visible;
padding: 10px 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        print-color-adjust: exact;
        webkit-print-color-adjust: exact; /* For WebKit-based browsers */
      background-color:  #09345C !important; /* Override background color */
      }

        .DocumentPreview{
          display:block;
          position: fixed !important;
          top: 0;import { INPUT_TYPE } from './../../../util/function';


          left: 0;
          width: 100%;
          z-index: 11999;
          visibility: visible;
          page-break-before: always;
        }

        .DocumentPreview,.DocumentPreview *{
            visibility: visible;
        }
      }

      `}
        </style>
        <Box>
          {!isLoading && (
            <RenderBottomAction
              handleAction={handleActionBtn}
              handleEdit={handleEditBtn}
              handlePrintBtn={printContent}
              status={detailDocument.product.status}
              // canAction={true}
              canAction={
                detailDocument?.canAction &&
                detailDocument?.product?.status === "Pending"
              }
              canDownload={detailDocument?.canDownload}
              documentId={selectedId}
              handleDownload={handleDownloadPdf}
            />
          )}
        </Box>
      </Card>
      <LeftDrawer open={showModal}>
        <Box>
          <Form
            title={"Approval Proforma Invoice"}
            onCloseModal={handleCloseModal}
            elementForm={renderFormElement}
            elementActionButton={renderActionButtonModal}
            onSubmit={handleSubmit}
            workingWithDrawer={true}
          />
        </Box>
      </LeftDrawer>
      <ModalConfirmCancel
        confirmModal={confirmModal}
        onCloseModal={handleCloseConfirmModal}
        onConfirmModal={handleYesConfirmModal}
        message={"You want to cancel the process approval proforma invoice"}
      />
    </>
  );
}

export default DetailProformaInvoice;
