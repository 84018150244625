import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Grid,
  Button,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  TextField,
  Modal,
  Box,
  InputLabel,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  Select,
  Autocomplete,
  Checkbox,
  IconButton,
  FormHelperText,
  Divider,
  CardActions,
  Tabs,
  Tab,
  Chip,
} from "@mui/material";
import { Stepper, Step, StepLabel, Container, Paper } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SearchIcon from "./../../assets/search.svg";
import CalendarIcon from "./../../assets/calendar.png";
import SearchIconBlack from "./../../assets/search-black.svg";
// import RemoveIcon from "@mui/icons-material/Remove";
import RemoveIcon from "./../../assets/minus.svg";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "./../../assets/back.svg";
import { AccessRestrictedAlert } from "../../components/Alert";

import {
  borderRadius,
  thirdColor,
  secondColor,
  btnWhite,
  formGroup,
  StatusSwitch,
  StatusBadge,
  defaultStylePage,
  FacebookCircularProgress,
  removeButton,
  justifyContentEnd,
  tabStyles,
} from "../../util/style";
import axios from "../../core/axios_config";
import Form, {
  CheckboxGroupInput,
  DatePickerInput,
  RadioGroupInput,
  SelectMultiple,
  SelectMultipleAutocomplete,
  SelectOne,
  SelectOneAutocomplete,
  TextInput,
  TextArea,
} from "../../components/Form";
import {
  addEmployee,
  deleteEmployee,
  editEmployee,
  getEmployee,
} from "./clients/actions";
import {
  ACTION_TYPE,
  USER_ACCESS as _,
  findMenuItemByLink,
  INPUT_TYPE,
  SELECT_OPTION,
  TabPanel,
} from "../../util/function";
import DataTable from "../../components/Table";
import ConfirmDelete from "../../components/ConfirmDelete";
import ModalConfirmCancel from "../../components/ConfirmCancel";
import { modalActionButton } from "../../util/style";
import { useNavigate, useParams } from "react-router-dom";
import Step1 from "./edit/step1";
import Step2 from "./edit/step2";
import Step3 from "./edit/step3";
import Step4 from "./edit/step4";

const EditEmployee = () => {
  /* -------------------------------------------------------------------------- */
  /*                           USER HAVE PERMISSION ???                          */
  /* -------------------------------------------------------------------------- */
  const [title, setTitle] = useState("Edit Employee");
  const [actionType, setActionType] = useState();
  const [currentPath, setCurrentPath] = useState("");
  const [isFetchLoading, setIsFetchLoading] = useState(true);

  // Create an array of data (e.g., binary or text data)
  const dataArray = ["Hello, World!"];
  // Create a Blob object from the array of data
  const blob = new Blob(dataArray, { type: "application/pdf" });
  // Create a File object with the Blob and a filename
  const file1 = new File([blob], "example1.pdf", { type: "application/pdf" });
  const file2 = new File([blob], "example2.pdf", { type: "application/pdf" });

  const [formData, setFormData] = useState({
    StepOne: {
      "Personal Data": {
        employe_name: "a",
        birthdate: "2023-08-24T17:00:00.000Z",
        birthplace: "bogor",
        gender: "female",
        religion_id: 1,
        marital_status_id: 1,
        blood_id: 3,
      },
      "Contact Information": {
        email: "muhamadsobari198@gmail.com",
        mobile_phone: "08912832",
        phone: "0898123817",
      },
      "Identity & Address": {
        identity_type: "ktp",
        identity_number: "0898123832832",
        identity_expiry: "2023-08-24T17:00:00.000Z",
        postal_code: "092382382",
        is_permanent: [1],
        citizen_id_address: "Citizen ID Address",
        use_as_residential_address: [1],
        residential_address: "Residential Address",
      },
      Family: [
        {
          id: 1,
          name: "family 1 ",
          relation_id: 1,
          birthdate: "2023-08-09T17:00:00.000Z",
          gender: "male",
          religion_id: 1,
          marital_status_id: 1,
          identity_number: "909238282",
          job_id: 1,
        },
        {
          id: 2,
          name: "family 2",
          relation_id: 2,
          birthdate: "2023-08-17T17:00:00.000Z",
          gender: "female",
          religion_id: 1,
          marital_status_id: 2,
          identity_number: "9292382",
          job_id: 5,
        },
      ],
      "Emergency Contact": [
        {
          id: 1,
          name: "Emergency Contac 1",
          relation_id: 2,
          phone: "0898123817",
        },
        {
          id: 2,
          name: "Emergency Contact 2",
          relation_id: 3,
          phone: "0898123817",
        },
      ],
    },
    StepTwo: {
      Employment: {
        company_name: "PT. Pacific Patent Multiglobal",
        brench_id: 1,
        organization_id: 2,
        position_id: 1,
        job_level_id: 1,
        employe_status_id: 1,
        join_date: "2023-08-24T17:00:00.000Z",
        sign_date: "2023-08-30T17:00:00.000Z",
        end_date: "2023-08-29T17:00:00.000Z",
        approval_id: 41,
      },
      Benefit: {
        allowance: "90000",
        meal_allowance: "23230303",
      },
    },
    StepThree: {
      "Formal Education": [
        {
          id: 1,
          grade_id: 1,
          major: "Majors1",
          institution_name: "asd",
          score: "100",
          start_year: "2021-12-31T17:00:00.000Z",
          end_year: "2026-04-30T17:00:00.000Z",
          certificates: [
            {
              id: 1,
              filename: "Screenshot_2021-11-23_21-18-06.png",
              size: "1161482",
              url: "http://localhost:4100/storage/certificates/OHp0NP5dyeYknvHblVJcFy24R2AzVhABdWkvUnWP.png",
            },
          ],
        },
        {
          id: 2,
          grade_id: 3,
          major: "Majors2",
          institution_name: "Institution Name asd",
          score: "0123",
          start_year: "2023-07-31T17:00:00.000Z",
          end_year: "2031-11-30T17:00:00.000Z",
          certificates: [
            {
              id: 2,
              filename: "_1682586953795.pdf",
              size: "1061482",
              url: "http://localhost:4100/storage/certificates/OHp0NP5dyeYknvHblVJcFy24R2AzVhABdWkvUnWP.png",
            },
          ],
        },
      ],
      "Informal Education": [
        {
          id: 1,
          name: "oi123123",
          organize_by: "Organized By",
          fee: "Fee",
          start_date: "2025-12-31T17:00:00.000Z",
          end_date: "2027-11-30T17:00:00.000Z",
          certificates: [],
        },
      ],
      "Work Experience": [
        {
          id: 1,
          company_name: "Company Name1",
          job_position: "Job Position2",
          start_date: "2022-04-30T17:00:00.000Z",
          end_date: "2030-10-31T17:00:00.000Z",
        },
        {
          id: 2,
          company_name: "Company Name2",
          job_position: "Job Position3",
          start_date: "2016-12-31T17:00:00.000Z",
          end_date: "2023-10-31T17:00:00.000Z",
        },
      ],
    },
    StepFour: {
      "Supporting Attachments": {
        bank_id: 2,
        bank_account: "test",
        bank_account_holder: "test",
        bpjs_employment: "asd12312323",
        bpjs_health: "kasd1231293824928",
      },
      "Tax Config": {
        npwp: "asu1282371239237248",
        ptkp_status_id: 4,
        employe_tax_status: "permanent",
        tax_method_id: 3,
      },
    },
  });
  const [updateFormData, setUpdateFormData] = useState({});

  const updateWizardData = (stepName, stepData) => {
    // debugger;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [stepName]: stepData,
    }));
  };

  const [userAccess, setUserAccess] = useState([
    _.CREATE,
    _.READ,
    _.UPDATE,
    _.DELETE,
  ]);
  const { menus } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.employees);

  useEffect(() => {
    // Get the current path when the component mounts
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  useEffect(() => {
    if (menus.length > 0) {
      // Find the menu item with the matching pathname
      const menuItem = findMenuItemByLink(menus, currentPath);

      if (menuItem) {
        // Filter permissions with has_access set to true
        const filteredPermissions = menuItem.permission.filter(
          (permission) => permission.has_access
        );

        // Extract the permission actions and update userAccess state
        const accessNames = filteredPermissions.map((permission) => {
          const action = permission.name.split("_").slice(-1)[0]; // Extract the action part
          return action.toUpperCase(); // Convert to uppercase
        });
        setUserAccess(accessNames);
      }
    }
  }, [currentPath, menus]);

  /* ------------------- Check if user has CREATE permission ------------------ */

  const hasEditPermission = userAccess.includes(_.EDIT);

  const formRef = useRef(null);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isNextTriggered, setIsNextTriggered] = useState({ isRun: "NOT" });

  const { id, type } = useParams();

  useEffect(() => {
    const tabsActive = [
      "Personal Information",
      "Employment",
      "Education & Experience",
      "Other Information",
    ].indexOf(type);

    setValue(tabsActive);
    // debugger;

    const fetchEmployeeData = async () => {
      setIsFetchLoading(true);
      if (id) {
        try {
          const response = await axios.get(`/employes/${id}/edit`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // debugger;
          setIsFetchLoading(false);
          setFormData(response.data.data);
          setUpdateFormData(response.data.data);
        } catch (error) {
          console.error("Error fetching employee data:", error);
        }
      }
    };

    fetchEmployeeData();
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = (e, { stepName, stepData }) => {
    // debugger;
    // updateWizardData(stepName, stepData);

    setUpdateFormData((prevUptFormData) => ({
      ...prevUptFormData,
      [stepName]: stepData,
    }));
    setIsNextTriggered({ isRun: "NOT" });
  };

  const handleChangeStates = ({ stepName, stepData }) => {
    setUpdateFormData((prevUptFormData) => ({
      ...prevUptFormData,
      [stepName]: stepData,
    }));
    setIsNextTriggered({ isRun: "NOT" });
  };

  useEffect(() => {
    if (isNextTriggered.isRun === "YES") {
      dispatch(editEmployee(token, updateFormData, id));
    }

    console.log(updateFormData, "#updateFormData");
  }, [updateFormData]);

  const handleSubmitt = (e) => {
    if (e) e.preventDefault();
    setIsNextTriggered({ isRun: "YES" });
  };

  useEffect(() => {
    if (isFormValid) {
      //   dispatch(addEmployee(token, formData));
      alert("Saved");
      setIsFormValid(false);
    }
  }, [isFormValid]);

  const handleCancel = () => {
    setConfirmModal(true);
  };

  const handleOnConfirmCancelModal = () => {
    setConfirmModal(false);
  };

  const handleOnCloseConfirmCancelModal = () => {
    setConfirmModal(false);
    navigate(-1);
  };

  const renderActionButtons = () => {
    return (
      <Box>
        <Button
          variant="outlined"
          sx={{ ...btnWhite }}
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          type="submit"
          disabled
          // onClick={handleSaveButtonClick}
        >
          Save
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Card
        style={{
          marginTop: "1rem",
          boxShadow: "unset",
          borderRadius,
          border: "1px solid #ddd",
          height: "85vh",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.4rem",
                fontWeight: 800,
                paddingLeft: "1rem",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                variant="outlined"
                onClick={() => navigate(-1)}
                sx={{ marginRight: ".5rem" }}
              >
                <img src={ArrowBackIcon} />
              </IconButton>
              {title}
            </div>
          }
        />
        <Divider />
        {isFetchLoading ? (
          <FacebookCircularProgress />
        ) : (
          <form ref={formRef} onSubmit={handleSubmitt}>
            <CardContent sx={{}}>
              <Box
                sx={{
                  ...tabStyles,
                  paddingBottom: "1rem",
                  marginLeft: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Chip
                  label="Personal Information"
                  className={value === 0 ? "activeChip" : "inactiveChip"}
                  onClick={() => setValue(0)}
                  color={value === 0 ? "primary" : "default"}
                />
                <Chip
                  label="Employment"
                  className={value === 1 ? "activeChip" : "inactiveChip"}
                  onClick={() => setValue(1)}
                  color={value === 1 ? "primary" : "default"}
                />
                <Chip
                  label="Education & Experience"
                  className={value === 2 ? "activeChip" : "inactiveChip"}
                  onClick={() => setValue(2)}
                  color={value === 2 ? "primary" : "default"}
                />
                <Chip
                  label="Other Information"
                  className={value === 3 ? "activeChip" : "inactiveChip"}
                  onClick={() => setValue(3)}
                  color={value === 3 ? "primary" : "default"}
                />
              </Box>
              {isLoading ? (
                <div
                  style={{
                    height: "45vh",
                    display: "flex",
                    alignItems: " center",
                    justifyContent: "center",
                  }}
                >
                  <FacebookCircularProgress />
                </div>
              ) : (
                <>
                  <TabPanel value={value} index={0}>
                    <Step1
                      handleSubmit={handleSubmit}
                      formData={
                        updateFormData["StepOne"] || formData["StepOne"]
                      }
                      isNextTriggered={isNextTriggered}
                      setIsNextTriggered={setIsNextTriggered}
                      handleChangeStates={handleChangeStates}
                      indexTabsActive={value}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Step2
                      handleSubmit={handleSubmit}
                      formData={
                        updateFormData["StepTwo"] || formData["StepTwo"]
                      }
                      isNextTriggered={isNextTriggered}
                      setIsNextTriggered={setIsNextTriggered}
                      handleChangeStates={handleChangeStates}
                      indexTabsActive={value}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Step3
                      handleSubmit={handleSubmit}
                      formData={
                        updateFormData["StepThree"] || formData["StepThree"]
                      }
                      isNextTriggered={isNextTriggered}
                      setIsNextTriggered={setIsNextTriggered}
                      handleChangeStates={handleChangeStates}
                      indexTabsActive={value}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Step4
                      handleSubmit={handleSubmit}
                      formData={
                        updateFormData["StepFour"] || formData["StepFour"]
                      }
                      isNextTriggered={isNextTriggered}
                      setIsNextTriggered={setIsNextTriggered}
                      handleChangeStates={handleChangeStates}
                      indexTabsActive={value}
                    />
                  </TabPanel>
                </>
              )}
            </CardContent>
            <CardActions
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: "100%",
                justifyContent: "flex-end",
                background: "white",
                padding: "20px",
              }}
            >
              {renderActionButtons()}
            </CardActions>
          </form>
        )}
      </Card>
    </>
  );
};

export default EditEmployee;
