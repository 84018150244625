import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../../util/global_state";
import {
  GET_ROOM_NAME_SUCCESS,
  GET_ROOM_NAME_FAILED,
  ADD_ROOM_NAME_SUCCESS,
  ADD_ROOM_NAME_FAILED,
  EDIT_ROOM_NAME_SUCCESS,
  EDIT_ROOM_NAME_FAILED,
  DELETE_ROOM_NAME_FAILED,
  DELETE_ROOM_NAME_SUCCESS,
} from "./state";
import { logout } from "../../../../auth/clients/login/actions";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
} from "../../../../../util/function";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getRoomName = ({
  token,
  page = 1,
  limit = 10,
  searchValue = null,
  searchBy = null,
  statusFilter = null,
  sortDirection = null,
  sortBy = null,
}) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: "room",
          page,
          limit,
          searchValue,
          searchBy,
          status: statusFilter == ACTION_TYPE.ALL_STATUS ? null : statusFilter,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200 && response.data.status === true) {
          dispatch(getRoomNameSuccess(response.data.data, response.data));
        } else if (response.status === 200 && response.data.status === false) {
          dispatch(getRoomNameSuccess([], null));
        } else {
          dispatch(getRoomNameFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(getRoomNameFailed(error.response.data.message));
          }
        } else {
          dispatch(getRoomNameFailed(error.message));
        }
      });
  };
};

const getRoomNameSuccess = (data, pagination) => {
  return {
    type: GET_ROOM_NAME_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
          page: pagination.page,
          limit: pagination.limit,
          totalData: pagination.total_data,
          totalPage: pagination.total_page,
        },
  };
};

const getRoomNameFailed = (errorMessage) => {
  return {
    type: GET_ROOM_NAME_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addRoomName = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .post(`room`, Object.assign({}, formData), headersAPIToken(token))
      .then((response) => {
        if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === true)
        ) {
          dispatch(RunModal("SAVED", "Room Name"));
          dispatch(addRoomNameSuccess(response.data.message));
        } else if (
          response.status === 201 ||
          (response.status === 200 && response.data.status === false)
        ) {
          dispatch(addRoomNameFailed(response.data.message));
        } else {
          dispatch(addRoomNameFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(addRoomNameFailed(error.response.data.message));
          }
        } else {
          dispatch(addRoomNameFailed(error.message));
        }
      });
  };
};

const addRoomNameSuccess = (message) => {
  return {
    type: ADD_ROOM_NAME_SUCCESS,
    payload: message,
  };
};

const addRoomNameFailed = (message) => {
  return {
    type: ADD_ROOM_NAME_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editRoomName = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .patch(
        `room/${id}`,
        Object.assign({}, formData),
        headersAPIToken(token)
      )
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("EDITED", "Room Name"));
          dispatch(editRoomNameSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(editRoomNameFailed(response.data.message));
        } else {
          dispatch(editRoomNameFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(editRoomNameFailed(error.response.data.message));
          }
        } else {
          dispatch(editRoomNameFailed(error.message));
        }
      });
  };
};

const editRoomNameSuccess = (message) => {
  return {
    type: EDIT_ROOM_NAME_SUCCESS,
    payload: message,
  };
};

const editRoomNameFailed = (message) => {
  return {
    type: EDIT_ROOM_NAME_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

export const deleteRoomName = (token, formData, toParams) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    const params = toParams ? "/" + formData.data.ids.join(",") : "";
    axios
      .delete(`room${params}`, { ...formData, ...headersAPIToken(token) })
      .then((response) => {
        if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === true
        ) {
          dispatch(RunModal("DELETED", "Room Name"));
          dispatch(deleteRoomNameSuccess(response.data.message));
        } else if (
          (response.status === 200 || response.status === 201) &&
          response.data.status === false
        ) {
          dispatch(deleteRoomNameFailed(response.data.message));
        } else {
          dispatch(deleteRoomNameFailed(response.data.message));
        }
      })
      .catch((error) => {
        if (error.response !== undefined && error.response.data !== undefined) {
          if (
            error.response.status === 401
          ) {
            dispatch(logout());
          } else {
            dispatch(deleteRoomNameFailed(error.response.data.message));
          }
        } else {
          dispatch(deleteRoomNameFailed(error.message));
        }
      });
  };
};

const deleteRoomNameSuccess = (message) => {
  return {
    type: DELETE_ROOM_NAME_SUCCESS,
    payload: message,
  };
};

const deleteRoomNameFailed = (message) => {
  return {
    type: DELETE_ROOM_NAME_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};
