//@ts-check

import { REQUEST_LOADING } from "../../../../util/global_state";
import {
  ADD_PERFORMA_INVOICE_FAIL,
  ADD_PERFORMA_INVOICE_SUCCESS,
  CLEAR_OPTION_KEY,
  DELETE_PERFORMA_INVOICE_FAIL,
  DELETE_PERFORMA_INVOICE_SUCCESS,
  EDIT_PERFORMA_INVOICE_FAIL,
  EDIT_PERFORMA_INVOICE_SUCCESS,
  GET_DETAIL_PERFORMA_INVOICE_FAIL,
  GET_PERFORMA_INVOICE_FAIL,
  GET_PERFORMA_INVOICE_SUCCESS,
  SET_ID_EDITED_DOCUMENT,
  SET_OPTION_KEY,
  GET_DETAIL_FOR_EDIT_SUCCESS,
  GET_DETAIL_FOR_EDIT_FAIL,
} from "./state";

const intiialState = {
  data: [],
  pagination: null,
  message: null,
  isLoading: false,
  response_status: false,
  detail_proforma_invoice: null,
  detail_for_edit: null,
  edited_id: null,
  optionsKey: null,
};

const performaInvoiceReducer = (
  state = intiialState,
  { type, payload, pagination }
) => {
  switch (type) {
    case REQUEST_LOADING:
      return {
        ...state,
        message: null,
        isLoading: true,
        response_status: false,
      };
    case GET_PERFORMA_INVOICE_SUCCESS:
      return {
        ...state,
        data: payload,
        pagination: pagination,
        message: null,
        isLoading: false,
      };
    case ADD_PERFORMA_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: payload,
        response_status: true,
      };
    case SET_ID_EDITED_DOCUMENT:
      return {
        ...state,
        edited_id: payload,
      };
    case SET_OPTION_KEY:
      let optionCopy = [];
      if (state.optionsKey !== null) {
        optionCopy = [...state.optionsKey];
      }
      optionCopy.push(payload);
      return {
        ...state,
        optionsKey: optionCopy,
      };
    case GET_DETAIL_FOR_EDIT_SUCCESS:
      // console.log("masuk detaul fgor edit", payload);
      return {
        ...state,
        detail_for_edit: payload,
        isLoading: false,
      };
    case CLEAR_OPTION_KEY:
      return {
        ...state,
        optionsKey: null,
      };
    case EDIT_PERFORMA_INVOICE_SUCCESS:
    case DELETE_PERFORMA_INVOICE_SUCCESS:
    case GET_PERFORMA_INVOICE_FAIL:
    case EDIT_PERFORMA_INVOICE_FAIL:
    case DELETE_PERFORMA_INVOICE_FAIL:
    case ADD_PERFORMA_INVOICE_FAIL:
    case GET_DETAIL_PERFORMA_INVOICE_FAIL:
    case GET_DETAIL_FOR_EDIT_FAIL:
      return {
        ...state,
        isLoading: false,
        message: payload,
      };
    default:
      return state;
  }
};

export default performaInvoiceReducer;
