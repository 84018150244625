//@ts-check

import axios, {
  headersAPIToken,
  urlRequest,
} from "../../../../core/axios_config";
import { RequestLoading, RunModal } from "../../../../util/global_state";
import {
  ADD_INVOICE_FAILED,
  ADD_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILED,
  DELETE_INVOICE_SUCCESS,
  DETAIL_INVOICE_FAILED,
  DETAIL_INVOICE_SUCCESS,
  EDIT_INVOICE_FAILED,
  EDIT_INVOICE_SUCCESS,
  GET_INVOICE_FAILED,
  GET_INVOICE_SUCCESS,
  GET_PAYLOAD_FORM_FAILED,
  GET_PAYLOAD_FORM_SUCCESS,
  SET_EDIT_ID,
} from "./state";
import {
  ACTION_TYPE,
  formatNetworkErrorMessage,
  SELECT_OPTION,
} from "../../../../util/function";
import { logout } from "../../../auth/clients/login/actions";
import { AxiosError } from "axios";

/* -------------------------------------------------------------------------- */
/*                                   HELPER                                   */
/* -------------------------------------------------------------------------- */

/**
 * @typedef {import("@reduxjs/toolkit").Dispatch} Dispatch
 * @typedef {'GET'|'POST'|'EDIT'|'DELETE'|'DETAIL'} TypeMethod
 */

function modalRuner(
  /**@type {TypeMethod} */ type,
  /**@type {import("@reduxjs/toolkit").Dispatch} */ dispatch
) {
  let message = "Invoice";
  console.log(type);
  switch (type) {
    case "POST":
      dispatch(RunModal("SAVED", message));
      break;
    case "EDIT":
      dispatch(RunModal("EDITED", message));
      break;
    case "DELETE":
      dispatch(RunModal("DELETED", message));
      break;
  }
}

/**
 *
 * @param {import("axios").AxiosResponse} response
 * @param {Dispatch} dispatch
 * @param {Function} successHandler
 * @param {Function} failHanlder
 * @param {TypeMethod} type
 */
function responseHandler(
  response,
  dispatch,
  successHandler,
  failHanlder,
  type
) {
  if (
    response.status === 201 ||
    (response.status === 200 && response.data.status === true)
  ) {
    modalRuner(type, dispatch);
    if (type === "GET") {
      console.log("GET TYPE");
      dispatch(successHandler(response.data.data, response.data));
    } else if (type === "DETAIL") {
      dispatch(successHandler(response.data.data));
    } else {
      console.log("OTHER TYPE", { type });
      dispatch(successHandler(response.data.message));
    }
  } else if (response.status === 200 && response.data.status === false) {
    dispatch(successHandler([], null));
  } else {
    dispatch(failHanlder(response.data.message));
  }
}

/**
 *
 * @param {AxiosError} errorResponse
 * @param {Dispatch} dispatch
 * @param {Function} errorHandler
 */
function failHandler(errorResponse, dispatch, errorHandler) {
  let messageError = "Failed";
  if (
    errorResponse.response !== undefined &&
    errorResponse.response.data !== undefined
  ) {
    if (errorResponse.response.status === 401) {
      dispatch(logout());
    } else {
      messageError = errorResponse.response.data.message;
      dispatch(errorHandler(errorResponse.response.data.message));
    }
  } else {
    messageError = formatNetworkErrorMessage(errorResponse.message);
    dispatch(errorHandler(messageError));
  }
  dispatch(RunModal("FAILED", messageError));
}

const headerTokenMultypart = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type":
        "multipart/form-data; boundary=<calculated when request is sent>",
    },
  };
};

const invoiceUrl = "invoices";

/* -------------------------------------------------------------------------- */
/*                                     GET                                    */
/* -------------------------------------------------------------------------- */

export const getInvoice = ({
  token,
  searchBy,
  page = 1,
  limit = 10,
  search = null,
  status = null,
  category = null,
  sortDirection = null,
  sortBy = null,
}) => {
  /**@param {Dispatch} dispatch */
  return (dispatch) => {
    dispatch(RequestLoading());
    axios
      .get(
        urlRequest({
          url: invoiceUrl,
          page,
          limit,
          search: null,
          searchBy: search === "" ? null : searchBy,
          searchValue: search,
          categoryId:
            category == ACTION_TYPE.DEFAULT_STATUS ||
            category === ACTION_TYPE.ALL_STATUS
              ? null
              : category,
          status:
            status == ACTION_TYPE.ALL_STATUS ||
            status === ACTION_TYPE.DEFAULT_STATUS
              ? null
              : status,
          sortDirection,
          sortBy,
        }),
        headersAPIToken(token)
      )
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          getInvoiceSuccess,
          getInvoiceFailed,
          "GET"
        );
      })
      .catch((error) => {
        failHandler(error, dispatch, getInvoiceFailed);
      });
  };
};

const getInvoiceSuccess = (data, pagination) => {
  return {
    type: GET_INVOICE_SUCCESS,
    payload: data,
    pagination:
      pagination === null
        ? null
        : {
            page: pagination.page,
            limit: pagination.limit,
            totalData: pagination.total_data,
            totalPage: pagination.total_page,
          },
  };
};

const getInvoiceFailed = (errorMessage) => {
  return {
    type: GET_INVOICE_FAILED,
    payload: formatNetworkErrorMessage(errorMessage),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    ADD                                     */
/* -------------------------------------------------------------------------- */

export const addInvoice = (token, formData) => {
  return (dispatch) => {
    dispatch(RequestLoading());
    return axios
      .post(invoiceUrl, formData, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          addInvoiceSuccess,
          addInvoiceFailed,
          "POST"
        );
        console.log("Done post");
      })
      .catch((error) => {
        const err = error;
        failHandler(error, dispatch, addInvoiceFailed);
      });
  };
};

const addInvoiceSuccess = (message) => {
  return {
    type: ADD_INVOICE_SUCCESS,
    payload: message,
  };
};

const addInvoiceFailed = (message) => {
  console.log("Sini");
  return {
    type: ADD_INVOICE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

export const editInvoice = (token, formData, id) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    axios
      .patch(invoiceUrl.concat("/", id), formData, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          editInvoiceSuccess,
          editInvoiceFailed,
          "EDIT"
        );
      })
      .catch((error) => {
        failHandler(error, dispatch, editInvoiceFailed);
      });
  };
};

const editInvoiceSuccess = (message) => {
  return {
    type: EDIT_INVOICE_SUCCESS,
    payload: message,
  };
};

const editInvoiceFailed = (message) => {
  return {
    type: EDIT_INVOICE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                                    DELETE                                  */
/* -------------------------------------------------------------------------- */

/**
 *
 * @param {string} token
 * @param {Array<string>} ids
 * @returns
 */
export const deleteInvoice = (token, ids) => {
  return async (dispatch) => {
    dispatch(RequestLoading());
    //* Join the id from array payload;
    let deleteUrl = invoiceUrl.concat("/", ids.join(","));
    axios
      .delete(deleteUrl, headersAPIToken(token))
      .then((response) => {
        responseHandler(
          response,
          dispatch,
          deleteInvoiceSuccess,
          deleteInvoiceFailed,
          "DELETE"
        );
      })
      .catch((error) => failHandler(error, dispatch, deleteInvoiceFailed));
  };
};

const deleteInvoiceSuccess = (message) => {
  return {
    type: DELETE_INVOICE_SUCCESS,
    payload: message,
  };
};

const deleteInvoiceFailed = (message) => {
  return {
    type: DELETE_INVOICE_FAILED,
    payload: formatNetworkErrorMessage(message),
  };
};

/* -------------------------------------------------------------------------- */
/*                              GET DATA FOR FORM                             */
/* -------------------------------------------------------------------------- */

export const getDataFormAdd = (token, payload) => {
  return async function (dispatch) {
    try {
      dispatch(RequestLoading());
      let urlParams = "";
      const { sales_order_id, proforma_invoice_id } = payload;

      if (proforma_invoice_id) {
        urlParams = urlParams.concat(
          "proforma-invoices/",
          proforma_invoice_id,
          "/edit"
        );
      } else if (sales_order_id) {
        let explodeId = sales_order_id.split("/")[0];
        urlParams = urlParams.concat(
          "sales-orders/",
          explodeId,
          "/detail-for-edit"
        );
      }

      const response = await axios.get(urlParams, headersAPIToken(token));
      responseHandler(
        response,
        dispatch,
        getPayloadAddFormSuccess,
        getPayloadAddFormFailed,
        "DETAIL"
      );
    } catch (error) {
      failHandler(error, dispatch, getPayloadAddFormFailed);
    }
  };
};

const getPayloadAddFormSuccess = (payload) => ({
  type: GET_PAYLOAD_FORM_SUCCESS,
  payload: payload,
});

const getPayloadAddFormFailed = (message) => ({
  type: GET_PAYLOAD_FORM_FAILED,
  payload: formatNetworkErrorMessage(message),
});

/* -------------------------------------------------------------------------- */
/*                                    DETAIL                                  */
/* -------------------------------------------------------------------------- */

export const getDetailInvoice = (token, id) => {
  return async (dispacth) => {
    try {
      dispacth(RequestLoading());
      let localUrl = invoiceUrl.concat("/", id);
      const response = await axios.get(localUrl, headersAPIToken(token));
      responseHandler(
        response,
        dispacth,
        getDetailInvoiceSuccess,
        getDetailInvoiceFailed,
        "DETAIL"
      );
    } catch (error) {
      failHandler(error, dispacth, getDetailInvoiceFailed);
    }
  };
};

const getDetailInvoiceSuccess = (payload) => ({
  type: DETAIL_INVOICE_SUCCESS,
  payload: payload,
});

const getDetailInvoiceFailed = (message) => ({
  type: DETAIL_INVOICE_FAILED,
  payload: message,
});

export const setEditId = (payload) => {
  return {
    type: SET_EDIT_ID,
    payload: payload,
  };
};
