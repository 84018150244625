export const GET_BOOKING_SUBMISSION_SUCCESS = "GET_BOOKING_SUBMISSION_SUCCESS";
export const GET_BOOKING_SUBMISSION_FAILED = "GET_BOOKING_SUBMISSION_FAILED";
export const ADD_BOOKING_SUBMISSION_SUCCESS = "ADD_BOOKING_SUBMISSION_SUCCESS";
export const ADD_BOOKING_SUBMISSION_FAILED = "ADD_BOOKING_SUBMISSION_FAILED";
export const EDIT_BOOKING_SUBMISSION_SUCCESS = "EDIT_BOOKING_SUBMISSION_SUCCESS";
export const EDIT_BOOKING_SUBMISSION_FAILED = "EDIT_BOOKING_SUBMISSION_FAILED";
export const DELETE_BOOKING_SUBMISSION_SUCCESS = "DELETE_BOOKING_SUBMISSION_SUCCESS";
export const DELETE_BOOKING_SUBMISSION_FAILED = "DELETE_BOOKING_SUBMISSION_FAILED";
export const CANCELED_BOOKING_SUBMISSION_SUCCESS = "CANCELED_BOOKING_SUBMISSION_SUCCESS";
export const CANCELED_BOOKING_SUBMISSION_FAILED = "CANCELED_BOOKING_SUBMISSION_FAILED";
