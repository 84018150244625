import {
  IconButton,
  Modal,
  Box,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import {
  modal,
  modalHeader,
  modalActionButton,
  btnWhite,
  confirmDelete,
  secondColor,
  fourColor,
} from "../util/style";
import successIMG from "./../assets/success.png";
import failedIMG from "./../assets/failed.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CloseModal } from "../util/global_state";

const NotificationSuccess = () => {
  const dispatch = useDispatch();

  const {
    modal: { isActive, title, subtitle },
  } = useSelector((state) => state.global);

  // useEffect(() => {
  //   if (isActive === true) {
  //     setTimeout(() => {
  //       dispatch(CloseModal());
  //     }, 1200);
  //   }
  // }, [isActive]);

  return (
    <Modal open={isActive}>
      <Box
        sx={{
          ...modal,
        }}
      >
        <Box style={{ ...confirmDelete, padding: "30px 0" }}>
          <img src={title === "Failed!" ? failedIMG : successIMG} />
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ fontWeight: "700" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: fourColor, fontStyle: "italic", fontWeight: "400", marginBottom: "1.5rem" }}
          >
            {subtitle}
          </Typography>
          <Button
            color={title === "Failed!" ? "error" : "success"}
            variant="outlined"
            disableElevation
            onClick={() => { dispatch(CloseModal()); }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NotificationSuccess;
