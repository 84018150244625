import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api",
  timeout: 0,
});

export const headersAPIToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
export const urlRequest = ({
  url,
  page,
  limit,
  search,
  searchValue = null,
  searchBy = null,
  status,
  sortDirection,
  sortBy,
  year = null,
  position = null,
  type = null,
  typeStage = null,
  dateFilter = null,
  filterDate = null,
  categoryFilter = null,
  startDate = null,
  endDate = null,
  action = null,
  categoryTimeOff = null,
  timeOffCategoryId = null,
  leaveCategory = null,
  userId = null,
  period = null,
  typeBenefit = null,
  hasAccessOnly = null,
  category = null,
  categoryId = null,
  fromDate = null,
  toDate = null,
}) => {
  if (
    page !== undefined &&
    page !== null &&
    page > 0 &&
    limit !== undefined &&
    limit !== null &&
    limit > 0
  ) {
    let requestUrl = `${url}/?page=${page}&limit=${limit}`;

    if (sortDirection !== undefined && sortDirection !== null) {
      requestUrl += `&sortDirection=${sortDirection}`;
    }

    if (sortBy !== undefined && sortBy !== null && sortBy !== "") {
      requestUrl += `&sortBy=${sortBy}`;
    }

    if (status !== undefined && status !== null && status !== "") {
      requestUrl += `&status=${status}`;
    }

    if (search !== undefined && search !== null && search !== "") {
      requestUrl += `&search=${search}`;
    }

    /* --------------------------------- BUsiness Permit --------------------------------- */


    if (searchValue !== undefined && searchValue !== null && searchValue !== "") {
      requestUrl += `&search_value=${searchValue}`;
    }

    if (searchBy !== undefined && searchBy !== null && searchBy !== "") {
      requestUrl += `&search_by=${searchBy}`;
    }

    if (categoryId !== undefined && categoryId !== null && categoryId !== "") {
      requestUrl += `&category_id=${categoryId}`;
    }




    /* --------------------------------- User Access --------------------------------- */

    if (position !== undefined && position !== null && position !== "") {
      requestUrl += `&position=${position}`;
    }

    if (
      hasAccessOnly !== undefined &&
      hasAccessOnly !== null &&
      hasAccessOnly !== ""
    ) {
      requestUrl += `&has_access_only=${hasAccessOnly}`;
    }

    /* --------------------------------- Reward --------------------------------- */
    if (year !== undefined && year !== null && year !== "") {
      requestUrl += `&year=${year}`;
    }

    if (filterDate !== undefined && filterDate !== null && filterDate !== "") {
      requestUrl += `&filter_date=${filterDate}`;
    }

    /* --------------------------------- Stages --------------------------------- */

    if (typeStage !== undefined && typeStage !== null && typeStage !== "") {
      requestUrl += `&type=${typeStage}`;
    }

    /* ---------------------------- HR - Setting - Time Off ---------------------- */

    if (
      categoryTimeOff !== undefined &&
      categoryTimeOff !== null &&
      categoryTimeOff !== ""
    ) {
      requestUrl += `&category=${categoryTimeOff}`;
    }

    /* ---------------------------- HR - Setting - Time Off Request ---------------------- */

    if (
      timeOffCategoryId !== undefined &&
      timeOffCategoryId !== null &&
      timeOffCategoryId !== ""
    ) {
      requestUrl += `&time_off_category_id=${timeOffCategoryId}`;
    }

    /* ---------------------------- HR - Setting - Leave---------------------- */

    if (
      leaveCategory !== undefined &&
      leaveCategory !== null &&
      leaveCategory !== ""
    ) {
      requestUrl += `&leave_category=${leaveCategory}`;
    }

    /* -------------------------------- User Logs ------------------------------- */

    if (action !== undefined && action !== null && action !== "") {
      requestUrl += `&action=${action}`;
    }

    /* -------------------------------- Detail Attendance ------------------------------- */

    if (userId !== undefined && userId !== null && userId !== "") {
      requestUrl += `&user_id=${userId}`;
    }

    /* -------------------------------- Benefit (Allowance / Meal Allowance) ------------------------------- */

    if (
      typeBenefit !== undefined &&
      typeBenefit !== null &&
      typeBenefit !== ""
    ) {
      requestUrl += `&type=${typeBenefit}`;
    }

    if (period !== undefined && period !== null && period !== "") {
      requestUrl += `&periode=${period}`;
    }

    if (dateFilter !== undefined && dateFilter !== null && dateFilter !== "") {
      requestUrl += `&date_filter=${dateFilter}`;
    }

    if (
      categoryFilter !== undefined &&
      categoryFilter !== null &&
      categoryFilter !== ""
    ) {
      requestUrl += `&filter_category=${categoryFilter}`;
    }

    if (
      startDate !== undefined &&
      startDate !== null &&
      startDate !== "" &&
      endDate !== undefined &&
      endDate !== null &&
      endDate !== ""
    ) {
      requestUrl += `&start_date=${startDate}&end_date=${endDate}`;
    }

    /* ------------------------ Human Resources - Activity----------------------- */
    if (
      fromDate !== undefined &&
      fromDate !== null &&
      fromDate !== "" &&
      toDate !== undefined &&
      toDate !== null &&
      toDate !== ""
    ) {
      requestUrl += `&from_date=${fromDate}&to_date=${toDate}`;
    }

    /* ------------------------ Sales Management - Client ----------------------- */

    if (type !== undefined && type !== null && type !== "") {
      requestUrl += `&type=${type}`;
    }

    /* ------------------------ Sales Management - SO & SQ ----------------------- */

    if (category !== undefined && category !== null && category !== "") {
      requestUrl += `&category=${category}`;
    }

    return requestUrl;
  } else if (page !== undefined && page !== null && page > 0) {
    return `${url}/?page=${page}`;
  } else if (limit !== undefined && limit !== null && limit > 0) {
    return `${url}/?limit=${limit}`;
  } else {
    return `${url}/`;
  }
};
